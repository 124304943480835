/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ListaExperiencias } from './exp/ListaExperiencias'
import { Avatar, Alert, Modal } from 'antd'
import { Col } from 'react-bootstrap'
import axios from 'axios'
import { BASE_PATH } from '../constants'
import { AuthContext } from '../../auth/AuthContext'
import { Message } from 'rsuite'
import { TabLink, TabContent, Tabs } from 'react-tabs-redux'
import { PiBinocularsBold, PiLightningBold } from 'react-icons/pi'
import AdminBoletos from '../Admin/items/boletos/AdminBoletos'
import ListaPaquetes from './ListaPaquetes'
import { useOutletContext } from 'react-router-dom'
import { Spinner } from 'componentes/commons'
import Login from 'componentes/Login/Login'

const Parati = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const { dominios } = useOutletContext()
    const [experiencias, setExperiencias] = useState(null)
    const [paquetes, setPaquetes] = useState([])
    const [categorys, setCategorys] = useState([])
    const [misgustos, setmisgustos] = useState([])
    const [alerta, setAlerta] = useState(false)
    const [invalidSesion, setinvalidSesion] = useState(undefined)
    const [showLogin, setShowLogin] = useState(false)
    const [loading, setLoading] = useState(true)
    const [defaultCategory, setdefaultCategory] = useState('Todas')

    const avatarSrc =
        user?.data?.state === 1
            ? user?.data?.user?.infoExtra
                ? user?.data?.user?.infoExtra?.picture
                    ? user?.data?.user?.infoExtra?.picture
                    : user?.data?.user?.picture
                : user?.data?.user?.picture
            : user?.data?.user?.picture

    const login = () => {
        // history.replace({ pathname: "/login" });
        // navigate('/login')
        setShowLogin(true)
    }
    const nft = () => {
        // history.replace({ pathname: "/nft" });
    }
    console.log(paquetes)
    const getPaquetes = async () => {
        try {
            let res = await axios.get(
                `${BASE_PATH}/experiences/packages/user`,
                {
                    headers: {
                        platform: dominios?.id,
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )
            setPaquetes(res.data)
        } catch (e) {
            console.error(e)
        }
    }

    const getExperiencias = async () => {
        try {
            let res = await axios.get(`${BASE_PATH}/experiences`, {
                headers: {
                    platform: dominios?.id,
                },
            })
            if (res.status === 200) {
                console.log(res)
                setExperiencias(
                    res.data.filter((experience) => !experience.isDraft)
                )
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const getCategorys = async () => {
        try {
            if (user.logged === true) {
                let result = await axios.get(`${BASE_PATH}/categories`, {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                })
                if (result.status === 200) {
                    let onlyCategoryName = result.data.rows
                        .reverse()
                        .map((item) => {
                            return {
                                value: item.category,
                                label: item.category,
                                rol: item.id,
                            }
                        })
                    onlyCategoryName.push({
                        value: 'Mis gustos',
                        label: 'Mis gustos',
                        rol: 'my',
                    })
                    onlyCategoryName.push({
                        value: 'Todas',
                        label: 'Todas',
                        rol: 'all',
                    })
                    onlyCategoryName.reverse()
                    setCategorys(onlyCategoryName)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getExpCategory = async (cats) => {
        try {
            let result = await axios.post(
                `${BASE_PATH}/experiences/by-category`,
                {
                    categories: cats,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )
            if (result.status === 200) {
                setExperiencias(result.data.rows)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const actionFilter = ({ rol, value }) => {
        setdefaultCategory(value)
        if (rol === 'all') {
            getExperiencias()
            getPaquetes()
        } else if (rol === 'my') {
            if (misgustos && misgustos.length > 0) {
                getExpCategory(misgustos)
            } else {
                getExperiencias()
                getPaquetes()
            }
        } else {
            getExpCategory([rol])
        }
    }

    const getMyGustos = async () => {
        try {
            let myGustos = await axios.get(
                `${BASE_PATH}/users/categories/${user.data.user.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (myGustos.status === 201) {
                const gustos = myGustos.data.row.categories.map((e) => e.id)

                if (gustos.length > 0) {
                    setmisgustos(gustos)
                    getExpCategory(gustos)
                } else {
                    setdefaultCategory('Todas')
                    getExperiencias()
                    getPaquetes()
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    // 0 === usuario no logeado
    // 1 === usuario logeado, sesion y token validos
    // 2 === usuar io logeado, sesion y token expirados
    const handleValidateToken = async () => {
        let token = user?.data?.token
        if (token !== undefined) {
            axios
                .post(`${BASE_PATH}/users/verifyTokenJwt`, { token })
                .then((resp) => {
                    if (
                        resp.status === 200 &&
                        resp.data.msg === 'Token valid'
                    ) {
                        setinvalidSesion(false)
                    } else {
                        setinvalidSesion(true)
                    }
                })
                .catch((err) => {
                    console.error(err)
                    setinvalidSesion(true)
                })
        }
    }

    useEffect(() => {
        if (invalidSesion !== undefined && user.logged) {
            if (!invalidSesion) {
                getCategorys()
                setdefaultCategory('Mis gustos')
                getMyGustos()
            } else {
                setdefaultCategory('Todas')
                getExperiencias()
                getPaquetes()
            }
        } else if (invalidSesion === undefined && !user.logged) {
            setdefaultCategory('Todas')
            getExperiencias()
            getPaquetes()
        }
    }, [invalidSesion, dominios])

    useEffect(() => {
        handleValidateToken()

        if (sessionStorage.getItem('alerta')) {
            if (user?.data?.user) {
                if (
                    !user.data.user.alias ||
                    !user.data.user.birthday ||
                    !user.data.user.description ||
                    !user.data.user.direction ||
                    !user.data.user.gender ||
                    !user.data.user.phone
                ) {
                    setAlerta(true)
                    sessionStorage.clear()
                }
            }
        }
    }, [])

    if (loading) {
        return <Spinner />
    }

    return (
        <>
            <div>
                <div>
                    <AdminBoletos dominio={dominios} />
                </div>
                <div>
                    <Tabs className="bg_parati">
                        <div className="sticky-top exp-tabs_first {colorChange ? 'navbar colorChange' : 'navbar'}">
                            <TabLink
                                key="tablink1_key"
                                to="tab1"
                                className="exp-contenido-btn exp-active flex gap-2"
                                activeClassName="green"
                            >
                                <PiBinocularsBold className="icon_menu_20" />{' '}
                                Experiencias
                            </TabLink>
                            <TabLink
                                key="tablink2_key"
                                to="tab2"
                                className="exp-contenido-btn exp-active flex gap-2"
                                activeClassName="green"
                            >
                                <PiLightningBold className="icon_menu_20" />
                                Paquetes
                            </TabLink>
                        </div>
                        <TabContent key="tab1_key" for="tab1">
                            <div style={{ paddingTop: '1rem' }}>
                                <div>
                                    <Col>
                                        <ListaExperiencias
                                            experiencias={experiencias}
                                            categorias={categorys}
                                            filterCategory={actionFilter}
                                            gustos={misgustos}
                                            loading={loading}
                                            defaultSelect={defaultCategory}
                                        />
                                    </Col>
                                </div>
                            </div>

                            <div className="search-movil d-none d-md-block d-lg-none d-sm-block d-md-none d-block d-sm-none">
                                <nav
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        background: 'transparent',
                                    }}
                                    className="navbar-sm-more navbar-light fixed-top"
                                >
                                    {/*<img src={logo} className="logo_in2" alt="logo" />*/}
                                    <div className="avatar_sm">
                                        {user.logged === false ? (
                                            <div>
                                                {/* <TranslateButton /> */}

                                                <Link to={`/login`}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary-reserva bold"
                                                        variant="primary"
                                                    >
                                                        Ingresar
                                                    </button>
                                                </Link>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {user.logged === true && (
                                            <Link
                                                to="/configuraciones"
                                                className=" btn-primary-menu"
                                            >
                                                <Avatar
                                                    size={40}
                                                    src={avatarSrc}
                                                />
                                            </Link>
                                        )}
                                    </div>
                                </nav>
                                <ListaExperiencias
                                    experiencias={experiencias}
                                    categorias={categorys}
                                    filterCategory={actionFilter}
                                    loading={loading}
                                    gustos={misgustos}
                                    defaultSelect={defaultCategory}
                                />
                            </div>
                        </TabContent>
                        <TabContent
                            key="tab2_key"
                            for="tab2"
                            style={{ paddingTop: '4rem' }}
                        >
                            <ListaPaquetes
                                paquetes={paquetes.filter(
                                    (item) => item.experiences.length > 0
                                )}
                                dominio={dominios}
                            />
                        </TabContent>
                    </Tabs>
                </div>
                {invalidSesion && (
                    <Message
                        style={{
                            position: 'fixed',
                            margin: '20px',
                            width: '',
                            top: '3rem',
                            height: '',
                            background: 'rgb(17, 17, 17)',
                            borderColor: 'gray',
                            borderWidth: 1,
                            // color: '#FFF',
                            zIndex: '10',
                        }}
                        type="warning"
                        header="warning"
                        description={
                            <p className="bold">
                                Tu sesión ha caducado. Por favor vuelve a
                                logearte!
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary-reserva bold mt-3 "
                                        href="/login"
                                        onClick={login}
                                    >
                                        {' '}
                                        Iniciar Sesión{' '}
                                    </button>
                                </div>
                            </p>
                        }
                    />
                )}
                {alerta && (
                    <Alert
                        // onClick={() => history.replace({ pathname: "/configuraciones" })}
                        style={{ position: 'fixed', bottom: '0' }}
                        message="¡Bienvenido a nuestra #comunidadtugo!, completa tu perfil de viajero AQUÍ"
                        showIcon
                        type="warning"
                        closable
                        className="fixed-bottom bold bg-orange-gradient border-0 alert__orange"
                    />
                )}
            </div>
            <Modal
                centered
                title=""
                open={showLogin}
                onCancel={() => setShowLogin(false)}
                footer={[]}
            >
                <Login onClose={() => setShowLogin(false)} />
            </Modal>
        </>
    )
}

export default Parati
