import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Card } from 'antd';
import 'antd/dist/antd.css';

const QRScanner = () => {
  const [result, setResult] = useState('');

  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div className='d-flex justify-content-center align-items-center'>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          className='qr'/>

        <div className='qr_link bold'>Link: {result}</div>
     
    </div>
  );
};

export default QRScanner;
