// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-alert-item-content div {
    color: #575757;
}

.login-logo {
    display: block;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
  }`, "",{"version":3,"sources":["webpack://./src/componentes/Login/style.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,sBAAsB;EACxB","sourcesContent":[".rs-alert-item-content div {\n    color: #575757;\n}\n\n.login-logo {\n    display: block;\n    margin: 0 auto;\n    text-align: center;\n    vertical-align: middle;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
