import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../../assets/css/App.css'
import 'antd/dist/antd.css'
import { Progress } from 'rsuite'
//Importar Recursos
import ExperienciaNewContent from '../../Anfitrion/Experiencias/ExperienciaNewContent'
import { AuthContext } from '../../../auth/AuthContext'
import axios from 'axios'
import { BASE_PATH } from '../../constants'
import { MessageUtils } from 'utils'
import { Form } from 'antd'

const initForm = {
    name: '',
    description: '',
    start: '',
    end: '',
    extra: [],
    minQuotas: '',
    maxQuotas: '',
    direction: '',
    duration: '',
}

const ExperienciaNew = () => {
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const [current, setCurrent] = React.useState(0)
    const [formValues, setFormValues] = useState(initForm)
    const [ubicacion, setUbicacion] = useState(false)
    const [form] = Form.useForm()
    const { Circle } = Progress
    const [loader, setLoader] = useState(false)
    const [percent, setpercent] = useState(1)

    const Datos = (form, ubicacion) => {
        setFormValues(form)
        setUbicacion(ubicacion)
    }

    const CreateExperience = async () => {
        try {
            // if (!!!ubicacion) {
            //     MessageUtils.warning('Agrega la ubicación')
            //     return
            // }

            setLoader(true)
            setpercent(15)
            const resp = await axios.post(
                `${BASE_PATH}/experiences`,
                {
                    name: formValues.name,
                    price: {
                        adultPrice: 0,
                        childrenPrice: 0,
                        coupon: {
                            code: '',
                            discount: '',
                        },
                        discount: {
                            price: '',
                            people: '',
                        },
                    },
                    description: formValues.description,
                    duration: `${formValues.duration} horas`,
                    direction: formValues.direction,
                    lat: String(ubicacion.lat),
                    long: String(ubicacion.lng),
                    quotas: {
                        min: formValues.minQuotas,
                        max: formValues.maxQuotas,
                    },
                    start: formValues.start,
                    end: formValues.end,
                    extra: formValues.extra,
                    enabled: false,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 201) {
                setpercent(99)
                setTimeout(() => {
                    setLoader(false)
                    MessageUtils.success('Se creó tu experiencia!')
                    navigate(`/host/creada/${resp.data.id}`, { replace: true })
                }, 1000)
            }
        } catch (err) {
            console.error(err)
            setLoader(false)
            MessageUtils.error('Ha ocurrido un error en el servidor')
        }
    }

    const steps = [
        {
            title: '',
            content: (
                <ExperienciaNewContent
                    setNewForm={setFormValues}
                    form={form}
                    newform={formValues}
                    newubicacion={ubicacion}
                    edit={false}
                    nextContent={Datos}
                    onFinish={CreateExperience}
                />
            ),
        },
    ]

    useEffect(() => {
        if (
            !user.logged ||
            (user.data.user.role !== 'owner' &&
                user.data.user.role !== 'admin' &&
                user.data.user.role !== 'company' &&
                user.data.user.role !== 'partner')
        ) {
            navigate('/', { replace: true })
        }
    }, [user])

    return (
        <div>
            {loader && (
                <div className="progress-loader">
                    <div className="progress-loader-content">
                        <Circle
                            percent={percent}
                            strokeColor="#262DFF"
                            status={percent < 99 ? 'active' : 'success'}
                        />
                    </div>
                </div>
            )}
            <>
                <div className="steps-content">{steps[current].content}</div>
            </>
        </div>
    )
}

export default ExperienciaNew
