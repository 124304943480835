import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Panel } from 'rsuite'

import { AuthContext } from '../../../../auth/AuthContext'
import { getData, putData } from '../../../../services'


const ValidacionQR = () => {
  const { user } = useContext(AuthContext)
  const { id } = useParams()
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(true)

  console.log('id del boleto', id)

  const incioComponente = async () => {
    try {
      setLoading(true)
      const responseData = await getData({
        path: `partners/tickets/valid/${id}/VALID`,
        token: user.logged && user.data.token,
        params: {},
        value: {},
        platform: null,
      })

      console.log('respes', responseData)
      setIsValid(responseData.isValid)
      if (!responseData.isValid) {
        const responseInfo = await getData({
          path: `partners/tickets?code=${id}`,
          token: user.logged && user.data.token,
          params: {},
          value: {},
          platform: null,
        })

        console.log('aqui haria lo de validar', responseInfo)
        const putTickets = await putData({
          path: `partners/tickets`,
          token: user.logged && user.data.token,
          params: {},
          value: { statusTicket: 'VALID' },
          id: responseInfo[0]?._id,
        })
      }
      // setIsValid()
      // setTikects(responseT)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    incioComponente()
  }, [])

  return (
    <Panel header={<h2>Boleto</h2>} bordered style={{ textAlign: 'center' }}>
      {!isValid ? (
        <div>
          {/* <img src={validacionTrue} /> */}
          <p>Boleto válido.</p>
        </div>
      ) : (
        <div>
          {/* <img src={validacionFalse} /> */}
          <p>Este QR ya ha sido utilizado.</p>
        </div>
      )}
      {/* <button onClick={handleValidation}>Validar QR</button> */}
    </Panel>
  )
}

export default ValidacionQR
