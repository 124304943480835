import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import PaginationCom from '../../../pagination/PaginationCom'
import Titulo from '../Titulo'
import TablaTickets from './items/TablaTickets'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import { MessageUtils } from 'utils'

const initForm = {
    merchantOwner: null,
    ownerPrice: null,
    salesPrice: null,
    type: null,
    quantity: null,
}

const ListaTickets = () => {
    const { user, dominio } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [tickets, setTickets] = useState([])
    const [ticketsTipo, setTicketsTipo] = useState([])
    const [revendedores, setRevendedores] = useState([])
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState([])
    const [action, setAction] = useState('new')
    const [formValues, setFormValues] = useState(initForm)
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [isLoadingOnFinish, setIsLoadignOnFinish] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const role = user.data.user.role

    const inicioComponente = async () => {
        try {
            setLoading(true)
            let query =
                user.data.user.role === 'merchant'
                    ? `?merchantOwner=${user.data.user.id}`
                    : `?partnerOwner=${user.data.user.id}`

            const responseData = await getData({
                path: `partners/tickets${query}&page=${page}&limit=10&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            console.log(responseData)
            setTickets(responseData[0]?.result)
            setMeta(responseData)

            const responseRevendedor = await getData({
                path: `users/partners/merchants`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })

            setRevendedores(responseRevendedor)

            const responseTipo = await getData({
                path: `partners/tickets/types/index`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            setTicketsTipo(responseTipo)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        inicioComponente()
    }, [])

    useEffect(() => {
        inicioComponente()
    }, [page])

    const onFinish = async () => {
        try {
            setIsLoadignOnFinish(true)
            await postData({
                path: `partners/tickets`,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                    ticketType: {
                        type: formValues.type,
                        quantity: formValues.quantity,
                    },
                },
                platform: dominio.id,
            })
            setFormValues(initForm)
            form.resetFields()
            inicioComponente()
            setOpen(false)
            MessageUtils.success('Se ha creado el ticket con éxito')
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar crear un ticket'
            )
        } finally {
            setIsLoadignOnFinish(false)
        }
    }

    const updateTicket = async () => {
        setIsLoadignOnFinish(true)

        try {
            await putData({
                path: `partners/tickets`,
                id: formValues.id,
                token: user.logged && user.data.token,
                parmas: {},
                value: formValues,
            })
            MessageUtils.success('Ticket modificado con éxito')
            inicioComponente()
            onCloseDrawer()
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar un ticket'
            )
        } finally {
            setIsLoadignOnFinish(false)
        }
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setOpen(false)
        setTimeout(() => {
            setAction('new')
        }, 300)
    }

    const onEdit = (value) => {
        const merchants = revendedores
            .filter((revendedor) => revendedor._id === value.merchantOwner)
            .map((merchant) => merchant._id)

        const tickets = ticketsTipo
            .filter((ticket) => ticket._id === value.ticketType._id)
            .map((ticket) => ticket._id)
        const values = {
            ...value,
            merchantOwner: merchants,
            type: tickets,
            salesPrice: value.salesPrice,
            id: value._id,
        }
        setFormValues(values)
        form.setFieldsValue(values)
        setAction('edit')
        setOpen(true)
    }

    const editSales = async () => {
        setLoading(true)
        try {
            await putData({
                path: 'partners/tickets',
                token: user.data.token,
                params: null,
                value: { salesPrice: formValues.salesPrice },
                id: formValues.id,
            })
            inicioComponente()
            onCloseDrawer()
            MessageUtils.success('Precio editado correctamente')
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar el precio'
            )
        } finally {
            setLoading(false)
        }
    }

    const deleteTicket = async () => {
        try {
            const response = await deleteData({
                path: 'partners/tickets',
                id: formValues.id,
                token: user.logged && user.data.token,
            })

            if (response || response === '') {
                MessageUtils.success('Ticket eliminado con éxito')
                inicioComponente()
                setOpenModal(false)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar el ticket'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar el ticket'
            )
        }
    }

    const onDeleteButton = (id) => {
        setFormValues((prevState) => ({ ...prevState, id }))
        setOpenModal(true)
    }

    const onCancelModal = () => {
        setFormValues(initForm)
        setOpenModal(false)
    }

    return (
        <div>
            <Titulo
                tituloBoton={'Crear tipos'}
                button={role === 'company'}
                onAcccion={() =>
                    navigate(
                        `/company/${dominio.platform.domain}/tipos-tickets`
                    )
                }
            />
            <Titulo
                titulo={'Tickets'}
                tituloBoton={'Crear Tickets'}
                button={role === 'company'}
                onAcccion={() => setOpen(true)}
            />
            <div>
                {loading && !open ? (
                    <Spinner />
                ) : (
                    <>
                        <TablaTickets
                            data={tickets}
                            user={user}
                            onEdit={onEdit}
                            onDelete={onDeleteButton}
                            showDelete={role === 'company'}
                            showEdit={role === 'company'}
                        />
                        {tickets.length > 0 && (
                            <PaginationCom
                                page={page}
                                meta={meta}
                                setPage={(value) => setPage(value)}
                            />
                        )}
                    </>
                )}
            </div>
            <SidebarForm
                title={action === 'new' ? 'Crear Ticket' : 'Editar Ticket'}
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                form={form}
                formValues={formValues}
                onCloseDrawer={onCloseDrawer}
                open={open}
                isLoading={isLoadingOnFinish}
                setFormValues={setFormValues}
                onFinish={
                    action === 'new'
                        ? onFinish
                        : action === 'edit' && role === 'company'
                          ? updateTicket
                          : editSales
                }
                inputs={
                    role === 'company'
                        ? [
                              {
                                  label: 'Revendedor',
                                  name: 'merchantOwner',
                                  type: 'select',
                                  modeSelect: 'multiple',
                                  items: revendedores?.map((item) => {
                                      return {
                                          label: item.name,
                                          value: item._id,
                                      }
                                  }),
                                  rules: [
                                      { required: true, message: 'Revendedor' },
                                  ],
                              },
                              {
                                  label: 'Precio',
                                  name: 'ownerPrice',
                                  rules: [
                                      { required: true, message: 'Precio' },
                                  ],
                              },
                              {
                                  label: 'Precio salida',
                                  name: 'salesPrice',
                                  rules: [
                                      {
                                          required: true,
                                          message: 'Precio salida',
                                      },
                                  ],
                              },
                              ...(action === 'new'
                                  ? [
                                        {
                                            label: 'Cantidad',
                                            name: 'quantity',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Cantidad',
                                                },
                                            ],
                                        },
                                    ]
                                  : []),
                              {
                                  label: 'Tipo de ticket',
                                  name: 'type',
                                  type: 'select',
                                  modeSelect: 'multiple',
                                  items: ticketsTipo?.map((item) => {
                                      return {
                                          label: item.typeName,
                                          value: item._id,
                                      }
                                  }),
                                  rules: [
                                      {
                                          required: true,
                                          message: 'Tipo de ticket',
                                      },
                                  ],
                              },
                          ]
                        : [
                              {
                                  label: 'Precio salida',
                                  name: 'salesPrice',
                                  rules: [
                                      {
                                          required: true,
                                          message: 'Precio salida',
                                      },
                                  ],
                              },
                          ]
                }
            />
            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={deleteTicket}
                openModal={openModal}
                strongText={
                    tickets.find((item) => item._id === formValues.id)
                        ?.ticketNumber
                }
                text={`¿Estás seguro de que deseas eliminar el siguiente ticket`}
                title={'Eliminar Ticket'}
            />
        </div>
    )
}

export default ListaTickets
