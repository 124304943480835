import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './styles.css'
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    ConversationHeader,
    TypingIndicator,
    MessageSeparator,
    Avatar,
    Conversation,
    Sidebar,
    Search,
    ConversationList,
} from '@chatscope/chat-ui-kit-react'

//Importar Recursos
import lillyIco from '../../assets/images/avatar.jpg'
import joeIco from '../../assets/images/pablo.jpg'
import zoeIco from '../../assets/images/pablo.jpg'

const Chat = () => {
    // Set initial message input value to empty string
    const [messageInputValue, setMessageInputValue] = useState('')

    const navigate = useNavigate()
    const Back = () => {
        navigate('/', { replace: true })
    }

    return (
        <div>
            <div
                className="vh-100"
                style={{
                    position: 'relative',
                }}
            >
                <MainContainer responsive>
                    <Sidebar position="left" scrollable={true}>
                        <ConversationList>
                            {/* //! Porque tener un boton en el chat para ir hacia atras? */}
                            {/* <button
                style={{ marginBottom: "-2rem" }}
                type="button"
                className="back-window-navbar fixed-top"
                onClick={Back}
              >
                <i className="demo-icon-exp  icon-left-arrow"></i>
              </button> */}
                            <Search placeholder="Julio..." />
                            <Conversation
                                name="Lilly"
                                lastSenderName="Lilly"
                                info="Yes i can do it for you"
                            >
                                <Avatar
                                    src={lillyIco}
                                    name="Lilly"
                                    status="available"
                                />
                            </Conversation>

                            <Conversation
                                name="Joe"
                                lastSenderName="Joe"
                                info="Yes i can do it for you"
                            >
                                <Avatar src={joeIco} name="Joe" status="dnd" />
                            </Conversation>
                        </ConversationList>
                    </Sidebar>

                    <ChatContainer>
                        <ConversationHeader>
                            <ConversationHeader.Back />
                            <Avatar src={zoeIco} name="Zoe" />
                            <ConversationHeader.Content
                                userName="Zoe"
                                info="Active 10 mins ago"
                            />
                        </ConversationHeader>

                        <MessageList typingIndicator={<TypingIndicator />}>
                            <MessageSeparator content="Lunes, 11 Noviembre 2021" />

                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Zoe',
                                    direction: 'incoming',
                                    position: 'single',
                                }}
                            >
                                <Message.Footer sentTime="justo ahora" />
                            </Message>

                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Patrik',
                                    direction: 'outgoing',
                                    position: 'single',
                                }}
                            >
                                <Message.Footer sentTime="justo ahora" />
                            </Message>

                            <MessageSeparator content="Martes, 12 Noviembre 2021" />

                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Zoe',
                                    direction: 'incoming',
                                    position: 'last',
                                }}
                            >
                                <Message.Footer sentTime="justo ahora" />
                            </Message>

                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Zoe',
                                    direction: 'incoming',
                                    position: 'first',
                                }}
                            >
                                <Message.Footer sentTime="justo ahora" />
                            </Message>

                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Patrik',
                                    direction: 'outgoing',
                                    position: 'first',
                                }}
                            />
                            <Message.Footer sentTime="justo ahora" />
                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Patrik',
                                    direction: 'outgoing',
                                    position: 'normal',
                                }}
                            />
                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Patrik',
                                    direction: 'outgoing',
                                    position: 'normal',
                                }}
                            />
                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Patrik',
                                    direction: 'outgoing',
                                    position: 'last',
                                }}
                            />

                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Zoe',
                                    direction: 'incoming',
                                    position: 'first',
                                }}
                            />
                            <Message
                                model={{
                                    message: 'Hello my friend',
                                    sentTime: '15 mins ago',
                                    sender: 'Zoe',
                                    direction: 'incoming',
                                    position: 'last',
                                }}
                            ></Message>
                        </MessageList>

                        <MessageInput
                            className="d-flex justify-content-start"
                            placeholder="Escribe a tu anfitrión"
                            value={messageInputValue}
                            onChange={(val) => setMessageInputValue(val)}
                            onSend={() => setMessageInputValue('')}
                            sendButton={true}
                            attachButton={true}
                            fancyScroll={true}
                        />
                    </ChatContainer>
                </MainContainer>
            </div>
        </div>
    )
}

export default Chat
