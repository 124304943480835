import { Tooltip } from 'antd'
import React from 'react'
import { ButtonsActionTable } from '../../../../Buttons/ButtonsActionTable'
import { TablaAnt } from '../../../../table/TablaAnt'

const TablaPaises = ({
 data,
 onEdit
}) => {

    const columns = [
        {
            title: 'PAIS',
            key: 'name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: 'Codigo',
            key: 'cod',
            dataIndex: 'cod',
            sorter: false
        },
        { 
            title: 'Acciones', 
            key: 'action', 
            align: 'right', 
            fixed: 'right', 
            render: (_, value) => ( 
                <ButtonsActionTable 
                    onEdit={() => onEdit(value)} 
                    editMessage="Editar pais"
                /> 
            ), 
        },
    ]
  return (
    <TablaAnt 
        scrollX='max-contend'
        columns={columns}
        data={data}
        loading={false}
        isCheckCell={false}
    />
  )
}

export default TablaPaises

