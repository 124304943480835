import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { RiAddFill } from "react-icons/ri";
import axios from "axios";
import { Avatar } from "antd";
import { Button, Loader, Message } from "rsuite";

import { Modal, Icon, Alert } from "rsuite";
import { ExperienciaCard } from "./elementos/ExperienciaCard";
import { AuthContext } from "../../../auth/AuthContext";
// Importar Recursos
import logo from "../../../assets/images/desvlop.svg";

import Anfitrion from "../../Navbars/Anfitrión";
import { BASE_PATH } from "../../constants";

const TusExperiencias = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [experiencias, setExperiencias] = useState([]);
  const [loading, setloading] = useState(true);
  const [selectExp, setSelectExp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invalidSesion, setinvalidSesion] = useState(false);

  const login = () => {
    navigate("/login", { replace: true });
  };

  const showModalConfirmation = () => {
    setVisible(true);
  };
  const closeModalConfirmation = () => {
    setVisible(false);
  };

  const getExperiencias = async () => {
    try {
      let res = await axios.get(`${BASE_PATH}/experiences?user=${id}`);
      if (res.status === 200) {
        setExperiencias(res.data.rows);
        setloading(false);
      }
    } catch (e) {
      console.error(e);
      setloading(false);
    }
  };

  const New = () => {
    const { bankAccount } = user.data.user;
    const { titular, numAccount } = bankAccount;

    if (titular && numAccount) {
      navigate("/new", { replace: true });
    } else {
      Alert.info(
        "Por favor agrega datos de cuenta bancaria en la seccion de pagos",
        5000
      );
    }
  };

  const confirmationDelete = (idExp) => {
    let findExp = experiencias.find((e) => e.id === idExp);
    setSelectExp(findExp);
    showModalConfirmation();
  };

  const deleteExp = async () => {
    try {
      const validate = await validateExp();
      if (validate) {
        let result = await axios.delete(
          `${BASE_PATH}/experiences/${selectExp.id}`,
          {
            headers: {
              Authorization: `Bearer ${user.data.token}`,
            },
          },
          {
            access_token: user.data.token,
          }
        );
        //console.log(result)
        if (result.status === 204) {
          let schedules = await axios.get(
            `${BASE_PATH}/schedules?experience=${selectExp.id}`,
            {
              headers: {
                Authorization: `Bearer ${user.data.token}`,
              },
            }
          );

          //console.log(schedules);
          schedules.data.rows.forEach(async (sch) => {
            //console.log(sch);
            let deleteSch = await axios.delete(
              `${BASE_PATH}/schedules/${sch.id}`,
              {
                headers: {
                  Authorization: `Bearer ${user.data.token}`,
                },
              }
            );
            //console.log(deleteSch);
          });

          setSelectExp(false);
          getExperiencias();
          closeModalConfirmation();
          Alert.success("Experiencia eliminada con exito");
        }
      } else {
        closeModalConfirmation();
        Alert.error(
          "No se puede eliminar esta experiencia porque hay reservas pendientes",
          5000
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateExp = async () => {
    try {
      let result = await axios.get(
        `${BASE_PATH}/payments/experience/${selectExp.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      if (result.status === 201) {
        const arrCount = result.data.payments.length;
        console.log(result.data.payments);
        if (arrCount > 0) {
          return false;
        }
      }

      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const handleValidateToken = async () => {
    let token = user?.data?.token;
    if (token != undefined) {
      axios
        .post(`${BASE_PATH}/users/verifyTokenJwt`, { token })
        .then((resp) => {
          if (resp.status === 200 && resp.data.msg !== "Token valid") {
            // console.log("token invalido");
            setinvalidSesion(true);
          } else {
            // console.log("token valido");
          }
        })
        .catch((err) => {
          console.log(err);
          // console.log("token invalido");
          setinvalidSesion(true);
        });
    } else {
      console.log("no hay sesion activa");
    }
  };

  useEffect(() => {
    if (user?.logged) {
      handleValidateToken();
      getExperiencias();
    } else {
      navigate("/", { replace: true });
    }
  }, [user]);

  return (
    <div style={{ paddingBottom: 140 }}>
      <div>
        <Anfitrion />
      </div>
      <div className="search-movil d-none d-md-block d-lg-none d-sm-block d-md-none d-block d-sm-none">
        <nav
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className="navbar-sm-more navbar-light fixed-top"
        >
          <img src={logo} className="logo_in2" alt="logo" />
          <div className="avatar_sm">
            {user.logged === true ? (
              <Link to="/host/configuraciones" className="btn-primary-menu">
                <Avatar
                  size={40}
                  src={
                    user.data.state == 1
                      ? user.data.user.infoExtra
                        ? user.data.user.infoExtra.picture
                          ? user.data.user.infoExtra.picture
                          : user.data.user.picture
                        : user.data.user.picture
                      : user.data.user.picture
                  }
                />
              </Link>
            ) : (
              <></>
            )}{" "}
          </div>
        </nav>
      </div>
      <section className="container page-tusexperiencias">
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 2,
            750: 3,
            1200: 4,
          }}
        >
          <Masonry gutter={20}>
            {experiencias.map((item) => (
              <Link key={item.id}>
                <ExperienciaCard
                  dataExp={item}
                  deleteExp={confirmationDelete}
                />
              </Link>
            ))}
          </Masonry>
        </ResponsiveMasonry>
        {!loading && experiencias.length === 0 && (
          <h3 style={{ textAlign: "center" }}>
            No tienes experiencias agregadas
          </h3>
        )}

        {loading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              inverse
              center
              content="Cargando..."
              className="text-white"
              size="md"
            />
          </div>
        )}
        <div className="d-flex justify-content-center exp-fixed-upload">
          <button
            type="button"
            className="exp-fixed-bottom-open-host-upload"
            onClick={New}
          >
            <RiAddFill style={{ fontSize: 40 }} />
          </button>
        </div>
      </section>

      {selectExp != false && (
        <Modal
          backdrop="static"
          show={visible}
          onHide={closeModalConfirmation}
          size="xs"
        >
          <div className="modalConfirmacion">
            <Modal.Body>
              <Icon
                icon="remind"
                style={{
                  color: "#ffb300",
                  fontSize: 24,
                }}
              />
              Esta seguro de eliminar la experiencia {selectExp.name} ?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={deleteExp} variant="danger" size="sm">
                Confirmar
              </Button>
              <Button onClick={closeModalConfirmation} variant="dark" size="sm">
                Cancelar
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      )}

      {invalidSesion && (
        <div className="d-flex justify-content-center">
          <Message
            style={{
              position: "fixed",
              margin: "20px",
              width: "",
              top: "3rem",
              height: "",
              color: "#FFF",
              zIndex: "10",
            }}
            type="warning"
            header="warning"
            description={
              <p className="bold">
                Tu sesión ha caducado. Por favor vuelve a logearte!
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary-reserva bold mt-3 "
                    href="/login"
                    onClick={login}
                  >
                    {" "}
                    Iniciar Sesión{" "}
                  </button>
                </div>
              </p>
            }
          ></Message>
        </div>
      )}
    </div>
  );
};

export default TusExperiencias;
