import { Map, Spinner } from 'componentes/commons'

const SimpleMapA = ({ location, setLoaction }) => {
    return location ? (
        <Map location={location} setLocation={setLoaction} />
    ) : (
        <Spinner />
    )
}

export default SimpleMapA
