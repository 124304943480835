import { Button, Col, Form, Input, Row, Space, Modal, Select, Tag } from 'antd'
import { Card } from 'react-bootstrap'
import React, { useContext, useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import './style.css'
import { useNavigate } from 'react-router-dom'
import QRCode from 'qrcode'
import { TabLink, TabContent, Tabs } from 'react-tabs-redux'
import { getData, postData } from '../../../../services'
import { AuthContext } from '../../../../auth/AuthContext'
import { BASE_PATH } from '../../../constants'
import { Loader } from 'rsuite'
import Login from '../../../Login/Login'
import { PiBinocularsBold, PiLightningBold } from 'react-icons/pi'
import SkeletonImage from 'antd/lib/skeleton/Image'
import { SkeletonTheme } from 'react-loading-skeleton'
import SkeletonNode from 'antd/lib/skeleton/Node'
import SkeletonButton from 'antd/lib/skeleton/Button'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import PayTicketModal from './PayTicketModal'
const { Option } = Select

const steps = [
    {
        title: 'Selecionar Parque',
        content: 'Experiencias',
    },
    {
        title: 'Pago',
        content: 'Last-content',
    },
    {
        title: 'Codigo',
        content: 'Last-content',
    },
]

const initTravelerForm = {
    name: '',
    email: '',
    phone: '',
    country: '',
    adult: '',
    children: '',
}

const AdminBoletos = ({ dominio }) => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(0)
    const [showLogin, setShowLogin] = useState(false)
    const [parques, setParques] = useState([])
    const [tickets, setTikects] = useState([])
    const [ticketSelect, setTikectSelect] = useState([])
    const [ticketsRe, setTikectsRe] = useState([])
    const [parque, setParque] = useState(null)
    const [experiencia, setExperiencia] = useState(null)
    const [QR, setQR] = useState(null)
    const [checkout, setCheckout] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [tiposBoletos, setTiposBoletos] = useState([])
    const [domain, setDomain] = useState()
    const [showPayTicketModal, setShowPayTicketModal] = useState(false)
    const [travelerForm, setTravelerForm] = useState(initTravelerForm)
    const [form] = Form.useForm()
    const rutaDominio = window.location.pathname
    const rutaB = rutaDominio.split('/')
    const platform =
        user?.data?.user?.role === 'merchant' ||
        user?.data?.user?.role === 'reseller'
            ? domain?.id
            : dominio?.id
    const handleCantidadChange = (index, value) => {
        const updatedTiposBoletos = [...tiposBoletos]
        updatedTiposBoletos[index].cantidad = value
        setTiposBoletos(updatedTiposBoletos)
    }
    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }))
    console.log(domain)
    console.log('ticketsRe', ticketsRe)
    const incioComponente = async () => {
        try {
            setLoading(true)

            const responseData = await getData({
                path: `users/partners/ptnr/public`,
                token: null,
                params: {},
                value: {},
                platform,
            })
            //! Lo cambie para poder mostrar los parques
            // setParques(responseData.filter((el) => el.hasTicket))
            setParques(responseData.map((el) => ({ ...el, hasTicket: true })))

            if (user.logged) {
                if (user?.data?.user?.role == 'merchant') {
                    const responseData = await getData({
                        path: `partners/tickets?merchantOwner=${user.data.user?.id}`,
                        token: user.logged && user.data.token,
                        params: {},
                        value: {},
                    })
                    setTikectsRe(responseData)
                }
            }
            // setTikects(responseT)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }
    console.log(ticketsRe)
    useEffect(() => {
        incioComponente()
    }, [dominio, domain])

    const realizarPeticionPost = (objeto) => {
        const obj = {
            ownerPrice: objeto.price,
            salesPrice: objeto.price,
            merchantOwner:
                user?.data?.user?.role == 'merchant'
                    ? user?.data?.user?.id
                    : null,
            ticketType: {
                type: objeto._id,
                quantity: 1,
            },
        }

        return fetch(`${BASE_PATH}/partners/tickets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.data.token}`,
                platform: '654670f326baa0361e0277d8',
            },
            body: JSON.stringify(obj),
        })
            .then((response) => response.json())
            .then((data) => {
                setTikects((tickets) => [...tickets, data[0]])
                return data[0] // Devuelve la respuesta para ser utilizada en Promise.all
            })
            .catch((error) => {
                console.error('Error:', error)
                throw error // Lanza el error para que Promise.all lo maneje
            })
    }

    const onFinish = async (values) => {
        setLoading(true)
        const promesasPeticiones = []

        tiposBoletos.forEach((objeto) => {
            if (objeto.cantidad > 0) {
                // Crear una promesa para cada petición y agregarla al array
                const peticiones = Array.from({ length: objeto.cantidad }, () =>
                    realizarPeticionPost(objeto)
                )

                promesasPeticiones.push(...peticiones)
            }
        })

        Promise.all(promesasPeticiones)
            .then((respuestas) => {
                // Acción a realizar después de que todas las peticiones se han completado
                Promise.all(
                    respuestas.map(async (objeto) => {
                        const dataURL = await QRCode.toDataURL(
                            `${
                                window.location.protocol +
                                '//' +
                                window.location.host
                            }/validacion/${objeto.code}`
                        )
                        return {
                            ...objeto,
                            qrCode: dataURL, // Asigna aquí el valor real del código QR si es necesario
                        }
                    })
                )
                    .then(async (nuevasRespuestas) => {
                        const response = await postData({
                            path: 'payment-processing',
                            token: user.logged && user.data.token,
                            params: {},
                            value: {
                                userOwner: user.data.user.id, //solo si es usuario existente
                                commissions: {
                                    tugo: 12.81,
                                    stripe: 2.5,
                                },
                                ticket: {
                                    // "userAnonymous": {
                                    //     "name": "Stefhani",
                                    //     "email": "sgomez@hotmail.com"
                                    // },
                                    tickets: nuevasRespuestas.map((obj) => ({
                                        ticketType: obj.ticketType, //ticket tipo niños
                                        ticket: obj._id, // Generado previamente
                                        salesPrice: obj.ownerPrice,
                                        qrCode: obj.qrCode,
                                    })),
                                    totalAmount: nuevasRespuestas.reduce(
                                        (total, objeto) =>
                                            total + objeto.ownerPrice,
                                        0
                                    ),
                                },
                                bookingCode: '', //generado previamente en otra endpoint
                                paymentType: 'TICKET',
                            },
                        })
                        setTimeout(() => {
                            setLoading(false)
                            setCurrent(3)
                        }, 1000)
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error('Error en Promise.all:', error)
                    })
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error en Promise.all:', error)
            })
    }

    const onFinishR = async (values) => {
        setLoading(true)
        const response = await postData({
            path: 'payment-processing',
            token: user.logged && user.data.token,
            params: {},
            value: {
                userOwner: user.data.user.id, //solo si es usuario existente
                commissions: {
                    tugo: 12.81,
                    stripe: 2.5,
                },
                ticket: {
                    userAnonymous: {
                        // cuadndo la compra no se realiza desde la app, sino desde puntos de venta, revendedor!
                        name: values.username,
                        email: values.email,
                    },
                    tickets: ticketsRe
                        .filter((ticket) =>
                            ticketSelect.some((t) => t === ticket._id)
                        )
                        .map((item) => {
                            return {
                                ticketType: item.ticketType._id, //ticket tipo niños
                                ticket: item._id, // Generado previamente
                                salesPrice: item.salesPrice,
                                qrCode: item.qrCode,
                            }
                        }),
                    totalAmount: ticketsRe
                        .filter((ticket) =>
                            ticketSelect.some((t) => t === ticket._id)
                        )
                        .reduce((sum, ticket) => sum + ticket.salesPrice, 0),
                },
                bookingCode: '', //generado previamente en otra endpoint
                paymentType: 'TICKET',
            },
        })
        setTimeout(() => {
            setTikectSelect([])
            setLoading(false)
            setCurrent(3)
        }, 1000)
    }

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo)
    }
    console.log(tiposBoletos)
    console.log(parques)
    const selectParque = async (value) => {
        try {
            setIsLoading(true)
            const responseT = await getData({
                path: `partners/tickets/types/index`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform,
            })

            //! El id del parque no coincide con el id del tipo de ticket
            setTiposBoletos(
                responseT?.length > 0
                    ? responseT
                          .filter((el) => el.partnerOwner === value)
                          .map((it) => {
                              return {
                                  ...it,
                                  cantidad: 0,
                              }
                          })
                    : []
            )
            setParque(value)
            setCurrent(1)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getDomain = async () => {
        let domain = rutaB[1]
        if (domain === 'company') {
            domain = rutaB[2]
        }
        try {
            const response = await getData({
                path: `config/public/query?domain=${domain}`,
                token: null,
                params: {},
                value: {},
                platform: null,
            })
            console.log('respuesta de l dominio', response)
            setDomain(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (parque != null) {
            selectParque(parque)
        }
    }, [user])

    useEffect(() => {
        if (
            user?.data?.user?.role === 'merchant' ||
            user?.data?.user?.role === 'reseller'
        ) {
            getDomain()
        }
    }, [])

    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: 'https://example.com/order/123/complete',
            },
        })

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message, 'error stripe')
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }
    console.log('Traveler form: ', travelerForm)

    const onValuesChange = (changedValues, allValues) => {
        console.log('Valores actualizados:', allValues)
        setTravelerForm(allValues)
    }

    return (
        <div className="d-flex  justify-content-center align-items-center">
            {/* <form onSubmit={handleSubmit}> */}
            {/* <PaymentElement /> */}
            {/* <button disabled={!stripe}>Submit</button> */}
            {/* </form> */}
            {current === 0 && (
                <div className="container containerBoleto">
                    <h2 className="text-center green bold">
                        ¡Compra tu Boleto de entrada aquí!
                    </h2>
                    <h4 className="text-grey text-center bold">
                        Selecciona el parque que visitarás
                    </h4>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 2, 750: 2, 1200: 4 }}
                        style={{ paddingTop: '2rem' }}
                    >
                        <Masonry gutter={10}>
                            {loading ? (
                                <>
                                    <SkeletonButton size={225} />
                                    <SkeletonInput />
                                </>
                            ) : isLoading ? (
                                <div className="test bg-transparent">
                                    <Loader
                                        inverse
                                        center
                                        size="sm"
                                        className="text-white"
                                    />
                                </div>
                            ) : (
                                <>
                                    {parques?.map((item) => (
                                        <button
                                            key={item._id}
                                            className="test"
                                            onClick={async () =>
                                                selectParque(item._id)
                                            }
                                            style={{
                                                backgroundImage: `url(${item.picture})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            <div className="card-name">
                                                {item.name}
                                            </div>
                                        </button>
                                    ))}
                                </>
                            )}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            )}
            {current === 1 && (
                <div className="containerBoleto w-full flex flex-col items-center">
                    {loading ? (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                            }}
                        >
                            <Loader
                                inverse
                                center
                                size="md"
                                content="Cargando..."
                                className="text-white"
                            />
                        </div>
                    ) : (
                        <>
                            {/* Cuando se toca en un parque */}
                            {user?.data?.user?.role === 'merchant' ? (
                                <Tabs className="bg_parati">
                                    <div className="sticky-top exp-tabs_first {colorChange ? 'navbar colorChange' : 'navbar'}">
                                        <TabLink
                                            key="tablink1_key"
                                            to="tab1"
                                            className="exp-contenido-btn exp-active  flex flex-col items-center"
                                            activeClassName="green"
                                        >
                                            <PiBinocularsBold className="icon_menu_20 " />{' '}
                                            Comprar boletos
                                        </TabLink>
                                        <TabLink
                                            key="tablink2_key"
                                            to="tab2"
                                            className="exp-contenido-btn exp-active flex flex-col items-center"
                                            activeClassName="green"
                                        >
                                            <PiLightningBold className="icon_menu_20" />
                                            Vender boletos
                                        </TabLink>
                                    </div>
                                    <TabContent key="tab1_key" for="tab1">
                                        <Row
                                            className="w-full flex items-center justify-center"
                                            style={{ paddingTop: '2rem' }}
                                        >
                                            <Col
                                                lg={7}
                                                className="containerBoleto_Col"
                                            >
                                                <Card className="image04">
                                                    <Card.Img
                                                        width={100}
                                                        src={
                                                            parques.filter(
                                                                (el) =>
                                                                    el._id ===
                                                                    parque
                                                            )[0].picture
                                                        }
                                                        alt={
                                                            parques.filter(
                                                                (el) =>
                                                                    el._id ===
                                                                    parque
                                                            )[0].name
                                                        }
                                                    />
                                                </Card>
                                                <div className="card-name text-center">
                                                    {
                                                        parques.filter(
                                                            (el) =>
                                                                el._id ===
                                                                parque
                                                        )[0].name
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={12} xs={24}>
                                                <Form
                                                    form={form}
                                                    name="basic"
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValues={travelerForm}
                                                    onValuesChange={
                                                        onValuesChange
                                                    }
                                                    onFinishFailed={
                                                        onFinishFailed
                                                    }
                                                    autoComplete="off"
                                                >
                                                    <div className="grid grid-cols-2 gap-4">
                                                        {/* Form.Item para Select con multiple */}
                                                        <Form.Item
                                                            className="col-span-2" // Asegura que ocupe toda la fila
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Ingrese al menos un boleto',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                placeholder="Agregar"
                                                                style={{
                                                                    width: '100%',
                                                                }} // Ocupar todo el ancho
                                                                onSelect={(
                                                                    value
                                                                ) =>
                                                                    setTikectSelect(
                                                                        (
                                                                            prevTickets
                                                                        ) => [
                                                                            ...prevTickets,
                                                                            value,
                                                                        ]
                                                                    )
                                                                }
                                                                onDeselect={(
                                                                    value
                                                                ) => {
                                                                    const deselectedTicket =
                                                                        ticketsRe.find(
                                                                            (
                                                                                ticket
                                                                            ) =>
                                                                                ticket._id ===
                                                                                value
                                                                        )
                                                                    if (
                                                                        deselectedTicket
                                                                    ) {
                                                                        setTikectSelect(
                                                                            (
                                                                                prevTickets
                                                                            ) =>
                                                                                prevTickets.filter(
                                                                                    (
                                                                                        ticketId
                                                                                    ) =>
                                                                                        ticketId !==
                                                                                        value
                                                                                )
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {ticketsRe.map(
                                                                    (
                                                                        item,
                                                                        idx
                                                                    ) => (
                                                                        <Select.Option
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                item._id
                                                                            }
                                                                        >
                                                                            {`${item?.ticketType?.typeName} - $${item?.salesPrice}`}
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </Form.Item>

                                                        {/* Total */}
                                                        <h5 className="col-span-2">
                                                            Total: $
                                                            {ticketsRe
                                                                .filter(
                                                                    (ticket) =>
                                                                        ticketSelect.some(
                                                                            (
                                                                                t
                                                                            ) =>
                                                                                t ===
                                                                                ticket._id
                                                                        )
                                                                )
                                                                .reduce(
                                                                    (
                                                                        sum,
                                                                        ticket
                                                                    ) =>
                                                                        sum +
                                                                        ticket.salesPrice,
                                                                    0
                                                                )
                                                                .toFixed(2)}
                                                        </h5>

                                                        {/* Botones */}
                                                        <Form.Item className="col-span-2">
                                                            <div className="flex w-full gap-3">
                                                                <Button
                                                                    onClick={() =>
                                                                        prev()
                                                                    }
                                                                >
                                                                    Regresar
                                                                </Button>
                                                                {user.logged ? (
                                                                    <Button
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                    >
                                                                        Pagar
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="primary"
                                                                        onClick={() =>
                                                                            setShowLogin(
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        Login
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </TabContent>

                                    <TabContent key="tab2_key" for="tab2">
                                        <h2 className="green bold">
                                            Ingresa datos
                                        </h2>
                                        <Row>
                                            <Col
                                                lg={7}
                                                className="containerBoleto_Col"
                                            >
                                                <Card className="image04">
                                                    <Card.Img
                                                        src={
                                                            parques.filter(
                                                                (el) =>
                                                                    el._id ==
                                                                    parque
                                                            )[0].picture
                                                        }
                                                        alt={
                                                            parques.filter(
                                                                (el) =>
                                                                    el._id ==
                                                                    parque
                                                            )[0].name
                                                        }
                                                    />
                                                </Card>
                                                <div className="card-name text-center">
                                                    {
                                                        parques.filter(
                                                            (el) =>
                                                                el._id == parque
                                                        )[0].name
                                                    }
                                                </div>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form
                                                    name="basic"
                                                    labelCol={{
                                                        span: 8,
                                                    }}
                                                    wrapperCol={{
                                                        span: 16,
                                                    }}
                                                    initialValues={{
                                                        remember: true,
                                                    }}
                                                    onFinish={onFinishR}
                                                    onFinishFailed={
                                                        onFinishFailed
                                                    }
                                                    autoComplete="off"
                                                >
                                                    <div className="grid grid-cols-2">
                                                        <Form.Item
                                                            // label="Username"

                                                            name="username"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: '',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                // className="form-control"
                                                                placeholder="Nombre del Titular"
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            // label="Password"
                                                            name="email"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: '',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                // className="form-control"
                                                                placeholder="Correo Electrónico"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            // label="Password"
                                                            name="telefono"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: '',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                // className="form-control"
                                                                placeholder="Teléfono"
                                                            />
                                                        </Form.Item>

                                                        <Form.Item className="col-span-2">
                                                            <Select
                                                                mode="multiple"
                                                                maxLength={1}
                                                                // className="form-control"
                                                                placeholder="Agregar boletos"
                                                                onSelect={(
                                                                    value
                                                                ) =>
                                                                    setTikectSelect(
                                                                        (
                                                                            prevTickets
                                                                        ) => [
                                                                            ...prevTickets,
                                                                            value,
                                                                        ]
                                                                    )
                                                                }
                                                                onDeselect={(
                                                                    value
                                                                ) => {
                                                                    const deselectedTicket =
                                                                        ticketsRe.find(
                                                                            (
                                                                                ticket
                                                                            ) =>
                                                                                ticket._id ===
                                                                                value
                                                                        )

                                                                    if (
                                                                        deselectedTicket
                                                                    ) {
                                                                        setTikectSelect(
                                                                            (
                                                                                prevTickets
                                                                            ) =>
                                                                                prevTickets.filter(
                                                                                    (
                                                                                        ticketId
                                                                                    ) =>
                                                                                        ticketId !==
                                                                                        value
                                                                                )
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {ticketsRe.map(
                                                                    (
                                                                        item,
                                                                        idx
                                                                    ) => (
                                                                        <Select.Option
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                item._id
                                                                            }
                                                                        >
                                                                            {`${item?.ticketType?.typeName} - $${item?.salesPrice}`}
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                        <h5>
                                                            Total: $
                                                            {ticketsRe
                                                                .filter(
                                                                    (ticket) =>
                                                                        ticketSelect.some(
                                                                            (
                                                                                t
                                                                            ) =>
                                                                                t ===
                                                                                ticket._id
                                                                        )
                                                                )
                                                                .reduce(
                                                                    (
                                                                        sum,
                                                                        ticket
                                                                    ) =>
                                                                        sum +
                                                                        ticket.salesPrice,
                                                                    0
                                                                )
                                                                .toFixed(2)}
                                                        </h5>
                                                        <Form.Item>
                                                            {user.logged ? (
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    // className="bold btn_stepsP"
                                                                >
                                                                    Vender
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        setShowLogin(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="bold btn_stepsP"
                                                                >
                                                                    Login
                                                                </Button>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </TabContent>
                                </Tabs>
                            ) : (
                                <>
                                    <h2 className="green bold">
                                        Ingresa tus Datos
                                    </h2>
                                    <Row
                                        className="w-full flex items-center justify-center"
                                        style={{ paddingTop: '2rem' }}
                                    >
                                        <Col
                                            lg={7}
                                            className="containerBoleto_Col"
                                        >
                                            <Card className="image04">
                                                <Card.Img
                                                    src={
                                                        parques.filter(
                                                            (el) =>
                                                                el._id ===
                                                                parque
                                                        )[0].picture
                                                    }
                                                    alt={
                                                        parques.filter(
                                                            (el) =>
                                                                el._id ===
                                                                parque
                                                        )[0].name
                                                    }
                                                />
                                            </Card>
                                            <div className="card-name text-center">
                                                {
                                                    parques.filter(
                                                        (el) =>
                                                            el._id === parque
                                                    )[0].name
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            {/* Formulario de compra ticket viajero */}
                                            <Form
                                                form={form}
                                                name="basic"
                                                labelCol={{
                                                    span: 8,
                                                }}
                                                wrapperCol={{
                                                    span: 16,
                                                }}
                                                initialValues={travelerForm}
                                                onValuesChange={onValuesChange}
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                                autoComplete="off"
                                            >
                                                <div className="grid grid-cols-2">
                                                    <Form.Item
                                                        name="name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Ingrese un nombre',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control"
                                                            placeholder="Nombre del Titular"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Ingrese un email',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control"
                                                            placeholder="Correo Electrónico"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="phone"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Ingrese un telefono',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control"
                                                            placeholder="Teléfono"
                                                            type="number"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="country"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Ingrese un pais',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control"
                                                            placeholder="País"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="adult"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Ingrese al menos una cantidad para un adulto',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="form-control"
                                                            placeholder="Adulto"
                                                            type="number"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="children">
                                                        <Input
                                                            className="form-control"
                                                            placeholder="Niño"
                                                            type="number"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item className="col-span-2">
                                                        <div className="flex w-full gap-3">
                                                            <Button
                                                                onClick={() =>
                                                                    prev()
                                                                }
                                                            >
                                                                Regresar
                                                            </Button>
                                                            {user.logged ? (
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                >
                                                                    Pagar
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        setShowLogin(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    Login
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
            {current === 3 && (
                <div className="">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col md={24} className="test">
                            <div className="card-name">
                                <h5>Boletos comprados con exito</h5>
                            </div>
                            <Button
                                type="primary"
                                style={{
                                    background: '#8EC715',
                                    color: '#000000',
                                    borderRadius: '10px',
                                    border: '1px solid #000',
                                }}
                                onClick={() =>
                                    navigate(`/${rutaB[1]}/planes`, {
                                        replace: true,
                                    })
                                }
                            >
                                Boletos
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}

            <Modal
                title="Login"
                open={showLogin}
                onCancel={() => setShowLogin(false)}
                footer={[
                    <Button key="back" onClick={() => setShowLogin(false)}>
                        Cerrar
                    </Button>,
                ]}
            >
                <Login
                    onSuccess={() => {
                        setShowLogin(false)
                        incioComponente()
                    }}
                    modal={true}
                />
            </Modal>

            {showPayTicketModal && <PayTicketModal />}
        </div>
    )
}

export default AdminBoletos
