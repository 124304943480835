import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Explora from '../componentes/Explora/Explora'
import ExperienciaGaleria from '../componentes/Experiencias/ExperienciaGaleria'
import VR from '../componentes/Experiencias/vr'
import Experiencia from '../componentes/Experiencias/Experiencia'
import ExperienciaHeader from '../componentes/Experiencias/ExperienciaHeader'
import ExperienciaDetails from '../componentes/Experiencias/ExperienciaDetails'
import ExperienciaPagar from '../componentes/Experiencias/ExperienciaPagar'
import Login from '../componentes/Login/Login'
import LoginCode from '../componentes/Login/LoginCode'
import LoginSocial from '../componentes/Login/LoginSocial'
import CreateUser from '../componentes/Login/CreateUser'
import Register from '../componentes/Login/Register'
import Maps from '../componentes/Experiencias/maps'
import ChatOpen from '../componentes/Chat/ChatOpen'
import ConfigViajero from '../componentes/Configuraciones/Configuraciones'
import ExperienciaOpen from '../componentes/Experiencias/ExperienciaOpen'
import ExperienciaReservada from '../componentes/Experiencias/ExperienciaReservada'
import TusExperiencias from '../componentes/Anfitrion/Experiencias/TusExperiencias'
import ExperienciaHost from '../componentes/Anfitrion/Experiencias/Experiencia'
import Pagos from '../componentes/Anfitrion/Pagos/Pagos'
import Calendario from '../componentes/Anfitrion/Calendario/Calendario'
import ExperienciaNew from '../componentes/Anfitrion/Experiencias/ExperienciaNew'
import { ExperienciaEditar } from '../componentes/Anfitrion/Experiencias/ExperienciaEditar'
import ChatA from '../componentes/Anfitrion/Chat/Chat'
import ExperienciaCreada from '../componentes/Anfitrion/Experiencias/ExperienciaCreada'
import Calendar from '../componentes/Experiencias/Calendar'
import Verify from '../componentes/VerifyEmail/Verify'
import IsVerify from '../componentes/Verify/IsVerify'
import Tutorial from '../componentes/Experiencias/tutorial'
import LandingPage from '../componentes/Info/Landing'
import ForgotPassword from '../componentes/ForgotPassword/ForgotPassword'
import NotFound from '../componentes/404'
import Schedules from '../componentes/Anfitrion/Experiencias/schedules'
import NFT from '../componentes/NFT/NFT'
import AdminIndex from '../componentes/Admin/AdminIndex'
import ViajeroIndex from '../componentes/Experiencias/ViajeroIndex'
import { AuthContext } from '../auth/AuthContext'
import Home from '../componentes/Home'
import AdminRoutes from './AdminRoutes'
import CompanyRoutes from './CompanyRoutes'
import TravelerRoutes from './TravelerRoutes'
import ConfigurationRoutes from './ConfigurationRoutes'
import ConfigurationLayout from './layout/ConfigurationLayout'
import Package from 'componentes/Admin/items/paquetes/Package'
import UploadPackage from 'componentes/Paquetes/uploadPackage'

const Router = () => {
    const { user } = useContext(AuthContext)
    const ruta = window.location.pathname
    const rutaB = ruta.split('/')
    console.log(`ruta: ${ruta} - user:`, user)

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/nft" element={<NFT />} />
                <Route path="/tuto" element={<Tutorial />} />
                <Route
                    path="/host/creada/:id"
                    element={<ExperienciaCreada />}
                />
                <Route path={'/new'} element={<ExperienciaNew />} />
                <Route path="/calendario" element={<Calendar />} />
                <Route path="/host" exact />

                <Route
                    path="/host/paquetes/_/:paqueteid"
                    element={<UploadPackage />}
                />
                <Route
                    path="/host/experiencia/_/:experienciaid"
                    element={<ExperienciaHost />}
                />
                <Route
                    path="/host/editar/_/:experienciaid"
                    element={<ExperienciaEditar />}
                />
                <Route
                    path="/experiencia/horarios/_/:experienciaId"
                    element={<Schedules />}
                />
                <Route path="/host/_/:id" element={<TusExperiencias />} />
                <Route path="/host/pagos" element={<Pagos />} />
                <Route path="/host/calendario" element={<Calendario />} />
                <Route path="/host/chat" element={<ChatA />} />
                <Route
                    path="/host/configuraciones"
                    element={<ConfigViajero />}
                />
                <Route path="/exp/ok" element={<ExperienciaReservada />} />
                <Route path="/send-verify-email/:user" element={<Verify />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/verify-email" element={<IsVerify />} />
                <Route
                    path="/exp/reservada/:id/:idReserva"
                    element={<ExperienciaOpen />}
                />
                <Route path="/configuraciones" element={<ConfigViajero />} />
                <Route path="/pay" element={<ExperienciaPagar />} />
                <Route path={'/maps'} element={<Maps />} />
                <Route path="/loginsocial" element={<LoginSocial />} />
                <Route path="/logincode" element={<LoginCode />} />
                <Route path="/login" element={<Login />} />
                <Route path="/createuser" element={<CreateUser />} />
                <Route path="/register" element={<Register />} />
                <Route
                    path={'/experienciadetails'}
                    element={<ExperienciaDetails />}
                />
                <Route path="/vr" element={<VR />} />
                <Route path={'/galeria'} element={<ExperienciaGaleria />} />
                <Route path={'/expheader'} element={<ExperienciaHeader />} />
                <Route path="/explora" element={<Explora />} />
                <Route path="/chatopen" element={<ChatOpen />} />
                <Route path="/info" element={<LandingPage />} />
                <Route path="/" element={<Home />} />
                {user?.data?.user?.role === 'admin' ? (
                    <Route path="/_/" element={<AdminIndex />}>
                        <Route path="*" element={<AdminRoutes />} />
                    </Route>
                ) : user?.data?.user?.role === 'company' ||
                    user?.data?.user?.role === 'partner' ||
                    user?.data?.user?.role === 'merchant' ||
                    user?.data?.user?.role === 'reseller' ? (
                    <Route path={`/company/:ruta`} element={<AdminIndex />}>
                        <Route path={'*'} element={<CompanyRoutes />} />
                    </Route>
                ) : null}
                <Route path={`/:id`} element={<ViajeroIndex />}>
                    <Route path={'*'} element={<TravelerRoutes />} />
                </Route>
                <Route
                    path={'/experiencia/:experiencia'}
                    element={<Experiencia />}
                />
                <Route path={'/paquete/:paquete'} element={<Package />} />
                <Route
                    path={'/configuraciones'}
                    element={<ConfigurationLayout />}
                >
                    <Route path={'*'} element={<ConfigurationRoutes />} />
                </Route>
                <Route path={'*'} element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router
