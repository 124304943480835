import React, { useContext, useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { types } from "../../types/types";
import { AuthContext } from "../../auth/AuthContext";
import {
  RiCamera2Fill,
  RiLoader2Fill,
  RiArrowRightCircleFill,
} from "react-icons/ri";
import { Upload } from "antd";
import axios from "axios";
import { Alert } from "rsuite";
import ImgCrop from "antd-img-crop";
//Importar Recursos
import Info from "../Navbars/Info";
import Anfitrion from "../Navbars/Anfitrión";
import viajero from "../../assets/images/viajero.png";
import VideoLooper from "react-video-looper";
import sampleVideo from "../../assets/images/sample.mp4";

const LandingPage = () => {
  const navigate = useNavigate();

  const App = () => {
    navigate("/", { replace: true });
  };

  return (
    <div>
      <Info />
      <section className="page-header-info d-flex justify-content-center">
        <Row className="page-header-container d-flex align-items-center">
          <Col lg={7} className="">
            <h1 className="title__info heavy">
              Vive experiencias turísticas únicas
            </h1>
            <span className="title__info_bubble">
              100% Inclusión Social y Financiera
            </span>
            <div className="divider-blank"></div>
            <h3>Te conectamos directamente con un anfitrión local.</h3>
            <Button className="btn-primary bold btn-app" onClick={App}>
              App <RiArrowRightCircleFill />
            </Button>
          </Col>
          <Col lg={5}>
            <img src={viajero} className="" style={{ width: "100%" }} />
          </Col>
        </Row>
      </section>
      <section
        className="container-sm"
        style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
      >
        <Row>
          <Col>
            <VideoLooper
              source={sampleVideo}
              height={"100%"}
              style={{ borderRadius: "30px" }}
              autoplay={true}
              loopCount={100}
            />
          </Col>
          <Col>
            <h1 className="title__secundary heavy orange__gradient">
              Tu viaje comienza desde casa
            </h1>
            <div className="divider-blank"></div>
            <h2 className="bold orange__gradient">Anfitriones</h2>
            <p>
              Conéctate directamente con guías locales que te ofrecerán desde
              deportes extremos, relajación, hasta gastronomía.
            </p>
            <h2 className="bold orange__gradient">Gustos</h2>
            <p>
              Tugo comprenderá lo que está pasando en su mente salvaje, te
              mostrará lo que realmente te gusta y deseas reservar.
            </p>
            <h2 className="bold orange__gradient">Seguridad</h2>
            <p>
              Desarrollamos nuestra propia plataforma financiera que garantiza
              tus reservas online, ten el control desde el principio y la
              seguridad que nos respalda.
            </p>
          </Col>
        </Row>
      </section>

      <section
        className="page-back-1 text-center d-flex align-items-center"
        style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
      >
        <div className="container-sm ">
          <h1 className="heavy" style={{ fontSize: "60px" }}>
            A qué nos referimos con experiencias <br /> e inclusión social?
          </h1>
          <p>
            Las experiencias son viajes diseñados y liderados por guías locales,
            estos te llevan a conocer lugares únicos en su localidad. Somos 100%
            inclusión social y financiera porque creamos un impacto
            socioeconómico directo en las Comunidades y ayudamos a con la
            desentralización de las divisas ingresadas por turismo en el país.
          </p>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
