import { Button, Tooltip } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'

export const ButtonsActionTable = ({
    onEdit,
    onView,
    edit,
    onDelete,
    editMessage = 'Edit',
    viewMessage = 'Ver',
    customMiddle,
    view,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: '0px',
                // marginRight: '12px',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
            }}
        >
            {edit && (
                <Tooltip title={editMessage}>
                    <Button
                        icon={<EditOutlined onClick={onEdit} />}
                        type="text"
                    />
                </Tooltip>
            )}
            {view && (
                <Tooltip title={viewMessage} placement="topRight">
                    <Button
                        icon={<EyeOutlined onClick={onView} />}
                        type="text"
                    />
                </Tooltip>
            )}
        </div>
    )
}
