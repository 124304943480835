import {Loader} from 'rsuite'

const Spinner = () => {
    return (
        <div className="flex justify-center">
            <Loader
                inverse
                center
                size="md"
                content="Cargando..."
                className="text-white"
            />
        </div>
    )
}

export default Spinner
