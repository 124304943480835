// export const BASE_PATH = 'https://dev-api.desvlop.com'
export const BASE_PATH = 'https://tugo.onrender.com'
// export const BASE_PATH = 'http://165.232.149.162'
// export const BASE_PATH = "https://34.29.125.246/"
// export const BASE_PATH = "http://0.0.0.0:9000"
export const appIDFacebook = '915713235938789'
export const API_COUNTRIES = 'https://restcountries.com/v3.1/all'

export const KEYS_SORT = {
    ascend: 'asc',
    descend: 'desc',
}
// test fork
