import { Button, Tooltip } from 'antd'
import React from 'react'
import { ButtonsActionTable } from '../../../../Buttons/ButtonsActionTable'
import { TablaAnt } from '../../../../table/TablaAnt'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'

const TablaPaquetes = ({
    data,
    onView,
    onEdit,
    onDelete,
    showEdit = true,
    showDelete = true,
    isMerchant = false,
    onMerchantEdition,
}) => {
    const columns = [
        {
            title: (
                <div>
                    <text className="bold titulo_table">Paquete</text>
                </div>
            ),
            key: 'name',
            dataIndex: 'name',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Descripción</text>
                </div>
            ),
            key: 'description',
            dataIndex: 'description',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Experiencias</text>
                </div>
            ),
            key: 'experiences',
            // align: 'right',
            // fixed: 'right',
            render: (_, value) => (
                <>
                    {value?.experiences?.map((item) => {
                        return <li>-{item?.experience?.name}</li>
                    })}
                </>
            ),
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Acciones</text>
                </div>
            ),
            key: 'acciones',
            align: 'right',
            fixed: 'right',
            render: (_, value) => (
                <>
                    {isMerchant && (
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => onMerchantEdition(value)}
                        />
                    )}
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => onView(value)}
                    />
                    {/* {showEdit && (
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => onEdit(value)}
                        />
                    )} */}
                    {showDelete && (
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete(value._id)}
                        />
                    )}
                </>
            ),
        },
    ]
    return (
        <TablaAnt
            scrollX="max-contend"
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaPaquetes
