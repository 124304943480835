import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import {} from 'react-router-dom'
import 'antd/dist/antd.css'
import { Avatar } from 'antd'
import axios from 'axios'
// import avatar from '../../assets/images/pablo.jpg';

import { Icon, Rate } from 'rsuite'
import moment from 'moment'

import 'moment/locale/es' // without this line it didn't work
import { RiEmotionHappyFill } from 'react-icons/ri'
import { BASE_PATH } from 'componentes/constants'
import { CalificarExp } from 'componentes/Experiencias/CalificarExp'
moment.locale('es')

const Review = (props) => {
    const [experiencia, setExperiencia] = useState([])
    const [reviews, setReviews] = useState([])
    const [usern, setusern] = useState([])

    const getExperiencia = async () => {
        try {
            let res = await axios.get(`${BASE_PATH}/experiences/${props.data}`)
            if (res.status === 200) {
                let exp = [res.data]
                setExperiencia(exp)

                if (exp[0].user?.infoExtra) {
                    setusern(exp[0].user?.infoExtra)
                } else {
                    setusern(exp[0].user)
                }
                // onReviews();
            }
        } catch (e) {
            console.log(e)
        }
    }
    const onReviews = async () => {
        try {
            let reviewss = await axios.get(
                `${BASE_PATH}/reviews?experience=${props.data}`
            )
            if (reviewss.status == 200) {
                //console.log('reviews >= ', reviewss.data.rows)
                setReviews(reviewss.data.rows)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const updateData = () => {
        //console.log('actuaizando..');
        onReviews()
    }
    useEffect(() => {
        getExperiencia()
    }, [])

    return (
        <>
            {' '}
            <div className="exp-details mb-32">
                {/* <CalificarExp
                    update={updateData}
                    experience={props.data}
                    dataReserva={props.dataReserva ? props.dataReserva : false}
                /> */}

                <section className="w-fit">
                    <h1 className="bold w-fit">
                        <Icon
                            icon="comments"
                            style={{ fontSize: '38px', color: '#fe5000' }}
                            className="mr-3"
                        />
                        Reviews
                    </h1>
                </section>

                {reviews.length > 0 ? (
                    reviews.map((review) => (
                        <section key={review.id}>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{ 240: 1 }}
                            >
                                <Masonry gutter={10}>
                                    <div
                                        className="exp-review-comments"
                                        style={{ width: '640px' }}
                                    >
                                        <Row>
                                            <Col sm={3} className="text-center">
                                                <Avatar
                                                    size={75}
                                                    src={review.user.picture}
                                                />
                                                <p
                                                    style={{
                                                        marginTop: '15px',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    {review.user.name}
                                                </p>
                                            </Col>
                                            <Col
                                                sm={9}
                                                style={{ paddingTop: 5 }}
                                            >
                                                <div
                                                    className="review-date"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    <text
                                                        style={{
                                                            fontSize: '13px',
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        {moment(
                                                            review.createdAt
                                                        ).fromNow()}
                                                    </text>
                                                </div>

                                                <p className="bold">
                                                    {review.title}
                                                </p>
                                                <p style={{ fontSize: '14px' }}>
                                                    {' '}
                                                    {review.description}
                                                </p>

                                                <div
                                                    className="review-rating"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <Rate
                                                        defaultValue={
                                                            review.star
                                                        }
                                                        allowHalf
                                                        readOnly
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Masonry>
                            </ResponsiveMasonry>
                        </section>
                    ))
                ) : (
                    <div className="text-center flex flex-col items-center">
                        <RiEmotionHappyFill fill="#EE4B00" size={50} />
                        <p>
                            ¡Se el primero en dejar una reseña! <br />
                            Tu opinión es importante para nosotros y para otros
                            usuarios.
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}

export default Review
