import Chat from "componentes/Chat/Chat";
import Parati from "componentes/Experiencias/Parati";
import Planes from "componentes/Planes/Planes";
import { Route, Routes } from "react-router-dom";

const TravelerRoutes = () => {
  return (
    <Routes>
      <Route index element={<Parati />} />
      <Route path="parati" element={<Parati />} />
      <Route path="planes" element={<Planes />} />
      <Route path="chat" element={<Chat />} />
    </Routes>
  );
};

export default TravelerRoutes;
