import { Row, Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../../../services'
import { Button, Card } from 'react-bootstrap'
import Titulo from '../Titulo'
import { Link, useOutletContext } from 'react-router-dom'
import { renderInput } from 'utils/inputs'
import '../../../../assets/css/App.css'
import { Spinner } from 'componentes/commons'
import { MessageUtils } from 'utils'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import { BASE_PATH } from 'componentes/constants'
import UploadImageCard from 'componentes/commons/UploadImageCard'

const initForm = {
    name: null,
    email: null,
    country: null,
    domain: null,
    description: null,
}
const PerfilCompania = () => {
    const { user, dominio } = useOutletContext()
    const [platform, setPlatform] = useState(null)
    const [isLoadingForms, setIsLoadingForms] = useState(false)
    const [picture, setPicture] = useState()
    const [isLoadingPic, setIsLoadingPic] = useState(false)
    const [formValues, setFormValues] = useState(initForm)
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm()
    const role = user?.data?.user?.role

    const inputs = [
        {
            label: `Nombre`,
            name: 'name',
            placeholder: 'Nombre',
            rules: [
                {
                    required: true,
                    message: 'Ingrese un nombre',
                },
            ],
        },
        {
            label: 'País',
            name: 'country',
            placeholder: 'País',
            rules: [
                {
                    required: true,
                    message: 'Ingrese el país',
                },
            ],
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'Email',
            rules: [
                {
                    required: true,
                    message: 'Ingrese el email',
                },
            ],
        },
        {
            label: 'Dominio',
            name: 'domain',
            placeholder: 'Dominio',
            disabled: true,
            rules: [
                {
                    required: true,
                    message: 'Ingrese el dominio',
                },
            ],
        },
        {
            label: 'Descripción',
            name: 'description',
            type: 'textArea',
        },
    ]

    const incioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({
                path: `users/${user.data.user.id}`,
                token: user.data.token,
                params: {},
                value: {},
            })
            const configId = dominio ? dominio.id : responseData?.config
            const responsePlatform = await getData({
                path: `config/${configId}`,
                token: user.data.token,
                params: {},
                value: {},
            })
            setPlatform(responsePlatform)
            console.log('Response data', responseData)
            console.log(responsePlatform)
            const values = {
                id: responseData?.id,
                name: responseData?.name,
                email: responseData?.email,
                country: responseData?.country,
                domain: responsePlatform?.platform?.domain || '',
                description: responsePlatform?.platform?.description,
            }
            setFormValues(values)
            setPicture(responseData.picture)
            form.setFieldsValue(values)
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setLoading(false)
        }
    }

    const onFinish = async () => {
        try {
            setIsLoadingForms(true)
            await putData({
                path: `users`,
                id: formValues.id,
                token: user.logged && user.data.token,
                params: {},
                value: { formValues, picture },
            })
            incioComponente()
            MessageUtils.success('Se han actualizado los datos')
        } catch (err) {
            console.error(err)
            MessageUtils.error('Ha ocurrido un error en el servidor')
        } finally {
            setIsLoadingForms(false)
        }
    }

    const uploadImage = async (fileList) => {
        const fdata = new FormData()
        let file
        if (fileList.fileList.length > 1) {
            file = fileList.fileList[1].originFileObj
        } else {
            file = fileList.fileList[0].originFileObj
        }
        fdata.append('file', file)
        try {
            setIsLoadingPic(true)
            const imagesUp = await axios.post(
                `${BASE_PATH}/images/upload-files`,
                fdata,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )
            if (imagesUp.status === 200) {
                setPicture(imagesUp.data.imageUrl)
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Error al subir la imagen')
        } finally {
            setIsLoadingPic(false)
        }
    }

    useEffect(() => {
        incioComponente()
    }, [user, dominio])

    if (loading) {
        return <Spinner />
    }

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                width: '100%',
                height: '100%',
                backgroundColor: '#fe5000',
                borderRadius: '100px',
            }}
            type="button"
        >
            {isLoadingPic ? <LoadingOutlined /> : <PlusOutlined />}
        </button>
    )

    return (
        <>
            <Titulo titulo={'Perfil'} button={false} />
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col>
                    <div
                        key="key1"
                        style={{
                            width: '262px',
                            height: '342px',
                            position: 'relative',
                        }}
                    >
                        <UploadImageCard
                            disabled={isLoadingPic}
                            onChange={uploadImage}
                            picture={picture}
                        />
                    </div>
                </Col>
                <Form
                    form={form}
                    formValues={formValues}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    style={{ minWidth: '50rem', marginLeft: '10rem' }}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            {inputs.slice(0, 3).map((input, index) => (
                                <Form.Item
                                    key={index}
                                    label={input.label}
                                    name={input.name}
                                    rules={input.rules}
                                >
                                    {renderInput(
                                        input,
                                        formValues,
                                        setFormValues
                                    )}
                                </Form.Item>
                            ))}
                        </Col>
                        <Col span={12}>
                            {inputs.slice(3).map((input, index) => (
                                <Form.Item
                                    key={index}
                                    label={input.label}
                                    name={input.name}
                                    rules={input.rules}
                                >
                                    {renderInput(
                                        input,
                                        formValues,
                                        setFormValues
                                    )}
                                </Form.Item>
                            ))}
                        </Col>
                    </Row>
                    <div
                        className="steps-action bold exp-edit-fixed-bottom"
                        style={{ right: '2rem', minWidth: '24rem' }}
                    >
                        <Button
                            className="bold exp-pasos btn-primary-reserva-host "
                            type="primary"
                            htmlType="submit"
                            disabled={isLoadingForms}
                        >
                            Actualizar
                        </Button>
                        {role === 'company' && (
                            <Link to={'/host/pagos'}>
                                <Button
                                    className="bold exp-pasos btn-primary-reserva-host min-w-52 mr-2"
                                    type="primary"
                                >
                                    Datos Bancarios
                                </Button>
                            </Link>
                        )}
                    </div>
                </Form>
            </Row>
        </>
    )
}

export default PerfilCompania
