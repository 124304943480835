// AdminPaises.jsx
import { Button, Checkbox, Drawer, Form, Input } from 'antd';
import React, { useState } from 'react';
import { ConstructorFormAntd } from '../../../Forms/ConstructorFormAnt';
import Titulo from '../Titulo';
import TablaPaises from './items/TablaPaises';

const AdminPaises = () => {
    // const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('new');
    const [idEdit, setIdEdit] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        cod: ''
    });

    const [data, setData] = useState([
        {
            id: 1,
            name: 'nombre de parque 1',
            cod: 'El Salvador',
        },
        {
            id: 2,
            name: 'Nombre de parque 2',
            cod: 'El Salvador',
        },
    ]);

    const onFinish = (values) => {
        setFormValues(values);
        console.log('el guardado', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const onEdit = (value) => {
        setIdEdit(value.id);
        setFormValues({
            name: value.name,
            cod: value.cod,
        });
        setAction('edit');
        setOpen(true);
    };

    const [form] = Form.useForm();

    return (
        <div>
            <Titulo
                titulo={'Lista de paises'}
                tituloBoton={'Crear pais'}
                onAcccion={() => setOpen(true)}
            />
            <div>
                <TablaPaises data={data} onEdit={onEdit} />
            </div>
            <Drawer
                title={action === 'new' ? 'Crear Pais' : 'Editar Pais'}
                placement="right"
                onClose={() => setOpen(false)}
                visible={open}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <ConstructorFormAntd
                        inputsForRows={2}
                        modeLarge={true}
                        listInputs={[
                            { label: 'Pais', name: 'name', rules: [{ required: false, message: 'Pais' }] },
                            { label: 'Codigo', name: 'cod', rules: [{ required: false, message: 'Código' }] },
                        ]}
                        values={formValues}  // Pasa los valores del formulario
                        formValues={formValues}
                        setValues={setFormValues}
                    />


                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
};

export default AdminPaises;
