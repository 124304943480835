import { Avatar, Button } from 'antd'
import React, { useState } from 'react'
import { TablaAnt } from '../../../../table/TablaAnt'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const TablaFundaciones = ({ data, onEdit, onDelete }) => {
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: '',
            key: 'picture',
            dataIndex: 'picture',
            render: (value) => <Avatar src={value} />,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Compañia</text>
                </div>
            ),
            key: 'name',
            dataIndex: 'name',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Email</text>
                </div>
            ),
            key: 'email',
            dataIndex: 'email',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Acciones</text>
                </div>
            ),
            key: 'acciones',
            align: 'right',
            fixed: 'right',
            render: (data) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => onEdit(data)}
                    />
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => onDelete(data)}
                    />
                </>
            ),
        },
    ]

    return (
        <>
            <TablaAnt
                scrollX="max-contend"
                columns={columns}
                data={data}
                loading={loading}
                isCheckCell={false}
            />
        </>
    )
}

export default TablaFundaciones
