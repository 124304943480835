import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap'

import { FirebaseAppProvider } from 'reactfire'
import { ConfigProvider } from 'antd'
import esEs from 'antd/lib/locale/es_ES'
import dayjs from 'dayjs'
import { Provider } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import AppTugo from './AppTugo'
import * as serviceWorker from './serviceWorkerRegistration'

import 'dayjs/locale/es'

import { store } from './redux/store'

dayjs.locale('es')

const firebaseConfig = {
    apiKey: 'AIzaSyBfuzf_YkehUolTYZqVAtPd5lNOBYy5Zd8',
    authDomain: 'tugo-a.firebaseapp.com',
    projectId: 'tugo-a',
    storageBucket: 'tugo-a.appspot.com',
    messagingSenderId: '528668811942',
    appId: '1:528668811942:web:815214986e4cc29e4c8a67',
    measurementId: 'G-DX7DCJK94D',
}
const stripePromise = loadStripe('pk_test_Dt4ZBItXSZT1EzmOd8yCxonL')
// const stripePromise = loadStripe(
//     'pk_test_51QCk5uFtyIBhK602jXEYgei0KxO42sP5ODmZvBsvaQzWbHDdTJ1Cn8XjxCX4P8G0B3agtIJUshRUXl3APjRZspPo00hnKcIt0O'
// )
const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
}

ReactDOM.render(
    <React.StrictMode>
        <FirebaseAppProvider firebaseConfig={firebaseConfig} options={options}>
            <Provider store={store}>
                <ConfigProvider locale={esEs}>
                    {/* <PreferencesProvider>
        <ProviderAntd> */}
                    <Elements stripe={stripePromise}>
                        <AppTugo />
                    </Elements>
                    {/* </ProviderAntd>
      </PreferencesProvider> */}
                </ConfigProvider>
            </Provider>
        </FirebaseAppProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register()
