import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { deleteData, getData } from '../../../../services'
import Titulo from '../Titulo'
import Experiencia from './items/Experiencia'
import TablaExperiencias from './items/TablaExperiencias'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import PaginationCom from 'componentes/pagination/PaginationCom'
import { MessageUtils } from 'utils'
import { Form } from 'antd'
import { duration } from 'moment'

const initForm = {
    name: '',
    description: '',
    duration: '',
    direction: '',
    start: '',
    end: '',
    grossPriceAdult: '',
    grossPriceChildre: '',
}

const AdminExperiencias = () => {
    const { user, dominio } = useOutletContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [openExperiencia, setOpenExperiencia] = useState(false)
    const [experiencias, setExperiencias] = useState([])
    const [experiencia, setExperiencia] = useState(null)
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState(null)
    const [selectedExperienceId, setSelectedExperienceId] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [form] = Form.useForm()
    const [formValues, setFormValues] = useState(initForm)
    const [editOpen, setEditOpen] = useState(false)
    const role = user.data.user.role

    const getExperiencias = async () => {
        setLoading(true)
        try {
            const response = await getData({
                // path: `experiences?skipValidation=true`,
                path: `experiences/?page=${page}&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: dominio ? dominio.id : user.data.user.config,
            })
            if (response) {
                setMeta(response)
                setExperiencias(response[0]?.result)
            } else {
                MessageUtils.error('Ha ocurrido un eror')
            }
        } catch (error) {
            MessageUtils.error('Ha ocurrido un eror')
            console.error(error)
        } finally {
            setLoading(false)
        }
    }
    console.log('Las experiencias son: ', experiencias)
    const onDeleteExperience = async () => {
        try {
            const response = await deleteData({
                path: `experiences`,
                id: selectedExperienceId,
                token: user.logged && user.data.token,
            })

            if (response || response === '') {
                MessageUtils.success('Experiencia eliminada con éxito')
                getExperiencias()
                setOpenModal(false)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar la experiencia'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar la experiencia'
            )
        }
    }

    useEffect(() => {
        if (dominio) {
            getExperiencias()
        } else {
            setLoading(false)
        }
    }, [page, dominio])

    const onAcccion = () => {
        navigate(`/company/${user.data.platform.domain}/nueva-experiencia`)
    }

    const handleOnView = (value) => {
        console.log({ value })
        navigate(`/host/experiencia/_/${value.id}`)
    }

    const onDeleteButton = (id) => {
        setSelectedExperienceId(id)
        setOpenModal(true)
    }

    const onCancelModal = () => {
        setSelectedExperienceId('')
        setOpenModal(false)
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setEditOpen(false)
    }

    const onMerchantEdition = (data) => {
        console.log('data dentro de onMerchant', data)
        setSelectedExperienceId(data.id)

        const values = {
            name: data.name,
            description: data.description,
            duration: data?.duration,
            direction: data.direction,
            start: data.start,
            end: data.end,
            grossPriceAdult: data?.quotes[0].prices[0].netPrice,
            grossPriceChildren: data?.quotes[0].prices[1].netPrice,
        }

        setFormValues(values)
        form.setFieldsValue(values)
        setEditOpen(true)
    }
    console.log(formValues)
    return (
        <div>
            <Titulo
                titulo={'Experiencias'}
                tituloBoton={'Crear'}
                onAcccion={onAcccion}
                button={
                    user.data.user.role === 'company' ||
                    user.data.user.role === 'partner'
                }
            />
            <div>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <TablaExperiencias
                            data={experiencias.slice(0, 6).map((item) => {
                                return {
                                    ...item,
                                    picture: item.pictures[0]?.path,
                                }
                            })}
                            onView={(value) => handleOnView(value)}
                            onDelete={onDeleteButton}
                            showDelete={role === 'company'}
                            isMerchant={
                                role === 'merchant' || role === 'reseller'
                            }
                            onMerchantEdition={onMerchantEdition}
                        />
                        {experiencias.length > 0 && (
                            <PaginationCom
                                page={page}
                                meta={meta}
                                setPage={(value) => setPage(value)}
                            />
                        )}
                    </>
                )}
            </div>

            <Experiencia
                close={() => {
                    setOpenExperiencia(false)
                }}
                experiencia={experiencia}
                openExperiencia={openExperiencia}
            />
            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={onDeleteExperience}
                openModal={openModal}
                strongText={
                    experiencias.find(
                        (item) => item.id === selectedExperienceId
                    )?.name
                }
                text={`¿Estás seguro de que deseas eliminar la siguiente experiencia?`}
                title={'Eliminar Experiencia'}
            />
            {(role == 'merchant' || role == 'reseller') && (
                <SidebarForm
                    title="Modifica el precio de la experiencia"
                    buttonText="Modificar"
                    form={form}
                    width={500}
                    formValues={formValues}
                    onCloseDrawer={onCloseDrawer}
                    open={editOpen}
                    setFormValues={setFormValues}
                    onFinish={() => console.log('funcion a desarrollar')}
                    inputs={[
                        {
                            label: 'Experiencia',
                            name: 'name',
                            placeholder: 'Nombre',
                            rules: [
                                {
                                    required: true,
                                    message: 'Ingrese un nombre',
                                },
                            ],
                            disabled: 'true',
                        },
                        {
                            label: 'Descripción',
                            name: 'description',
                            type: 'textArea',
                            rules: [
                                {
                                    required: true,
                                    message: 'Ingrese una descripción',
                                },
                            ],
                            disabled: 'true',
                        },
                        {
                            label: 'Duración',
                            name: 'duration',
                            disabled: 'true',
                        },
                        {
                            label: 'Dirección',
                            name: 'duration',
                            disabled: 'true',
                        },
                        {
                            label: 'Inicio',
                            name: 'start',
                            disabled: 'true',
                        },
                        {
                            label: 'Fin',
                            name: 'end',
                            disabled: 'true',
                        },
                        {
                            label: 'Precio Adulto',
                            name: 'grossPriceAdult',
                            rules: [
                                {
                                    required: true,
                                    message: 'Ingrese un precio',
                                },
                            ],
                        },
                        {
                            label: 'Precio Niño',
                            name: 'grossPriceChildren',
                            rules: [
                                {
                                    required: true,
                                    message: 'Ingrese un precio',
                                },
                            ],
                        },
                    ]}
                />
            )}
        </div>
    )
}

export default AdminExperiencias
