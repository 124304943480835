import React, { useEffect, useState } from 'react'
import {} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import '../../assets/css/App.css'
import 'antd/dist/antd.css'
import { Avatar } from 'antd'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Icon } from 'rsuite'

//Importar Recursos
import Maps from '../Experiencias/maps'
import axios from 'axios'
import { BASE_PATH } from '../constants'
import { Map } from 'componentes/commons'

const ExperienciaDetails = (props) => {
    const [experiencia, setExperiencia] = useState([])
    const [ubicacion, setUbicacion] = useState({
        lat: '37.7749295',
        lng: '-122.4194155',
    })
    const [usern, setusern] = useState([])

    const getExperiencia = async () => {
        try {
            let res = await axios.get(`${BASE_PATH}/experiences/${props.data}`)
            if (res.status === 200) {
                let exp = [res.data]
                setExperiencia(exp)

                if (exp[0].user.infoExtra) {
                    setusern(exp[0].user?.infoExtra)
                    // console.log("owner =>", exp[0].user);
                } else {
                    setusern(exp[0].user)
                }
                if (exp[0].lat) {
                    //console.log("latitud => " + exp.lat);
                    setUbicacion({
                        lat: exp[0].lat,
                        lng: exp[0].long,
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getExperiencia()
        //console.log(ubicacion);
    }, [])

    return (
        <>
            {' '}
            {experiencia.length > 0 && (
                <div className="exp-details">
                    <section>
                        <div className="bold">
                            <span style={{ fontSize: 20 }}>Inicio: </span>
                            <p>
                                {experiencia.length > 0 ? (
                                    experiencia[0].start
                                ) : (
                                    <></>
                                )}
                            </p>
                            <br />
                            <span style={{ fontSize: 20 }}>Final: </span>
                            <p>
                                {experiencia.length > 0 ? (
                                    experiencia[0].end
                                ) : (
                                    <></>
                                )}
                            </p>
                        </div>
                        <div className="container-sm d-block d-sm-none d-none d-sm-block d-md-none text-center">
                            <Row>
                                <Col className="text-center card4">
                                    <p className="bold orange">Cupo</p>
                                    <p className="bold">
                                        Hasta{' '}
                                        {experiencia.length > 0
                                            ? experiencia[0].quotas.max
                                            : null}
                                    </p>
                                    <br /> <p>personas </p>
                                </Col>
                                <Col className="text-center card4">
                                    <p className="bold orange">Duración</p>
                                    <p className="bold">
                                        {experiencia.length > 0
                                            ? experiencia[0].duration
                                            : null}
                                    </p>
                                    <br /> <p>horas </p>
                                </Col>
                                <Col className="text-center card4">
                                    <p className="bold orange">Precio</p>
                                    <Row>
                                        <Col xs={6} className="bold">
                                            <p>Adultos </p> <br /> $
                                            {experiencia.length > 0 ? (
                                                experiencia[0].price
                                                    ?.adultPriceWithCommissions ||
                                                experiencia[0].price?.adultPrice
                                            ) : (
                                                <>hola2</>
                                            )}
                                        </Col>
                                        <Col xs={6} className="bold">
                                            <p>Niños</p> <br /> $
                                            {experiencia.length > 0 ? (
                                                experiencia[0].price
                                                    ?.childrenPriceWithCommissions ||
                                                experiencia[0].price
                                                    ?.childrenPrice
                                            ) : (
                                                <></>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    <section className="exp-details-about">
                        <Row>
                            <Col sm={3} className="text-center">
                                <Avatar
                                    size={140}
                                    src={
                                        usern.picture
                                            ? usern.picture
                                            : experiencia[0].user?.picture
                                    }
                                />
                            </Col>
                            <Col sm={8} style={{ paddingTop: '20px' }}>
                                <p>Anfitrión</p>
                                <p
                                    className="heavy mt-3"
                                    style={{
                                        fontSize: '45px',
                                        lineHeight: '45px',
                                    }}
                                >
                                    {usern.name ? usern.name : 'No disponible'}
                                </p>
                                <p>
                                    {usern.description
                                        ? usern.description
                                        : 'No disponible'}
                                </p>
                            </Col>
                        </Row>
                    </section>

                    <section>
                        <h3 className="bold">Lo que haremos</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    experiencia.length > 0
                                        ? experiencia[0].description.replace(
                                              /\n/g,
                                              '<br />'
                                          )
                                        : '',
                            }}
                        ></p>{' '}
                    </section>

                    <li className="dropdown-divider"></li>

                    <section>
                        <h3 className="bold">Ubicación</h3>
                        <div style={{ height: '10rem' }}>
                            <Map location={ubicacion} />
                        </div>
                    </section>

                    <li className="dropdown-divider"></li>

                    <section>
                        <h3 className="bold">Incluye</h3>
                        <OwlCarousel
                            className="owl-carousel"
                            margin={20}
                            nav={false}
                            dots={true}
                            responsive={{
                                1200: {
                                    items: 4,
                                },
                                300: {
                                    items: 2,
                                },
                            }}
                        >
                            {experiencia.length > 0 ? (
                                experiencia[0].extra.map((item) => (
                                    <div
                                        className="item card2 text-center"
                                        key={item.valor + '_key'}
                                    >
                                        <Card>
                                            <Card.Body className="bold">
                                                <Icon
                                                    icon={item.icon}
                                                    size="3x"
                                                />
                                                <br />
                                                <h6
                                                    style={{
                                                        paddingTop: 20,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    {item.valor}
                                                </h6>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                        </OwlCarousel>
                    </section>
                </div>
            )}
        </>
    )
}

export default ExperienciaDetails
