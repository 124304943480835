import React, { useState } from 'react'
import {} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import '../../../assets/css/App.css'
import 'antd/dist/antd.css'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {
    RiCalendarFill,
    RiTimer2Fill,
    RiUserSmileFill,
    RiMoneyDollarCircleFill,
} from 'react-icons/ri'
import { Icon } from 'rsuite'
import { Map } from 'componentes/commons'

const ExperienciaDetailsOpen = ({ experience }) => {
    const [quotes, setquotes] = useState({ available: 0, reserved: 0 })
    let location = { lat: experience[0]?.lat, lng: experience[0]?.long }

    return (
        <>
            {experience.length > 0 && (
                <div className="exp-details">
                    <section>
                        <text style={{ fontSize: 20 }}>
                            {experience.length > 0 ? experience[0].name : <></>}
                        </text>
                        <p className="bold">
                            <text style={{ fontSize: 16 }}>Inicio: </text>
                            {experience.length > 0 ? (
                                experience[0].start
                            ) : (
                                <></>
                            )}
                            <br />
                            <text style={{ fontSize: 16 }}>Final: </text>
                            {experience.length > 0 ? experience[0].end : <></>}
                        </p>
                        <Row md={4}>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiCalendarFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Fecha</text>
                                        <p className="bold">
                                            Consultar horarios*
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiTimer2Fill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Horario</text>
                                        <p className="bold">
                                            Consultar horarios*
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiMoneyDollarCircleFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Precio</text>
                                        <p className="bold">
                                            Adultos ${' '}
                                            {experience.length > 0 ? (
                                                experience[0]?.quotes[0]
                                                    ?.prices[0]?.netPrice ||
                                                experience[0].price?.adultPrice
                                            ) : (
                                                <></>
                                            )}
                                            , Niños ${' '}
                                            {experience.length > 0 ? (
                                                experience[0]?.quotes[0]
                                                    ?.prices[1]?.netPrice ||
                                                experience[0].price
                                                    ?.childrenPrice
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiUserSmileFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Cupones</text>
                                        <p className="bold">
                                            {experience.length > 0 ? (
                                                experience[0].quotas.min + ' - '
                                            ) : (
                                                <></>
                                            )}
                                            {experience.length > 0 ? (
                                                experience[0].quotas.max + ' '
                                            ) : (
                                                <></>
                                            )}
                                            personas por horario
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiUserSmileFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Reservados</text>
                                        <p className="bold">
                                            {quotes.reserved}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiUserSmileFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Disponibles</text>
                                        <p className="bold">
                                            {quotes.available}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>

                    <section>
                        <h3 className="bold">Lo que haremos</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    experience.length > 0
                                        ? experience[0].description.replace(
                                              /\n/g,
                                              '<br />'
                                          )
                                        : '',
                            }}
                        ></p>
                    </section>

                    <li className="dropdown-divider"></li>

                    <section>
                        <h3 className="bold">Ubicación</h3>

                        <div style={{ height: '10rem' }}>
                            <Map location={location} />
                        </div>
                    </section>

                    <li className="dropdown-divider"></li>

                    <section>
                        <h3 className="bold">Incluye</h3>
                        <OwlCarousel
                            className="owl-carousel"
                            margin={20}
                            nav={false}
                            dots={true}
                            responsive={{
                                1200: {
                                    items: 4,
                                },
                                300: {
                                    items: 2,
                                },
                            }}
                        >
                            {experience[0]?.extra.map((item, i) => (
                                <div
                                    className="item card2 text-center"
                                    key={`${item.valor}_${item.icon}_${i}`}
                                >
                                    <Card>
                                        <Card.Body className="bold">
                                            <Icon icon={item.icon} size="3x" />
                                            <br />
                                            <h6
                                                style={{
                                                    paddingTop: 20,
                                                    marginBottom: 1,
                                                }}
                                            >
                                                {item.valor}
                                            </h6>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </OwlCarousel>
                    </section>
                </div>
            )}
        </>
    )
}

export default ExperienciaDetailsOpen
