import React, { useState, useContext, useEffect } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import axios from 'axios'
import { GoogleLogin } from 'react-google-login'
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { BASE_PATH } from '../constants'
import { Form, FormControl, Button, Input } from 'rsuite'
import {} from 'react-bootstrap'
import './style.css'
import { AuthContext } from '../../auth/AuthContext'
import { types } from '../../types/types'
import { Modal, message } from 'antd'
import 'antd/dist/antd.dark.css' // Importa el tema oscuro antes que tus otros componentes y estilos
import { PiArrowRightFill } from 'react-icons/pi'

//Importar Recursos
import InputPassword from './components/InputPassword'

const Login = ({ modal, onSuccess, companias, onClose }) => {
    const { dispatch } = useContext(AuthContext)
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [openModal, setopenModal] = useState(false)
    const [openModalP, setopenModalP] = useState(false)
    const [openModalC, setopenModalC] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [showPolicy, setShowPolicy] = useState(false)
    const [forgotEmail, setforgotEmail] = useState('')

    const HomeB = () => {
        navigate('/', { replace: true })
    }
    const register = () => {
        navigate('/register', { replace: true })
    }

    const handleOpenModal = () => {
        openModal ? setopenModal(false) : setopenModal(true)
    }
    const handleOpenModalP = () => {
        openModalP ? setopenModalP(false) : setopenModalP(true)
    }
    const handleOpenModalC = () => {
        openModalC ? setopenModalC(false) : setopenModalC(true)
    }

    const ruta = window.location.pathname
    const rutaB = ruta.split('/')
    const onSubmit = async () => {
        if (password === '' || email === '') {
            message.error('Campos requeridos vacios.', 2)
            return
        }

        try {
            const response = await axios({
                method: 'POST',
                url: `${BASE_PATH}/auth`,
                auth: {
                    username: email,
                    password: password,
                },
            })

            if (response.status === 201) {
                sessionStorage.setItem('alerta', true)
                dispatch({
                    type: types.login,
                    payload: response,
                })
                message.success('Bienvenido de vuelta!', 2)
                if (modal) {
                    console.log('Respuesta del login', response)
                    if (response.data.user.role === 'admin') {
                        navigate(`/_/dashboard`, { replace: true })
                    } else {
                        if (response.data.user.role !== 'user') {
                            // navigate(`/${response.data.platform.domain}/dashboard`, {
                            //   replace: true,
                            // });
                            console.log(response)
                            navigate(
                                `/company/${response.data.platform.domain}/dashboard`,
                                {
                                    replace: true,
                                }
                            )
                            // window.location.reload();
                        } else {
                            onSuccess()
                        }
                    }
                } else {
                    if (response.data.user.role === 'owner') {
                        navigate(`/host/${rutaB[1]}/${response.data.user.id}`, {
                            replace: true,
                        })
                    } else {
                        onClose()
                        setShowLogin(false)
                    }
                }
            } else if (
                response.status === 200 &&
                !response.data.emailVerified
            ) {
                handleSendVerifyEmail(response.data.lastToken)
            }
        } catch (error) {
            if (error.response) {
                if (
                    error.response.status === 404 ||
                    error.response.status === 400
                ) {
                    //  console.log(e.response.data.message);
                    message.error(error.response.data.message, 2)
                } else if (
                    error.response.status === 500 ||
                    error.response.status === 401
                ) {
                    message.error('Datos incorrectos', 2)
                }
            } else {
                message.error('Error en el servidor, intentalo luego', 2)
            }
        }
    }

    const responseGoogle = async (response) => {
        try {
            let Login = await axios.post(`${BASE_PATH}/auth/google`, {
                access_token: response.accessToken,
            })
            if (Login.status === 201) {
                // onSuscribeNotification(Login.data.user.id, Login.data.token);
                if (onClose) {
                    onClose()
                }
                sessionStorage.setItem('alerta', true)
                message.success('Bienvenido de vuelta!', 1)
                dispatch({
                    type: types.login,
                    payload: Login,
                })
                onSuccess()
                if (Login.data.user.role === 'owner') {
                    navigate(`/host/_/${Login.data.user.id}`, { replace: true })
                } else {
                    navigate('/')
                }
            }
        } catch (e) {
            if (e.response) {
                if (e.response.status === 404 || e.response.status === 400) {
                    //  console.log(e.response.data.message);
                    message.warning(e.response.data.message, 2)
                }
            }
        }
    }

    const [visible, setVisible] = React.useState(false)

    const handleChange = () => {
        setVisible(!visible)
    }

    const responseFacebook = async (response) => {
        try {
            let loginFacebook = await axios.post(`${BASE_PATH}/auth/facebook`, {
                access_token: response.accessToken,
            })

            if (loginFacebook.status == 201) {
                message.success('Bienvenido de vuelta!', 2)
                sessionStorage.setItem('alerta', true)
                // onSuscribeNotification(
                //   loginFacebook.data.user.id,
                //   loginFacebook.data.token
                // );

                dispatch({
                    type: types.login,
                    payload: loginFacebook,
                })
                if (loginFacebook.data.user.role === 'owner') {
                    navigate(`/host/_/${loginFacebook.data.user.id}`, {
                        replace: true,
                    })
                } else {
                    navigate('/')
                }
            } else {
                message.warning(
                    'Fallo en la inicio de sesion, vuelva a intentar',
                    2
                )
            }
        } catch (e) {
            if (e.response) {
                if (e.response.status === 404 || e.response.status === 400) {
                    message.warning(e.response.data.message, 2)
                }
            }
        }
    }

    const handleSendVerifyEmail = async (id) => {
        try {
            const request = await axios.post(
                `${BASE_PATH}/users/send-verify-email`,
                {
                    email: email,
                    originUrl: window.location.origin,
                }
            )
            //console.log(request)
            if (request.status === 200 && request.data.emailSended) {
                message.success('Se ha enviado un correo de verificación!', 2)
                navigate(`/send-verify-email/${id}`, { replace: true })
            }
        } catch (error) {
            message.error('Ha ocurrido un error en el servidor', 2)
            console.log(error)
        }
    }

    const forgotPassword = async () => {
        if (!!!forgotEmail) {
            return
        }
        try {
            const request = await axios.post(
                `${BASE_PATH}/users/forgot-password`,
                {
                    email: forgotEmail,
                    originUrl: window.location.origin,
                }
            )

            if (request.status === 200) {
                handleOpenModal()
                setforgotEmail('')
                message.success('Se ha enviado un correo de recuperación!', 5)
            }
            //console.log(request);
        } catch (error) {
            console.log(error)
            message.error('Ha ocurrido un error en el servidor', 3)
        }
    }

    const onSuscribeNotification = async (userId, token) => {
        const Ftoken = sessionStorage.getItem('notification_token')
        if (!!Ftoken) {
            const suscribe = await axios.post(
                `${BASE_PATH}/notifications/subscribe`,
                { fToken: Ftoken, userId: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
        }
    }

    useEffect(() => {
        dispatch({ type: types.logout })
    }, [])

    return (
        <div>
            <Card className="border-0">
                <Card.Body>
                    <Card.Title className="bold login-title d-flex justify-content-center"></Card.Title>
                    <p className="text-center">
                        Guarda tus reservas, historial y planes de viaje.
                    </p>
                    <Form
                        className="flex justify-center flex-col"
                        layout="horizontal"
                        fluid
                    >
                        <Card.Title className="Medium login-title-code"></Card.Title>
                        <Row className="mb-3">
                            <Col
                                sm={12}
                                xs={12}
                                className="d-flex justify-content-center"
                            >
                                <GoogleLogin
                                    clientId="528668811942-ssf2q4juvjfkdru5lk5u9nfqs1nbq5nb.apps.googleusercontent.com"
                                    render={(renderProps) => (
                                        <button
                                            className="bold btn-social"
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                        >
                                            <i className="icon-google text-danger mr-3"></i>
                                            Ingresa con Google
                                        </button>
                                    )}
                                    buttonText="Google"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                />
                            </Col>
                        </Row>
                        <p
                            className="lite  text-center"
                            style={{
                                marginTop: 2,
                                fontSize: '12px',
                                lineHeight: '17px',
                            }}
                        >
                            Al iniciar sesión o registrarte aceptas nuestras{' '}
                            <br />
                            <a
                                onClick={() => setShowPolicy(true)}
                                className="link"
                            >
                                Políticas, Términos y Condiciones de Uso.
                            </a>
                        </p>
                        <div style={{ margin: '' }}></div>
                        <button
                            className="flex items-center justify-center"
                            style={{ marginTop: 11 }}
                            onClick={() => setShowLogin(true)}
                        >
                            Ingreso de Compañias
                            <PiArrowRightFill className="ml-3" />
                        </button>
                    </Form>
                </Card.Body>
            </Card>

            <Modal
                footer={null}
                size="xs"
                show={openModal}
                onHide={setopenModal}
                style={{ maxWidth: '98%' }}
            >
                <Modal.Header>
                    <Modal.Title>Ingreso de compañias</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        placeholder="Correo"
                        onChange={(value) => setforgotEmail(value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ background: '#fe5000' }}
                        onClick={forgotPassword}
                        appearance="primary"
                    >
                        Recuperar
                    </Button>
                    <Button
                        onClick={() => {
                            handleOpenModal()
                        }}
                        appearance="subtle"
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                centered
                width={1000}
                open={showPolicy}
                onCancel={() => setShowPolicy(false)}
            >
                <h3 className="bold">
                    Políticas de Privacidad y Condiciones de Uso
                </h3>
                Ir a enlace temporal{' '}
                <a
                    href="https://tugo.app/policy.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    Link
                </a>
            </Modal>

            <Modal
                footer={null}
                centered
                title=""
                open={showLogin}
                onCancel={() => setShowLogin(false)}
            >
                <Form>
                    <FormControl
                        className="form-control text-pass btn-lg mb-3 mt-4"
                        value={email}
                        onChange={(val) => setEmail(val)}
                        placeholder="Correo"
                        type="email"
                    />

                    <InputPassword value={password} setValue={setPassword} />
                    <Button
                        className="bold text-center btn-primary btn-login-first mt-3"
                        onClick={onSubmit}
                    >
                        Entrar
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}

export default Login
