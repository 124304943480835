import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../../assets/css/App.css";
import "antd/dist/antd.css";

//Importar Recursos

const ExperienciaCreada = () => {
  const { id: experienceId } = useParams();
  const [tempo, settempo] = useState(5);
  const navigate = useNavigate();

  const TusExp = () => {
    navigate(`/host/experiencia/_/${experienceId}`, { replace: true });
  };

  useEffect(() => {
    let count = 5;

    let interval = setInterval(() => {
      if (count === 0) {
        TusExp();
      }
      if (count >= 0) {
        settempo(count);
        count--;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className="bg-neonblue-gradient 
    vh-100 
    d-flex justify-content-center 
    align-items-center"
    >
      <Row
        className="container-sm 
      d-flex justify-content-center 
      align-items-center"
      >
        <Col className="text-center">
          <h1 className="heavy">¡Listo!</h1>
          <h5 className="bold">
            Tu experiencia ha sido creada en <br />
            Modo Borrador
          </h5>
          <div onClick={TusExp}>
            <text>
              Redireccionando a <span className="bold">Tu Experiencia</span> en{" "}
              {tempo} seg.
            </text>
          </div>
          <div className="divider-down"></div>
        </Col>
      </Row>
    </div>
  );
};

export default ExperienciaCreada;
