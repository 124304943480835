// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomTableAntd_darkmodeCheckbox__ruqGm div div div div div table thead tr th  label span span { 
    border: 1px solid #FFF !important; 
} 
.CustomTableAntd_darkmodeCheckbox__ruqGm div div div div div table tbody tr td label span span { 
    border: 1px solid #FFF !important; 
}

.CustomTableAntd_fuente__7KdmK div div div div  { 
    font-size: 13px !important; 
} 
/* 575757 */`, "",{"version":3,"sources":["webpack://./src/componentes/table/CustomTableAntd.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;AACA;IACI,iCAAiC;AACrC;;AAEA;IACI,0BAA0B;AAC9B;AACA,WAAW","sourcesContent":[".darkmodeCheckbox div div div div div table thead tr th  label span span { \n    border: 1px solid #FFF !important; \n} \n.darkmodeCheckbox div div div div div table tbody tr td label span span { \n    border: 1px solid #FFF !important; \n}\n\n.fuente div div div div  { \n    font-size: 13px !important; \n} \n/* 575757 */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkmodeCheckbox": `CustomTableAntd_darkmodeCheckbox__ruqGm`,
	"fuente": `CustomTableAntd_fuente__7KdmK`
};
export default ___CSS_LOADER_EXPORT___;
