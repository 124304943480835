import React, { useEffect, useState } from 'react'
import {} from 'react-router-dom'
import { Button, Col, Form, Row } from 'antd'
import { renderInput } from 'utils/inputs'
import 'antd/dist/antd.css'

const ExperienciaNewPagar = ({ form, newForm, setNewForm, onFinish }) => {
    const [pricesWithComisions, setpricesWithComisions] = useState({
        adult: null,
        children: null,
    })

    const inputs = [
        {
            label: (
                <span>
                    Adultos{' '}
                    <text className="text-grey" style={{ fontSize: '16px' }}>
                        + Comisión Tugo <text className="bold">15%</text> = $
                        {pricesWithComisions.adult || 0}
                    </text>
                </span>
            ),
            name: 'adult',
            placeholder: 'USD$ 25.20',
            type: 'number',
            rules: [
                { required: true, message: 'Ingrese un precio para adultos' },
            ],
            prefix: '$',
        },
        {
            label: (
                <span>
                    Niños{' '}
                    <text className="text-grey" style={{ fontSize: '16px' }}>
                        + Comisión Tugo <text className="bold">15%</text> = $
                        {pricesWithComisions.children || 0}
                    </text>
                </span>
            ),
            name: 'children',
            type: 'number',
            placeholder: 'USD$ 18.20',
            prefix: '$',
            rules: [
                { required: true, message: 'Ingrese un precio para niños' },
            ],
        },
        {
            label: (
                <span>
                    <p style={{ fontWeight: 'bold' }}>Cantidad de personas</p>
                    <p style={{ fontSize: 16 }}>
                        Agrega la cantidad minima de personas para aplicar el
                        descuento
                    </p>
                </span>
            ),
            name: 'discountPeople',
            placeholder: '10 personas',
            type: 'number',
        },
        {
            label: (
                <span>
                    <p style={{ fontWeight: 'bold' }}>Aplica descuento </p>
                    <p style={{ fontSize: 16 }}>
                        Descuento a aplicar desde los {newForm.discountPeople}{' '}
                        cupos reservados
                    </p>
                </span>
            ),
            name: 'discountPrice',
            placeholder: 'USD$ 5.25',
            prefix: '$',
            type: 'number',
        },
        {
            label: (
                <span>
                    <p style={{ fontWeight: 'bold' }}>Cupón</p>

                    <p style={{ fontSize: 16 }}>
                        Puedes generar cupones de descuento para compartir.
                    </p>
                </span>
            ),
            name: 'couponCode',
            placeholder: 'Ej: PROMOSURFGT',
        },
        {
            label: (
                <span>
                    <p style={{ fontWeight: 'bold' }}>Porcentaje</p>

                    <p style={{ fontSize: 16 }}>
                        Agrega el porcentaje de descuento del cupón{' '}
                    </p>
                </span>
            ),
            placeholder: '15%',
            suffix: '%',
            name: 'couponDiscount',
            type: 'number',
        },
    ]

    const calculatedComissions = (price) => {
        const parsePrice = parseFloat(price)
        const comisionVSNet =
            parsePrice +
            (parsePrice * parseFloat(process.env.REACT_APP_VSNET_COMISION)) /
                100
        const total =
            comisionVSNet +
            (comisionVSNet * parseFloat(process.env.REACT_APP_TUGO_COMISION)) /
                100

        return total
    }

    useEffect(() => {
        setpricesWithComisions({
            adult: newForm?.adult
                ? calculatedComissions(newForm.adult).toFixed(2)
                : '',
            children: newForm?.children
                ? calculatedComissions(newForm.children).toFixed(2)
                : '',
        })
    }, [newForm?.adult, newForm?.children])

    useEffect(() => {
        setpricesWithComisions({
            adult: newForm?.adult
                ? calculatedComissions(newForm.adult).toFixed(2)
                : '',
            children: newForm?.children
                ? calculatedComissions(newForm.children).toFixed(2)
                : '',
        })
    }, [])

    return (
        <div className="container-sm page_pay">
            <section lg={10} xs={12}>
                <h5 className="bold">Pon precio a tu experiencia</h5>
                <text style={{ fontSize: 16 }}>
                    Si no varian los precios de edades puedes dejar el campo
                    vacio, en tu precio toma en cuenta que nos dejarás una
                    comisión del 15%.
                </text>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    style={{ marginTop: '4rem' }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Row gutter={20}>
                        {inputs.map((input, index) => (
                            <Col span={12} key={index}>
                                <Form.Item
                                    label={input.label}
                                    name={input.name}
                                    rules={input.rules}
                                    className="mt-3"
                                >
                                    {renderInput(input, newForm, setNewForm)}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <div
                            className=" bold navbar-sm-more-host exp-fixed-bottom"
                            style={{ width: '0px' }}
                        >
                            <Button
                                className="bold  btn-primary-host btn-reserva "
                                style={{ borderRadius: '6px', color: 'black' }}
                                type="primary"
                                htmlType="submit"
                            >
                                Guardar
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </section>
            <br />
        </div>
    )
}

export default ExperienciaNewPagar
