import axios from 'axios'
import { BASE_PATH } from '../componentes/constants'

export const getData = async ({
    path,
    token,
    params,
    cancelToken,
    platform,
}) => {
    try {
        const { data, status } = await axios.get(`${BASE_PATH}/${path}`, {
            headers: {
                Authorization: `Bearer ${token ?? ''}`,
                platform: platform ? platform : null,
            },
            params: params ?? {},
            cancelToken: cancelToken ?? '',
        })

        return status === 200 ? data : null
    } catch (error) {
        if (axios.isCancel(error)) {
            console.error('cancelled => ', error)
        } else {
            console.error(error)
        }
        return null
    }
}

export const postData = async ({
    path,
    token,
    params,
    value,
    platform,
    cancelToken,
}) => {
    try {
        const { data, status } = await axios.post(
            `${BASE_PATH}/${path}`,
            value,
            {
                headers: {
                    Authorization: `Bearer ${token ?? ''}`,
                    platform: platform ?? null,
                },
                params: params ?? {},
                cancelToken: cancelToken ?? '',
            }
        )

        return data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const putData = async ({
    path,
    token,
    params,
    value,
    id,
    cancelToken,
}) => {
    console.log('dentro de putData')
    try {
        const { data, status } = await axios.put(
            `${BASE_PATH}/${path}/${id}`,
            value,
            {
                headers: {
                    Authorization: `Bearer ${token ?? ''}`,
                },
                params: params ?? {},
                cancelToken: cancelToken ?? '',
            }
        )

        return status === 200 ? data : null
    } catch (error) {
        if (axios.isCancel(error)) {
            console.error('cancelled => ', error)
        } else {
            console.error(error)
        }
        return error
    }
}

export const deleteData = async ({ path, token, id }) => {
    try {
        const { data, status } = await axios.delete(
            `${BASE_PATH}/${path}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token ?? ''}`,
                },
            }
        )

        return status === 200 || status === 204 ? data : null
    } catch (error) {
        if (axios.isCancel(error)) {
            console.error('cancelled => ', error)
        } else {
            console.error(error)
        }
        return null
    }
}
