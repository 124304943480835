import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import Titulo from '../Titulo'
import TablaCategorias from './items/TablaCategorias'
import { useOutletContext } from 'react-router-dom'
import { MessageUtils } from 'utils'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'

const AdminCategorias = () => {
    const { user } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [categorias, setCategorias] = useState([])
    const [action, setAction] = useState('new')
    const [formValues, setFormValues] = useState({
        icon: 'icono',
        name: '',
    })
    const [form] = Form.useForm()

    const inicioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({
                path: `categories`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            setCategorias(responseData?.rows)
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        inicioComponente()
    }, [])

    const onFinish = async () => {
        try {
            await postData({
                path: `categories`,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                },
            })
            setFormValues({
                name: '',
                description: '',
                expirationDate: null,
                experiences: [],
            })
            inicioComponente()
            setOpen(false)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const updateCategory = async () => {
        try {
            await putData({
                path: `categories`,
                id: formValues.id,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                },
            })
            MessageUtils.success('Categoría modificada con éxito')
            inicioComponente()
            onCloseDrawer()
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar la categoría'
            )
        }
    }

    const deleteCategory = async () => {
        try {
            const response = await deleteData({
                path: `categories`,
                id: formValues.id,
                token: user.logged && user.data.token,
            })

            if (response || response === '') {
                MessageUtils.success('Categoría eliminado con éxito')
                inicioComponente()
                setOpenModal(false)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar la categoría'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar la categoría'
            )
        }
    }

    const onEdit = (data) => {
        const values = { ...data, name: data.category }
        setFormValues(values)
        form.setFieldsValue(values)
        setOpen(true)
        setAction('edit')
    }

    const onDelete = (category) => {
        setFormValues(category)
        setOpenModal(true)
    }

    const onCancelModal = () => {
        setFormValues({ name: '' })
        form.resetFields()
        setOpenModal(false)
    }

    const onCloseDrawer = () => {
        setFormValues({ name: '' })
        form.resetFields()
        setOpen(false)
        setTimeout(() => {
            setAction('new')
        }, 300)
    }

    return (
        <div>
            <Titulo
                titulo={'Lista de Categorias'}
                tituloBoton={'Crear Categoria'}
                onAcccion={() => setOpen(true)}
            />

            <div>
                {loading ? (
                    <Spinner />
                ) : (
                    <TablaCategorias
                        data={categorias}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                )}
            </div>
            <SidebarForm
                title={
                    action === 'new' ? 'Crear Categoría' : 'Editar Categoría'
                }
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                form={form}
                formValues={formValues}
                onCloseDrawer={onCloseDrawer}
                open={open}
                setFormValues={setFormValues}
                onFinish={action === 'new' ? onFinish : updateCategory}
                inputs={[
                    {
                        label: 'Categoria',
                        name: 'name',
                        placeholder: 'Nombre',
                        rules: [
                            {
                                required: true,
                                message: 'Ingrese un nombre',
                            },
                        ],
                    },
                ]}
            />
            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={deleteCategory}
                openModal={openModal}
                strongText={
                    categorias?.find(
                        (category) => category.id === formValues.id
                    )?.category
                }
                text={`¿Estás seguro de que deseas eliminar la siguiente categoría`}
                title={'Eliminar Categoría'}
            />
        </div>
    )
}

export default AdminCategorias
