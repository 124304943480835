import React, { useState, useEffect, useContext } from "react";
// import ImagePicker from 'react-image-picker'
import axios from "axios";
import { BASE_PATH } from "../../constants";
import { AuthContext } from "../../../auth/AuthContext";
import PickerImage from "./elementos/ImagePicker";
import { Skeleton } from "antd";

//import images from local
import img1 from "../../../assets/images/gustos/host/amor.svg";
import img2 from "../../../assets/images/gustos/host/aves.svg";
import img3 from "../../../assets/images/gustos/host/bici.svg";
import img4 from "../../../assets/images/gustos/host/bienestar.svg";
import img5 from "../../../assets/images/gustos/host/camp.svg";
import img6 from "../../../assets/images/gustos/host/caminata.svg";
import img7 from "../../../assets/images/gustos/host/ciudad.svg";
import img8 from "../../../assets/images/gustos/host/comida.svg";
import img9 from "../../../assets/images/gustos/host/correr.svg";
import img10 from "../../../assets/images/gustos/host/deportes.svg";
import img11 from "../../../assets/images/gustos/host/escalar.svg";
import img12 from "../../../assets/images/gustos/host/fiesta.svg";
import img13 from "../../../assets/images/gustos/host/musica.svg";
import img14 from "../../../assets/images/gustos/host/nadar.svg";
import img15 from "../../../assets/images/gustos/host/navegar.svg";
import img16 from "../../../assets/images/gustos/host/playa.svg";
import img17 from "../../../assets/images/gustos/host/surf.svg";
import img18 from "../../../assets/images/gustos/host/torneo.svg";
import img19 from "../../../assets/images/gustos/host/volcan.svg";
import img20 from "../../../assets/images/gustos/host/astro.svg";
import img21 from "../../../assets/images/gustos/host/cultura.svg";
import img22 from "../../../assets/images/gustos/host/storytelling.svg";
import img23 from "../../../assets/images/gustos/host/artesania.svg";

const newImageList = {
  Amor: { image: img1, isSelected: false },
  Aves: { image: img2, isSelected: false },
  Bicicleta: { image: img3, isSelected: false },
  Bienestar: { image: img4, isSelected: false },
  Campamento: { image: img5, isSelected: false },
  Caminata: { image: img6, isSelected: false },
  Urbano: { image: img7, isSelected: false },
  Comida: { image: img8, isSelected: false },
  Correr: { image: img9, isSelected: false },
  Extremo: { image: img10, isSelected: false },
  Escalada: { image: img11, isSelected: false },
  Fiesta: { image: img12, isSelected: false },
  Musica: { image: img13, isSelected: false },
  Nadar: { image: img14, isSelected: false },
  Navegar: { image: img15, isSelected: false },
  Playa: { image: img16, isSelected: false },
  Surf: { image: img17, isSelected: false },
  Torneos: { image: img18, isSelected: false },
  Volcanes: { image: img19, isSelected: false },
  Astrologia: { image: img20, isSelected: false },
  Cultura: { image: img21, isSelected: false },
  Storytelling: { image: img22, isSelected: false },
  Artesanías: { image: img23, isSelected: false },
};
const ExperienciaNewGustos = ({ cats, selectCategories }) => {
  
  const { user } = useContext(AuthContext);
  const [categorys, setCategorys] = useState([]);
  const [loading, setloading] = useState(true);


  const onSelected = (e) => selectCategories(e)


  const getCategorys = async () => {
    try {
      const result = await axios.get(`${BASE_PATH}/categories?isEnabled=true`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
          platform: user?.data?.platform?.id
        },
      });

      if (result.status === 200) {
        // console.log(result.data.rows);
        const allCategories = result.data.rows
          .map((cat) => {
            return {
              id: cat.id,
              name: cat.category,
              ...newImageList[cat.category],
            };
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

        console.log('categorias llenas', allCategories);
        setCategorys(allCategories);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {

    getCategorys();

  }, []);

  return (
    <div style={{ paddingBottom: "6rem" }}>

      {loading && (
        <>
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: "1rem", paddingBotton: "4rem" }}
          >
            <div className="page_pay">
              <div className="image_picker">
                {Object.keys(newImageList).map((key) => (
                  <Skeleton key={key} className="ip_tarjeta no-events" active />
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && categorys.length > 0 && (
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "1rem", paddingBotton: "4rem" }}
        >
          <div className="page_pay">
            <PickerImage
              categorys={categorys}
              onSelected={onSelected}
              selectes={cats}
            ></PickerImage>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExperienciaNewGustos;
