import { Avatar, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { RiCamera2Fill } from 'react-icons/ri'
import { Loader } from 'rsuite'

const UploadImageCard = ({ picture, disabled, onChange }) => {
    return (
        <>
            <Avatar size={170} src={picture} />
            <ImgCrop rotate>
                <Upload
                    className="update-usr-picture"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture"
                    maxCount={1}
                    beforeUpload={() => false}
                    onChange={onChange}
                >
                    <button
                        disabled={disabled}
                        className="photo-edit flex justify-center items-center"
                    >
                        {disabled ? (
                            <Loader backdrop vertical />
                        ) : (
                            <RiCamera2Fill />
                        )}
                    </button>
                </Upload>
            </ImgCrop>
        </>
    )
}

export default UploadImageCard
