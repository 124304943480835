import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import '../../../assets/css/App.css'
import { Col, Row } from 'rsuite'
import SimpleMap from '../../Experiencias/mapsAdd'
import { MessageUtils } from 'utils'

const AgregarUbicacion = ({ dataUb, direccionn }) => {
    const [ubicacion, setUbicacion] = useState({
        lat: 14.6349149,
        lng: -90.5068824,
    })
    const [direccion, setDireccion] = useState(false)
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    })
    const ref = useOnclickOutside(() => {
        clearSuggestions()
    })

    const handleInput = (e) => {
        setValue(e.target.value)
    }

    const handleSelect =
        ({ description }) =>
        () => {
            setValue(description, false)
            clearSuggestions()

            getGeocode({ address: description })
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    setUbicacion({ lat: lat, lng: lng })
                    setDireccion(description)
                })
                .catch((error) => {
                    console.error('😱 Error: ', error)
                })
        }

    const renderSuggestions = () =>
        data.map((suggestion, index) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion

            return (
                <li
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                    style={{
                        borderRadius: '100px',
                        backgroundColor: '#2a2b2c',
                        padding: '1.2rem 1rem',
                        borderTop: '1px solid black',
                        color: 'white',
                    }}
                >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            )
        })

    const agregar = () => {
        ubicacion === false
            ? MessageUtils.warning('Seleccionar una dirección')
            : direccionn(ubicacion)
    }

    useEffect(() => {
        if (dataUb) {
            setUbicacion({
                lat: parseFloat(dataUb.lat),
                lng: parseFloat(dataUb.lng),
            })
        }
    }, [dataUb])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            {/* <div ref={ref} className="text_map">
                <input
                    className="form-map"
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder="Busca la ubicación"
                />
                
                {status === 'OK' && <ul>{renderSuggestions()}</ul>}
            </div> */}
            <div style={{ flexGrow: 1 }}>
                <SimpleMap location={ubicacion} setLoaction={setUbicacion} />
            </div>
            <Row>
                <Col
                    md={24}
                    className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center"
                >
                    <button
                        type="button"
                        onClick={agregar}
                        className="bold  btn-map"
                    >
                        Agregar
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default AgregarUbicacion
