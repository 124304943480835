import { Avatar, Button, Col, Drawer, Row, Space } from 'antd'
import React from 'react'
import TablaHistorial from './TablaHistorial'
import { PiPrinterBold } from "react-icons/pi";

const Viajero = ({
    close,
    openViajero,
    viajero
}) => {
    return (
        <Drawer
            width={700}
            title={<text className='bold titulo_table'>Viajero</text>}
            placement="right"
            onClose={() => close()}
            visible={openViajero}
            extra={
                <Space className='mr-2'>
                  <Button type="primary" className='bold btn_desvlop' style={{ background: '#8EC715', color: '#000000', borderRadius: '10px', border: '1px solid #000' }}>
                    <PiPrinterBold className='icon_menu_20'/>
                    Imprimir
                  </Button>
                </Space>
              }
        >
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col md={8} sm={12} xs={12}>
                    <Avatar shape="square" size={200} src={viajero?.picture} />
                </Col>
                <Col md={16} sm={12} xs={12}>
                    <Col md={24} sm={24} xs={24}>
                        <h4 style={{ marginBottom: '0px' }}>{viajero?.name}</h4>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <h5 style={{ marginBottom: '0px' }}>{viajero?.email}</h5>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <h5 style={{ marginBottom: '0px' }}>{viajero?.direction}</h5>
                    </Col>
                </Col>

                <Col md={24} sm={24} xs={24}>
                    <h5 style={{ marginTop: '10px' }}>Historial</h5>
                </Col>
                <Col md={24} sm={24} xs={24}>
                    <TablaHistorial data={[]} />
                </Col>
            </Row>
        </Drawer>
    )
}

export default Viajero
