import { Button, Drawer, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import { ConstructorFormAntd } from '../../../Forms/ConstructorFormAnt'
import { OPTION_TYPE_FORM } from '../../../InputForm/InputForm'
import Titulo from '../Titulo'
import TablaDescuentos from './items/TablaDescuentos'
import moment from 'moment'
import { PiMagicWandBold } from 'react-icons/pi'
import { useOutletContext } from 'react-router-dom'
import PaginationCom from 'componentes/pagination/PaginationCom'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import { MessageUtils } from 'utils'

const initForm = {
    description: '',
    expirationDate: null,
    minNumberTickets: null,
    maxNumberTickets: null,
    percentage: null,
    discountCode: null,
}

const AdminDescuentos = () => {
    const { user } = useOutletContext()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [descuentos, setDescuentos] = useState([])
    const [discountCodeSelectedId, setDiscountCodeSelectedId] = useState('')
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState(null)
    const [action, setAction] = useState('new')
    const [formValues, setFormValues] = useState(initForm)
    const [form] = Form.useForm()
    const [openModal, setOpenModal] = useState(false)

    const role = user?.data?.user?.role

    const platform =
        role === 'company' ? user?.data?.user?.config : user?.data?.platform?.id

    const inicioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({
                // path: `codes`,
                path: `codes/?page=${page}&limit=10&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: platform,
            })
            setDescuentos(responseData[0].result)
            setMeta(responseData)
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setLoading(false)
        }
    }

    // useEffect(() => {
    //     inicioComponente()
    // }, [])

    useEffect(() => {
        inicioComponente()
    }, [page])

    const onFinish = async () => {
        try {
            const response = await postData({
                path: `codes`,
                token: user.logged && user.data.token,
                params: {},
                value: formValues,
            })
            inicioComponente()
            onCloseDrawer()
            MessageUtils.success(
                'Se ha creado un código de descuento con éxito'
            )
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar crear un código de descuento'
            )
        } finally {
            setLoading(false)
        }
    }

    const updateDiscountCode = async () => {
        try {
            const response = await putData({
                path: `codes`,
                id: discountCodeSelectedId,
                token: user.logged && user.data.token,
                params: {},
                value: formValues,
            })
            if (response) {
                MessageUtils.success('Código de descuento modificado con éxito')
                inicioComponente()
                onCloseDrawer()
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar modificar el código de descuento'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar el código de descuento '
            )
        }
    }

    const onEdit = (discountCode) => {
        const values = {
            description: discountCode.description,
            expirationDate: moment(
                discountCode.expirationDate,
                'D [de] MMMM [de] YYYY',
                'es'
            ),
            minNumberTickets: discountCode.minNumberTickets,
            maxNumberTickets: discountCode.maxNumberTickets,
            percentage: discountCode.percentage.replace('%', ''),
            discountCode: discountCode.discountCode,
        }
        setFormValues(values)
        form.setFieldsValue(values)
        setOpen(true)
        setAction('edit')
        setDiscountCodeSelectedId(discountCode._id)
    }

    const onDelete = async () => {
        try {
            const response = await deleteData({
                path: `codes`,
                id: discountCodeSelectedId,
                token: user.logged && user.data.token,
            })
            console.log(response)
            if (response || response === '') {
                MessageUtils.success(
                    'Se ha eliminado el código de descuento con éxito'
                )
                inicioComponente()
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar el código de descuento'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar el código de descuento'
            )
            console.log(error)
        } finally {
            setOpenModal(false)
            setDiscountCodeSelectedId('')
        }
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setOpen(false)
        setTimeout(() => {
            setAction('new')
        }, 300)
    }

    const onCancelModal = () => {
        setDiscountCodeSelectedId('')
        setOpenModal(false)
    }

    const handleDeleteButton = (discountCode) => {
        setDiscountCodeSelectedId(discountCode._id)
        setOpenModal(true)
    }

    return (
        <div>
            <Titulo
                titulo={'Codigos de descuentos'}
                tituloBoton={'Crear Código'}
                onAcccion={() => setOpen(true)}
                button={role !== 'merchant'}
            />
            {loading && !open ? (
                <Spinner />
            ) : (
                <>
                    <TablaDescuentos
                        data={descuentos?.map((item) => {
                            return {
                                ...item,
                                expirationDate: moment(
                                    item.expirationDate
                                ).format('LL'),
                                percentage: `${item.percentage}%`,
                            }
                        })}
                        onEdit={onEdit}
                        showActions={role !== 'merchant'}
                        onDelete={handleDeleteButton}
                    />
                    {descuentos.length > 0 && (
                        <PaginationCom
                            page={page}
                            meta={meta}
                            setPage={(value) => setPage(value)}
                        />
                    )}
                </>
            )}
            <SidebarForm
                title={action === 'new' ? 'Crear Código' : 'Editar Código'}
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                form={form}
                formValues={formValues}
                onFinish={action === 'new' ? onFinish : updateDiscountCode}
                open={open}
                setFormValues={setFormValues}
                onCloseDrawer={onCloseDrawer}
                width={500}
                inputs={[
                    {
                        label: 'Descripción',
                        name: 'description',
                        type: 'textArea',
                        rules: [{ required: true, message: 'Descripción' }],
                    },
                    {
                        label: 'Fecha de expiración',
                        name: 'expirationDate',
                        type: 'date',
                        rules: [{ required: true, message: 'Fecha' }],
                    },
                    {
                        label: 'Minimo de boletos',
                        name: 'minNumberTickets',
                        rules: [{ required: true, message: 'Minimo' }],
                    },
                    {
                        label: 'Maximo de boletos',
                        name: 'maxNumberTickets',
                        rules: [{ required: true, message: 'Maximo' }],
                    },
                    {
                        label: 'Porcentaje',
                        name: 'percentage',
                        rules: [{ required: true, message: '%' }],
                    },
                    {
                        label: 'Codigo de descuento',
                        name: 'discountCode',
                        rules: [{ required: true, message: 'Codigo' }],
                    },
                ]}
            />
            {/* <Drawer
                width={500}
                title={action === 'new' ? 'Crear Código' : 'Editar Código'}
                placement="right"
                onClose={() => setOpen(false)}
                visible={open}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <ConstructorFormAntd
                        inputsForRows={1}
                        modeLarge={true}
                        listInputs={[
                            // { label: 'Coodigo', name: 'name', rules: [{ required: true, message: 'Codigo descuento' }] },
                            {
                                label: 'Descripción',
                                name: 'description',
                                type: OPTION_TYPE_FORM.textArea,
                                rules: [
                                    { required: true, message: 'Descripción' },
                                ],
                            },
                            {
                                label: 'Fecha de expiración',
                                name: 'expirationDate',
                                type: OPTION_TYPE_FORM.date,
                                rules: [{ required: true, message: 'Fecha' }],
                            },
                            {
                                label: 'Minimo de boletos',
                                name: 'minNumberTickets',
                                rules: [{ required: true, message: 'Minimo' }],
                            },
                            {
                                label: 'Maximo de boletos',
                                name: 'maxNumberTickets',
                                rules: [{ required: true, message: 'Maximo' }],
                            },
                            {
                                label: 'Porcentaje de descuento',
                                name: 'percentage',
                                rules: [{ required: true, message: '%' }],
                            },
                            {
                                label: 'Codigo de descuento',
                                name: 'discountCode',
                                rules: [{ required: true, message: 'Codigo' }],
                            },
                        ]}
                        values={formValues} // Pasa los valores del formulario
                        formValues={formValues}
                        setValues={setFormValues}
                    />

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bold btn_crear "
                        >
                            <PiMagicWandBold className="icon_menu_20" /> Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer> */}
            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={onDelete}
                openModal={openModal}
                strongText={
                    descuentos.find(
                        (discountCode) =>
                            discountCode._id === discountCodeSelectedId
                    )?.name
                }
                text={`¿Estás seguro de que deseas eliminar el código de descuento?`}
                title={'Eliminar código de descuento'}
            />
        </div>
    )
}

export default AdminDescuentos
