import { Form, Skeleton } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../../../auth/AuthContext'
import { useWindowsSize } from '../../../../hooks/WindowsSize'
import { BASE_PATH } from '../../../constants'
import ScheduleCard from './components/card'
import { RiAddFill } from 'react-icons/ri'
import {
    dateOptions,
    daysOfWeek,
    daysOfWeekObject,
    disabledDate,
    getMaximDueDate,
    periodicities,
    periodicitiesObject,
    setTimeInDate,
} from './utils'
import { MessageUtils } from 'utils'
import { CancelConfirmModal, SidebarForm } from 'componentes/commons'
import moment from 'moment'
import momenttz from 'moment-timezone'

const initStateScheduleForm = {
    periodicity: '',
    time: {
        dateFormat: momenttz.tz(new Date(), 'America/El_Salvador').toDate(),
        timeFormat: '',
    },
    days: [],
    title: '',
    from: new Date(),
    dueDate: getMaximDueDate(new Date()),
    start: '',
}

const Schedules = () => {
    const { experienciaId } = useParams()
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [wSize] = useWindowsSize()
    const [experienceData, setexperienceData] = useState(null)
    const [schedules, setschedules] = useState([])
    const [loading, setloading] = useState(true)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [showModal, setshowModal] = useState(false)
    const [scheduleForm, setScheduleForm] = useState(initStateScheduleForm)
    const [formValues, setFormValues] = useState(initStateScheduleForm)
    const [action, setAction] = useState('new')
    const [open, setOpen] = useState(false)
    const [form] = Form.useForm()
    const [selectedScheduleId, setSelectedScheduleId] = useState('')
    console.log(formValues)
    const getExperience = async () => {
        try {
            const resp = await axios.get(
                `${BASE_PATH}/experiences/${experienciaId}`
            )
            if (resp.status === 200) {
                setexperienceData(resp.data)
                getSchedules()
            }
        } catch (e) {
            console.error(e)
            MessageUtils.error('Ha ocurrido un error')
        }
    }

    const getSchedules = async () => {
        try {
            const resp = await axios.get(
                `${BASE_PATH}/schedules?experience=${experienciaId}`
            )

            if (resp.status === 200) {
                setschedules(resp.data.rows.filter((el) => el.isEnabled))
                setloading(false)
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
        }
    }

    const navigateToBack = () => {
        navigate(`/host/experiencia/_/${experienciaId}`, { replace: true })
    }

    const selectSchedule = (schedule) => {
        setAction('edit')
        let time = setTimeInDate(schedule.start || '00:00', new Date())
        let data = {
            periodicity: schedule.periodicity.repeat,
            time: {
                dateFormat: time,
                timeFormat: schedule.start,
            },
            start: moment(schedule.start).local(),
            days: schedule.periodicity.days,
            title: schedule.name,
            from: moment(schedule.date),
            dueDate:
                moment(schedule.dueDate) ||
                getMaximDueDate(new Date(schedule.date)),
            id: schedule.id,
        }

        setFormValues(data)
        form.setFieldsValue(data)
        setOpen(true)
    }

    const onSaveNewSchedule = async () => {
        const { days, dueDate, periodicity, from, time, title, start } =
            formValues
        const newScheduleData = {
            experience: experienciaId,
            user: user.data.user.id,
            periodicity: {
                days,
                repeat: periodicity,
            },
            dueDate: momenttz.tz(dueDate, 'America/El_Salvador').format(),
            date: momenttz.tz(from, 'America/El_Salvador').format(),
            name: title,
            start: momenttz.tz(start, 'America/El_Salvador').format(),
        }

        try {
            const resp = await axios.post(
                `${BASE_PATH}/schedules`,
                newScheduleData,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 201) {
                getSchedules()
                setOpen(false)
                setFormValues(initStateScheduleForm)
                setAction('new')
                MessageUtils.success('Se ha creado el nuevo horario')
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar crear un nuevo horario'
                )
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Algo salió mal, inténtalo de nuevo')
        }
    }

    const onUpdateSchedule = async () => {
        const { days, dueDate, periodicity, from, time, title, id, start } =
            formValues
        try {
            const newScheduleData = {
                experience: experienciaId,
                periodicity: {
                    days,
                    repeat: periodicity,
                },
                dueDate: momenttz.tz(dueDate, 'America/El_Salvador').format(),
                date: momenttz.tz(from, 'America/El_Salvador').format(),
                name: title,
                start: momenttz.tz(start, 'America/El_Salvador').format(),
            }

            const resp = await axios.put(
                `${BASE_PATH}/schedules/${id}`,
                newScheduleData,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 200) {
                getSchedules()
                onCloseDrawer()
                MessageUtils.success('Se ha actualizado el horario')
            } else {
                MessageUtils.error('Algo salió mal, inténtalo de nuevo')
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Algo salió mal, inténtalo de nuevo')
        }
    }

    const handleNewSchedule = () => {
        setOpen(true)
    }

    const handleDeleteButton = (id) => {
        setshowModal(true)
        setSelectedScheduleId(id)
    }

    const handeDeleteSchedule = async () => {
        try {
            setloading(true)
            const resp = await axios.delete(
                `${BASE_PATH}/schedules/${selectedScheduleId}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 204) {
                getSchedules()
                setshowModal(false)
                setSelectedScheduleId('')
                MessageUtils.success('¡El horario se ha eliminado con éxito!')
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar el horario'
                )
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar el horario'
            )
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const onCloseDrawer = () => {
        setFormValues(initStateScheduleForm)
        form.resetFields()
        setOpen(false)
        setSelectedScheduleId('')
        setAction('new')
    }

    const onCancelModal = () => {
        setSelectedScheduleId('')
        setshowModal(false)
    }

    useEffect(() => {
        if (user.logged) {
            getExperience()
        }
    }, [user])

    const disabledBeforeMinDate = (current) => {
        return current && current < formValues.dueDate
    }
    return (
        <>
            <nav className="navbar-titles navbar-light d-flex align-items-center">
                <button
                    type="button"
                    className="back-window-navbar"
                    onClick={navigateToBack}
                >
                    <i className="s icon-left-arrow"></i>
                </button>
                <h5 className="bold">
                    Agrega o edita horarios{' '}
                    {/* disponible para{" "}
                    {experienceData?.name || ""}*/}
                </h5>
            </nav>

            <div className="schedules-page-content-list">
                <div className="schedules-page-content-list-grid">
                    {loading &&
                        [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                            <article className="schedule-item" key={index}>
                                <Skeleton
                                    title={false}
                                    loading={loading}
                                    active
                                ></Skeleton>
                                <Skeleton
                                    title={false}
                                    loading={loading}
                                    active
                                ></Skeleton>
                                <Skeleton
                                    title={false}
                                    loading={loading}
                                    active
                                ></Skeleton>
                            </article>
                        ))}
                </div>

                <div className="schedules-page-content-list-grid">
                    {!loading &&
                        schedules.map((item, i) => (
                            <ScheduleCard
                                isSelected={item.id === selectedScheduleId}
                                key={i}
                                title={item.name || 'Horario sin título'}
                                days={
                                    item.periodicity
                                        ? item.periodicity.days
                                              .map(
                                                  (day) => daysOfWeekObject[day]
                                              )
                                              .join(', ')
                                        : 'no se han definido'
                                }
                                time={
                                    item.start
                                        ? momenttz(item.start)
                                              .tz('America/El_Salvador')
                                              .format('h:mm a')
                                        : 'no se ha definido'
                                }
                                from={new Date(item.date).toLocaleDateString(
                                    'es-ES',
                                    dateOptions
                                )}
                                to={new Date(item.dueDate).toLocaleDateString(
                                    'es-ES',
                                    dateOptions
                                )}
                                repeat={
                                    periodicitiesObject[
                                        item.periodicity.repeat
                                    ] || 'no se ha definido'
                                }
                                onSelect={() => selectSchedule(item)}
                                onDelete={() => handleDeleteButton(item.id)}
                                id={item.id}
                            />
                        ))}
                </div>

                {!loading && schedules.length === 0 && (
                    <h6
                        style={{ textAlign: 'center', marginTop: '100px' }}
                        className="bold"
                    >
                        Aún no has agregado horarios
                    </h6>
                )}
            </div>
            <SidebarForm
                title={`¡
                    ${action === 'edit' ? 'Actualiza este' : 'Agrega un nuevo'} horario!`}
                buttonText={action === 'edit' ? 'Editar' : 'Crear'}
                form={form}
                formValues={formValues}
                width={450}
                onCloseDrawer={onCloseDrawer}
                open={open}
                setFormValues={setFormValues}
                onFinish={
                    action === 'edit' ? onUpdateSchedule : onSaveNewSchedule
                }
                inputs={[
                    {
                        label: 'Título',
                        name: 'title',
                        placeholder: 'Título',
                        rules: [
                            {
                                required: true,
                                message: 'Ingrese un título',
                            },
                        ],
                    },
                    {
                        label: 'Repetir cada',
                        name: 'periodicity',
                        placeholder: 'Seleccionar',
                        type: 'select',
                        items: periodicities.map((item) => {
                            return {
                                label: item.label,
                                value: item.value,
                            }
                        }),
                        rules: [
                            {
                                required: true,
                                message: 'Seleccione una opción',
                            },
                        ],
                    },
                    {
                        label: 'Días',
                        name: 'days',
                        type: 'select',
                        modeSelect: 'multiple',
                        placeholder: 'seleccionar',
                        items: daysOfWeek.map((item) => {
                            return {
                                label: item.label,
                                value: item.value,
                            }
                        }),
                        rules: [
                            {
                                required: true,
                                message: 'Elige los días',
                            },
                        ],
                    },
                    {
                        label: 'Hora de Inicio',
                        name: 'start',
                        type: 'time',
                        placeholder: 'Seleccionar',
                        rules: [
                            {
                                required: true,
                                message: 'Seleccione la hora de inicio',
                            },
                        ],
                    },
                    {
                        label: 'Fecha de inicio',
                        name: 'from',
                        type: 'date',
                        defaultValue: new Date(),
                        disableDate: disabledDate,
                    },
                    {
                        label: 'Disponible hasta',
                        name: 'dueDate',
                        type: 'date',
                        disableDate: disabledBeforeMinDate,
                        defaultValue: formValues.dueDate,
                    },
                ]}
            />
            <div className="d-flex justify-content-center exp-fixed-upload">
                <button
                    type="button"
                    className="exp-fixed-bottom-open-host-upload"
                    onClick={handleNewSchedule}
                >
                    <RiAddFill style={{ fontSize: 40 }} />
                </button>
            </div>
            <CancelConfirmModal
                loading={isLoadingDelete}
                onCancelModal={onCancelModal}
                onOk={handeDeleteSchedule}
                openModal={showModal}
                strongText={
                    schedules.find(
                        (schedule) => schedule.id === selectedScheduleId
                    )?.name
                }
                text={`¿Estás seguro de que deseas eliminar `}
                title={'Eliminar Horario'}
            />
        </>
    )
}

export default Schedules
