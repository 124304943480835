import React, { useContext, useState, useEffect } from 'react'
import { Card, Row, Col, FormControl } from 'react-bootstrap'
import { TabLink, TabContent, Tabs } from 'react-tabs-redux'
import axios from 'axios'
import { types } from '../../../types/types'
import { BASE_PATH } from '../../constants'
import { Doughnut } from 'react-chartjs-2'
import { Modal, Alert, Button } from 'rsuite'
import { AuthContext } from '../../../auth/AuthContext'

//Importar Recursos

import Anfitrion from '../../Navbars/Anfitrión'
import logo from '../../../assets/images/logo.svg'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Select } from 'antd'

const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]
const { Option } = Select

const accountTypes = {
    ahorro: 'Cuenta de ahorros',
    monetaria: 'Cuenta monetaria',
}

const Pagos = () => {
    const { dispatch } = useContext(AuthContext)
    const { user } = useContext(AuthContext)
    const [openModal, setopenModal] = useState(false)
    const [dateToDay, setdateToDay] = useState({ month: '', year: '' })
    const [totalPerMonth, settotalPerMonth] = useState(0)
    const [totalRecord, settotalRecord] = useState(0)
    const navigate = useNavigate()
    const [countsPerMonth, setcountsPerMonth] = useState({
        finalized: 0,
        pending: 0,
    })
    const [bankAcount, setbanckAcount] = useState({
        titular: user?.data?.user?.bankAccount?.titular,
        numAccount: user?.data?.user?.bankAccount?.numAccount,
        bankName: user?.data?.user?.bankAccount?.bankName,
        accountType: user?.data?.user?.bankAccount?.accountType || '',
    })

    const handleChange = ({ target: { name, value } }) => {
        //console.log(name, value);
        setbanckAcount({ ...bankAcount, [name]: value })
    }

    const getPaymentsPerMonth = async () => {
        const thisYear = new Date(Date.now()).getFullYear()
        const thisMonth = months[new Date(Date.now()).getMonth()]
        //console.log(thisMonth, thisYear);
        setdateToDay({ year: thisYear, month: thisMonth })
        try {
            let payments = await axios.get(
                `${BASE_PATH}/payments/total/${user?.data.user.id}?month=${thisMonth}&year=${thisYear}`,
                {
                    access_token: user?.data.token,
                }
            )

            if (payments.status === 200 && payments.data.mes) {
                settotalPerMonth(payments.data.total)
                getTotalPaymentsPerMonth(thisMonth, thisYear)
                //console.log(payments.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTotalPaymentsPerMonth = async (month, year) => {
        try {
            let payments = await axios.get(
                `${BASE_PATH}/payments/showBookingToMonth/${user?.data.user.id}?month=${month}&year=${year}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (payments.status === 200) {
                setcountsPerMonth({
                    pending: payments.data.pendiente.total,
                    finalized: payments.data.finalizado.total,
                })
                getTotalRecord()
            }
        } catch (error) {
            Alert.error('Ha ocurrido un error en el servidor', 4000)
            console.log(error)
        }
    }

    const getTotalRecord = async () => {
        try {
            let record = await axios.get(
                `${BASE_PATH}/payments/historicalRecord/${user.data.user.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            // console.log(record);
            if (record.status === 200) {
                settotalRecord(record.data.total)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = async () => {
        const { accountType, bankName, numAccount, titular } = bankAcount

        if (!!!titular || !!!numAccount || !!!bankName || !!!accountType) {
            handleOpenModal()

            return
        }
        try {
            const updateUser = await axios.put(
                `${BASE_PATH}/users/${user.data.user.id}`,
                {
                    access_token: user?.data.token,
                    ...user.data.user,
                    bankAccount: { ...bankAcount },
                }
            )

            if (updateUser.status === 200) {
                // console.log(updateUser);
                dispatch({
                    type: types.login,
                    payload: {
                        data: {
                            token: user.data.token,
                            user: updateUser.data,
                        },
                    },
                })
                handleOpenModal()
                Alert.success('Datos actualizados con exito', 5000)
                setbanckAcount({
                    titular: '',
                    numAccount: '',
                    accountType: '',
                    bankName: '',
                })
            } else {
                //console.log("No se completo");
                handleOpenModal()
                Alert.error(
                    'No se pudieron actualizar los datos, intente de nuevo'
                )
            }
        } catch (e) {
            console.error(e)
            handleOpenModal()
        }
    }

    const handleOpenModal = () => {
        openModal ? setopenModal(false) : setopenModal(true)
    }

    useEffect(() => {
        getPaymentsPerMonth()
    }, [])

    return (
        <div className="page-pagos-host vh-100">
            <div>{/* <Anfitrion /> */}</div>
            <button
                style={{ zIndex: '9999' }}
                type="button"
                className="back-window fixed-top mr-2"
                onClick={() => navigate(-1)}
            >
                <i className="icon-back icon-left-arrow"></i>
            </button>
            <Tabs className="container-sm mt-2">
                <div className="sticky-top exp-tabs exp-tabs-host">
                    <TabLink
                        to="tab1"
                        className="exp-contenido-btn exp-active"
                        activeClassName="neon_blue"
                    >
                        <i className="demo-icon-exp icon-"></i> Balance
                    </TabLink>
                    <TabLink
                        to="tab2"
                        className="exp-contenido-btn exp-active"
                        activeClassName="neon_blue"
                    >
                        <i className="demo-icon-exp icon-"></i>Bancos
                    </TabLink>
                </div>
                <div className="d-flex justify-content-center">
                    <TabContent for="tab1">
                        <Row>
                            <Col lg={6} xs={12}>
                                <Card className="card-balance text-right">
                                    <div className="flex flex-row justify-between">
                                        <text className="pagos">
                                            Ingreso Total
                                        </text>
                                        <p className="bold ">
                                            {totalRecord}{' '}
                                            <span className="lite">USD</span>
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <text className="pagos">
                                            Ingreso Mensual
                                        </text>
                                        <p className="bold ">
                                            {totalPerMonth}{' '}
                                            <span className="lite">USD</span>
                                        </p>
                                    </div>
                                    <text
                                        style={{
                                            position: 'absolute',
                                            bottom: '1rem',
                                        }}
                                        className="text-left pagos"
                                    >
                                        {dateToDay.month} {dateToDay.year}
                                    </text>
                                </Card>
                            </Col>
                            <Col lg={6} xs={12}>
                                <div className="mt-16">
                                    <Doughnut
                                        className="pagos-graficas"
                                        data={{
                                            labels: ['Pendiente', 'Activo'],
                                            datasets: [
                                                {
                                                    label: '# of Votes',
                                                    data: [
                                                        // countsPerMonth.pending,
                                                        // countsPerMonth.finalized,
                                                        20,
                                                        5,
                                                    ],
                                                    backgroundColor: [
                                                        '#2a2b2c',
                                                        'green',
                                                    ],
                                                    borderColor: [
                                                        'rgba(255, 99, 132, 0.2)',
                                                        'rgba(54, 162, 235, 0.2)',
                                                    ],
                                                    borderWidth: 0,
                                                },
                                            ],
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </TabContent>
                    <TabContent for="tab2">
                        <Form className="pagos-bancos">
                            <Form.Item label="Titular de la Cuenta">
                                <Input
                                    placeholder={'Jairo Hernandez'}
                                    type={'text'}
                                    value={bankAcount?.titular}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                            <Form.Item label="Número de Cuenta">
                                <Input
                                    name="numAccount"
                                    onChange={handleChange}
                                    type="text"
                                    value={bankAcount?.numAccount}
                                    placeholder="0000000 000  00"
                                />
                            </Form.Item>
                            <Form.Item label="Nombre de la institución">
                                <Input
                                    name="bankName"
                                    onChange={handleChange}
                                    type="text"
                                    value={bankAcount?.bankName}
                                    placeholder="Nombre de la institución bancaria"
                                />
                            </Form.Item>
                            <Form.Item label="Tipo de cuenta">
                                <Select
                                    name="accountType"
                                    onChange={handleChange}
                                    value={bankAcount?.accountType}
                                    defaultValue=""
                                    placeholder="Seleccionar"
                                >
                                    <Option key={0} value="">
                                        Seleccione una opción
                                    </Option>
                                    <Option key={1} value="Cuenta de ahorros">
                                        Cuenta de ahorros
                                    </Option>
                                    <Option key={2} value="Cuenta monetaria">
                                        Cuenta monetaria
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Form>
                        <button
                            type="button"
                            onClick={handleOpenModal}
                            className="btn btn__bancos bold"
                        >
                            Guardar
                        </button>
                    </TabContent>
                </div>
            </Tabs>

            <Modal
                backdrop="static"
                role="alertdialog"
                size="xs"
                show={openModal}
                onHide={handleOpenModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        Se modificaran los datos de cuenta
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h6>¿Estás seguro de cambiar estos datos?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleClick}
                        appearance="primary"
                        style={{ background: '#fe5000' }}
                    >
                        De acuerdo
                    </Button>
                    <Button onClick={handleOpenModal} appearance="subtle">
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Pagos
