import React, { useEffect, useRef, useState } from "react";
import { BASE_PATH } from "../constants";
import logo from "../../assets/images/logo.svg";
import { Alert, Form, FormGroup, FormControl, Schema } from "rsuite";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StringType } from "schema-typed";
import axios from "axios";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const userForm = {
    password: "",
    password_confirmation: "",
  };
  const formRef = useRef();
  const [authForm, setauthForm] = useState(userForm);

  const getToken = () => {
    const parameter = "token".replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + parameter + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const modelForm = Schema.Model({
    password: StringType()
      .addRule((value, data) => {
        return value.length > 6;
      }, "Elija una contraseña mas segura")
      .isRequired("Campo obligatorio"),
    password_confirmation: StringType()
      .addRule((value, data) => {
        return value === data.password;
      }, "Las contraseñas no coinciden")
      .isRequired("Campo obligatorio"),
  });

  const handleChange = (data) => {
    setauthForm(data);
  };
  const onSubmit = async () => {
    if (!formRef.current.check()) {
      Alert.warning("Campos invalidos.", 4000);
    } else {
      try {
        const request = await axios.post(
          `${BASE_PATH}/users/recover-password`,
          {
            token: getToken(),
            password: authForm.password,
          }
        );

        if (request.status === 200 && request.data.passwordUpdated) {
          Alert.success("Tu contraseña se actualizó!", 4000);
          navigate("/login", { replace: true });
        }
      } catch (error) {
        console.log(error);
        Alert.error("Ha ocurrido un error en el servidor", 4000);
      }
    }
  };
  useEffect(() => {
    if (!!!getToken()) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <>
      <div className="menu-desp">
        <div className="d-none d-md-none d-lg-block">
          <nav className="navbar navbar-light fixed-top">
            <img src={logo} className="logo" alt="logo" />

            <ul className="navbar-nav navbar-first nav-pills d-none d-md-none d-lg-block">
              <li>
                <Link to="/" className="bold padding btn-primary-menu-first">
                  <i className="demo-icon-first icon-home orange"></i>
                  Home
                </Link>

                <Link
                  to="/login"
                  className="bold padding btn-primary-menu-first"
                >
                  <i className="demo-icon-first icon-login orange"></i>
                  Login
                </Link>
              </li>
            </ul>

            <div className="dropdown">
              <button
                type="button"
                className="dropdown-avatar image04"
                id="dropdownMenuVerify"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {/* <Avatar size={53} src={user?.picture} /> */}
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none text-center">
        <nav className="navbar-sm fixed-top">
          <img src={logo} className="logo" alt="logo" />
        </nav>
      </div>
      <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none text-center">
        <nav className="navbar-sm fixed-bottom">
          <ul className="navbar-nav">
            <li>
              <Link to="/" className="bold btn-primary-menu">
                <i className="demo-icon icon-home orange"></i>
              </Link>
              <Link to="/login" className="bold btn-primary-menu">
                <i className="demo-icon icon-login orange"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div
        style={{
          marginTop: "150px",
          width: "100%",
          textAlign: "center",
          padding: "50px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="verify-email-container"
      >
        <FontAwesomeIcon
          style={{ fontSize: "50px" }}
          icon={faLock}
          className="orange"
        />
        <h4>Cambio de contraseña</h4>
        <Form
          onChange={(formValue) => handleChange(formValue)}
          model={modelForm}
          formValue={authForm}
          ref={formRef}
          onSubmit={onSubmit}
        >
          <FormGroup>
            <FormControl
              className="form-control text-pass btn-lg"
              name="password"
              placeholder="Contraseña"
              type="password"
            />
          </FormGroup>

          <FormGroup className="formGroup-auth">
            <FormControl
              className="form-control text-pass btn-lg"
              name="password_confirmation"
              placeholder="Repetir contraseña"
              type="password"
            />
          </FormGroup>

          <button
            style={{ border: "none" }}
            type="button"
            onClick={onSubmit}
            className="btn btn-primary bold btn-auth"
            //disabled={bntLocked}
          >
            Guardar
          </button>
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
