import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import PaginationCom from '../../../pagination/PaginationCom'
import Titulo from '../Titulo'
import Paquete from './items/Paquete'
import TablaPaquetes from './items/TablaPaquetes'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import moment from 'moment'
import { MessageUtils } from 'utils'
import { disabledDate } from 'componentes/Anfitrion/Experiencias/schedules/utils'

const initForm = {
    name: '',
    description: '',
    expirationDate: null,
    experiences: [],
    duration: '',
}

const AdminPaquetes = () => {
    const { user } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openPaquete, setOpenPaquete] = useState(false)
    const [experiencias, setExperiencias] = useState([])
    const [paquetes, setPaquetes] = useState([])
    const [paquete, setPaquete] = useState(null)
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState([])
    const [action, setAction] = useState('new')
    const [formValues, setFormValues] = useState(initForm)
    const [selectedPackageId, setSelectedPackageId] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [form] = Form.useForm()
    const role = user?.data?.user?.role
    const platform =
        role === 'company' ? user?.data?.user?.config : user?.data?.platform?.id
    const isCompanyPartner = role === 'company' || role === 'partner'
    const navigate = useNavigate()
    console.log(paquetes)
    const getPackages = async () => {
        setLoading(true)
        try {
            const response = await getData({
                path: `experiences/packages/user?page=${page}&limit=10&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: user?.data?.platform?.id,
            })
            console.log(response)
            if (response) {
                setPaquetes(response[0].result)
                setMeta(response)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al cargar los paquetes'
                )
            }
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error al cargar los paquetes')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getExperiences = async () => {
        try {
            const response = await getData({
                path: `experiences`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: platform,
            })
            setExperiencias(response)
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al cargar las experiencias'
            )
            console.log(error)
        }
    }

    const updatePackage = async () => {
        try {
            const experiencesValues = formValues.experiences.flatMap((item) =>
                typeof item === 'string' ? item : item.value
            )
            await putData({
                path: `experiences/packages`,
                id: selectedPackageId,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                    experiences: experiencesValues,
                },
            })
            MessageUtils.success('Paquete modificado con éxito')
            getPackages()
            onCloseDrawer()
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar un paquete'
            )
        }
    }

    const deletePackage = async () => {
        try {
            const response = await deleteData({
                path: `experiences/packages`,
                id: selectedPackageId,
                token: user.logged && user.data.token,
            })

            if (response || response === '') {
                MessageUtils.success('Paquete eliminado con éxito')
                getPackages()
                setOpenModal(false)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar un paquete'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar un paquete'
            )
        }
    }

    const onDeleteButton = (id) => {
        setSelectedPackageId(id)
        setOpenModal(true)
    }

    const onCancelModal = () => {
        setSelectedPackageId('')
        setOpenModal(false)
    }

    useEffect(() => {
        if (role !== 'merchant') {
            getExperiences()
        }
    }, [])

    useEffect(() => {
        getPackages()
    }, [page])

    useEffect(() => {
        form.setFieldsValue(formValues)
    }, [formValues, form])

    const onFinish = async () => {
        try {
            await postData({
                path: `experiences/packages`,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                    grossPriceAdult: formValues.experiences.reduce(
                        (sum, experienceId) => {
                            const experience = experiencias.find(
                                (exp) => exp._id === experienceId
                            )
                            if (
                                experience &&
                                experience.quotes &&
                                experience.quotes.length > 0
                            ) {
                                return (
                                    sum +
                                    experience.quotes[0].prices[0].netPrice
                                )
                            }
                            return sum
                        },
                        0
                    ),
                    grossPriceChildren: formValues.experiences.reduce(
                        (sum, experienceId) => {
                            const experience = experiencias.find(
                                (exp) => exp._id === experienceId
                            )
                            if (
                                experience &&
                                experience.quotes &&
                                experience.quotes.length > 0
                            ) {
                                return (
                                    sum +
                                    experience.quotes[0].prices[1].netPrice
                                )
                            }
                            return sum
                        },
                        0
                    ),
                    experiences: formValues.experiences.map((item) => {
                        return {
                            experience: item,
                        }
                    }),
                },
            })
            getPackages()
            onCloseDrawer()
            MessageUtils.success('Paquete creado con éxito')
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar crear el paquete'
            )
        } finally {
            setLoading(false)
        }
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setOpen(false)
        setTimeout(() => {
            setAction('new')
        }, 300)
    }

    const onEdit = (data) => {
        setSelectedPackageId(data._id)
        const mappedExperiences = data.experiences.map((exp) => ({
            value: exp.experience.id,
            label: exp.experience.name,
        }))

        setFormValues({
            name: data.name,
            description: data.description,
            expirationDate: data.expirationDate
                ? moment(data.expirationDate)
                : null,
            experiences: mappedExperiences,
            grossPriceAdult: data?.grossPriceAdult,
            grossPriceChildren: data?.grossPriceChildren,
            duration: data?.duration,
        })
        setOpen(true)
        setAction('edit')
    }

    const onView = (value) => {
        navigate(`/host/paquetes/_/${value._id}`)
    }

    const onMerchantEdition = (data) => {
        setSelectedPackageId(data._id)
        const mappedExperiences = data.experiences.map((exp) => ({
            value: exp.experience.id,
            label: exp.experience.name,
        }))
        setFormValues({
            name: data.name,
            description: data.description,
            expirationDate: data.expirationDate
                ? moment(data.expirationDate)
                : null,
            experiences: mappedExperiences,
            duration: data?.duration,
            grossPriceAdult: data?.grossPriceAdult,
            grossPriceChildren: data?.grossPriceChildren,
        })
        setEditOpen(true)
    }

    return (
        <div>
            {/* <ToastContainer /> */}
            <Titulo
                button={isCompanyPartner}
                titulo={'Lista de paquetes'}
                tituloBoton={'Crear paquete'}
                onAcccion={() => setOpen(true)}
            />
            <>
                {loading && !open ? (
                    <Spinner />
                ) : (
                    <>
                        <div>
                            <TablaPaquetes
                                data={paquetes}
                                // onView={(value) => {
                                //     setPaquete(value)
                                //     setOpenPaquete(true)
                                // }}
                                onView={onView}
                                // onEdit={onEdit}
                                onMerchantEdition={onMerchantEdition}
                                onDelete={onDeleteButton}
                                showDelete={isCompanyPartner}
                                showEdit={isCompanyPartner}
                                isMerchant={
                                    role === 'merchant' || role === 'reseller'
                                }
                            />
                            {paquetes.length > 0 && (
                                <PaginationCom
                                    page={page}
                                    meta={meta}
                                    setPage={(value) => setPage(value)}
                                />
                            )}
                        </div>

                        <Paquete
                            paquete={paquete}
                            openPaquete={openPaquete}
                            close={() => setOpenPaquete(false)}
                        />
                        {experiencias && (
                            <SidebarForm
                                title={
                                    action === 'new'
                                        ? 'Crear Paquete'
                                        : 'Editar Paquete'
                                }
                                buttonText={
                                    action === 'new' ? 'Crear' : 'Editar'
                                }
                                form={form}
                                width={500}
                                formValues={formValues}
                                onCloseDrawer={onCloseDrawer}
                                open={open}
                                setFormValues={setFormValues}
                                onFinish={
                                    action === 'new' ? onFinish : updatePackage
                                }
                                inputs={[
                                    {
                                        label: 'Paquete',
                                        name: 'name',
                                        placeholder: 'Nombre',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese un nombre',
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Descripción',
                                        name: 'description',
                                        type: 'textArea',
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Ingrese una descripción',
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Fecha de expiración',
                                        name: 'expirationDate',
                                        type: 'date',
                                        disabledDate: disabledDate,
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Ingrese uan fecha de expiración',
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Experiencia',
                                        name: 'experiences',
                                        type: 'select',
                                        items: experiencias?.map((item) => {
                                            return {
                                                label: item.name,
                                                value: item._id,
                                            }
                                        }),
                                        modeSelect: 'multiple',
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Seleccione las experiencias',
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Duración',
                                        name: 'duration',
                                        type: 'number',
                                        placeholder: 'Duración',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese una duración',
                                            },
                                        ],
                                    },
                                ]}
                            />
                        )}
                        {(role == 'merchant' || role == 'reseller') && (
                            <SidebarForm
                                title="Modifica el precio del paquete"
                                buttonText="Modificar"
                                form={form}
                                width={500}
                                formValues={formValues}
                                onCloseDrawer={() => setEditOpen(false)}
                                open={editOpen}
                                setFormValues={setFormValues}
                                onFinish={() =>
                                    console.log('funcion a desarrollar')
                                }
                                inputs={[
                                    {
                                        label: 'Paquete',
                                        name: 'name',
                                        placeholder: 'Nombre',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese un nombre',
                                            },
                                        ],
                                        disabled: 'true',
                                    },
                                    {
                                        label: 'Descripción',
                                        name: 'description',
                                        type: 'textArea',
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Ingrese una descripción',
                                            },
                                        ],
                                        disabled: 'true',
                                    },
                                    {
                                        label: 'Fecha de expiración',
                                        name: 'expirationDate',
                                        type: 'date',
                                        disabledDate: disabledDate,
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Ingrese uan fecha de expiración',
                                            },
                                        ],
                                        disabled: 'true',
                                    },
                                    {
                                        label: 'Experiencia',
                                        name: 'experiences',
                                        type: 'select',
                                        items: experiencias?.map((item) => {
                                            return {
                                                label: item.name,
                                                value: item._id,
                                            }
                                        }),
                                        modeSelect: 'multiple',
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Seleccione las experiencias',
                                            },
                                        ],
                                        disabled: 'true',
                                    },
                                    {
                                        label: 'Duración',
                                        name: 'duration',
                                        type: 'number',
                                        placeholder: 'Duración',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese una duración',
                                            },
                                        ],
                                        disabled: 'true',
                                    },
                                    {
                                        label: 'Precio Adulto',
                                        name: 'grossPriceAdult',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese un precio',
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Precio Niño',
                                        name: 'grossPriceChildren',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ingrese un precio',
                                            },
                                        ],
                                    },
                                ]}
                            />
                        )}
                    </>
                )}
            </>

            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={deletePackage}
                openModal={openModal}
                strongText={
                    paquetes?.find((item) => item._id === selectedPackageId)
                        ?.name
                }
                text={`¿Estás seguro de que deseas eliminar el siguiente paquete`}
                title={'Eliminar Paquete'}
            />
        </div>
    )
}

export default AdminPaquetes
