import { Form } from 'antd'
import axios from 'axios'
import { BASE_PATH } from 'componentes/constants'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { deleteData, getData, postData, putData } from 'services'
import { MessageUtils } from 'utils'

const initForm = {
    name: '',
    email: '',
    password: '',
    country: 'El Salvador',
    role: 'merchant',
    merchantType: '',
}

const initLinkForm = {
    merchant: '',
    tickets: [],
    package: '',
    experiences: [],
}

const useAbmRoles = ({ merchantType }) => {
    const { user } = useOutletContext()
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('new')
    const [formValues, setFormValues] = useState(initForm)
    const [userIdSelected, setUserIdSelected] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [meta, setMeta] = useState()
    const [form] = Form.useForm()
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const [linkForm] = Form.useForm()
    const [openLink, setOpenLink] = useState(false)
    const [linkFormValues, setLinkFormValues] = useState(initLinkForm)
    const [experiences, setExperiences] = useState([])
    const [paquetes, setPaquetes] = useState([])
    const [tickets, setTickets] = useState([])

    const platform = user?.data?.user?.config

    const incioComponente = async () => {
        setIsLoading(true)
        try {
            const responseData = await getData({
                path: `users/partners/merchants/?page=${page}&limit=10&isPaginate=true&merchantType=${merchantType}`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            console.log(responseData)
            if (responseData && responseData[0]) {
                const filterData = responseData[0]?.result?.map((user) => {
                    const { password, ...rest } = user
                    return rest
                })
                setData(filterData)
                setMeta(responseData)
            } else {
                setData([])
                setMeta([])
            }
            // const filterData = responseData[0]?.result?.map((user) => {
            //     const { password, ...rest } = user
            //     return rest
            // })

            // setData(filterData)
            // setMeta(responseData)
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setIsLoading(false)
        }
    }

    const getExperiences = async () => {
        try {
            const response = await getData({
                path: `experiences`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: platform,
            })
            setExperiences(response)
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al cargar las experiencias'
            )
            console.log(error)
        }
    }

    const getPackages = async () => {
        setIsLoading(true)
        try {
            const response = await getData({
                path: `experiences/packages/user?page=${page}&limit=10&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: user?.data?.platform?.id,
            })
            console.log(response)
            if (response) {
                setPaquetes(response[0].result)
                setMeta(response)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al cargar los paquetes'
                )
            }
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error al cargar los paquetes')
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getTickets = async () => {
        try {
            setIsLoading(true)

            const responseData = await getData({
                path: `partners/tickets/?partnerOwner=${user.data.user.id}`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            console.log(responseData)
            setTickets(responseData)
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    const updateTicket = async () => {
        try {
            setIsLoadingButton(true)
            const updatePromises = linkFormValues?.tickets?.map(
                async (ticket) => {
                    const response = await putData({
                        path: `partners/tickets`,
                        id: ticket,
                        token: user.logged && user.data.token,
                        params: {},
                        value: { merchantOwner: linkFormValues.merchant },
                    })
                    console.log(response)
                }
            )

            await Promise.all(updatePromises)

            MessageUtils.success('Tickets modificados con éxito')
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar los tickets'
            )
            console.log(error)
        } finally {
            setIsLoadingButton(false)
        }
    }
    console.log(linkFormValues)
    const updatePackage = async () => {
        try {
            setIsLoadingButton(true)
            const updatePackageOwner = linkFormValues?.package?.map(
                async (paquete) => {
                    const response = await putData({
                        path: `experiences/packages`,
                        id: paquete,
                        token: user.logged && user.data.token,
                        params: {},
                        value: {
                            merchantOwner: linkFormValues.merchant,
                        },
                    })
                    console.log(response)
                }
            )
            await Promise.all(updatePackageOwner)
            MessageUtils.success('Paquete vinculado con éxito')
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar vincular el paquete'
            )
            console.log(error)
        } finally {
            setIsLoadingButton(false)
        }
    }

    useEffect(() => {
        incioComponente()
        getExperiences()
        getPackages()
        getTickets()
    }, [])

    useEffect(() => {
        incioComponente()
        getExperiences()
        getPackages()
        getTickets()
    }, [page])

    const onFinish = async () => {
        try {
            if (action === 'new') {
                await postData({
                    path: `users/partners/merchants`,
                    token: user.logged && user.data.token,
                    params: {},
                    value: { ...formValues, merchantType },
                })
            } else {
                const { password, ...valuesToSend } = formValues
                await putData({
                    path: `users`,
                    id: userIdSelected,
                    token: user.logged && user.data.token,
                    params: {},
                    value: valuesToSend,
                })
                if (password) {
                    await axios.put(
                        `${BASE_PATH}/users/${userIdSelected}/password-from-admin`,
                        { password },
                        {
                            headers: {
                                Authorization: `Bearer ${user.logged && user.data.token}`,
                            },
                        }
                    )
                }
            }
            MessageUtils.success(
                action === 'new'
                    ? `Se ha creado un ${merchantType === 'salesPoint' ? 'punto de venta' : 'revendedor'} con éxito`
                    : `Se ha editado un ${merchantType === 'salesPoint' ? 'punto de venta' : 'revendedor'} con éxito`
            )
            setFormValues(initForm)
            form.resetFields()
            incioComponente()
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error')
            console.log(error)
        } finally {
            setOpen(false)
            setAction('new')
        }
    }

    const onFinishLink = async () => {
        if (linkFormValues.tickets.length > 0) {
            await updateTicket()
        }
        if (linkFormValues.package.length > 0) {
            await updatePackage()
        }
        onCloseLinkDrawer()
    }

    const onEdit = (user) => {
        const values = {
            name: user.name,
            email: user.email,
            password: user.password,
            country: user.country,
            role: user.role,
            merchantType: user.merchantType,
        }
        setFormValues(values)
        form.setFieldsValue(values)
        setOpen(true)
        setAction('edit')
        setUserIdSelected(user._id)
    }

    const handleDeleteButton = (user) => {
        setUserIdSelected(user._id)
        setOpenModal(true)
    }

    const onDeleteUser = async () => {
        setIsLoading(true)
        try {
            await deleteData({
                path: `users`,
                id: userIdSelected,
                token: user.logged && user.data.token,
            })
            MessageUtils.success('Se ha eliminado al usuario con éxito')
            incioComponente()
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error')
            console.log(error)
        } finally {
            setOpenModal(false)
            setIsLoading(false)
            setUserIdSelected('')
        }
    }

    const onCancelModal = () => {
        setUserIdSelected('')
        setOpenModal(false)
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setTimeout(() => {
            setAction('new')
        }, 300)
        setOpen(false)
    }

    const onCloseLinkDrawer = () => {
        setLinkFormValues(initLinkForm)
        linkForm.resetFields()
        setTimeout(() => {
            setAction('new')
        }, 300)
        setOpenLink(false)
    }

    return {
        data,
        action,
        deleteData,
        formValues,
        handleDeleteButton,
        onFinish,
        isLoading,
        onCancelModal,
        onCloseDrawer,
        onDeleteUser,
        onEdit,
        open,
        openModal,
        setAction,
        setFormValues,
        setIsLoading,
        setOpen,
        setOpenModal,
        setUserIdSelected,
        user,
        userIdSelected,
        page,
        setPage,
        meta,
        form,
        linkForm,
        openLink,
        setOpenLink,
        linkFormValues,
        setLinkFormValues,
        onCloseLinkDrawer,
        experiences,
        paquetes,
        tickets,
        onFinishLink,
        isLoadingButton,
    }
}

export default useAbmRoles
