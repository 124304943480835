// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
    text-align: center; /* Centra los elementos hijos horizontalmente */
    margin-top: 20px; /* Espacio superior para separar los datos de la paginación */
  }

  /* Aplica estilos al submenú "User" y sus elementos de menú */
.ant-menu-submenu-title {
  font-size: 28px !important; /* Tamaño de fuente personalizado */
}

/* Aplica estilos a los elementos de menú dentro del submenú "User" */
.ant-menu-submenu .ant-menu-item {
  font-size: 18px; /* Tamaño de fuente personalizado para los elementos de menú dentro del submenú "User" */
  
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: #64B32E !important;
  
 
}

.ant-menu {
  padding: 1rem;
  font-size: 18px;
}

.ant-table-tbody > tr > td {
  border-bottom: 5px solid #171718;
  transition: background 0.3s;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/Admin/items/Viajeros/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,+CAA+C;IACnE,gBAAgB,EAAE,6DAA6D;EACjF;;EAEA,6DAA6D;AAC/D;EACE,0BAA0B,EAAE,mCAAmC;AACjE;;AAEA,qEAAqE;AACrE;EACE,eAAe,EAAE,wFAAwF;;AAE3G;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;;;AAG3B;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".pagination-container {\n    text-align: center; /* Centra los elementos hijos horizontalmente */\n    margin-top: 20px; /* Espacio superior para separar los datos de la paginación */\n  }\n\n  /* Aplica estilos al submenú \"User\" y sus elementos de menú */\n.ant-menu-submenu-title {\n  font-size: 28px !important; /* Tamaño de fuente personalizado */\n}\n\n/* Aplica estilos a los elementos de menú dentro del submenú \"User\" */\n.ant-menu-submenu .ant-menu-item {\n  font-size: 18px; /* Tamaño de fuente personalizado para los elementos de menú dentro del submenú \"User\" */\n  \n}\n\n.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {\n  background-color: transparent;\n  color: #64B32E !important;\n  \n \n}\n\n.ant-menu {\n  padding: 1rem;\n  font-size: 18px;\n}\n\n.ant-table-tbody > tr > td {\n  border-bottom: 5px solid #171718;\n  transition: background 0.3s;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
