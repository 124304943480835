import React, { useContext, useState, useEffect } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { types } from '../../types/types'
import { AuthContext } from '../../auth/AuthContext'
import axios from 'axios'
import { BASE_PATH } from '../constants'
import { putData } from 'services'
import UploadImageCard from 'componentes/commons/UploadImageCard'

const Configuraciones = () => {
    const navigate = useNavigate()
    const { dispatch } = useContext(AuthContext)
    const { user } = useContext(AuthContext)
    const [anfitrion, setAnfitrion] = useState(false)
    const [picture, setPicture] = useState('')
    const [subiendo, setSubiendo] = useState(false)
    const [userInfo, setuserInfo] = useState({
        alias: 'Sin Alias',
        description: 'no hay descripcion',
        picture: null,
    })

    const Info = () => {
        navigate('perfil', { replace: true })
    }

    const MetodoPago = () => {
        navigate('metodopago')
    }
    const TusGustos = () => {
        navigate('gustos', { replace: true })
    }
    const changeUser = async () => {
        let switchh = 0
        if (window.location.pathname == '/configuraciones') {
            switchh = 1
        }
        let headers = {
            Authorization: `Bearer ${user?.data?.token}`,
            'user-switch': switchh,
        }
        let change = await axios.post(
            `${BASE_PATH}/users/switchUser/`,
            {},
            {
                headers: headers,
            }
        )
        if (change.status === 200) {
            dispatch({
                type: types.login,
                payload: change,
            })

            if (change.data.user.state === 1) {
                navigate(`/host/_/${user?.data?.user?.id}`, { replace: true })
            } else if (change.data.user.state === 0) {
                navigate('/', { replace: true })
            }
        }
    }

    const cerrar = () => {
        navigate('/', { replace: true })
        dispatch({
            type: types.logout,
        })
    }

    const newPhoto = ({ fileList: newFileList }) => {
        // console.log(newFileList)
        uploadPicture(newFileList[0].originFileObj)
        //enviar()
    }

    const uploadPicture = async (file) => {
        try {
            setSubiendo(true)
            var fdata = new FormData()
            fdata.append('file', file)
            let setPhoto = await axios.post(
                `${BASE_PATH}/images/upload-files`,
                fdata,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            setPicture(setPhoto.data.imageUrl)
            await putData({
                path: 'users',
                token: user?.data?.token,
                id: user?.data?.id,
                value: { picture: setPhoto.data.imageUrl },
            })
        } catch (e) {
            console.error(e)
        } finally {
            setSubiendo(false)
        }
    }

    useEffect(() => {
        if (user) {
            if (user?.data) {
                if (user?.data?.user?.state === 0 || !user?.data?.user?.state) {
                    setuserInfo({ ...userInfo, ...user?.data?.user })
                } else if (
                    user?.data?.user?.state === 1 ||
                    user?.data?.user?.role === 'owner'
                ) {
                    setAnfitrion(true)
                    setuserInfo({ ...userInfo, ...user?.data?.user?.infoExtra })
                }
            } else {
                navigate('/', { replace: true })
            }
        } else {
            navigate('/', { replace: true })
        }
    }, [user])

    return (
        <>
            <button
                type="button"
                className="back-window fixed-top mr-2"
                onClick={() => navigate(-1)}
            >
                <i className="icon-back icon-left-arrow"></i>
            </button>
            <div className="container-sm d-flex align-items-center">
                {user && (
                    <Row className="config">
                        <Col className="config-perfil">
                            <div className="text-center">
                                <UploadImageCard
                                    disabled={subiendo}
                                    onChange={newPhoto}
                                    picture={picture || userInfo.picture}
                                />
                                <h3 className="bold">{userInfo.alias}</h3>
                                <p className="lite text-gray perfil-name">
                                    {user.data ? user.data.user.name : ''}
                                </p>
                                <div className=" perfil-about text-left">
                                    <h4 className="bold orange">Sobre mi</h4>
                                    <p>{userInfo.description}</p>
                                </div>
                            </div>
                        </Col>
                        <div className="switch d-block d-sm-none d-none d-sm-block d-md-none">
                            <button
                                type="button"
                                className="back-window-navbar neon_blue"
                                onClick={changeUser}
                            >
                                <i className="icon-swap"></i>
                            </button>
                            <button
                                type="button"
                                className="back-window-navbar-logout"
                                onClick={cerrar}
                            >
                                <i className="fi fi-br-sign-in-alt icon-font"></i>
                            </button>
                        </div>
                        <Col className="config-menu">
                            <Row>
                                <Col lg={12} xs={12}>
                                    <div className="card3">
                                        <Card onClick={Info}>
                                            <Card.Body className="bold">
                                                <Row>
                                                    <Col lg={5} xs={4}>
                                                        <i className="demo-icon-config icon-profile"></i>
                                                    </Col>
                                                    <Col className="d-flex align-items-center">
                                                        <h6 className="bold">
                                                            Perfil
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>

                                {!anfitrion && (
                                    <Col lg={12} xs={12}>
                                        <div className="card3">
                                            <Card onClick={TusGustos}>
                                                <Card.Body className="bold">
                                                    <Row>
                                                        <Col lg={5} xs={4}>
                                                            <i className="demo-icon-config icon-face"></i>
                                                        </Col>
                                                        <Col className="d-flex align-items-center">
                                                            <h6 className="bold">
                                                                Gustos
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                )}
                                {!anfitrion && (
                                    <Col lg={12} xs={12}>
                                        <div className="card3">
                                            <Card onClick={MetodoPago}>
                                                <Card.Body className="bold">
                                                    <Row>
                                                        <Col lg={4} xs={3}>
                                                            <i className="demo-icon-config icon-card"></i>
                                                        </Col>
                                                        <Col className="d-flex align-items-center">
                                                            <h6 className="bold">
                                                                Método de Pago
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    )
}

export default Configuraciones
