import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "antd/dist/antd.css";
import { Media, Row, Col } from "react-bootstrap";
import { TabLink, TabContent, Tabs } from "react-tabs-redux";
import { Drawer } from "rsuite";
import axios from "axios";
import { BASE_PATH } from "../constants";
import { AuthContext } from "../../auth/AuthContext";

//Importar Recursos
import Background from "../../assets/images/cards/surf2.jpg";
import ExperienciaGaleria from "./ExperienciaGaleria";
import ExperienciaDetailsOpen from "./ExperienciaDetailsOpen";
import ExperienciaReview from "./ExperienciaReview";
import Chat from "./chat/Chat";
import logo from "../../assets/images/desvlop.svg";
const defaultPhoto =
  "https://tugofiles.fra1.digitaloceanspaces.com/1630952868200-Icono_512x512.png";

const ExperienciaOpen = () => {
  //! Chequear que funcionen bien las peticiones, no se donde se encuentra en la web
  const [visible, setVisible] = useState(false);
  const { id: experienceId } = useParams();
  const { user } = useContext(AuthContext);
  const [experiencia, setExperiencia] = useState();
  const [reserva, setReserva] = useState();
  const [photos, setphotos] = useState([]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const navigate = useNavigate();
  const HomeB = () => {
    navigate("/planes", { replace: true });
  };

  const getExperiencia = async () => {
    try {
      let res = await axios.get(
        `${BASE_PATH}/experiences/${experienceId}`
      );
      if (res.status === 200) {
        setExperiencia(res.data);
        console.log(experiencia);
        const pics = res.data.pictures.map((pic) => {
          return {
            url: pic.path,
            id: pic.image_id,
          };
        });

        setphotos(pics);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getReservas = async () => {
    if (user.logged === true) {
      try {
        const result = await axios.get(
          `${BASE_PATH}/payments/${experienceId}`,
          {
            headers: {
              Authorization: `Bearer ${user.data.token}`,
            },
          }
        );
        if (result.status === 200) {
          setReserva(result.data);
        } else {
          HomeB();
        }
      } catch (error) {
        console.error(error);
        HomeB();
      }
    } else {
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    getExperiencia();
    getReservas();
  }, []);

  return (
    <div>
      {experiencia && reserva && (
        <>
          <button
            type="button"
            style={{ zIndex: "10 !important" }}
            className="back-window fixed-top"
            onClick={HomeB}
          >
            <i className=" icon-left-arrow"></i>
          </button>
          <img src={logo} className="logo_in" alt="logo" />

          <section className="exp-header fixed-top d-none d-md-none d-lg-block">
            <Row className="">
              <Col>
                <div
                  className="d-flex align-items-center"
                  style={{ height: "100vh" }}
                >
                  <div className="bold exp-titulo exp-container">
                    <Media className="mb-1">
                      <Media.Body>
                        <h5 className="text-white">{experiencia?.direction}</h5>
                      </Media.Body>
                    </Media>
                    <p style={{ fontSize: "70px" }}>{experiencia?.name}</p>
                    <div className="review">
                      <i
                        className="icon-star"
                        style={{ color: "#fe5000", fontSize: "30px" }}
                      ></i>
                      <text className="bold" style={{ fontSize: "30px" }}>
                        {experiencia?.rating}
                      </text>
                    </div>
                    <div>
                      <Row
                        className="exp-details-about"
                        style={{ width: "600px" }}
                      >
                        <Col className="text-center">
                          <i
                            className="icon-face"
                            style={{ fontSize: "45px" }}
                          ></i>
                          <p style={{ fontSize: "16px", color: "grey" }}>
                            Cupo
                          </p>
                          <p className="bold">
                            Hasta {experiencia?.quotas.max} personas
                          </p>
                        </Col>
                        <Col className="text-center">
                          <i
                            className="icon-time"
                            style={{ fontSize: "45px" }}
                          ></i>
                          <p style={{ fontSize: "16px", color: "grey" }}>
                            Duración
                          </p>
                          <p className="bold">{experiencia?.duration}</p>
                        </Col>
                        <Col className="text-center">
                          <i
                            className="icon-money"
                            style={{ fontSize: "45px" }}
                          ></i>
                          <p style={{ fontSize: "16px", color: "grey" }}>
                            Duración
                          </p>
                          <p className="bold">
                            {/* Adultos $ {experiencia?.price?.adultPriceWithCommissions || experiencia?.price.adultPrice} */}
                            , <br />
                            {/* Niños $ {experiencia?.price.childrenPriceWithCommissions || experiencia?.price.childrenPrice} <></> */}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                className="exp-header-pic"
                lg={4}
                style={{
                  backgroundImage: `url(${
                    experiencia?.pictures[0]
                      ? experiencia?.pictures[0].path
                      : Background
                  })`,
                }}
              ></Col>
            </Row>
            <i className="fi fi-br-angle-down orange icon-font-exp text-center"></i>
          </section>

          <section className="exp-contenido-open">
            <Tabs className="container-sm">
              <div className="sticky-top exp-tabs">
                <TabLink
                  to="tab1"
                  className="exp-contenido-btn exp-active"
                  activeClassName="orange "
                >
                  <i className=" icon-details"></i> Info
                </TabLink>
                <TabLink
                  to="tab2"
                  className="exp-contenido-btn exp-active "
                  activeClassName="orange"
                >
                  <i className=" icon-pics"></i>Pics
                </TabLink>
                <TabLink
                  to="tab3"
                  className="exp-contenido-btn exp-active "
                  activeClassName="orange"
                >
                  <i className=" icon-star "></i>
                </TabLink>
              </div>
              <TabContent for="tab1">
                <ExperienciaDetailsOpen
                  dataExp={experiencia}
                  dataReserva={reserva}
                />
              </TabContent>
              <TabContent for="tab2" className="exp-galeria">
                <ExperienciaGaleria photos={photos} />
              </TabContent>
              <TabContent for="tab3">
                <ExperienciaReview
                  data={experienceId}
                  dataReserva={reserva}
                />
              </TabContent>
              <TabContent for="tab4"></TabContent>
            </Tabs>
            <>
              <button
                type="button"
                className="exp-chat exp-fixed-bottom-open btn-primary"
                onClick={showDrawer}
              >
                <i className=" icon-chat" style={{ fontSize: 26 }}></i>
              </button>
              <Drawer
                size="xs"
                onHide={onClose}
                show={visible}
                style={{ background: "#171718" }}
              >
                <Drawer.Body className="exp-chat-open">
                  {user.logged === true &&
                    visible === true &&
                    experiencia?.user?._id != user.data.user.id && (
                      <Chat
                        dataUser={
                          experiencia.user.infoExtra
                            ? {
                                ...experiencia.user.infoExtra,
                                _id: experiencia.user._id,
                              }
                            : { ...experiencia.user }
                        }
                        closeChat={onClose}
                      ></Chat>
                    )}
                  {user.logged === true &&
                    visible === true &&
                    experiencia?.user?._id === user.data.user.id && (
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                          marginTop: "100px",
                        }}
                      >
                        <button
                          type="button"
                          className="back-window-navbar"
                          style={{
                            position: "absolute",
                            margin: "20px",
                            top: "0",
                          }}
                          onClick={onClose}
                        >
                          <i className="demo-icon-exp icon-left-arrow"></i>
                        </button>
                        <img
                          src={defaultPhoto}
                          alt="tugo image"
                          style={{
                            maxWidth: "250px",
                            width: "100%",
                            margin: "auto",
                          }}
                        />
                        <h2 style={{ fontSize: "18px", textAlign: "center" }}>
                          Chat no disponible en esta experiencia
                        </h2>
                      </div>
                    )}
                </Drawer.Body>
              </Drawer>
            </>
          </section>
        </>
      )}
    </div>
  );
};

export default ExperienciaOpen;
