import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../assets/css/App.css";
import "antd/dist/antd.css";
import { BASE_PATH } from "../constants";
import Rive from "rive-react";

//Importar Recursos
import ImagenDone from "../../assets/images/done.riv";

const ExperienciaReservada = ({ experiencia, reserva }) => {
  const navigate = useNavigate();
  //console.log(experiencia)

  const [tempo, settempo] = useState(5);
  //console.log(name)

  const Planes = () => {
    navigate("/planes", { replace: true });
  };

  const ExpReservada = () => {
    navigate(`/exp/reservada/${experiencia[0].id}/${reserva.id}`, {
      replace: true,
    });
  };

  useEffect(() => {
    let count = 5;

    let interval = setInterval(() => {
      if (count === 0) {
        Planes();
      }
      if (count >= 0) {
        settempo(count);
        count--;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="bg-orange vh-100 d-flex justify-content-center align-items-center">
      <Row className="container-sm d-flex justify-content-center align-items-center">
        <Col className="text-center">
          <Rive src={ImagenDone} />
          <h1 className="heavy" style={{ fontSize: "65px", marginTop: "1rem" }}>
            ¡Listo!
          </h1>
          <h2 className="bold">Tu experiencia ha sido reservada</h2>
          <button onClick={Planes} className="exp-reservada">
            <p>
              Redireccionando a <span className="bold">Planes</span> en {tempo}{" "}
              seg.
            </p>
          </button>
          <div className="divider-down"></div>
        </Col>
      </Row>
    </div>
  );
};

export default ExperienciaReservada;
