import React, { useContext } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Card, Image } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import Imagen2 from '../../assets/images/icono.svg'

import { InputPicker } from 'rsuite'
import { AuthContext } from '../../auth/AuthContext'

const ListaPaquetes = ({
    paquetes = [],
    categorias = [],
    filterCategory,
    gustos = [],
    exploring = false,
    loading = false,
    defaultSelect = 'Todas',
}) => {
    const navigate = useNavigate()
    console.log({ paquetes })
    const selectCategory = (_, item) => filterCategory(item)

    const cleanFilter = () => filterCategory({ rol: 'all', value: 'Todas' })

    const { user } = useContext(AuthContext)
    return (
        <section /*className=" page-parati"*/ className="container_table">
            {categorias.length > 0 && user?.logged && (
                <div className="d-flex justify-content-center">
                    <div
                        className="categoria-parati"
                        style={{ marginBottom: '1rem' }}
                    >
                        <InputPicker
                            data={categorias}
                            value={defaultSelect}
                            onSelect={selectCategory}
                            onClean={cleanFilter}
                            placement="top"
                            style={{ zIndex: '1' }}
                        ></InputPicker>
                    </div>
                </div>
            )}
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 2, 750: 2, 1200: 3 }}
            >
                <Masonry gutter={20}>
                    {!loading &&
                        paquetes.map((item, index) => {
                            // let user = item?.user?.infoExtra
                            //     ? item?.user?.infoExtra
                            //     : item.user
                            return (
                                <Link key={index} to={`/paquete/${item?._id}`}>
                                    <div>
                                        <Card className=" image04 flex flex-col h-80 relative">
                                            <Card.Img
                                                src={
                                                    item.pictures[0]?.imageUrl
                                                        ? item.pictures[0]
                                                              ?.imageUrl
                                                        : Imagen2
                                                }
                                                className="object-cover w-full h-full opacity-90"
                                            />
                                            <Card.ImgOverlay>
                                                <div className="card-bottom">
                                                    <h3 className="bold text-white card-titulo">
                                                        {item.name}
                                                    </h3>
                                                    <p
                                                        className="text-white bold d-none d-md-none d-lg-block card-precio"
                                                        style={{
                                                            lineHeight: '18px',
                                                        }}
                                                    >
                                                        <text
                                                            className="orange__gradient heavy"
                                                            style={{
                                                                fontSize:
                                                                    '25px',
                                                            }}
                                                        >
                                                            $
                                                            {
                                                                item?.grossPriceAdult
                                                            }{' '}
                                                            <text
                                                                className="lite"
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                }}
                                                            >
                                                                por persona
                                                            </text>
                                                        </text>
                                                    </p>
                                                    <p className="place">
                                                        {item?.description}
                                                    </p>
                                                </div>
                                            </Card.ImgOverlay>
                                        </Card>
                                    </div>
                                </Link>
                            )
                        })}
                </Masonry>
            </ResponsiveMasonry>

            {paquetes.length === 0 && !exploring && !loading && (
                <h2
                    style={{
                        textAlign: 'center',
                        padding: '20px',
                        fontSize: '18px',
                    }}
                >
                    No hay paquetes para mostrar. Prueba eligiendo otra
                    categoría
                </h2>
            )}
        </section>
    )
}

export default ListaPaquetes
