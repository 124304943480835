import { Pagination } from 'antd'
import React from 'react'

const PaginationCom = ({ page, meta, setPage }) => {
    if (!meta) return null
    return (
        <div className="pagination-container">
            <Pagination
                current={page}
                total={meta[0]?.totalPages * 10}
                onChange={(value) => setPage(value)}
                pageSize={10}
                showSizeChanger={false}
            />
        </div>
    )
}

export default PaginationCom
