import { Button, Col, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PiPencilBold } from 'react-icons/pi'

const TituloTiposTickets = ({
    titulo,
    tituloBoton,
    onAcccion,
    button = true,
    dominio,
}) => {
    const navigate = useNavigate()
    const Tickets = () => {
        navigate(-1)
    }
    return (
        <Row style={{ marginBottom: '0px' }}>
            <Col span={2}>
                <button
                    type="button"
                    className="back-window_2"
                    onClick={Tickets}
                >
                    <i className="icon-back  icon-left-arrow"></i>
                </button>
            </Col>
            <Col span={10}>
                <h2 className="titulo_table bold">{titulo}</h2>
            </Col>
            {button && (
                <Col
                    span={6}
                    offset={6}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button
                        onClick={onAcccion}
                        className=" bold btn_crear"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        type="primary"
                    >
                        <PiPencilBold className="icon_menu_20" />
                        {tituloBoton}
                    </Button>
                </Col>
            )}
        </Row>
    )
}

export default TituloTiposTickets
