import {
    AdminCategorias,
    AdminDashboard,
    AdminDescuentos,
    AdminExperiencias,
    AdminPaquetes,
    AdminParques,
    AdminPuntosVentas,
    AdminRevendedores,
    Ingresos,
    ListaTickets,
    ListaViajeros,
    PerfilCompania,
    TiposTickets,
} from 'componentes/Admin'
import { Route, Routes } from 'react-router-dom'
import DashboardAdminLayout from './layout/DashboardAdminLayout'
import Chat from 'componentes/Chat/Chat'
import ExperienciaNew from 'componentes/Anfitrion/Experiencias/ExperienciaNew'
import Parati from 'componentes/Experiencias/Parati'

const CompanyRoutes = () => {
    return (
        <Routes>
            <Route index element={<AdminDashboard />} />
            <Route path="dashboard" element={<DashboardAdminLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="viajeros" element={<ListaViajeros />} />
                <Route path="ingresos" element={<Ingresos />} />
            </Route>
            <Route path="perfil" element={<PerfilCompania />} />
            <Route path="parques" element={<AdminParques />} />
            <Route path="categorias" element={<AdminCategorias />} />
            <Route path="revendedores" element={<AdminRevendedores />} />
            <Route path="puntos-ventas" element={<AdminPuntosVentas />} />
            <Route path="lista-experiencias" element={<AdminExperiencias />} />
            <Route path="nueva-experiencia" element={<ExperienciaNew />} />
            <Route path="ingresos" element={<Ingresos />} />
            <Route path="paquetes" element={<AdminPaquetes />} />
            <Route path="tickets" element={<ListaTickets />} />
            <Route path="tipos-tickets" element={<TiposTickets />} />
            <Route path="descuentos" element={<AdminDescuentos />} />
            <Route path="parati" element={<Parati />} />
            <Route path="chat" element={<Chat />} />
        </Routes>
    )
}

export default CompanyRoutes
