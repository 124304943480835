/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { getData } from "../../../../services";
import PaginationCom from "../../../pagination/PaginationCom";
import Titulo from "../Titulo";
import TablaViajeros from "./items/TablaViajeros";
import Viajero from "./items/Viajero";
import "./style.css";
import { useOutletContext } from "react-router-dom";

const ListaViajeros = () => {
  const { user } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [viajeros, setViajeros] = useState([]);
  const [viajero, setViajero] = useState(null);
  const [page, setPage] = useState(1);
  const [openViajero, setOpenViejero] = useState(false);
  const [meta, setMeta] = useState(null);
  const getViajeros = async () => {
    try {
      setLoading(true);
      const response = await getData({
        path: `users/tours/urs?page=${page}&limit=11&isPaginate=true`,
        token: user.logged && user.data.token,
        params: {},
        value: {},
      });
      console.log("token", user.logged);
      setMeta(response);
      setViajeros(response[0].result.filter((el) => el.role == "user"));
      console.log(
        "ver",
        response[0].result.filter((el) => el.role == "user").length
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getViajeros();
  }, []);

  useEffect(() => {
    getViajeros();
  }, [page]);

  const verViajero = (value) => {
    setViajero(value);
    setOpenViejero(true);
  };
  return (
    <div>
      <Titulo
        titulo={"Lista de viajeros"}
        tituloBoton={"Crear viajero"}
        onAcccion={() => console.log("accion")}
        button={false}
      />
      <div>
        <TablaViajeros data={viajeros} onView={verViajero} />
        {viajeros.length > 0 && (
          <PaginationCom
            page={page}
            meta={meta}
            setPage={(value) => setPage(value)}
          />
        )}

        <Viajero
          viajero={viajero}
          openViajero={openViajero}
          close={() => {
            setViajero(null);
            setOpenViejero(false);
          }}
        />
      </div>
    </div>
  );
};

export default ListaViajeros;
