import { Avatar, Col, Drawer, Row } from 'antd'
import React from 'react'
import moment from 'moment'

const Paquete = ({
    openPaquete,
    close,
    paquete
}) => {
    return (
        <Drawer
            width={700}
            title={<text className='bold titulo_table'>Paquete</text>}
            placement="right"
            onClose={() => close()}
            visible={openPaquete}
        >

            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}>
                <Col md={24} sm={24} xs={24}><text className='bold titulo_table'>Información del paquete</text></Col>
                <Col md={6} sm={8} xs={8}>{paquete?.name}</Col>
                <Col md={9} sm={8} xs={8}>{paquete?.description}</Col>
                <Col md={9} sm={8} xs={8}>Expiración: {moment(paquete?.expirationDate).format('LL')}</Col>
            </Row>
            <Row
            style={{ marginBottom: '10px', marginTop: '20px' }}
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                {paquete?.experiences?.map((item) =>
                (
                    <Col md={12} sm={24} xs={24}>
                        <Row
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}>
                            <Col md={8} sm={12} xs={12}>
                                <Avatar shape="square" size={100} src={item?.experience?.pictures[0]?.path} />
                            </Col>
                            <Col md={16} sm={12} xs={12} >
                                <Row
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col md={24} sm={24} xs={24}>
                                        <p style={{ marginBottom: '0px' }}>{item?.experience?.name}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}>
                            <Col md={12} sm={12} xs={12}>
                                <text className='bold titulo_table'>Comienza</text>
                                <p style={{ marginBottom: '0px' }}>{item?.experience?.start}</p>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                                <text className='bold titulo_table'>Termina</text>
                                <p style={{ marginBottom: '0px' }}>{item?.experience?.end}</p>
                            </Col>
                        </Row>
                    </Col>
                )
                )
                }
            </Row>
        </Drawer>
    )
}

export default Paquete
