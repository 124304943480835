import { Input, Select, DatePicker } from 'antd'
import moment from 'moment'

const { TextArea } = Input
const { Option } = Select

export const renderInput = (input, newform, setNewForm) => {
    switch (input.type) {
        case 'textArea':
            return (
                <TextArea
                    placeholder={input.placeholder}
                    value={newform[input.name]}
                    onChange={(e) =>
                        setNewForm({
                            ...newform,
                            [input.name]: e.target.value,
                        })
                    }
                />
            )
        case 'date':
            return (
                <DatePicker
                    disabledDate={input.disabledDate}
                    placeholder={input.placeholder}
                    value={
                        newform[input.name] ? moment(newform[input.name]) : null
                    }
                    onChange={(date) =>
                        setNewForm({
                            ...newform,
                            [input.name]: date,
                        })
                    }
                />
            )
        case 'select':
            return (
                <Select
                    mode={input.modeSelect}
                    placeholder={input.placeholder}
                    value={newform[input.name]}
                    onChange={(selectedValues) => {
                        const selectedItems = selectedValues.map((value) => {
                            const selectedItem = input.items.find(
                                (item) => item.value === value
                            )
                            return {
                                icon: selectedItem.icon,
                                valor: value,
                            }
                        })

                        setNewForm({
                            ...newform,
                            [input.name]: selectedItems,
                        })
                    }}
                >
                    {input.items.map((item, idx) => (
                        <Option key={item.value} value={item.value}>
                            <i
                                className={`rs-icon rs-icon-${item.icon} mr-2`}
                            />
                            {item.label}
                        </Option>
                    ))}
                </Select>
            )
        case 'uniqueSelect':
            return (
                <Select
                    placeholder={input.placeholder}
                    value={newform[input.name]?.value || undefined}
                    onChange={(selectedValue) => {
                        setNewForm({
                            ...newform,
                            [input.name]: {
                                value: selectedValue,
                            },
                        })
                    }}
                >
                    {input.items.map((item, idx) => (
                        <Option key={item.value} value={item.value}>
                            <i
                                className={`rs-icon rs-icon-${item.icon} mr-2`}
                            />
                            {item.label}
                        </Option>
                    ))}
                </Select>
            )

        default:
            return (
                <Input
                    disabled={input?.disabled || false}
                    prefix={input?.prefix || ''}
                    placeholder={input.placeholder}
                    type={input.type || 'text'}
                    suffix={input?.suffix || ''}
                    value={newform[input.name]}
                    onChange={(e) =>
                        setNewForm({
                            ...newform,
                            [input.name]: e.target.value,
                        })
                    }
                />
            )
    }
}
