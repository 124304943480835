import React from 'react'
import { PiPencilBold } from 'react-icons/pi'

const Titulo = ({ titulo, tituloBoton, onAcccion, button = true }) => {
    return (
        <div className="flex items-center justify-between w-full">
            <h2 className="titulo_table bold">{titulo}</h2>
            {button && (
                <button
                    onClick={onAcccion}
                    className="flex items-center gap-2 border-[#FE5000] p-2 rounded-xl border-solid border-[2px] duration-300 transition-colors text-[#FE5000] hover:text-[#ff8045] hover:border-[#ff8045]"
                    type="primary"
                >
                    <PiPencilBold />
                    {tituloBoton}
                </button>
            )}
        </div>
    )
}

export default Titulo
