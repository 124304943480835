import { ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

const CancelConfirmModal = ({
    openModal,
    onOk,
    onCancelModal,
    loading,
    title,
    text,
    strongText,
}) => {
    return (
        <Modal
            title={
                <div>
                    <ExclamationCircleFilled /> {title}
                </div>
            }
            closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
            open={openModal}
            okType="danger"
            onOk={onOk}
            onCancel={onCancelModal}
            confirmLoading={loading}
        >
            <p>
                {text} <strong className="text-primary">{strongText}</strong>?
            </p>
        </Modal>
    )
}

export default CancelConfirmModal
