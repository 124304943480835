import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../services'
import PaginationCom from '../../../pagination/PaginationCom'
import Titulo from '../Titulo'
import TablaPlanes from './items/TablaPlanes'

const AdminPlanes = ({ user }) => {

    const [loading, setLoading] = useState(false)
    const [QR, setQR] = useState(null)
    const [tikects, setTikects] = useState([])
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState([])
    const [show, setShow] = useState(false)

    const incioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({ path: `partners/tickets?page=${page}&limit=5&isPaginate=true`, token: user.logged && user.data.token, params: {}, value: {} })
            setTikects(responseData[0].result)
            setMeta(responseData)
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        incioComponente()
    }, [page])
    return (
        <div>
            <Titulo
                titulo={'Tickets'}
                onAcccion={() => { }}
                button={false}
            />
            <div>
                <TablaPlanes
                    data={tikects.map((item) => {
                        return {
                            ...item,
                            ticketType: item?.ticketType?.typeName
                        }
                    })}
                    onQR={(value) => {
                        setQR(value)
                        setShow(true)
                    }}
                />

                {tikects.length > 0 &&
                    <PaginationCom
                        page={page}
                        meta={meta}
                        setPage={(value) => setPage(value)}
                    />
                }
            </div>

            <Modal title="QR" open={show} onCancel={() => setShow(false)}
                footer={[
                    <Button key="back" onClick={() => setShow(false)}>
                        Cerrar
                    </Button>,
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={QR} alt="QR Code" width={150} />
                </div>
            </Modal>
        </div>
    )
}

export default AdminPlanes
