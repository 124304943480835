/* eslint-disable eqeqeq */
import 'antd/dist/antd.dark.css'
import React, { useContext, useEffect, useState } from 'react'
import { Button, ConfigProvider, Layout, Row, Col } from 'antd'
import SidebarIndex from '../Navbars/SidebarIndex'
import { Outlet, useLocation } from 'react-router-dom'
import logo from '../../assets/images/logoDestinos.svg'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { MenuDesplegable } from '../Navbars/elementos/MenuDesplegable'
import { PiDoorBold } from 'react-icons/pi'
import { getData } from '../../services'
import { AuthContext } from 'auth/AuthContext'
import { HomeButton } from 'componentes/commons'

const { Header, Sider, Content } = Layout

const AdminIndex = () => {
    const [collapsed, setCollapsed] = useState(false)
    const [dominio, setDominio] = useState()
    const { user } = useContext(AuthContext)
    const { pathname } = useLocation()

    const [darkMode, setDarkMode] = useState(false)

    const path = pathname.split('/')

    // Función para cambiar el modo (oscuro o claro)
    const toggleDarkMode = () => {
        setDarkMode(!darkMode)
    }
    const inicioComponente = async () => {
        console.log(
            'El path2 lo que va a buscar luego para el dominio',
            path[2]
        )
        console.log('me ejecuto en adminindex')
        try {
            let res = await getData({
                //! Del lado del admin va a devolver un 404 porque busca el domain=_ antes pasaba lo mismo
                path: `config/public/query?domain=${path[2]}`,
                token: null,
                params: {},
                value: {},
                platform: null,
            })
            console.log('dominio en adminIndex', res)
            setDominio(res)
        } catch (error) {
            console.error('error', error)
        }
    }

    useEffect(() => {
        inicioComponente()
    }, [])

    return (
        <ConfigProvider theme={darkMode ? 'dark' : 'light'}>
            <div
                className={`app ${darkMode ? 'dark-mode' : ''}`}
                style={{ height: '100vh' }}
            >
                <Layout style={{ height: '100%', margin: 0 }}>
                    <div className="">
                        <Sider
                            width={'16rem'}
                            trigger={null}
                            collapsible
                            collapsed={collapsed}
                            breakpoint="lg"
                            collapsedWidth={'0'}
                            className="bg_content border_Sider"
                            style={{
                                overflow: 'auto',
                                height: '100vh',
                                /*position: 'fixed',*/
                                left: 0,
                                top: 0,
                                bottom: 0,
                            }}
                        >
                            <div>
                                <div>
                                    <HomeButton />
                                    <img
                                        src={
                                            user.data.user.role == 'admin'
                                                ? logo
                                                : user.data.user.picture
                                        }
                                        className=" logo_dash "
                                    />
                                </div>
                                <SidebarIndex user={user} />
                                {/*<div className="center-container">
                            <Button
                                onClick={() => history.replace({ pathname: "/_/boletos" })}
                                type="primary"
                                className='btn_desvlop bold'>
                                <img src={buyTicket} className='icon_20' alt="" />
                                Comprar Boleto
                            </Button>
                        </div>*/}
                            </div>
                            <Row className="btn_user fixed ">
                                <Col>
                                    {user.logged === true ? (
                                        <MenuDesplegable
                                            collapsed={collapsed}
                                            rol={'Viajero'}
                                        ></MenuDesplegable>
                                    ) : (
                                        <div className="dropdown">
                                            <div
                                                className="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenu2"
                                            >
                                                <h4 className="bold text-center">
                                                    Pablo Sierra
                                                </h4>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                <Col className="">
                                    {user.logged === false ? (
                                        <div>
                                            {/* <Link to={`/login`}> */}
                                            <Button className="btn-primary-login bold">
                                                <PiDoorBold className="icon_menu_20" />
                                                Ingresar
                                            </Button>
                                            {/* </Link> */}
                                            {/*<button className="exp-fixed-bottom-help  bold" onClick={Tutorial}>
                                ¿Qué es Tugo?
                            </button>*/}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {user.logged && <></>}
                                </Col>
                            </Row>
                        </Sider>
                    </div>
                    <Layout style={{ height: '100%', margin: 0 }}>
                        <Header
                            style={{
                                padding: 0,
                                backgroundColor: 'rgb(17, 17, 17) ',
                                zIndex: '1',
                            }}
                        >
                            <Button
                                type="text"
                                icon={
                                    collapsed ? (
                                        <MenuUnfoldOutlined />
                                    ) : (
                                        <MenuFoldOutlined />
                                    )
                                }
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    color: '#fff',
                                }}
                            />
                            {/* <Icon component={EyeOutlined} onClick={toggleDarkMode} style={{ cursor: 'pointer', color: darkMode ? '#fff' : 'black' }} /> */}
                        </Header>
                        <Content
                            className=" bg_content"
                            style={{ padding: '2rem' }}
                        >
                            <Outlet context={{ user, dominio }} />
                            {/*
              

              {ruta == "paises" && (
                <AdminPaises user={user} dominio={dominio} />
              )}
              {ruta == "boletos" && (
                <AdminBoletos user={user} dominio={dominio} />
              )}
              
              
              {ruta == "boletos-lista" && (
                <ListaBoletos user={user} dominio={dominio} />
              )}
              {ruta == "tipos-tickets" && (
                <TiposTickets user={user} dominio={dominio} />
              )}
              
              {ruta == "tickets" && (
                <ListaTickets user={user} dominio={dominio} />
              )}
              
              {ruta == "parati" && <Parati user={user} dominio={dominio} />}
              {ruta == "chat" && <ChatA user={user} />}
              {ruta == "nueva-experiencia" && (
                <ExperienciaNew user={user} dominio={dominio} />
              )}
              {ruta == "experiencia/horarios/_/:id" && (
                <Schedules dominio={dominio} />
              )}
              
              {ruta == "scanner" && <QRScanner user={user} dominio={dominio} />}
              {ruta == "planes" && (
                // <AdminPlanes user={user} dominio={dominio} />
                <Planes />
              )} */}
                            {/* {ruta == 'validacion/:id' &&
                                <ValidacionQR user={user} />
                            } */}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        </ConfigProvider>
    )
}
export default AdminIndex
