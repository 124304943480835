import { Button, Tooltip } from 'antd'
import React from 'react'
import { ButtonsActionTable } from '../../../../Buttons/ButtonsActionTable'
import { TablaAnt } from '../../../../table/TablaAnt'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const TablaTickets = ({
    data,
    user,
    onEdit,
    onDelete,
    showDelete,
    showEdit,
}) => {
    const columns = [
        {
            title: (
                <div>
                    <text className="bold titulo_table">#</text>
                </div>
            ),
            key: 'ticketNumber',
            dataIndex: 'ticketNumber',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Status</text>
                </div>
            ),
            key: 'statusTicket',
            dataIndex: 'statusTicket',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Precio salida</text>
                </div>
            ),
            key: 'ownerPrice',
            dataIndex: 'ownerPrice',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Nuevo precio</text>
                </div>
            ),
            key: 'salesPrice',
            dataIndex: 'salesPrice',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Acciones</text>
                </div>
            ),
            key: 'acciones',
            align: 'right',
            render: (_, value) => (
                <>
                    <ButtonsActionTable
                        edit={user.data.user.role == 'merchant' ? true : false}
                        onEdit={() => onEdit(value)}
                        editMessage="Editar precio"
                    />
                    {showEdit && (
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => onEdit(value)}
                        />
                    )}
                    {showDelete && (
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete(value._id)}
                        />
                    )}
                </>
            ),
        },
    ]
    return (
        // <></>
        <TablaAnt
            scrollX="max-contend"
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaTickets
