import React, { useState, useContext } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import axios from 'axios'
import {
    Alert,
    Col,
    Form,
    Grid,
    Row,
    Drawer,
    FormGroup,
    Button,
    Icon,
    Modal,
    Input,
} from 'rsuite'
import { AuthContext } from '../../auth/AuthContext'
//Importar Recursos
import logo from '../../assets/images/icono.svg'
import {} from 'antd'
import { Register } from './Register'
import { types } from '../../types/types'
import { GoogleLogin } from 'react-google-login'
import { BASE_PATH } from '../constants'
import InputPassword from '../Login/components/InputPassword'
import { MessageUtils } from 'utils'

export const Login = (props) => {
    const [openModal, setopenModal] = useState(false)
    const [forgotEmail, setforgotEmail] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const { dispatch } = useContext(AuthContext)

    const [visible, setVisible] = useState(false)
    const handleOpenModal = () => {
        openModal ? setopenModal(false) : setopenModal(true)
    }
    const forgotPassword = async () => {
        if (!!!forgotEmail) {
            return
        }
        try {
            const request = await axios.post(
                `${BASE_PATH}/users/forgot-password`,
                {
                    email: forgotEmail,
                    originUrl: window.location.origin,
                }
            )

            if (request.status === 200) {
                handleOpenModal()
                setforgotEmail('')
                Alert.success('Se ha enviado un correo de recuperación!', 5000)
            }
            //console.log(request);
        } catch (error) {
            console.log(error)
            Alert.error('Ha ocurrido un error en el servidor', 3000)
        }
    }

    const responseGoogle = async (response) => {
        try {
            let Login = await axios.post(`${BASE_PATH}/auth/google`, {
                access_token: response.accessToken,
            })
            if (Login.status === 201) {
                sessionStorage.setItem('alerta', true)
                MessageUtils.success('Logeado!')
                dispatch({
                    type: types.login,
                    payload: Login,
                })
            }
        } catch (e) {
            console.error(e)
            if (e.response) {
                if (e.response.status === 404 || e.response.status === 400) {
                    //  console.log(e.response.data.message);
                    Alert.warning(e.response.data.message, 2000)
                }
            }
        }
    }

    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }

    const succesSocial = () => {
        props.onSuccess()
        setVisible(false)
    }

    const onSubmit = async () => {
        if (password == '' || email == '') {
            Alert.error('Campos requeridos vacios.', 2000)
            return
        } else {
            //console.log(email, password);
            axios({
                method: 'POST',
                url: `${BASE_PATH}/auth`,
                auth: {
                    username: email,
                    password: password,
                },
            })
                .then(function (response) {
                    if (response.status === 201) {
                        //console.log(response);
                        onSuscribeNotification(
                            response.data.user.id,
                            response.data.token
                        )
                        sessionStorage.setItem('alerta', true)
                        dispatch({
                            type: types.login,
                            payload: response,
                        })
                        MessageUtils.success('Bienvenido de vuelta!')
                        succesSocial()
                    } else if (
                        response.status === 200 &&
                        !response.data.emailVerified
                    ) {
                        handleSendVerifyEmail(response.data.lastToken)
                    }
                })
                .catch(function (error) {
                    //console.log(error.response);

                    if (error.response) {
                        if (
                            error.response.status === 404 ||
                            error.response.status === 400
                        ) {
                            //  console.log(e.response.data.message);
                            Alert.error(error.response.data.message, 2000)
                        } else if (
                            error.response.status === 500 ||
                            error.response.status === 401
                        ) {
                            Alert.error('Datos incorrectos', 2000)
                        }
                    } else {
                        Alert.error(
                            'Error en el servidor, intentalo luego',
                            2000
                        )
                    }
                })
        }
    }

    const onSuscribeNotification = async (userId, token) => {
        // const Ftoken = sessionStorage.getItem("notification_token");
        // if (!!Ftoken) {
        //   const suscribe = await axios.post(
        //     `${BASE_PATH}/notifications/subscribe`,
        //     { fToken: Ftoken, userId: userId },
        //     {
        //       headers: {
        //         Authorization: `Bearer ${token}`,
        //       },
        //     }
        //   );
        // }
    }

    const handleSendVerifyEmail = async (id) => {
        try {
            const request = await axios.post(
                `${BASE_PATH}/users/send-verify-email`,
                {
                    email: email,
                    originUrl: window.location.origin,
                }
            )
            //console.log(request)
            if (request.status === 200 && request.data.emailSended) {
                Alert.success('Se ha enviado un correo de verificación!')
                navigate(`/send-verify-email/${id}`, { replace: true })
            }
        } catch (error) {
            Alert.error('Ha ocurrido un error en el servidor')
            console.log(error)
        }
    }
    return (
        <>
            <Grid
                fluid
                className="d-flex justify-content-center
        d-flex align-items-center vh-100"
            >
                <Card className="border-0">
                    <img src={logo} className="login-logo" alt="logo" />
                    <Card.Body>
                        <Card.Title className="bold login-title text-center">
                            Iniciar Sesión
                            <br />
                        </Card.Title>

                        <Form layout="horizontal" fluid>
                            <Card.Title className="Medium login-title-code"></Card.Title>
                            <Row>
                                <Col xs={24}>
                                    <GoogleLogin
                                        clientId="820836317775-da5ht99v02j9d0mb8j636vm5on2oa5mk.apps.googleusercontent.com"
                                        render={(renderProps) => (
                                            <button
                                                className="bold btn-social "
                                                onClick={renderProps.onClick}
                                                disabled={renderProps.disabled}
                                            >
                                                <i className="icon-google text-danger"></i>
                                            </button>
                                        )}
                                        buttonText="Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                    />
                                </Col>
                            </Row>
                            <div style={{ margin: '' }}></div>
                            <p
                                className="lite text-center"
                                style={{ marginTop: 21, fontSize: '18px' }}
                            >
                                ó ingresa con tu cuenta de correo
                            </p>
                            <FormGroup>
                                <Input
                                    className="form-control text-pass btn-lg"
                                    value={email}
                                    onChange={(val) => setEmail(val)}
                                    placeholder="Correo"
                                    type="email"
                                />
                            </FormGroup>

                            <FormGroup>
                                <InputPassword
                                    value={password}
                                    setValue={setPassword}
                                />

                                {/*<Button
                  className="forgot-password text-center btn-black"
                  onClick={setopenModal}
                >
                  ¿Olvidaste tu contraseña?
                    </Button>*/}
                            </FormGroup>

                            <Button
                                className="bold text-center btn-primary btn-login-first"
                                onClick={onSubmit}
                            >
                                Entrar
                            </Button>
                            <div className="d-flex justify-content-center">
                                <Button
                                    className=" text-center  mt-2"
                                    onClick={showDrawer}
                                >
                                    Regístrate manualmente
                                    <Icon
                                        icon="arrow-circle-right"
                                        style={{ marginLeft: '0.6rem' }}
                                    />
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Grid>
            <Drawer
                size="xs"
                onHide={onClose}
                show={visible}
                style={{ background: '#171718' }}
            >
                <Drawer.Body>
                    <Grid fluid>
                        <button
                            type="button"
                            className="back-window-navbar"
                            onClick={onClose}
                        >
                            <i className="icon-back icon-left-arrow"></i>
                        </button>
                        <Register onSuccess={succesSocial} />
                    </Grid>
                </Drawer.Body>
            </Drawer>
            <Modal
                size="xs"
                show={openModal}
                onHide={setopenModal}
                style={{ maxWidth: '98%' }}
            >
                <Modal.Header>
                    <Modal.Title>Ingresa el correo de tu cuenta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        placeholder="Correo"
                        onChange={(value) => setforgotEmail(value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ background: '#fe5000' }}
                        onClick={forgotPassword}
                        appearance="primary"
                    >
                        Recuperar
                    </Button>
                    <Button
                        onClick={() => {
                            handleOpenModal()
                        }}
                        appearance="subtle"
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
