import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import '../../assets/css/App.css'
import 'antd/dist/antd.css'
import { Avatar } from 'antd'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Icon } from 'rsuite'

//Importar Recursos
import Maps from '../Experiencias/maps'
import axios from 'axios'
import { BASE_PATH } from '../constants'
import { Map } from 'componentes/commons'
import {
    RiCalendarFill,
    RiMoneyDollarCircleFill,
    RiTimer2Fill,
    RiUserSmileFill,
} from 'react-icons/ri'

const PackageDetails = ({ experiences, paquete }) => {
    const navigate = useNavigate()
    const uniqueExtras = Array.from(
        new Set(
            experiences.flatMap((exp) => exp.extra).map((item) => item.valor)
        )
    ).map((valor) =>
        experiences
            .flatMap((exp) => exp.extra)
            .find((item) => item.valor === valor)
    )
    console.log(paquete)
    console.log(experiences)

    return (
        <>
            {experiences.length > 0 && (
                <div className="exp-details">
                    <section>
                        <text style={{ fontSize: 20 }}>
                            {paquete.length > 0 ? paquete[0].name : <></>}
                        </text>
                        <p className="bold mb-4">
                            <text style={{ fontSize: 16 }}>Inicio: </text>
                            {experiences.length > 0 ? (
                                experiences[0].start
                            ) : (
                                <></>
                            )}
                            <br />
                            <text style={{ fontSize: 16 }}>Final: </text>
                            {experiences.length > 0 ? (
                                experiences[experiences.length - 1].end
                            ) : (
                                <></>
                            )}
                        </p>
                        <Row md={4}>
                            {/* <Col md={4}>
                                <Row className="card-details mb-4">
                                    <Col xs={3}>
                                        <RiCalendarFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Fecha</text>
                                        <p className="bold">
                                            Consultar horarios*
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiTimer2Fill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Horario</text>
                                        <p className="bold">
                                            Consultar horarios*
                                        </p>
                                    </Col>
                                </Row>
                            </Col> */}
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiMoneyDollarCircleFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Precio</text>
                                        <p className="bold">
                                            Adultos ${' '}
                                            {paquete.length > 0 ? (
                                                paquete[0]?.grossPriceAdult
                                            ) : (
                                                <></>
                                            )}
                                            , Niños ${' '}
                                            {paquete.length > 0 ? (
                                                paquete[0]?.grossPriceChildren
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiUserSmileFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Cupones</text>
                                        <p className="bold">
                                            {/* {experience.length > 0 ? (
                                        experience[0].quotas.min + ' - '
                                    ) : (
                                        <></>
                                    )}
                                    {experience.length > 0 ? (
                                        experience[0].quotas.max + ' '
                                    ) : (
                                        <></>
                                    )} */}
                                            personas por horario
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiUserSmileFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Reservados</text>
                                        <p className="bold">
                                            {/* {quotes.reserved} */}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row className="card-details">
                                    <Col xs={3}>
                                        <RiUserSmileFill className="demo-icon-details neon_blue" />
                                    </Col>
                                    <Col>
                                        <text>Disponibles</text>
                                        <p className="bold">
                                            {/* {quotes.available} */}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>

                    <section>
                        <h3 className="bold">Lo que haremos</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    paquete.length > 0
                                        ? paquete[0].description.replace(
                                              /\n/g,
                                              '<br />'
                                          )
                                        : '',
                            }}
                        ></p>
                    </section>

                    <section>
                        <h3 className="bold">Experiencias</h3>
                        <OwlCarousel
                            className="owl-carousel"
                            margin={20}
                            nav={false}
                            dots={true}
                            responsive={{
                                1200: {
                                    items: 4,
                                },
                                300: {
                                    items: 2,
                                },
                            }}
                        >
                            {experiences.map((item, i) => (
                                <div className="item card2 text-center" key={i}>
                                    <button
                                        onClick={() =>
                                            navigate(
                                                `/host/experiencia/_/${item.id}`
                                            )
                                        }
                                    >
                                        <Card>
                                            <Card.Body className="bold">
                                                <h6
                                                    style={{
                                                        paddingTop: 20,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    {item.name}
                                                </h6>
                                            </Card.Body>
                                        </Card>
                                    </button>
                                </div>
                            ))}
                        </OwlCarousel>
                    </section>

                    <li className="dropdown-divider"></li>

                    <section>
                        <h3 className="bold">Ubicación</h3>

                        <div style={{ height: '10rem' }}>
                            <Map
                                location={{
                                    lat: experiences[0]?.lat,
                                    lng: experiences[0]?.long,
                                }}
                            />
                        </div>
                    </section>

                    <li className="dropdown-divider"></li>

                    <section>
                        <h3 className="bold">Incluye</h3>
                        <OwlCarousel
                            className="owl-carousel"
                            margin={20}
                            nav={false}
                            dots={true}
                            responsive={{
                                1200: {
                                    items: 4,
                                },
                                300: {
                                    items: 2,
                                },
                            }}
                        >
                            {uniqueExtras.map((item, i) => (
                                <div
                                    className="item card2 text-center"
                                    key={`${item.valor}_${item.icon}_${i}`}
                                >
                                    <Card>
                                        <Card.Body className="bold">
                                            <Icon icon={item.icon} size="3x" />
                                            <br />
                                            <h6
                                                style={{
                                                    paddingTop: 20,
                                                    marginBottom: 1,
                                                }}
                                            >
                                                {item.valor}
                                            </h6>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </OwlCarousel>
                    </section>
                </div>
            )}
        </>
    )
}

export default PackageDetails
