import { Form } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import { BASE_PATH } from '../../../constants'
import { OPTION_TYPE_FORM } from '../../../InputForm/InputForm'
import Titulo from '../Titulo'
import TablaParques from './items/TablaParques'
import { useOutletContext } from 'react-router-dom'
import PaginationCom from 'componentes/pagination/PaginationCom'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import { MessageUtils } from 'utils'

const initForm = {
    name: '',
    email: '',
    password: null,
    country: 'El Salvador',
    partnerType: 'park',
}

const AdminParques = () => {
    const { user } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [parques, setParques] = useState([])
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState([])
    const [action, setAction] = useState('new')
    const uploaderRef = React.useRef()
    const [fileList, setFileList] = useState(null)
    const [selectedDestinyId, setSelectedDestinyId] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [formValues, setFormValues] = useState(initForm)
    const [form] = Form.useForm()
    const [isLoadingPutDelete, setIsLoadingPutDelete] = useState(false)

    const handleFile = (event) => {
        const selectedFile = uploaderRef.current.start()
        setFileList(selectedFile)
    }

    const inicioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({
                path: `users/partners/ptnr/?page=${page}&limit=11&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            const filterData = responseData[0].result.map((user) => {
                const { password, ...rest } = user
                return rest
            })
            setParques(filterData)
            setMeta(responseData)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        inicioComponente()
    }, [])

    useEffect(() => {
        inicioComponente()
    }, [page])

    const onFinish = async (values) => {
        // setFormValues(values);
        // if (fileList != null) {
        setIsLoadingPutDelete(true)
        try {
            setLoading(true)
            const fdata = new FormData()
            // fdata.append('file', fileList.blobFile)
            // console.log('ftada', fdata)

            // const imagesUp = await axios.post(
            //     `${BASE_PATH}/images/upload-files`,
            //     fdata,
            //     {
            //         headers: {
            //             'Content-Type': 'multipart/form-data',
            //             Authorization: `Bearer ${user.data.token}`,
            //         },
            //     }
            // )

            const response = await postData({
                path: `users/partners/ptnr`,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                    // picture: imagesUp.data.imageUrl
                },
            })
            setFormValues(initForm)
            form.resetFields()
            inicioComponente()
            setOpen(false)
            MessageUtils.success('Destino creado con éxito')
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar crear un destino'
            )
            console.log(error)
        } finally {
            setLoading(false)
            setIsLoadingPutDelete(false)
        }
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setOpen(false)
        setTimeout(() => {
            setAction('new')
        }, 300)
    }

    const onEdit = (destiny) => {
        const values = {
            name: destiny.name,
            email: destiny.email,
            password: destiny.password,
            country: destiny.country,
            partnerType: destiny.partnerType,
        }
        setFormValues(values)
        form.setFieldsValue(values)
        setOpen(true)
        setAction('edit')
        setSelectedDestinyId(destiny._id)
    }

    const onCancelModal = () => {
        setSelectedDestinyId('')
        setOpenModal(false)
    }

    const handleDeleteButton = (destiny) => {
        setSelectedDestinyId(destiny._id)
        setOpenModal(true)
    }

    const updateDestiny = async () => {
        try {
            setIsLoadingPutDelete(true)
            const { password, ...valuesToSend } = formValues
            await putData({
                path: `users/partners/ptnr`,
                token: user.data.token,
                params: null,
                value: valuesToSend,
                id: selectedDestinyId,
            })
            if (password) {
                await axios.put(
                    `${BASE_PATH}/users/${selectedDestinyId}/password-from-admin`,
                    { password },
                    {
                        headers: {
                            Authorization: `Bearer ${user.logged && user.data.token}`,
                        },
                    }
                )
            }
            inicioComponente()
            onCloseDrawer()
            MessageUtils.success('Destino editado correctamente')
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar editar el destino'
            )
        } finally {
            setIsLoadingPutDelete(false)
        }
    }

    const deleteDestiny = async () => {
        try {
            const response = await deleteData({
                path: `users/partners`,
                id: selectedDestinyId,
                token: user.logged && user.data.token,
            })
            if (response) {
                MessageUtils.success('Se ha eliminado el destino con éxito')
                inicioComponente()
                setOpenModal(false)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar el destino'
                )
            }
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error')
            console.log(error)
        }
    }

    return (
        <div>
            <Titulo
                titulo={'Lista de Destinos'}
                tituloBoton={'Crear Destino'}
                onAcccion={() => setOpen(true)}
                button={user?.data?.user?.role == 'admin' ? false : true}
            />
            <div>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <TablaParques
                            data={parques}
                            onEdit={onEdit}
                            onDelete={handleDeleteButton}
                        />
                        {parques.length > 0 && (
                            <PaginationCom
                                page={page}
                                meta={meta}
                                setPage={(value) => setPage(value)}
                            />
                        )}
                    </>
                )}
            </div>
            <SidebarForm
                title={action === 'new' ? 'Crear Destino' : 'Editar Destino'}
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                form={form}
                formValues={formValues}
                onFinish={action === 'new' ? onFinish : updateDestiny}
                open={open}
                isLoading={isLoadingPutDelete}
                setFormValues={setFormValues}
                onCloseDrawer={onCloseDrawer}
                inputs={[
                    {
                        label: 'Destino',
                        name: 'name',
                        rules: [
                            {
                                required: true,
                                message: 'Ingrese un nombre de destino',
                            },
                        ],
                    },
                    {
                        label: 'Email',
                        name: 'email',
                        rules: [
                            {
                                required: true,
                                pattern:
                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                message: 'Ingrese un email',
                            },
                        ],
                    },
                    {
                        label: 'Password',
                        name: 'password',
                        type: OPTION_TYPE_FORM.password,
                        rules: [
                            {
                                required: true,
                                message:
                                    'Ingrese una contraseña de al menos 6 caracteres',
                            },
                        ],
                    },
                ]}
            />
            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={deleteDestiny}
                openModal={openModal}
                strongText={
                    parques.find((destiny) => destiny._id === selectedDestinyId)
                        ?.name
                }
                text={`¿Estás seguro de que deseas eliminar al destino`}
                title={'Eliminar Destino'}
            />
        </div>
    )
}

export default AdminParques
