import moment from 'moment'

export const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

export const daysOfWeek = [
    {
        label: 'Domingo',
        value: 0,
    },
    {
        label: 'Lunes',
        value: 1,
    },
    {
        label: 'Martes',
        value: 2,
    },
    {
        label: 'Miercoles',
        value: 3,
    },
    {
        label: 'Jueves',
        value: 4,
    },
    {
        label: 'Viernes',
        value: 5,
    },
    {
        label: 'Sábado',
        value: 6,
    },
]

export const periodicities = [
    { label: 'Día', value: 'daily' },
    { label: 'Semana', value: 'weekly' },
    { label: 'Mes', value: 'monthly' },
]

export const daysOfWeekObject = {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
}

export const periodicitiesObject = {
    dayly: 'Día',
    weekly: 'Semana',
    monthly: 'Mes',
}

export const monthsInYear = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre',
}

export const getMaximDueDate = (date, extraMonths = 0) => {
    let newDate = new Date(date)
    let currentMonths = newDate.getMonth()
    let newMonths = currentMonths + 12 + extraMonths

    newDate.setMonth(newMonths)
    return newDate
}

export const getFormatTime = (time) => {
    const splitTime = time.split(':')
    const splitFormat = splitTime?.[1]?.split(' ')

    const minutes = splitFormat[0]
    let hours = parseInt(splitTime[0])
    const originalHours = hours

    if (hours > 12) {
        hours = hours - 12
    }

    const formatTime = `${hours}:${minutes} ${originalHours > 12 ? 'pm' : 'am'}`

    return formatTime
}

// export const setTimeInDate = (time, date) => {
//     const splitTime = time.split(':')
//     const splitFormat = splitTime[1].split(' ')

//     const minutes = splitFormat[0]
//     let hours = splitTime[0]

//     if (splitFormat[1] === 'pm') {
//         hours = parseInt(hours) + 12
//     }

//     date.setHours(hours)
//     date.setMinutes(minutes)
//     console.log(date)
//     return date
// }
export const setTimeInDate = (startTime, date) => {
    const time = new Date(startTime) // Convierte 'start' a objeto Date

    // Ajustar horas y minutos de 'startTime' en 'date'
    date.setHours(time.getUTCHours())
    date.setMinutes(time.getUTCMinutes())
    date.setSeconds(0) // Si no deseas incluir segundos

    console.log(date)
    return date
}

export const getAvailableMonths = () => {
    const currentDate = new Date()
    let dueDate = getMaximDueDate(currentDate)
    const arrDates = []

    while (
        currentDate.getMonth() < dueDate.getMonth() ||
        currentDate.getFullYear() < dueDate.getFullYear()
    ) {
        let newdueDate = new Date(dueDate.setMonth(dueDate.getMonth() - 1))
        arrDates.push({
            month: newdueDate.getMonth(),
            year: newdueDate.getFullYear(),
        })
        dueDate = newdueDate
    }

    return arrDates.reverse()
}

export const DaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate()

export const WeeksInMonths = (year, month) => {
    const daysInMonth = DaysInMonth(year, month)
    const weeksInMonth = []
    let currentDay = 1
    const currentDate = new Date()
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    const initDate = new Date(year, month, 1)

    if (currentDate.getTime() >= initDate.getTime()) {
        currentDay = currentDate.getDate()
    }

    while (currentDay <= daysInMonth) {
        let lastWeekDay =
            currentDay + 6 > daysInMonth ? daysInMonth : currentDay + 6
        let initDayInWeek = new Date(year, month, currentDay).getDay()
        let lastDayInWeek = new Date(year, month, lastWeekDay).getDay()

        weeksInMonth.push({
            initDay: currentDay,
            lastDay: lastWeekDay,
            initDayInWeek,
            lastDayInWeek,
        })

        currentDay = currentDay + 7
    }

    return weeksInMonth
}

export const isIncludedDayInFirstWeek = (dayInMonth) => {
    let init = 1
    let included = false
    while (init <= 7) {
        if (dayInMonth === init) {
            included = true
            break
        }
        init = init + 1
    }

    return included
}

export const setLeftZeroInNumber = (number) => {
    let newNumber = number
    if (newNumber < 10) {
        newNumber = `0${newNumber}`
    }

    return newNumber
}

export const disabledDate = (current) => {
    console.log({ current })
    return current && current < moment().startOf('day')
}

export const formatToElSalvadorTime = (date) => {
    return moment(date).tz('America/El_Salvador').format()
}
