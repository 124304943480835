const { PiHouseBold } = require("react-icons/pi");
const { Link } = require("react-router-dom");

const HomeButton = () => {
  return (
    <Link
      to={"/"}
      className="flex items-center rounded-full ml-2 mt-2 w-8 bg-[#FE5000] p-2 text-[#3A1507] transition-colors duration-200 hover:bg-[#3A1507] hover:text-white"
    >
      <PiHouseBold />
    </Link>
  );
};

export default HomeButton;
