import React, { useState, useEffect } from 'react'
import {} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import '../../assets/css/App.css'
import 'antd/dist/antd.css'
import { Avatar } from 'antd'
import { Icon } from 'rsuite'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import {
    RiCalendarFill,
    RiTimer2Fill,
    RiUserSmileFill,
    RiMoneyDollarCircleFill,
} from 'react-icons/ri'

//Importar Recursos
import Maps from '../Experiencias/maps'
import {
    dateOptions,
    getFormatTime,
    setLeftZeroInNumber,
} from '../Anfitrion/Experiencias/schedules/utils'

const ExperienciaDetailsOpen = ({ dataExp = {}, dataReserva = {} }) => {
    const cupos = parseInt(dataReserva.adult) + parseInt(dataReserva.children)
    const { picture } = dataExp?.user
    const [usern, setusern] = useState([])
    const hours = new Date(dataReserva?.bookedDateTime).getHours()
    const minutes = setLeftZeroInNumber(
        new Date(dataReserva?.bookedDateTime).getMinutes()
    )
    const timeFormat = `${hours}:${minutes}`

    useEffect(() => {
        if (dataExp?.user?.infoExtra) {
            setusern(dataExp?.user?.infoExtra)
        } else {
            setusern(dataExp?.user)
        }
    }, [])
    return (
        <div className="exp-details">
            <section>
                <text>{dataExp.direction}</text>
                <p className="bold">
                    <text style={{ fontSize: 20 }}>Inicio: </text>
                    {dataExp.start}
                    <br />
                    <text style={{ fontSize: 20 }}>Final: </text>
                    {dataExp.end}
                </p>
                <Row md={4}>
                    <Col md={5}>
                        <Row className="card-details">
                            <Col xs={3}>
                                <RiCalendarFill className="demo-icon-details" />
                            </Col>
                            <Col>
                                <text>Fecha</text>
                                <p className="bold">
                                    {new Date(
                                        dataReserva?.bookedDateTime
                                    ).toLocaleDateString('es-ES', dateOptions)}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <Row className="card-details">
                            <Col xs={4}>
                                <RiTimer2Fill className="demo-icon-details" />
                            </Col>
                            <Col>
                                <text>Horario</text>
                                <p className="bold">
                                    {getFormatTime(timeFormat)}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <Row className="card-details">
                            <Col xs={4}>
                                <RiUserSmileFill className="demo-icon-details" />
                            </Col>
                            <Col>
                                <text>Cupo</text>
                                <p className="bold">
                                    {dataReserva?.adult || 0} Adultos,{' '}
                                    {dataReserva?.children || 0} Niños
                                </p>
                                <p className="bold"></p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <Row className="card-details">
                            <Col xs={4}>
                                <RiMoneyDollarCircleFill className="demo-icon-details" />
                            </Col>
                            <Col>
                                <text>Precio</text>
                                <p className="bold">$ {dataReserva.mount}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>

            <section className="exp-details-about">
                <Row>
                    <Col sm={3} className="text-center">
                        <Avatar
                            size={140}
                            src={usern.picture ? usern.picture : picture}
                        />
                    </Col>
                    <Col sm={8} style={{ paddingTop: 10 }}>
                        <p>Anfitrión</p>
                        <p className="heavy mt-3" style={{ fontSize: '45px' }}>
                            {usern.name ? usern.name : <></>}
                        </p>
                        <p>{usern.description ? usern.description : <></>}</p>
                    </Col>
                </Row>
            </section>

            <section>
                <h3 className="bold">Lo que haremos</h3>
                <p>{dataExp.description}</p>
            </section>

            <li className="dropdown-divider"></li>

            <section>
                <h3 className="bold">Ubicación</h3>
                <div style={{ height: '10rem' }}>
                    <Maps
                        isCenter={{
                            lat: parseFloat(dataExp.lat),
                            lng: parseFloat(dataExp.long),
                        }}
                    />
                </div>
            </section>

            <li className="dropdown-divider"></li>

            <section>
                <h3 className="bold">Incluye</h3>
                <OwlCarousel
                    className="owl-carousel"
                    margin={20}
                    nav={false}
                    dots={true}
                    responsive={{
                        1200: {
                            items: 4,
                        },
                        300: {
                            items: 2,
                        },
                    }}
                >
                    {dataExp?.extra.map((item, i) => (
                        <div
                            className="item card2 text-center"
                            key={`${item.valor}_${item.icon}_${i}`}
                        >
                            <Card>
                                <Card.Body className="bold">
                                    <Icon icon={item.icon} size="3x" />
                                    <br />
                                    <h6
                                        style={{
                                            paddingTop: 20,
                                            marginBottom: 1,
                                        }}
                                    >
                                        {item.valor}
                                    </h6>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </OwlCarousel>
            </section>
        </div>
    )
}

export default ExperienciaDetailsOpen
