import { Avatar } from 'antd'
import React from 'react'
import { ButtonsActionTable } from '../../../../Buttons/ButtonsActionTable'
import { TablaAnt } from '../../../../table/TablaAnt'

const TablaHistorial = ({
    data
}) => {
    const columns = [
        {
            title: <div><text className='bold titulo_table'>Fecha de visita</text></div>,
            key: 'name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: <div><text className='bold titulo_table'>Boletos</text></div>,
            key: 'email',
            dataIndex: 'email',
            sorter: false
        },
    ]
    return (
        <TablaAnt
            scrollX='max-contend'
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaHistorial
