import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {} from "react-bootstrap";
import "../../assets/css/App.css";
import "antd/dist/antd.css";
import styles from "../Chat/styles.css";

//Importar Recursos
import logo from "../../assets/images/desvlop.svg";
import "../../assets/css/iconos/css/fontello.css";
import { AuthContext } from "../../auth/AuthContext";

import { MenuDesplegable } from "./elementos/MenuDesplegable";

const Info = ({ searchExp = "" }) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="menu-desp">
      <div className="d-none d-md-none d-lg-block">
        <nav className="navbar navbar-info navbar-light fixed-top">
          <img src={logo} className="logo" alt="logo" />

          <ul className="navbar-nav navbar-first nav-pills d-none d-md-none d-lg-block">
            <li>
              <Link to="/" className="bold padding btn-primary-menu-first">
                <i className="demo-icon-first icon-home orange"></i>
                Blog
              </Link>
              {user.logged && (
                <>
                  <Link
                    to="/planes"
                    className="bold padding btn-primary-menu-first"
                  >
                    <i className="demo-icon-first icon-luggage orange"></i>
                    Planes
                  </Link>
                  <Link
                    to="/chat"
                    className="bold padding btn-primary-menu-first"
                    style={{ marginRight: "1.7rem" }}
                  >
                    <i className="demo-icon-first icon-chat orange"></i>
                    Chats
                  </Link>
                </>
              )}
              {user.logged === false ? (
                <Link to="/">
                  <button
                    type="button"
                    className="btn btn-primary-reserva bold"
                    variant="primary"
                  >
                    App
                  </button>
                </Link>
              ) : (
                <></>
              )}
            </li>
          </ul>
          {user.logged === true ? (
            <MenuDesplegable rol={"Viajero"}></MenuDesplegable>
          ) : (
            <div className="dropdown">
              <div
                className="dropdown-menu 
                            dropdown-menu-right"
                aria-labelledby="dropdownMenu2"
              >
                <h4 className="bold text-center">Pablo Sierra</h4>
                <Link to="/configuraciones" style={{ textDecoration: "none" }}>
                  <button
                    className="dropdown-item text-white bold"
                    type="button"
                  >
                    <i className="demo-icon-menu icon-settings orange"></i>
                    Configuraciones
                  </button>
                </Link>
              </div>
            </div>
          )}
        </nav>
      </div>

      <div className="d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none text-center">
        <nav className="navbar-sm fixed-bottom">
          <ul className="navbar-nav">
            <li>
              <Link to="/" className="bold btn-primary-menu">
                <i className="demo-icon icon-home"></i>
              </Link>
              <Link to="/explora" className="bold btn-primary-menu">
                <i className="demo-icon icon-search"></i>
              </Link>
              {user.logged && (
                <Link to="/planes" className="bold btn-primary-menu">
                  <i className="demo-icon icon-luggage"></i>
                </Link>
              )}

              {user.logged && (
                <Link to="/chat" className="bold btn-primary-menu">
                  <i className="demo-icon icon-comment"></i>
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Info;
