import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { BASE_PATH } from "../constants";
import {
  Alert,
  Form,
  FormGroup,
  FormControl,
  Schema,
  InputGroup,
  Icon,
  IconButton,
} from "rsuite";
import { StringType } from "schema-typed";

import "./style.css";

//Importar Recursos
import logo from "../../assets/images/icono.svg";

const Register = () => {
  const navigate = useNavigate();
  const HomeB = () => {
    navigate("/login", { replace: true });
  };
  const userForm = {
    email: "",
    password: "",
    password_confirmation: "",
    /*name: "",*/
    /*phone: "",*/
  };
  const formRef = useRef();
  const [authForm, setauthForm] = useState(userForm);
  const [bntLocked, setbntLocked] = useState(false);

  const [show, setshow] = useState(false);

  const handleShowPassword = () => setshow(!show);

  const modelForm = Schema.Model({
    /*name: StringType().isRequired("Campo obligatorio."),*/
    password: StringType()
      .addRule((value, data) => {
        return value.length > 6;
      }, "Elija una contraseña mas segura")
      .isRequired("Campo obligatorio"),
    /*phone: StringType().isRequired("Campo obligatorio"),*/
    email: StringType().isRequired("Campo obligatorio"),
    password_confirmation: StringType()
      .addRule((value, data) => {
        //console.log(data);
        return value === data.password;
      }, "Las contraseñas no coinciden")
      .isRequired("Campo obligatorio"),
  });

  const getRequestBody = () => {
    return {
      /*name: authForm.name,*/
      password: authForm.password,
      /*phone: authForm.phone,*/
      email: authForm.email,
    };
  };

  const onSubmit = async () => {
    if (!formRef.current.check()) {
      Alert.warning("Campos requeridos vacios.", 2000);
    } else {
      setbntLocked(true);
      try {
        let registerRequest = await axios.post(
          `${BASE_PATH}/users`,
          getRequestBody()
        );

        if (
          registerRequest.status === 200 &&
          registerRequest.data.msg === "Correo o telefono en uso"
        ) {
          Alert.error("El correo o teléfono ya está registrado.");
          setbntLocked(false);
        } else if (registerRequest.status === 201) {
          handleSendEmail(registerRequest.data.user);
        }
      } catch (e) {
        console.error(e);
        Alert.error("Ha ocurrido un error ");
        setbntLocked(false);
      }
    }
  };

  const handleSendEmail = async (user) => {
    try {
      const request = await axios.post(`${BASE_PATH}/users/send-verify-email`, {
        email: user?.email,
        originUrl: window.location.origin,
      });
      //console.log(request)
      if (request.status === 200 && request.data.emailSended) {
        //Alert.success("Correo reenviado exitosamente!")
        setbntLocked(false);
        navigate(`/send-verify-email/${user?.id}`, { replace: true });
      }
    } catch (error) {
      Alert.error("Ha ocurrido un error en el servidor");
      console.log(error);
      setbntLocked(false);
    }
  };

  const handleChange = (data) => {
    setauthForm(data);
  };

  return (
    <div
      className="d-flex justify-content-center
        d-flex align-items-center vh-100"
    >
      <button type="button" className="back-window fixed-top" onClick={HomeB}>
        <i className=" icon-left-arrow"></i>
      </button>
      <Card style={{ width: "18em" }} className="border-0">
        <img src={logo} className="login-logo" alt="logo" />
        <Card.Body>
          <Card.Title className="bold login-title text-center">
            Viaja sin límites
            <br />
            <p className="Medium">Crea una cuenta</p>
          </Card.Title>

          <Form
            onChange={(formValue) => handleChange(formValue)}
            model={modelForm}
            formValue={authForm}
            ref={formRef}
            onSubmit={onSubmit}
          >
            <FormGroup className="formGroup-auth">
              <FormControl
                className="form-control text-pass btn-lg"
                name="email"
                placeholder="Email"
                type="email"
              />
            </FormGroup>

            <FormGroup className="formGroup-auth">
              <InputGroup inside style={{ width: "100%" }}>
                <FormControl
                  className="form-control text-pass btn-lg"
                  name="password"
                  placeholder="Contraseña"
                  type={show ? "text" : "password"}
                />
                <InputGroup.Addon style={{ height: "100%" }}>
                  <IconButton
                    onClick={handleShowPassword}
                    icon={<Icon icon={show ? "eye-slash" : "eye"} />}
                    circle
                    size="xs"
                  />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>

            <FormGroup className="formGroup-auth">
              <FormControl
                className="form-control text-pass btn-lg"
                name="password_confirmation"
                placeholder="Repetir contraseña"
                type="password"
              />
            </FormGroup>

            <button
              style={{ border: "none" }}
              type="button"
              onClick={onSubmit}
              className="btn btn-primary bold btn-auth"
              disabled={bntLocked}
            >
              Registrarme
            </button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Register;
