import { Avatar } from 'antd'
import React from 'react'
import { ButtonsActionTable } from '../../../../Buttons/ButtonsActionTable'
import { TablaAnt } from '../../../../table/TablaAnt'
import { render } from 'react-dom'

const TablaViajeros = ({
    data,
    onView
}) => {
    const columns = [
        {
            title: '',
            key: 'picture',
            dataIndex: 'picture',
            render: (value) => (
                <Avatar src={value} />
            ),
        },
        {
            title: <div><p className='bold titulo_table'>Viajero</p></div>,
            key: 'name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: <div><p className='bold titulo_table'>Email</p></div>,
            key: 'email',
            dataIndex: 'email',
            sorter: false
        },
        {
            title: <div><p className='bold titulo_table'>Telefono</p></div>,
            key: 'phone',
            dataIndex: 'phone',
            sorter: false
        },
        {
            title: <div><p className='bold titulo_table'>Nacionalidad</p></div>,
            key: 'direction',
            render: (_, value) => value?.direction === 'Mexico' ? 'Local' : 'Extranjero',
            sorter: false
        },
        {
            title: <div><p className='bold titulo_table'>Acciones</p></div>,
            key: 'acciones',
            // align: 'right',
            fixed: 'right',
            render: (_, value) => (
                <ButtonsActionTable
                    view
                    onView={() => onView(value)}
                    viewMessage="Ver Viajero"
                />
            ),
        },
    ]
    return (
        <TablaAnt
            scrollX='max-contend'
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaViajeros
