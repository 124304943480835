import React, { useState, useEffect } from 'react'
import { Menu } from 'antd'
import { Link, useParams } from 'react-router-dom' // Importa Link
import {
    PiFireBold,
    PiBackpackBold,
    PiChatTeardropTextBold,
    PiSquaresFourBold,
    PiBinocularsBold,
    PiParkBold,
    PiMedalBold,
    PiTicketBold,
    PiLightningBold,
    PiMoneyBold,
} from 'react-icons/pi'

function getItem(label, key, icon, path, children) {
    return {
        key,
        icon,
        children,
        label,
        path, // Agrega la propiedad path para la navegación
    }
}

const SidebarIndex = ({ user }) => {
    const { id } = useParams()
    console.log('User dentreo de SidebarIndex', user)
    const [company, setCompany] = useState('')
    const ruta = window.location.pathname
    const rutaB = ruta.split('/')
    const items =
        user?.data?.user?.role === 'user' ||
        (id !== undefined && user.logged && user.data.user.role !== 'partner')
            ? [
                  //getItem('Viajeros', 'viajeros', <PiFireBold className='icon_menu_20' />, '/_/viajeros'),
                  // getItem('Tickets', 'tickets', <PiMedalBold className='icon_menu_20' />, '/_/tickets'),
                  getItem(
                      'Para ti',
                      'parti',
                      <PiFireBold className="icon_menu_20" />,
                      // `/${company}`
                      'parati'
                  ),
                  getItem(
                      'Planes',
                      'planes',
                      <PiBackpackBold className="icon_menu_20" />,
                      `planes`
                  ), //en este menu colocar tambien el de tickets para que vea el listado de tickets
                  getItem(
                      'Chat',
                      'chat',
                      <PiChatTeardropTextBold className="icon_menu_20" />,
                      `chat`
                  ),
              ]
            : user?.data?.user?.role === 'admin'
              ? [
                    getItem(
                        'Dashboard',
                        'dashboard',
                        <PiSquaresFourBold className="icon_menu_20" />,
                        `dashboard`
                    ),
                    getItem(
                        'Compañias',
                        'fundaciones',
                        <PiBinocularsBold className="icon_menu_20" />,
                        `/_/fundaciones`
                    ),
                    getItem(
                        'Destinos',
                        'parques',
                        <PiParkBold className="icon_menu_20" />,
                        `/_/parques`
                    ),
                    getItem(
                        'Revendedores',
                        'revendedores',
                        <PiFireBold className="icon_menu_20" />,
                        `/_/revendedores`
                    ),
                    getItem(
                        'Puntos Ventas',
                        'puntos-ventas',
                        <PiFireBold className="icon_menu_20" />,
                        `/_/puntos-ventas`
                    ),
                    getItem(
                        'Experiencias',
                        'lista-experiencias',
                        <PiBinocularsBold className="icon_menu_20" />,
                        `/_/lista-experiencias`
                    ),
                    getItem(
                        'Paquetes',
                        'paquetes',
                        <PiLightningBold className="icon_menu_20" />,
                        `/_/paquetes`
                    ),
                    getItem(
                        'Promociones',
                        'descuentos',
                        <PiMedalBold className="icon_menu_20" />,
                        `/_/descuentos`
                    ),

                    //Ahora el botón Viajero esta en el Dashboard
                    //getItem('Viajeros', 'viajeros', <PiFireBold className='icon_menu_20' />, '/_/viajeros'),
                    //getItem('Tipos', 'tipos', <PiTicketBold className='icon_menu_20' />, '/_/tipos-tickets'),
                    // getItem('Tickets', 'tickets', <PiTicketBold className='icon_menu_20' />, '/_/tickets'),
                    // getItem('Para Ti', 'parati', <PiFireBold className='icon_menu_20' />, '/parati'),
                    //getItem('Planes', 'planes', <PiBackpackBold className='icon_menu_20' />, '/planes'),
                    //getItem('Chat', 'chat', <PiChatTeardropTextBold className='icon_menu_20' />, '/chat'),
                ]
              : user?.data?.user?.role === 'company'
                ? [
                      getItem(
                          'Dashboard',
                          'dashboard',
                          <PiSquaresFourBold className="icon_menu_20" />,
                          `dashboard`
                      ),
                      getItem(
                          'Destinos',
                          'parques',
                          <PiParkBold className="icon_menu_20" />,
                          `parques`
                      ),
                      getItem(
                          'Categorias',
                          'categorias',
                          <PiFireBold className="icon_menu_20" />,
                          `categorias`
                      ),
                      getItem(
                          'Revendedores',
                          'revendedores',
                          <PiFireBold className="icon_menu_20" />,
                          `revendedores`
                      ),
                      getItem(
                          'Puntos Ventas',
                          'puntos-ventas',
                          <PiFireBold className="icon_menu_20" />,
                          `puntos-ventas`
                      ),
                      getItem(
                          'Experiencias',
                          'lista-experiencias',
                          <PiBinocularsBold className="icon_menu_20" />,
                          `lista-experiencias`
                      ),
                      getItem(
                          'Ingresos',
                          'ingresos',
                          <PiMoneyBold className="icon_menu_20" />,
                          `ingresos`
                      ),
                      // getItem('Viajeros', 'viajeros', <PiUsers className='icon_menu_20' />, `/${rutaB[1]}/viajeros`),
                      getItem(
                          'Paquetes',
                          'paquetes',
                          <PiLightningBold className="icon_menu_20" />,
                          `paquetes`
                      ),
                      getItem(
                          'Promociones',
                          'descuentos',
                          <PiMedalBold className="icon_menu_20" />,
                          `descuentos`
                      ),
                      getItem(
                          'Tickets',
                          'tickets',
                          <PiTicketBold className="icon_menu_20" />,
                          `tickets`
                      ),
                      getItem(
                          'Chat',
                          'chat',
                          <PiChatTeardropTextBold className="icon_menu_20" />,
                          `chat`
                      ),
                  ]
                : user?.data?.user?.role === 'partner'
                  ? user?.data?.user?.partnerType === 'park'
                      ? [
                            getItem(
                                'Dashboard',
                                'dashboard',
                                <PiSquaresFourBold className="icon_menu_20" />,
                                `dashboard`
                            ),
                            getItem(
                                'Revendedores',
                                'revendedores',
                                <PiFireBold className="icon_menu_20" />,
                                `revendedores`
                            ),
                            getItem(
                                'Puntos Ventas',
                                'puntos-ventas',
                                <PiFireBold className="icon_menu_20" />,
                                `puntos-ventas`
                            ),
                            getItem(
                                'Tickets',
                                'tickets',
                                <PiTicketBold className="icon_menu_20" />,
                                `tickets`
                            ),
                            getItem(
                                'Experiencias',
                                'lista-experiencias',
                                <PiBinocularsBold className="icon_menu_20" />,
                                `lista-experiencias`
                            ),
                            getItem(
                                'Paquetes',
                                'paquetes',
                                <PiLightningBold className="icon_menu_20" />,
                                `paquetes`
                            ),
                            getItem(
                                'Promociones',
                                'descuentos',
                                <PiMedalBold className="icon_menu_20" />,
                                `descuentos`
                            ),
                            // getItem('Scanner', 'scanner', <PiMedalBold className='icon_menu_20' />, `/${rutaB[1]}/scanner`),
                            getItem(
                                'Chat',
                                'chat',
                                <PiChatTeardropTextBold className="icon_menu_20" />,
                                'chat'
                            ),
                        ]
                      : []
                  : user?.data?.user?.role === 'merchant'
                    ? user?.data?.user?.merchantType === 'reseller'
                        ? [
                              getItem(
                                  'Para ti',
                                  'parti',
                                  <PiFireBold className="icon_menu_20" />,
                                  'parati'
                              ),
                              getItem(
                                  'Dashboard',
                                  'dashboard',
                                  <PiSquaresFourBold className="icon_menu_20" />,
                                  'dashboard'
                              ),
                              getItem(
                                  'Experiencias',
                                  'lista-experiencias',
                                  <PiBinocularsBold className="icon_menu_20" />,
                                  `lista-experiencias`
                              ),
                              getItem(
                                  'Paquetes',
                                  'paquetes',
                                  <PiLightningBold className="icon_menu_20" />,
                                  `paquetes`
                              ),
                              getItem(
                                  'Tickets',
                                  'tickets',
                                  <PiMedalBold className="icon_menu_20" />,
                                  `tickets`
                              ),
                              getItem(
                                  'Promociones',
                                  'descuentos',
                                  <PiMedalBold className="icon_menu_20" />,
                                  `descuentos`
                              ),
                              //getItem('Viajeros', 'viajeros', <PiFireBold className='icon_menu_20' />, '/_/viajeros'),
                              getItem(
                                  'Chat',
                                  'chat',
                                  <PiChatTeardropTextBold className="icon_menu_20" />,
                                  `chat`
                              ),
                          ]
                        : user?.data?.user?.merchantType === 'salesPoint' && [
                              getItem(
                                  'Dashboard',
                                  'dashboard',
                                  <PiSquaresFourBold className="icon_menu_20" />,
                                  'dashboard'
                              ),
                              getItem(
                                  'Experiencias',
                                  'lista-experiencias',
                                  <PiBinocularsBold className="icon_menu_20" />,
                                  `lista-experiencias`
                              ),
                              getItem(
                                  'Paquetes',
                                  'paquetes',
                                  <PiLightningBold className="icon_menu_20" />,
                                  `paquetes`
                              ),
                              getItem(
                                  'Tickets',
                                  'tickets',
                                  <PiMedalBold className="icon_menu_20" />,
                                  `tickets`
                              ),
                              getItem(
                                  'Promociones',
                                  'descuentos',
                                  <PiMedalBold className="icon_menu_20" />,
                                  `descuentos`
                              ),
                              getItem(
                                  'Scanner',
                                  'scanner',
                                  <PiMedalBold className="icon_menu_20" />,
                                  `/${rutaB[1]}/scanner`
                              ),
                              getItem(
                                  'Para ti',
                                  'parti',
                                  <PiFireBold className="icon_menu_20" />,
                                  `parati`
                              ),
                              //getItem('Viajeros', 'viajeros', <PiFireBold className='icon_menu_20' />, '/_/viajeros'),
                              getItem(
                                  'Chat',
                                  'chat',
                                  <PiChatTeardropTextBold className="icon_menu_20" />,
                                  `chat`
                              ),
                          ]
                    : []

    useEffect(() => {
        const ruta = window.location.pathname.split('/')

        setCompany(ruta[1])
    }, [company])

    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['dashboard']}
            style={{ marginTop: '-1.5rem' }}
        >
            {items.map((item) => (
                // icon={item.icon}
                <Menu.Item
                    key={item.key}
                    className="bold"
                    style={{ marginTop: '-0.5rem' }}
                >
                    <Link to={item.path}>
                        <div className="flex">
                            {item.icon}
                            {item.label}
                        </div>
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    )
}

export default SidebarIndex
