import React, { useState, useContext, useEffect } from "react";
import {
  Popover,
  Whisper,
  IconButton,
  Icon,
  Alert,
  Modal,
  Button,
} from "rsuite";
import axios from "axios";
import { BASE_PATH } from "../../constants";
import { AuthContext } from "../../../auth/AuthContext";

export const Favoritos = ({ idExp, shareDetails = {} }) => {

  const { user } = useContext(AuthContext);
  const [color, setColor] = useState("#3d3d3d");
  const [iconColor, setIconColor] = useState("#fe5000");
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [message, setMessage] = useState({
    title: "Agrega esta experiencia a tu lista de deseos",
    msg: "",
  });
  const [idFavorite, setIdFavorite] = useState("");
  const FavIconStyle = {
    "--fav-color-btn": iconColor,
    backgroundColor: color,
  };
  const speaker = (
    <Popover title={message.title} style={{ background: "#171718" }}>
      <p>{message.msg}</p>
    </Popover>
  );
  const speaker2 = (
    <Popover
      title={"Comparte esta experiencia con tus amigos!"}
      style={{ background: "#171718" }}
    >
      <p>{message.msg}</p>
    </Popover>
  );

  const actionFavorite = () => {
    if (color === "#3d3d3d") {
      addFavorite();
    } else {
      //console.log('Se eliminara de favoritos')
      setModalConfirmation(true);
    }
  };

  const addFavorite = async () => {
    //console.log('Agregando a favoritos')
    try {
      let result = await axios.post(`${BASE_PATH}/favorites`, {
        access_token: user.data.token,
        experiences: idExp,
      });
      //console.log(result)

      if (result.status === 201) {
        Alert.success("Se agrego a favoritos", 5000);
        setColor("#fe5000");
        setIconColor("#3d3d3d");
        setMessage({
          title: "Experiencia Favorita",
          msg: "Agrega esta experiencia a tu lista de deseos",
        });
        setIdFavorite(result.data.id);
      } else {
        Alert.error("Error, vuelva a intentar", 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFavorite = async () => {
    try {
      let result = await axios.get(
        `${BASE_PATH}/favorites?user=${user.data.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      //console.log(result)

      if (result.status === 200) {
        //console.log(result)
        let findExp = result.data.rows.find((e) => e.experiences.id === idExp);
        //console.log(findExp)

        if (findExp) {
          setColor("#fe5000");
          setIconColor("#3d3d3d");
          setMessage({
            title: "Experiencia Favorita",
            msg: "Agrega esta experiencia a tu lista de deseos",
          });
          setIdFavorite(findExp.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFavorite = async () => {
    //console.log('Borrando...')
    try {
      let result = await axios.delete(`${BASE_PATH}/favorites/${idFavorite}`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });
      //console.log(result)

      if (result.status === 204) {
        setColor("#3d3d3d");
        setIconColor("#fe5000");
        setModalConfirmation(false);
        setMessage({
          title: "Agregar a Favoritos",
          msg: "Agrega esta experiencia a tu lista de deseos",
        });
        setIdFavorite("");
      }
    } catch (error) { }
  };

  const handleSharing = async () => {

    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() => {
            //Alert.success("probando...", 5000);

          });
      } catch (error) {
        Alert.error("error", 5000);

        console.error(`Oops! ha ocurrido un error: ${error}`);
      }
    } else {
      // fallback code
      console.error("navegador no soportado!");
      Alert.error("No soportado.", 5000);

    }
  };

  useEffect(() => {
    getFavorite();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "16px",
        paddingRight: "18px",
      }}
    >
      <div className="d-block d-sm-none d-none d-sm-block d-md-none  d-lg-none">
        <Whisper
          // placement="left"
          trigger="hover"
          speaker={speaker2}
          placement="bottomEnd"
        >
          <IconButton
            style={{ borderRadius: "50px", marginRight: "0.5rem" }}
            icon={
              <Icon
                className="add-favorites-btn"
                icon="share-alt"
                style={FavIconStyle}
              />
            }
            size="lg"
            onClick={handleSharing}
          />
        </Whisper>
      </div>

      <Whisper
        // placement="left"
        trigger="hover"
        speaker={speaker}
        placement="bottomEnd"
      >
        <IconButton
          style={{ borderRadius: "50px" }}
          icon={
            <Icon
              className="add-favorites-btn"
              icon="star"
              style={FavIconStyle}
            />
          }
          size="lg"
          onClick={actionFavorite}
        />
      </Whisper>

      <Modal
        backdrop="static"
        show={modalConfirmation}
        onHide={() => {
          setModalConfirmation(false);
        }}
        size="xs"
      >
        <div className="modalConfirmacion">
          <Modal.Body>
            <Icon
              icon="remind"
              style={{
                color: "#ffb300",
                fontSize: 24,
              }}
            />
            {"  "}
            ¿Estás seguro de quitar esta experiencia de Favoritos?
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={deleteFavorite}
              appearance="primary"
              className="btn-primary-navbar"
            >
              Confirmar
            </Button>
            <Button
              onClick={() => {
                setModalConfirmation(false);
              }}
              appearance="default"
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};
