// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exp-pay-card-style {
    /* padding-left: 1.7rem !important; */
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
    background: #323335 !important;
}

.rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    border: 1px solid #323335;
}

.rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #323335;
    box-shadow:
        0 0 10px rgb(0 0 0 / 6%),
        0 4px 4px rgb(0 0 0 / 12%);
    overflow: hidden;
    transition: none;
}

.rs-picker-check-menu-items div :hover {
    background-color: rgb(249, 75, 15);
    /*color: black !important*/
}

.rs-picker-check-menu-items div ::selection {
    color: black !important;
    background: #1890ff;
    /*color: black !important*/
}

.custom-alert {
    background-color: rgb(249, 75, 15);
}
`, "",{"version":3,"sources":["webpack://./src/componentes/Anfitrion/Experiencias/style.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA;;;;IAII,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;IACb,eAAe;IACf,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,yBAAyB;IAIzB;;kCAE8B;IAC9B,gBAAgB;IAEhB,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;IAClC,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".exp-pay-card-style {\n    /* padding-left: 1.7rem !important; */\n    padding-top: 1rem !important;\n    padding-bottom: 1rem !important;\n}\n\n.rs-picker-default .rs-btn,\n.rs-picker-input .rs-btn,\n.rs-picker-default .rs-picker-toggle,\n.rs-picker-input .rs-picker-toggle {\n    background: #323335 !important;\n}\n\n.rs-picker-default .rs-picker-toggle {\n    position: relative;\n    z-index: 5;\n    padding-right: 32px;\n    display: inline-block;\n    outline: none;\n    cursor: pointer;\n    color: #575757;\n    border: 1px solid #323335;\n}\n\n.rs-picker-menu {\n    position: absolute;\n    text-align: left;\n    z-index: 7;\n    border-radius: 6px;\n    background-color: #323335;\n    -webkit-box-shadow:\n        0 0 10px rgb(0 0 0 / 6%),\n        0 4px 4px rgb(0 0 0 / 12%);\n    box-shadow:\n        0 0 10px rgb(0 0 0 / 6%),\n        0 4px 4px rgb(0 0 0 / 12%);\n    overflow: hidden;\n    -webkit-transition: none;\n    transition: none;\n}\n\n.rs-picker-check-menu-items div :hover {\n    background-color: rgb(249, 75, 15);\n    /*color: black !important*/\n}\n\n.rs-picker-check-menu-items div ::selection {\n    color: black !important;\n    background: #1890ff;\n    /*color: black !important*/\n}\n\n.custom-alert {\n    background-color: rgb(249, 75, 15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
