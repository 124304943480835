import styles from './CustomTableAntd.module.css'
import { Table } from 'antd'
import { Grid } from 'antd'
import { KEYS_SORT } from '../constants'
import { useEffect, useState } from 'react'

const { useBreakpoint } = Grid

export const TablaAnt = ({
    columns,
    data,
    loading,
    onSort,
    rowChecks = [],
    onChecks,
    isCheckCell = false,
    isCheckAll = true,
    limitCheck = false,
    scrollX = 100,
    modeResponsive = false,
    autoHeight = false,
}) => {
    const screens = useBreakpoint()
    const [dataWithKeys, setDataWithKeys] = useState([])

    const [selectedRowKeys, setSelectedRowKeys] = useState(rowChecks)

    useEffect(() => {
        // Asignar una key única a cada objeto de la data
        const updatedData = data?.map((item, index) => ({
            ...item,
            key: item?.id || item?._id || index.toString(),
        }))
        setDataWithKeys(updatedData)
    }, [data])

    const handleTableChange = (_, __, sorter) => {
        if (sorter.order) {
            onSort({
                key: sorter.columnKey || '',
                mode: KEYS_SORT[sorter.order] || '',
            })

            return
        }
        onSort({
            key: '',
            mode: '',
        })
    }

    const onSelectChange = (newSelectedRowKeys) => {
        // Actualizar el estado del objeto seleccionado

        setSelectedRowKeys(newSelectedRowKeys)

        // onChecks(newSelectedRowKeys)
    }

    const rowSelection = {
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
        columnTitle: isCheckAll ? '' : 'Select',
    }

    return (
        <>
            {!modeResponsive && (
                <Table
                    className={styles.fuente}
                    style={{ width: '200%' }}
                    columns={columns}
                    dataSource={dataWithKeys}
                    pagination={false}
                    size="small"
                    onChange={handleTableChange}
                    loading={loading}

                    // rowSelection={isCheckCell && rowSelection}
                    // scroll={{ x: scrollX, y: autoHeight ? "73vh" : undefined }}
                />
            )}

            {modeResponsive && <></>}
        </>
    )
}
