import React, { useReducer, useEffect } from "react";
import "./assets/css/App.css";
import "../src/componentes/Navbars/style.css";
import "./assets/css/Imagenes.css";
import "./assets/css/css/uicons-bold-rounded.css";
import "antd/dist/antd.css";
import "rsuite/lib/styles/themes/default/index.less";
import "rsuite/dist/styles/rsuite-default.css";

import { AuthReducer } from "./auth/AuthReducer";
import { AuthContext } from "./auth/AuthContext";

import { FirestoreProvider, useFirebaseApp, StorageProvider } from "reactfire";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

import { VsNetContext } from "./contexts/VsNetContext";
import VsNetReducer from "./reducers/VsNetReducer";
import Router from "./Routes";

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false }
}

function AppTugo() {
  const fireStoreInstance = getFirestore(useFirebaseApp())
  const storageInstace = getStorage(useFirebaseApp())
  const messaging = getMessaging()

  const [user, dispatch] = useReducer(AuthReducer, {}, init);
  const [deviceFingerprintID, setDeviceFingerprintID] = useReducer(
    VsNetReducer,
    "EXAMPLE_DEFAULT_VALUE"
  );

  getToken(messaging, {
    vapidKey:
      'BJz-QgPV_ckeXnc9eNN30y6Lpdg9zzR5w7ZVeduwA8ilCQWGP4nVi5wABUI6GezJisoOR8azRMUQ9tw6iqTQSGI',
  })
    .then((currentToken) => {
      if (currentToken) {
        sessionStorage.setItem('notification_token', currentToken)
      } else {
        sessionStorage.setItem('notification_token', null)
      }
    })
    .catch((err) => {
      console.error('ocurrio un error con el  token. ', err)
      sessionStorage.setItem('notification_token', undefined)
    })

  onMessage(messaging, (payload) => {});

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <FirestoreProvider sdk={fireStoreInstance}>
      <StorageProvider sdk={storageInstace}>
        <AuthContext.Provider value={{ user, dispatch }}>
          <VsNetContext.Provider
            value={{ deviceFingerprintID, setDeviceFingerprintID }}
          >
            <Router />
          </VsNetContext.Provider>
        </AuthContext.Provider>
      </StorageProvider>
    </FirestoreProvider>
  )
}

export default AppTugo
