import React, { useContext } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Card, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Imagen2 from '../../../assets/images/icono.svg'

import { InputPicker, Loader } from 'rsuite'
import { AuthContext } from '../../../auth/AuthContext'

export const ListaExperiencias = ({
    experiencias = [],
    categorias = [],
    filterCategory,
    gustos = [],
    exploring = false,
    loading,
    defaultSelect = 'Todas',
}) => {
    const { user } = useContext(AuthContext)
    const selectCategory = (_, item) => filterCategory(item)
    const cleanFilter = () => filterCategory({ rol: 'all', value: 'Todas' })

    return (
        <section className="container_table">
            {loading ? (
                <Loader
                    inverse
                    center
                    size="md"
                    content="Cargando..."
                    className="text-white"
                />
            ) : experiencias?.length === 0 && !exploring ? (
                <h2
                    style={{
                        textAlign: 'center',
                        padding: '20px',
                        fontSize: '18px',
                    }}
                >
                    No hay experiencias para mostrar. Prueba eligiendo otra
                    categoría
                </h2>
            ) : (
                <>
                    {/* {categorias.length > 0 && user?.logged && (
                        <div className="d-flex justify-content-center">
                            <div
                                className="categoria-parati"
                                style={{ marginBottom: '1rem' }}
                            >
                                <InputPicker
                                    data={categorias}
                                    value={defaultSelect}
                                    onSelect={selectCategory}
                                    onClean={cleanFilter}
                                    placement="top"
                                    style={{ zIndex: '1' }}
                                ></InputPicker>
                            </div>
                        </div>
                    )} */}
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 2, 750: 2, 1200: 3 }}
                    >
                        <Masonry gutter={20}>
                            {!loading &&
                                experiencias?.map((item) => {
                                    let user = item?.user?.infoExtra
                                        ? item?.user?.infoExtra
                                        : item.user
                                    return (
                                        <Link
                                            key={item.id}
                                            to={`/experiencia/${item._id}`}
                                        >
                                            <div>
                                                <Card className="image04 flex flex-col h-80 relative">
                                                    <Card.Img
                                                        src={
                                                            item.pictures[0]
                                                                ? item
                                                                      .pictures[0]
                                                                      .imageUrl
                                                                : Imagen2
                                                        }
                                                        className="object-cover w-full h-full opacity-90"
                                                    />
                                                    <Card.ImgOverlay>
                                                        {user?.picture ? (
                                                            <Image
                                                                src={
                                                                    user?.picture
                                                                }
                                                                roundedCircle
                                                                className="avatar"
                                                            />
                                                        ) : null}

                                                        <div className="card-bottom">
                                                            <h3 className="bold text-white card-titulo">
                                                                {item.name}
                                                            </h3>
                                                            <p
                                                                className="text-white bold d-none d-md-none d-lg-block card-precio"
                                                                style={{
                                                                    lineHeight:
                                                                        '18px',
                                                                }}
                                                            >
                                                                <text
                                                                    className="orange__gradient heavy"
                                                                    style={{
                                                                        fontSize:
                                                                            '25px',
                                                                    }}
                                                                >
                                                                    $
                                                                    {
                                                                        item
                                                                            .quotes[0]
                                                                            .prices[0]
                                                                            .netPrice
                                                                    }{' '}
                                                                    <text
                                                                        className="lite"
                                                                        style={{
                                                                            fontSize:
                                                                                '16px',
                                                                        }}
                                                                    >
                                                                        por
                                                                        persona
                                                                    </text>
                                                                </text>
                                                            </p>
                                                            <p className="place">
                                                                <i className="icon-marker"></i>
                                                                {'   '}
                                                                {item.direction}
                                                            </p>
                                                        </div>
                                                    </Card.ImgOverlay>
                                                </Card>
                                            </div>
                                        </Link>
                                    )
                                })}
                        </Masonry>
                    </ResponsiveMasonry>
                </>
            )}
        </section>
    )
}
