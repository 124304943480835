import React, { useState, useContext } from 'react'
import { Upload } from 'antd'
import axios from 'axios'
import 'antd/lib/style/index.css'
import { MessageUtils } from 'utils'
import { AuthContext } from 'auth/AuthContext'
import { Spinner } from 'componentes/commons'
import { BASE_PATH } from 'componentes/constants'

const PhotosModal = ({ pictures, selectPictures, packageId, getPackage }) => {
    const [fileList, setFileList] = useState(pictures)
    const { user } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    console.log(pictures)
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        selectPictures(newFileList)
    }

    const removePhoto = async (photico) => {
        setIsLoading(true)
        try {
            if (photico.url) {
                const uploadImages = pictures.filter(
                    (picture) => picture.url !== photico.url && picture.url
                )
                let deleteUploadPhoto
                try {
                    deleteUploadPhoto = await axios.delete(
                        `${BASE_PATH}/images/delete-file`,
                        {
                            headers: {
                                Authorization: `Bearer ${user.data.token}`,
                            },
                            data: { file: photico.url },
                        }
                    )
                } catch (error) {
                    console.log(error)
                    MessageUtils.error('No se pudo eliminar la foto')
                }

                if (deleteUploadPhoto) {
                    const resp = await axios.put(
                        `${BASE_PATH}/experiences/packages/${packageId}`,
                        {
                            pictures: uploadImages,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${user.data.token}`,
                            },
                        }
                    )
                    if (resp.status === 200) {
                        MessageUtils.success('Se eliminó la foto correctamente')
                        getPackage()
                    }
                }
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error en el servidor')
        } finally {
            setIsLoading(false)
        }
    }

    const onPreview = async (file) => {
        let src = file.url
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const uploadButton = (
        <div>
            <i
                className="demo-icon icon-camera neon_blue"
                style={{ fontSize: '25px' }}
            ></i>
        </div>
    )

    return (
        <div>
            {isLoading ? (
                <Spinner />
            ) : (
                <div className="container-sm" style={{ paddingTop: '6rem' }}>
                    <h5 className="bold">Sigue estas instrucciones:</h5>
                    <div className="mb-3 text-grey text-instructivo">
                        1 - Ingresa a{' '}
                        <a
                            href="https://www.iloveimg.com"
                            target="_blank"
                            className="orange"
                            rel="noreferrer"
                        >
                            {' '}
                            https://www.iloveimg.com{' '}
                        </a>
                        , comprime y luego reduce el tamaño de tus imagenes en
                        las opciones COMPRESS IMAGE y RESIZE IMAGE. <br />
                        2 - Cuando descargues tus fotografías luego de
                        comprimirlas, asegurate de que estén en su rotación
                        correcta.
                        <br />3 - Sube tus fotografías en lotes de 5, tienes
                        para subir hasta 20 fotografías, ¡LISTO!.
                    </div>
                    <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        defaultFileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={() => false}
                        onRemove={removePhoto}
                    >
                        {fileList.length >= 20 ? null : uploadButton}
                    </Upload>
                </div>
            )}
        </div>
    )
}

export default PhotosModal
