import React, { useContext, useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../../assets/css/App.css'
import './style.css'
import { AuthContext } from '../../../auth/AuthContext'
import AgregarUbicacion from './AgregarUbicacion'
import { Col, DatePicker, Drawer, Form, Input, Row, Select } from 'antd'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import { Option } from 'antd/lib/mentions'
import { Button } from 'react-bootstrap'

const ExperienciaNewContent = ({
    buttonText,
    nextContent,
    newform,
    form,
    setNewForm,
    newubicacion,
    onFinish,
}) => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [ubicacion, setUbicacion] = useState(newubicacion)
    const [visible, setVisible] = useState(false)

    const showDrawer = () => {
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    const dataExtras = [
        {
            label: 'Transporte',
            value: 'Transporte',
            icon: 'bus',
        },
        {
            label: 'Almuerzo',
            value: 'Almuerzo',
            icon: 'cutlery',
        },
        {
            label: 'Hotel',
            value: 'Hotel',
            icon: 'home',
        },
        {
            label: 'Bicicleta',
            value: 'Bicicleta',
            icon: 'bicycle',
        },
        {
            label: 'Pieza de Jade',
            value: 'Pieza de Jade',
            icon: 'creative',
        },
        {
            label: 'Equipo de Protección',
            value: 'Protección',
            icon: 'shield',
        },
        {
            label: 'Bebida',
            value: 'Bebida',
            icon: 'beer',
        },
        {
            label: 'Souvenir',
            value: 'Souvenir',
            icon: 'tag-authorize',
        },
        {
            label: 'Chocolate',
            value: 'Chocolate',
            icon: 'hand-peace-o',
        },
        {
            label: 'Herramientas',
            value: 'Herramientas',
            icon: 'gears2',
        },
    ]

    const inputs = [
        {
            label: 'Ponle nombre',
            name: 'name',
            placeholder: 'Un nombre super cool',
            rules: [
                {
                    required: true,
                    message: 'Ingrese un nombre',
                },
            ],
        },
        {
            label: '¿Dónde comienza?',
            name: 'start',
            placeholder: 'Destino donde recibirás o recogerás a tus viajeros',
            rules: [
                {
                    required: true,
                    message: 'Ingrese un destino',
                },
            ],
        },
        {
            label: '¿Cuántas horas dura?',
            name: 'duration',
            type: 'number',
            placeholder: '2 h',
            rules: [
                {
                    required: true,
                    message: 'Ingrese una duración',
                },
            ],
        },
        {
            label: '¿Cuéntanos que haremos?',
            name: 'description',
            placeholder: 'Qué osadías experimentaremos',
            type: 'textArea',
            rules: [
                {
                    required: true,
                    message: 'Ingrese una descripción',
                },
            ],
        },
        {
            label: '¿Qué incluye?',
            name: 'extra',
            type: 'select',
            placeholder: 'Transporte, Alimentación',
            modeSelect: 'multiple',
            items: [...newform.extra, ...dataExtras]
                .map((item) => {
                    return {
                        label: item.label || item.valor,
                        value: item.value || item.valor,
                        icon: item.icon,
                    }
                })
                .filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.value === value.value)
                ),
            rules: [
                {
                    required: true,
                    message: 'Ingrese al menos un extra',
                },
            ],
        },
        {
            label: '¿Dónde termina?',
            name: 'end',
            placeholder: 'Destino donde terminará la experiencia',
            rules: [
                {
                    required: true,
                    message: 'Ingrese el destino',
                },
            ],
        },
        {
            label: 'Cupo mínimo',
            name: 'minQuotas',
            type: 'number',
            placeholder: '0',
            rules: [
                {
                    required: true,
                    message: 'Ingrese un cupo mínimo',
                    max: 50,
                    min: 0,
                },
            ],
        },
        {
            label: 'Cupo máximo',
            name: 'maxQuotas',
            type: 'number',
            placeholder: '0',
            rules: [
                {
                    required: true,
                    message: 'Ingrese un cupo máximo',
                    max: 50,
                    min: 0,
                },
            ],
        },
        {
            label: 'País',
            name: 'direction',
            placeholder: 'Localidad donde se vivirá la experiencia',
            rules: [
                {
                    required: true,
                    message: 'Ingrese la localidad',
                },
            ],
        },
    ]

    const onDireccion = (data, frm = newform) => {
        onClose()
        setUbicacion(data)
        nextContent(frm, data)
    }

    const renderInput = (input) => {
        switch (input.type) {
            case 'textArea':
                return (
                    <TextArea
                        placeholder={input.placeholder}
                        value={newform[input.name]}
                        onChange={(e) =>
                            setNewForm({
                                ...newform,
                                [input.name]: e.target.value,
                            })
                        }
                    />
                )
            case 'date':
                return (
                    <DatePicker
                        disabledDate={input.disabledDate}
                        placeholder={input.placeholder}
                        value={
                            newform[input.name]
                                ? moment(newform[input.name])
                                : null
                        }
                        onChange={(date) =>
                            setNewForm({
                                ...newform,
                                [input.name]: date,
                            })
                        }
                    />
                )
            case 'select':
                return (
                    <Select
                        mode={input.modeSelect}
                        placeholder={input.placeholder}
                        value={newform[input.name]}
                        onChange={(selectedValues) => {
                            const selectedItems = selectedValues.map(
                                (value) => {
                                    const selectedItem = input.items.find(
                                        (item) => item.value === value
                                    )
                                    return {
                                        icon: selectedItem.icon,
                                        valor: value,
                                    }
                                }
                            )

                            setNewForm({
                                ...newform,
                                [input.name]: selectedItems,
                            })
                        }}
                    >
                        {input.items.map((item, idx) => (
                            <Option key={item.value} value={item.value}>
                                <i
                                    className={`rs-icon rs-icon-${item.icon} mr-2`}
                                />
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                )
            default:
                return (
                    <Input
                        placeholder={input.placeholder}
                        type={input.type || 'text'}
                        value={newform[input.name]}
                        onChange={(e) =>
                            setNewForm({
                                ...newform,
                                [input.name]: e.target.value,
                            })
                        }
                    />
                )
        }
    }

    return (
        <div className="container-sm">
            <Form
                form={form}
                formValues={newform}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row gutter={16}>
                    <Col span={12}>
                        {inputs.slice(0, 5).map((input, index) => (
                            <Form.Item
                                key={index}
                                label={input.label}
                                name={input.name}
                                rules={input.rules}
                            >
                                {renderInput(input)}
                            </Form.Item>
                        ))}
                    </Col>
                    <Col span={12}>
                        {inputs.slice(5).map((input, index) => (
                            <Form.Item
                                key={index}
                                label={input.label}
                                name={input.name}
                                rules={input.rules}
                            >
                                {renderInput(input)}
                            </Form.Item>
                        ))}
                        <div className="flex justify-end w-full">
                            <button
                                type="button"
                                onClick={showDrawer}
                                className="btn btn__bancos bold"
                                style={{
                                    width: '80%',
                                    height: '3rem',
                                }}
                            >
                                <i className="demo-icon-first icon-compass"></i>
                                Agregar ubicación
                            </button>
                        </div>
                    </Col>
                </Row>

                {!buttonText && (
                    <div
                        className="steps-action bold navbar-sm-more-host exp-edit-fixed-bottom"
                        style={{ width: 0, right: '10rem' }}
                    >
                        <Button
                            className="bold exp-pasos btn-primary-reserva-host"
                            type="primary"
                            onClick={() => navigate(-1)}
                        >
                            Cancelar
                        </Button>
                    </div>
                )}
                <div
                    className="steps-action bold navbar-sm-more-host exp-edit-fixed-bottom"
                    style={{ width: 0, right: '2rem' }}
                >
                    <Button
                        className="bold exp-pasos btn-primary-reserva-host"
                        type="primary"
                        htmlType="submit"
                    >
                        {buttonText ? buttonText : 'Crear'}
                    </Button>
                </div>
            </Form>

            <Drawer
                placement="top"
                height="100vh"
                open={visible}
                onClose={onClose}
            >
                <AgregarUbicacion dataUb={ubicacion} direccionn={onDireccion} />
            </Drawer>
        </div>
    )
}

export default ExperienciaNewContent
