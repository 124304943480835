import React from "react";
import { Card } from "react-bootstrap";
import Imagen5 from "../../../../assets/images/icono.svg";

import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";

export const ExperienciaCard = ({ dataExp, deleteExp }) => {
  const navigate = useNavigate();

  const expDelete = () => {
    deleteExp(dataExp.id);
  };

  return (
    <div>
      <Card className="pointer border-0 image04">
        <Button
          className="btn_cancel"
          onClick={expDelete}
          style={{ zIndex: "100" }}
        >
          <i className="icon-cancel"></i>
        </Button>
        <Card.Img
          src={dataExp.pictures[0] ? dataExp.pictures[0].path : Imagen5}
        />
        <Card.ImgOverlay
          onClick={() => navigate(`/host/experiencia/_/${dataExp.id}`)}
        >
          <div className="card-bottom">
            <h3 className="bold text-white card-titulo">{`${dataExp.name} `}</h3>
            <text className="">{`${
              dataExp.isDraft ? "(borrador)" : "(publicada)"
            }`}</text>
            <p className="text-white bold d-none d-md-none d-lg-block card-precio">
              $
              <span>
                {dataExp?.price?.adultPriceWithCommissions ||
                  dataExp?.price?.adultPrice}{" "}
              </span>
              por persona
            </p>
            <p className="place">
              <i className="icon-marker"></i>
              {"   "}
              {dataExp.direction}
            </p>
            <p className="text-white bold d-none d-md-none d-lg-block ">
              <i className="icon-star neon_blue"></i>
              {dataExp.rating}
            </p>
          </div>
        </Card.ImgOverlay>
      </Card>
    </div>
  );
};
