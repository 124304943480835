import React from 'react'
import Titulo from '../Titulo'
import TablaBoletos from './items/TablaBoletos'

const ListaBoletos = ({
    user
}) => {

    const boletos = [
        {
            picture: 'https://borealtech.com/wp-content/uploads/2018/10/codigo-qr-1024x1024-1.jpg',
            name: '¡Conoce la Cultura Maya Kaqchikel a fondo!',
            cantidad: 4
        },
        {
            picture: 'https://borealtech.com/wp-content/uploads/2018/10/codigo-qr-1024x1024-1.jpg',
            name: 'Parque Central, La Antigua Guatemala',
            cantidad: 2
        },
        {
            picture: 'https://borealtech.com/wp-content/uploads/2018/10/codigo-qr-1024x1024-1.jpg',
            name: 'Panajachel',
            cantidad: 3
        }
    ]
    return (
        <div>
            <Titulo
                titulo={'Boletos'}
                tituloBoton={'Crear paquete'}
                // onAcccion={() => setOpen(true)}
                button={false}
            />

            <TablaBoletos
                data={boletos}
            />
        </div>
    )
}

export default ListaBoletos
