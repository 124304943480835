import React, { useContext, useEffect, useState } from 'react'

import '../../../../assets/css/App.css'
import 'antd/dist/antd.css'

//Importar Recursos
import '../../../../assets/css/iconos/css/fontello.css'

import ExperienciaReservada from 'componentes/Experiencias/ExperienciaPagar'

import axios from 'axios'

import {
    Col,
    Row,
    Drawer,
    Grid,
    Container,
    Button,
    Loader,
    Notification,
    List,
} from 'rsuite'
import { Modal, message } from 'antd'

import logoC from '../../../../assets/images/desvlop.svg'
import { collection, doc, setDoc } from '@firebase/firestore'
import { useFirestore } from 'reactfire'

import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'componentes/commons'
import { CloseOutlined } from '@ant-design/icons'
import { getData } from 'services'
import ExperienciaHeader from 'componentes/Experiencias/ExperienciaHeader'
import ExperienciaPagar from 'componentes/Experiencias/ExperienciaPagar'
import ExperienciaCalendar from 'componentes/Experiencias/ExperienciaCalendar'
import { dateOptions } from 'componentes/Anfitrion/Experiencias/schedules/utils'
import { VsNetContext } from 'contexts/VsNetContext'
import { BASE_PATH } from 'componentes/constants'
import { Login } from 'componentes/Experiencias/Login'
import { AuthContext } from 'auth/AuthContext'
import { Favoritos } from 'componentes/Experiencias/exp/Favoritos'
import PackageHeader from './PackageHeader'

const vsNetErrorsMessages = {
    EXPIRED_CARD: 'Tarjeta expirada',
    'paymentInformation.card.expirationYear': 'Año de expiración',
    'orderInformation.billTo.administrativeArea': 'Ciudad o Estado',
    'orderInformation.billTo.postalCode': 'Código Postal',
    'paymentInformation.card.expirationMonth': 'Mes de expiración',
    'orderInformation.billTo.country': 'País',
    'orderInformation.billTo.email': 'Email',
    'orderInformation.billTo.locality': 'Ciudad o Estado',
    'paymentInformation.card.number': 'Tarjeta Inválida',
}

const Package = () => {
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const { paquete: pack } = useParams()
    const { deviceFingerprintID } = useContext(VsNetContext)
    const [isLoading, setIsLoading] = useState([true])
    const [adult, setAdult] = useState(false)
    const [children, setChildren] = useState(0)
    const [loading, setloading] = useState(false)
    const [metodoPago, setMetodoPago] = useState(false)
    const [expReservada, setExpReservada] = useState(false)
    const [totalReserva, setTotalReserva] = useState(0)
    const [paquete, setPaquete] = useState([])
    const [descAplicado, setdescAplicado] = useState(false)
    const [descxpersonas, setdescxpersonas] = useState(false)
    const [visible, setVisible] = useState(false)
    const [openPaymentModal, setopenPaymentModal] = useState(false)
    const [current, setCurrent] = useState(0)
    const [dataPreview, setdataPreview] = useState({})
    const [paymentsData, setpaymentsData] = useState([])
    const [disabledBtn, setdisabledBtn] = useState(false)
    const [galleryPhotos, setGalleryPhotos] = useState([])
    const [experiences, setExperiences] = useState([])
    const [schedule, setschedule] = useState({
        id: null,
        date: null,
        time: null,
    })
    const platform =
        user?.data?.user?.role === 'company'
            ? user?.data?.user?.config
            : user?.data?.platform?.id

    const steps = [
        {
            title: '',
            content: (
                <PackageHeader
                    paquete={paquete}
                    galleryPhotos={galleryPhotos}
                />
            ),
        },
        {
            title: '',
            // content: (
            //     <ExperienciaCalendar
            //         setSchedule={selectSchedule}
            //         experienceId={exp}
            //     />
            // ),
        },
        {
            title: '',
            // content: (
            //     <ExperienciaPagar
            //         experiencia={experiencia}
            //         setAdultos={adultosReserva}
            //         setMetodoPago={(data) => setMetodoPago(data)}
            //         setNiños={niñosReserva}
            //         setCupon={setCupon}
            //         dateTime={`${new Date(schedule.date).toLocaleDateString(
            //             'es-ES',
            //             dateOptions
            //         )}, ${schedule.time}`}
            //     />
            // ),
        },
    ]
    const loginExp = () => {
        navigate('/login', { replace: true })
    }

    const HomeB = () => {
        navigate(-1)
    }
    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }
    const onSuccess = () => {
        setVisible(false)
    }

    console.log(paquete)
    console.log(experiences)
    console.log(galleryPhotos)
    const getPackage = async () => {
        try {
            const response = await getData({
                path: `experiences/packages/${pack}`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: platform,
            })
            setPaquete(response)
            console.log(response)
            const experienceIds = response?.experiences?.map(
                (exp) => exp.experience
            )
            const experiences = await fetchExperiences(experienceIds)
            setgalleryPhotos(response, experiences)
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    const fetchExperiences = async (experienceIds) => {
        try {
            console.log('hola')
            const experienceData = await Promise.all(
                experienceIds.map(async (id) => {
                    const res = await axios.get(
                        `${BASE_PATH}/experiences/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${user?.data?.token}`,
                            },
                        }
                    )

                    return res.data
                })
            )
            console.log(experienceData)
            setExperiences(experienceData)
            return experienceData
        } catch (e) {
            console.error('Error al obtener experiencias:', e)
        }
    }
    const next = () => {
        if (current === 0) {
            setCurrent(current + 1)
        } else if (current === 1) {
            schedule.id
                ? setCurrent(current + 1)
                : message.warning('Selecciona un horario', 3)
        } else if (current === 2) {
            if (adult) {
                setConfirmation()
            } else {
                message.warning('Campos requeridos vacios', 2)
            }
        }
    }

    const prev = () => {
        setCurrent(current - 1)
        // if (experiencia[0].quotes[0].prices[0].netPrice !== totalReserva) {
        //     setTotalReserva(
        //         experiencia[0].quotes[0].prices[0].netPrice ||
        //             experiencia[0]?.price?.adultPrice
        //     )
        // }
    }

    const getCodeForTransaction = async () => {
        const respCode = await axios.get(`${BASE_PATH}/income/getCode`, {
            headers: {
                Authorization: `Bearer ${user?.data?.token}`,
            },
        })
        if (respCode.status === 200) {
            return respCode?.data.code
        } else {
            return null
        }
    }

    const assambleObjectToPay = async () => {
        // const prices = experiencia[0]?.quotes[0].prices
        // let montoAdultos = prices[0]?.grossPrice * adult
        // let montoAdultosWithCommisions = adult * prices[0]?.netPrice
        // let montoNinos = prices[1]?.grossPrice
        // let montoNinosWithComisions = children * prices[1]?.netPrice
        // let totalWithComissions =
        //     montoAdultosWithCommisions + montoNinosWithComisions
        // let total = montoAdultos + montoNinos,
        //     descuento = 0,
        //     descuentoWithComisions = 0
        // if (descxpersonas) {
        //     if (adult + children >= descxpersonas.people) {
        //         total = total - parseFloat(descxpersonas?.price)
        //         totalWithComissions =
        //             totalWithComissions - parseFloat(descxpersonas?.price)
        //     }
        // }
        // if (descAplicado) {
        //     descuento = (montoAdultos + montoNinos) * descAplicado
        //     descuentoWithComisions =
        //         (montoAdultosWithCommisions + montoNinosWithComisions) *
        //         descAplicado
        // }
        // setdataPreview({
        //     name: experiencia[0]?.name,
        //     date: `${new Date(schedule.date).toLocaleDateString(
        //         'es-ES',
        //         dateOptions
        //     )}, ${schedule.time}`,
        //     coupos: adult + children,
        //     coupon: '',
        //     discount: '',
        //     amount: (totalWithComissions - descuentoWithComisions).toFixed(2),
        //     card: {
        //         number: '**** **** **** ',
        //         type: 'Visa',
        //         persona: '',
        //     },
        // })
    }

    const setConfirmation = async () => {
        assambleObjectToPay()
        setopenPaymentModal(true)
    }

    // const validateAvailableQuotes = async () => {
    //     const quotas = adult + children
    //     console.log(user?.data?.token)
    //     try {
    //         // const bookedDateTime = new Date(
    //         //     setTimeInDate(schedule.time, schedule.date)
    //         // )
    //         // const resp = await axios.get(
    //         //     `${BASE_PATH}/schedules/${experiencia[0].user._id}/${
    //         //         experiencia[0].id
    //         //     }/${schedule.id}/${bookedDateTime.getTime()}/${quotas}`,
    //         //     {
    //         //         headers: {
    //         //             Authorization: `Bearer ${user?.data?.token}`,
    //         //         },
    //         //     }
    //         // )
    //         if (resp.status === 200) {
    //             if (!resp.data.isValid) {
    //                 setopenPaymentModal(false)
    //                 message.warning(resp.data.message, 5)
    //                 return
    //             }
    //             createIncomePayment()
    //         }
    //     } catch (error) {
    //         console.error(error)
    //         message.error('Ha ocurrido un error en el servidor', 3)
    //     }
    // }

    const setgalleryPhotos = (paquete, experiences) => {
        console.log(paquete, experiences)
        const photos = [
            ...(paquete?.pictures?.map((picture) => picture.imageUrl) || []),

            ...(paquete?.experiences?.map((exp) => {
                const experienciaCompleta = experiences?.filter(
                    (experiencia) => experiencia.id === exp.experience
                )

                return experienciaCompleta[0]?.pictures
                    ? experienciaCompleta[0].pictures.map((p) => p.imageUrl)
                    : []
            }) || []),
        ]
        const uniquePhotos = Array.from(new Set(photos))

        const formattedPhotos = uniquePhotos.map((url, index) => ({
            url,
            id: index,
        }))
        setGalleryPhotos(formattedPhotos)
    }

    const createIncomePayment = async () => {
        setdisabledBtn(true)
        setloading(true)

        if (paymentsData.totalAmount > 0) {
            axios
                .post(
                    `${BASE_PATH}/income`,
                    {
                        ...paymentsData,
                        fingerprintSessionId: deviceFingerprintID,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${user?.data?.token}`,
                        },
                    }
                )
                .then((incomePayment) => {
                    if (
                        incomePayment.status === 200 &&
                        incomePayment.data &&
                        incomePayment.data.status === 'AUTHORIZED'
                    ) {
                        saveReservation(
                            incomePayment.data.reconciliationId,
                            incomePayment.data.totalAmount,
                            incomePayment.data.commissions
                        )
                    }
                    if (incomePayment.data.status !== 'AUTHORIZED') {
                        message.warning(
                            'Ocurrio un error al procesar la solitud',
                            2
                        )

                        setdisabledBtn(false)
                        setloading(false)
                    }
                })
                .catch((err) => {
                    const errorInfo = err.response.data.response
                        ? JSON.parse(err.response.data.response.text)
                        : err.response.data.errorInformation

                    let errorMessage = ''

                    console.error(errorInfo)
                    if (errorInfo.details) {
                        const fields = errorInfo.details.map(
                            (el) => vsNetErrorsMessages[el.field]
                        )
                        openNotification(fields)
                        errorMessage = fields[0]
                    } else {
                        const field = vsNetErrorsMessages[errorInfo.reason]
                        openNotification([field])
                        errorMessage = field
                    }

                    if (!errorMessage) {
                        message.error(
                            'Ocurrio un error al procesar la solitud',
                            2
                        )
                        setdisabledBtn(false)
                        setloading(false)

                        return
                    }

                    setdisabledBtn(false)
                    setloading(false)
                })
        } else {
            const commissions = {
                tugo: 0,
                visaNet: 0,
            }
            saveReservation(deviceFingerprintID, 0, commissions)
        }
    }

    function openNotification(fields) {
        Notification['error']({
            title: 'Campos inválidos',
            description: (
                <List style={{ width: 'min(100%, 300px)' }}>
                    {fields.map((item, index) => (
                        <List.Item
                            style={{
                                fontSize: '12px !important',
                                padding: '5px',
                            }}
                            key={index}
                            index={index}
                        >
                            {item}
                        </List.Item>
                    ))}
                </List>
            ),
        })
    }

    const saveReservation = async (
        transactionId = '',
        totalAmount,
        commissions
    ) => {
        // try {
        //     const bookedDateTime = new Date(
        //         setTimeInDate(schedule.time, schedule.date)
        //     )
        //     const salvadorDateTime = new Date(
        //         bookedDateTime.toLocaleString('en-US', {
        //             timeZone: 'America/El_Salvador',
        //         })
        //     )
        //     const dataToSend = {
        //         access_token: user.data.token,
        //         experiences: experiencia[0].id,
        //         date: `${moment(salvadorDateTime).format('YYYY-MM-DD')} ${
        //             schedule.time
        //         }`,
        //         adult: adult,
        //         children: children,
        //         // card: metodoPago.id,
        //         card: '623deef869d72f0018b9666a',
        //         mount: parseFloat(totalReserva).toFixed(2),
        //         code: paymentsData?.code,
        //         transactionId: 'testEstani',
        //         // transactionId: transactionId,
        //         schedule: schedule.id,
        //         commissions,
        //         bookedDateTime: salvadorDateTime.getTime(),
        //     }
        //     console.log('Data to send in /payments :', dataToSend) // Verifica los datos antes de enviarlos
        //     const respNewPayment = await axios.post(
        //         `${BASE_PATH}/payments`,
        //         dataToSend,
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${user.data.token}`,
        //             },
        //         }
        //     )
        //     console.log('Returned data:', respNewPayment)
        //     if (respNewPayment.status === 200) {
        //         sendMessage()
        //         setopenPaymentModal(false)
        //         setExpReservada(respNewPayment.data)
        //         setdisabledBtn(false)
        //         setloading(false)
        //     } else {
        //         setopenPaymentModal(false)
        //         message.warning(
        //             'Error en hacer la reserva, vuelva a intentar',
        //             2
        //         )
        //         setdisabledBtn(false)
        //         setloading(false)
        //     }
        // } catch (error) {
        //     console.error(error)
        //     setopenPaymentModal(false)
        //     setdisabledBtn(false)
        //     setloading(false)
        //     message.error('Error en hacer la reserva, vuelva a intentar', 2)
        // }
    }

    const sendMessage = async () => {
        // const dateMsg = new Date().toISOString()
        // let msg = {
        //     active: true,
        //     image: '',
        //     text: `Ha reservado ${adult} cupos para adult y ${children} cupos para niños en tu experiencia ${
        //         experiencia[0].name
        //     }, para el ${new Date(schedule.date).toLocaleDateString(
        //         'es-ES',
        //         dateOptions
        //     )}, ${schedule.time} `,
        //     time: dateMsg,
        //     userby: user.data.user.id,
        //     type: '2',
        //     reservation: true,
        // }
        // await setDoc(doc(refOwnerCollection), msg).then(async () => {
        //     await setDoc(doc(refOwnersDoc, user?.data.user.id), {
        //         msg: `Ha reservado en: ${experiencia[0].name} `,
        //         time: dateMsg,
        //         name: user?.data.user.name,
        //         image: user?.data.user.picture,
        //     }).catch((err) => {
        //         console.error(err)
        //     })
        // })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getPackage()
    }, [])

    if (isLoading) {
        return <Spinner />
    }

    return (
        <>
            {loading && (
                <div className="progress-loader">
                    <div className="progress-loader-content">
                        <Loader
                            size="lg"
                            inverse
                            center
                            className="progess-loader-text"
                            content={
                                <h4 style={{ color: '#fff' }}>
                                    Por favor espera...
                                </h4>
                            }
                        />
                    </div>
                </div>
            )}

            {current === 0 && (
                <div>
                    <button
                        style={{ zIndex: '9999' }}
                        type="button"
                        className="back-window fixed-top mr-2"
                        onClick={HomeB}
                    >
                        <i className="icon-back icon-left-arrow"></i>
                    </button>
                    <img src={logoC} className="logo_in ml-5" alt="logo" />
                </div>
            )}
            <img src={logoC} className="logo_in ml-5" alt="logo" />

            {!expReservada && (
                <Grid fluid>
                    {user.logged ? (
                        <>
                            <div className="fixed-top favorites">
                                {/* <Favoritos
                                    idExp={exp}
                                    shareDetails={shareDetails}
                                ></Favoritos> */}
                            </div>
                            <div className="steps-content">
                                {steps[current].content}
                            </div>
                            <div className="steps-action bold navbar-sm-more exp-fixed-bottom">
                                <span
                                    className="orange"
                                    style={{ paddingLeft: '10px' }}
                                >
                                    ${''}
                                    {paquete.grossPriceAdult}
                                </span>
                                <span className="text-white">
                                    {/* {experiencia.length > 0 ? (
                                        parseFloat(totalReserva).toFixed(2)
                                    ) : (
                                        <></>
                                    )} */}
                                    <span style={{ fontSize: 12 }}></span>
                                </span>
                                {current < steps.length - 1 &&
                                    user?.data.user.id !== paquete.parentId && (
                                        <Button
                                            className="bold exp-pasos btn-primary btn-reserva"
                                            type="dark"
                                            onClick={() => next()}
                                        >
                                            Reserva
                                        </Button>
                                    )}
                                {current > 0 && (
                                    <button className="back-window-book fixed-top">
                                        <i
                                            onClick={() => prev()}
                                            className="icon-back icon-left-arrow"
                                        ></i>
                                    </button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button
                                        className="bold exp-pasos btn-primary btn-reserva"
                                        type="dark"
                                        onClick={() => next()}
                                    >
                                        Confirmar
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="steps-content">
                                {steps[current].content}
                            </div>
                            <div className="steps-action bold navbar-sm-more exp-fixed-bottom">
                                <span
                                    className="orange"
                                    style={{ paddingLeft: '10px' }}
                                >
                                    ${' '}
                                </span>
                                <span className="text-white">
                                    {/* {experiencia.length > 0 ? (
                                        parseFloat(totalReserva).toFixed(2)
                                    ) : (
                                        <></>
                                    )} */}
                                    <span style={{ fontSize: 12 }}></span>
                                </span>
                                {/* {current < steps.length - 1 &&
                                    user?.data.user.id !==
                                        paquete.parentOwner && ( */}
                                <Button
                                    className="bold exp-pasos btn-primary btn-reserva"
                                    type="dark"
                                    onClick={() => next()}
                                >
                                    Reserva
                                </Button>
                                {/* )} */}
                                {current < steps.length - 1 &&
                                    !user?.logged && (
                                        <Button
                                            className="bold exp-pasos btn-primary btn-reserva"
                                            type="dark"
                                            onClick={showDrawer}
                                            // onClick={loginExp}
                                        >
                                            Login
                                        </Button>
                                    )}
                                {current > 0 && (
                                    <div className="exp-pasos-prev">
                                        <i
                                            style={{
                                                margin: '-4px 0px 0 -10px',
                                            }}
                                            onClick={() => prev()}
                                            className="demo-icon icon-left-arrow"
                                        ></i>
                                    </div>
                                )}
                                {current === steps.length - 1 && (
                                    <Button
                                        className="bold exp-pasos btn-primary btn-reserva"
                                        type="dark"
                                        onClick={showDrawer}
                                    >
                                        Confirmar
                                    </Button>
                                )}
                            </div>

                            <Drawer
                                size="xs"
                                onHide={onClose}
                                show={visible}
                                style={{ background: '#171718' }}
                            >
                                <button
                                    type="button"
                                    className="back-window fixed-top"
                                    onClick={onClose}
                                >
                                    <i className="icon-back icon-left-arrow"></i>
                                </button>
                                <Drawer.Body className="exp-chat-open">
                                    <Container>
                                        <Row>
                                            <Col md={24} className="mt-4"></Col>
                                        </Row>
                                        <Login onSuccess={onSuccess} />
                                    </Container>
                                </Drawer.Body>
                            </Drawer>
                        </>
                    )}
                </Grid>
            )}

            {/* {expReservada && experiencia.length > 0 && (
                <ExperienciaReservada
                    experiencia={experiencia}
                    reserva={expReservada}
                />
            )} */}
            <Modal
                title="Confirma los datos"
                open={openPaymentModal}
                closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
                onCancel={() => setopenPaymentModal(false)}
                okText="Pagar"
                onOk={saveReservation}
                okButtonProps={{
                    style: { background: '#fe5000', borderColor: '#fe5000' },
                }}
            >
                <div className="mr-2 ml-2">
                    <h5 className="flex justify-center mb-6">
                        {dataPreview?.name}
                    </h5>
                    <div className="flex justify-between text-center">
                        <div>
                            <i
                                className="icon-face"
                                style={{ fontSize: '20px' }}
                            />
                            <p style={{ fontSize: '12px', color: 'grey' }}>
                                Cupo
                            </p>
                            <p className="bold" style={{ fontSize: '14px' }}>
                                {dataPreview?.coupos} adquirido(s)
                            </p>
                        </div>
                        <div>
                            <i
                                className="icon-time"
                                style={{ fontSize: '20px' }}
                            />
                            <p style={{ fontSize: '12px', color: 'grey' }}>
                                Fecha
                            </p>
                            <p className="bold" style={{ fontSize: '14px' }}>
                                {dataPreview?.date}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-between text-center mt-4">
                        <div>
                            <i
                                className="icon-card"
                                style={{ fontSize: '20px' }}
                            />
                            <p style={{ fontSize: '12px', color: 'grey' }}>
                                {dataPreview?.card?.type}
                            </p>
                            <p className="bold" style={{ fontSize: '14px' }}>
                                {dataPreview?.card?.number}
                            </p>
                        </div>
                        <div>
                            <i
                                className="icon-money"
                                style={{ fontSize: '20px' }}
                            />
                            <p style={{ fontSize: '12px', color: 'grey' }}>
                                Descuento
                            </p>
                            <p className="bold" style={{ fontSize: '14px' }}>
                                {descAplicado
                                    ? `${dataPreview?.coupon} aplicado con ${dataPreview?.discount} %`
                                    : 'No aplicado'}
                            </p>
                        </div>
                        <div>
                            <i
                                className="icon-money"
                                style={{ fontSize: '20px' }}
                            />
                            <p style={{ fontSize: '12px', color: 'grey' }}>
                                Total
                            </p>
                            <p className="bold" style={{ fontSize: '14px' }}>
                                ${parseFloat(dataPreview?.amount).toFixed(2)}
                                <></>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* <Modal
        show={openPaymentModal}
        onHide={() => {
          setopenPaymentModal(false);
        }}
        size="lg"
        overflow={false}
      >
        <Modal.Header>
          <Modal.Title>Confirma los datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Panel  bordered>
              <Row style={{ marginBottom: "5px", textAlign: "center" }}>
                <h5>{dataPreview?.name}</h5>
              </Row>
              <div>
                <Col md={10} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-face" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Cupo</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {dataPreview?.coupos} adquirido(s)
                  </p>
                </Col>
                <Col md={10} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-time" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Fecha</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {dataPreview?.date}
                  </p>
                </Col>
              </div>
            </Panel> */}

            {/* <Panel style={{ marginTop: "5px" }} bordered>
              <div>
                <Col md={6} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-card" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>
                    {dataPreview?.card?.type}
                  </p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {dataPreview?.card?.number}
                  </p>
                </Col>
                <Col md={6} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-money" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Descuento</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    {descAplicado
                      ? `${dataPreview?.coupon} aplicado con ${dataPreview?.discount} %`
                      : "No aplicado"}
                  </p>
                </Col>
                <Col md={6} style={{ margin: "20px" }} className="text-center">
                  <i className="icon-money" style={{ fontSize: "20px" }}></i>
                  <p style={{ fontSize: "12px", color: "grey" }}>Total</p>
                  <p className="bold" style={{ fontSize: "14px" }}>
                    ${parseFloat(dataPreview?.amount).toFixed(2)}
                    <></>
                  </p>
                </Col>
              </div>
            </Panel>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            // onClick={validateAvailableQuotes}
            onClick={saveReservation}
            style={{ background: "#fe5000" }}
            appearance="primary"
            disabled={disabledBtn}
          >
            Pagar
          </Button>
          <Button
            onClick={() => {
              setopenPaymentModal(false);
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>  */}
        </>
    )
}

export default Package
