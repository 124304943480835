import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../assets/css/App.css'
import 'antd/dist/antd.css'
import axios from 'axios'
import { types } from '../../types/types'
import { BASE_PATH, API_COUNTRIES } from '../constants'
import { AuthContext } from '../../auth/AuthContext'
import { Col, Form, Row } from 'antd'
import { renderInput } from 'utils/inputs'
import { MessageUtils } from 'utils'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { Spinner } from 'componentes/commons'

const InfoViajero = () => {
    const navigate = useNavigate()
    const { dispatch } = useContext(AuthContext)
    const { user } = useContext(AuthContext)
    const [countries, setcountries] = useState([])
    const [umovil, setumovil] = useState(false)
    const [uemail, setuemail] = useState(false)
    let initForm = {
        name: '',
        alias: '',
        email: '',
        phone: '',
        gender: '',
        description: '',
        birthday: new Date(),
        direction: '',
    }
    const [formValues, setFormValues] = useState(initForm)
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(true)

    const Back = () => {
        if (!user.data.user.state || user.data.user.state === 0) {
            navigate('/configuraciones', { replace: true })
        } else {
            navigate('/host/configuraciones', { replace: true })
        }
    }
    const getCountries = async () => {
        try {
            setIsLoading(true)
            let response = await axios.get(`${API_COUNTRIES}`)
            const allCountries = response.data

            const countryOptions = allCountries.map((country) => ({
                value: country.name.common,
                label: country.name.common,
            }))
            countryOptions.sort((a, b) => a.label.localeCompare(b.label))
            setcountries(countryOptions)
        } catch (error) {
            console.error('Error fetching countries', error)
            MessageUtils.error('Ha ocurrido un error al cargar los países')
        } finally {
            setIsLoading(false)
        }
    }

    const updateOwnerInfo = async () => {
        try {
            setIsLoading(true)
            let updateUser = await axios.put(
                `${BASE_PATH}/users/${user.data.user.id}`,
                {
                    access_token: user.data.token,
                    name: user.data.user.name
                        ? user.data.user.name
                        : formValues.name + '_',
                    phone: user.data.user.phone
                        ? user.data.user.phone
                        : formValues.phone,
                    description: user.data.user.description
                        ? user.data.user.description
                        : formValues.description + '_',
                    birthday: user.data.user.birthday,
                    gender: user.data.user.gender,
                    direction: user.data.user.direction.value,
                    alias: user.data.user.alias
                        ? user.data.user.alias
                        : formValues.alias + '_',
                    picture: user.data.user.picture,
                    email: user.data.user.email
                        ? user.data.user.email
                        : formValues.email,
                    state: user.data.user.state,
                    infoExtra: {
                        name: formValues.name,
                        phone: formValues.phone,
                        description: formValues.description,
                        birthday: formValues.birthday,
                        gender: formValues.gender,
                        direction: formValues.direction,
                        alias: formValues.alias,
                        picture: formValues.picture,
                        email: formValues.email,
                    },
                }
            )

            if (updateUser.status === 200) {
                dispatch({
                    type: types.login,
                    payload: {
                        data: {
                            token: user.data.token,
                            user: updateUser.data,
                        },
                    },
                })
                MessageUtils.success('Datos actualizados con éxito')
            } else {
                MessageUtils.error(
                    'No se pudieron actualizar los datos, intente de nuevo'
                )
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }
    const updateInfoUser = async () => {
        try {
            setIsLoading(true)
            let updateUser = await axios.put(
                `${BASE_PATH}/users/${user.data.user.id}`,
                {
                    access_token: user.data.token,
                    name: formValues.name,
                    phone: formValues.phone,
                    description: formValues.description,
                    birthday: formValues.birthday,
                    gender: formValues.gender,
                    direction: formValues.direction.value,
                    alias: formValues.alias,
                    email: formValues.email,
                    infoExtra: user.data.user.infoExtra,
                }
            )

            if (updateUser.status === 200) {
                dispatch({
                    type: types.login,
                    payload: {
                        data: {
                            token: user.data.token,
                            user: updateUser.data,
                        },
                    },
                })
                MessageUtils.success('Datos actualizados con éxito')
            } else {
                MessageUtils.error(
                    'No se pudieron actualizar los datos, intente de nuevo'
                )
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    const inputs = [
        {
            label: `Nombre y Apellido`,
            name: 'name',
            placeholder: 'Nombre y Apellido',
            rules: [
                {
                    required: true,
                    message: 'Ingrese su nombre y apellido',
                },
            ],
        },
        {
            label: 'Alias',
            name: 'alias',
            placeholder: 'Alias',
            disabled: true,
            rules: [
                {
                    required: true,
                    message: 'Ingrese el alias',
                },
            ],
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'Email',
            rules: [
                {
                    required: true,
                    message: 'Ingrese el email',
                },
            ],
        },
        {
            label: 'Dirección',
            name: 'direction',
            placeholder: 'País donde vives',
            disabled: true,
            type: 'uniqueSelect',
            items: countries?.map((item) => {
                return {
                    label: item.label,
                    value: item.value,
                }
            }),
            rules: [
                {
                    required: true,
                    message: 'Ingrese la dirección',
                },
            ],
        },
        {
            label: 'Género',
            name: 'gender',
            type: 'uniqueSelect',
            placeholder: 'Seleccione un género',
            items: [
                { label: 'Hombre', value: 'Hombre' },
                { label: 'Mujer', value: 'Mujer' },
            ],
            rules: [
                {
                    required: true,
                    message: 'Seleccione un género',
                },
            ],
        },
        {
            label: 'Teléfono',
            name: 'phone',
            type: 'number',
            placeholder: '503 0000 0000',
            rules: [
                {
                    required: true,
                    message: 'Ingrese un número de contacto',
                },
            ],
        },
        {
            label: 'Fecha de Nacimiento',
            name: 'birthday',
            type: 'date',
            rules: [
                {
                    required: true,
                    message: 'Ingrese la fecha de nacimiento',
                },
            ],
        },
        {
            label: '¿Cómo te describes?',
            name: 'description',
            type: 'textArea',
            rules: [
                {
                    required: true,
                    message: 'Ingrese una descripción',
                },
            ],
        },
    ]

    const onFinish = () => {
        if (!user.data.user.state || user.data.user.state === 0) {
            updateInfoUser()
        } else if (user.data.user.state === 1) {
            updateOwnerInfo()
        }
    }

    useEffect(() => {
        getCountries()
        if (user) {
            const values = {
                name: user.data.user?.name,
                alias: user.data.user?.alias,
                email: user.data.user?.email,
                phone: user.data.user?.phone,
                gender: user.data.user?.gender,
                description: user.data.user?.description,
                birthday: moment(user.data.user?.birthday || moment.now()),
                direction: user.data.user?.direction,
            }
            setFormValues(values)
            form.setFieldsValue(values)
        } else {
            navigate('/', { replace: true })
        }
    }, [user])

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className="container-sm">
            <nav className="navbar-titles navbar-light d-flex align-items-center">
                <button
                    type="button"
                    className="back-window-navbar"
                    onClick={Back}
                >
                    <i className="  icon-left-arrow"></i>
                </button>
                <h5 className="bold">Información Básica</h5>
            </nav>
            <Form
                form={form}
                formValues={formValues}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                style={{ minWidth: '50rem', paddingTop: '12rem' }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        {inputs.slice(0, 5).map((input, index) => (
                            <Form.Item
                                key={index}
                                label={input.label}
                                name={input.name}
                                rules={input.rules}
                            >
                                {renderInput(input, formValues, setFormValues)}
                            </Form.Item>
                        ))}
                    </Col>
                    <Col span={12}>
                        {inputs.slice(5).map((input, index) => (
                            <Form.Item
                                key={index}
                                label={input.label}
                                name={input.name}
                                rules={input.rules}
                            >
                                {renderInput(input, formValues, setFormValues)}
                            </Form.Item>
                        ))}
                    </Col>
                </Row>
                <div
                    className="steps-action bold navbar-sm-more-host exp-edit-fixed-bottom"
                    style={{ width: 0, right: '2rem' }}
                >
                    <Button
                        className="bold exp-pasos btn-primary-reserva-host"
                        type="primary"
                        htmlType="submit"
                        // disabled={isLoadingForms}
                    >
                        Guardar
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default InfoViajero
