import {
    AdminDashboard,
    AdminDescuentos,
    AdminExperiencias,
    AdminFundaciones,
    AdminPaquetes,
    AdminParques,
    AdminPuntosVentas,
    AdminRevendedores,
    Ingresos,
    ListaViajeros,
    PerfilCompania,
} from 'componentes/Admin'
import DashboardAdminLayout from './layout/DashboardAdminLayout'

const { Route, Routes } = require('react-router-dom')

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="dashboard" element={<DashboardAdminLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="viajeros" element={<ListaViajeros />} />
                <Route path="ingresos" element={<Ingresos />} />
            </Route>
            <Route path="lista-experiencias" element={<AdminExperiencias />} />
            <Route path="parques" element={<AdminParques />} />
            <Route path="perfil" element={<PerfilCompania />} />
            <Route path="fundaciones" element={<AdminFundaciones />} />
            <Route path="revendedores" element={<AdminRevendedores />} />
            <Route path="puntos-ventas" element={<AdminPuntosVentas />} />
            <Route path="paquetes" element={<AdminPaquetes />} />
            <Route path="descuentos" element={<AdminDescuentos />} />
        </Routes>
    )
}

export default AdminRoutes
