import { DatePicker, Form, Input, InputNumber, Select, Switch } from "antd" 
import styles from "./InputForm.module.css" 
 
const { Option } = Select 
const { RangePicker } = DatePicker; 
 
export const OPTION_TYPE_FORM = { 
    text: "text", 
    password: "password", 
    phone: "phone", 
    number: "number", 
    switch: "switch", 
    textArea: "textArea", 
    plainText: "plainText", 
    date: "date", 
    rangePicker: "rangePicker" 
} 
export const MODE_SELECT = { 
    multiple: "multiple", 
    tags: "tags" 
} 
 
export const InputForm = ({ 
    label, 
    name, 
    rules = [], 
    items, 
    value, 
    type = OPTION_TYPE_FORM.text, 
    modeSelect = undefined, 
    placeholder = "", 
    iconBefore, 
    showSearch = false,
    onChange,
}) => {
 
    const { TextArea } = Input; 
 
    const formatPhoneNumber = (value) => { 
        value = value.replace(/\D/g, ''); 
 
        const match = value.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/) 
        value = !match ? value : `(${match[1]}) ${match[2]}-${match[3]}`
 
        return value; 
    } 
 
    return ( 
        <> 
            {type === OPTION_TYPE_FORM.switch && 
                <Form.Item label={label} valuePropName="checked" name={name} > 
                    <Switch value={value} onChange={onChange}/> 
                </Form.Item> 
            } 
            {type !== OPTION_TYPE_FORM.switch && 
                <Form.Item 
                    label={label} 
                    name={name} 
                    rules={rules || []} 
                    className={styles.InputForm} 
                    // getValueFromEvent={type === OPTION_TYPE_FORM.phone && ((e) => formatPhoneNumber(e.target.value))} 
                > 
                    {(type === OPTION_TYPE_FORM.text && !items) && 
                        <Input 
                            placeholder={label} 
                            addonBefore={iconBefore} 
                            value={value} 
                            onChange={onChange}
                        /> 
                    } 
                    {items && 
                        ( 
                            <Select placeholder={label} 
                                mode={modeSelect} 
                                allowClear 
                                filterOption={(input, option) => 
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) 
                                } 
                                showSearch={showSearch} 
                                options={items} 
                                value={value} 
                                onChange={onChange}
                            > 
 
                            </Select> 
                        ) 
                    } 
                    {type === OPTION_TYPE_FORM.password && 
                        <Input.Password  value={value} onChange={onChange}/> 
                    } 
                    {type === OPTION_TYPE_FORM.phone && 
                        <Input 
                            placeholder="(123) 456-7890" 
                            addonBefore={iconBefore} 
                            value={value} 
                            onChange={onChange}
                        /> 
                    } 
                    {type === OPTION_TYPE_FORM.number && 
                        <InputNumber 
                            min={0} 
                            controls={false} 
                            addonBefore={iconBefore} 
                            style={{ width: "100%" }} 
                            value={value} 
                            onChange={onChange}
                        /> 
                    } 
                    {type === OPTION_TYPE_FORM.textArea && 
                        <TextArea 
                            rows={3} 
                            placeholder={placeholder} 
                            value={value} 
                            onChange={onChange}
                        /> 
                    } 
                    {type === OPTION_TYPE_FORM.plainText && 
                        <Input 
                            disabled 
                            value={value} 
                            onChange={onChange}
                        /> 
                    } 
                    {type === OPTION_TYPE_FORM.date && 
                        <DatePicker style={{ width: "100%" }} value={value} onChange={onChange}/> 
                    } 
                    {type === OPTION_TYPE_FORM.rangePicker && 
                        <RangePicker value={value} onChange={onChange}/> 
                    } 
                </Form.Item> 
            } 
        </> 
 
    ) 
}