import { message } from 'antd'

const duration = 3

const MessageUtils = {
    success: (content) => {
        message.success(content, duration)
    },
    error: (content) => {
        message.error(content, duration)
    },
    info: (content) => {
        message.info(content, duration)
    },
    warning: (content) => {
        message.warning(content, duration)
    },
}

export default MessageUtils
