import {
  Configuraciones,
  InfoViajero,
  MetodoPago,
  TusGustos,
} from "componentes/Configuraciones";
import { Route, Routes } from "react-router-dom";

const ConfigurationRoutes = () => {
  return (
    <Routes>
      <Route index element={<Configuraciones />} />
      <Route path="gustos" element={<TusGustos />} />
      <Route path="metodopago" element={<MetodoPago />} />
      <Route path="perfil" element={<InfoViajero />} />
    </Routes>
  );
};

export default ConfigurationRoutes;
