import { Form } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import { BASE_PATH } from '../../../constants'
import Titulo from '../Titulo'
import TablaFundaciones from './items/TablaFundaciones'
import { useOutletContext } from 'react-router-dom'
import PaginationCom from 'componentes/pagination/PaginationCom'
import { MessageUtils } from 'utils'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'

const initForm = {
    name: '',
    email: '',
    password: null,
    country: 'El Salvador',
    domain: null,
}

const AdminFundaciones = () => {
    const { user } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fundaciones, setFundaciones] = useState([])
    const [page, setPage] = useState(1)
    const uploaderRef = useRef()
    const [fileList, setFileList] = useState(null)
    const [fileImg, setFileImg] = useState(null)
    const [meta, setMeta] = useState([])
    const [action, setAction] = useState('new')
    const [formValues, setFormValues] = useState(initForm)
    const [openModal, setOpenModal] = useState(false)
    const [isLoadingForm, setIsLoadingForm] = useState(false)
    const [form] = Form.useForm()

    const incioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({
                path: `users?page=${page}&limit=11&isPaginate=true&role=company`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            const filterData = responseData[0].result.map((company) => {
                const { password, ...rest } = company
                return rest
            })
            setFundaciones(filterData)
            setMeta(responseData)
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        incioComponente()
    }, [])

    useEffect(() => {
        incioComponente()
    }, [page])

    const getImageUrl = async (file) => {
        const fdata = new FormData()
        fdata.append('file', file[0].originFileObj)
        try {
            const imageUrl = await axios.post(
                `${BASE_PATH}/images/upload-files`,
                fdata,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )
            return imageUrl.data.imageUrl
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar subir la imagen'
            )
        }
    }

    const onFinish = async () => {
        try {
            setIsLoadingForm(true)
            let logoUrl = ''
            if (fileList.length > 0) {
                logoUrl = await getImageUrl(fileList)
            }
            const response = await postData({
                path: `users/company`,
                token: user.logged && user.data.token,
                params: {},
                value: { ...formValues, picture: logoUrl },
            })
            setFormValues(initForm)
            form.resetFields()
            setOpen(false)
            incioComponente()
            let coverUrl = ''
            if (fileImg) {
                coverUrl = await getImageUrl(fileImg)
            }
            await putData({
                path: `config`,
                token: user.logged && user.data.token,
                params: {},
                value: { domain: formValues.domain, logoUrl: coverUrl },
                id: response.config,
            })
            MessageUtils.success('Se ha creado la compañia con éxito')
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error al crear la Compañia')
        } finally {
            setIsLoadingForm(false)
        }
    }

    const onEdit = (data) => {
        if (data.picture !== '') {
            const formattedFileList = {
                uid: `001`,
                name: `Imagen-001`,
                status: 'done',
                url: data.picture,
            }
            setFileList([formattedFileList])
        }
        setOpen(true)
        const values = {
            id: data._id,
            name: data.name,
            email: data.email,
            password: data.password,
            country: data.country,
            picture: data.picture,
        }
        setFormValues(values)
        form.setFieldsValue(values)
        setOpen(true)
        setAction('edit')
    }

    const addFile = (file, isFileList) => {
        if (file.file.status === 'removed') {
            if (isFileList) {
                setFileList([])
            } else {
                setFileImg([])
            }
        } else {
            const changeFile = file.fileList.filter(
                (value) => value.name === file.file.name
            )

            const formattedFile = {
                uid: file.file.uid,
                name: file.file.name,
                status: 'done',
                originFileObj: changeFile[0].originFileObj,
            }
            if (isFileList) {
                setFileList([formattedFile])
            } else {
                setFileImg([formattedFile])
            }
        }
    }

    const updateCompany = async () => {
        try {
            setIsLoadingForm(true)
            let logoUrl = ''
            if ('originFileObj' in fileList[0]) {
                logoUrl = await getImageUrl(fileList)
            }
            const { password, ...valuesToSend } = formValues
            await putData({
                path: `users`,
                token: user.logged && user.data.token,
                id: formValues.id,
                params: {},
                value: {
                    ...valuesToSend,
                    picture: logoUrl !== '' ? logoUrl : formValues.picture,
                },
            })
            if (password) {
                await axios.put(
                    `${BASE_PATH}/users/${formValues.id}/password-from-admin`,
                    { password },
                    {
                        headers: {
                            Authorization: `Bearer ${user.logged && user.data.token}`,
                        },
                    }
                )
            }
            setFormValues(initForm)
            form.resetFields()
            setOpen(false)
            incioComponente()
            MessageUtils.success('Se ha modificado la compañia con éxito')
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error al modificar la Compañia')
        } finally {
            setIsLoadingForm(false)
        }
    }

    const onDeleteCompany = async () => {
        try {
            setIsLoadingForm(true)
            await deleteData({
                path: `users`,
                id: formValues._id,
                token: user.logged && user.data.token,
            })
            MessageUtils.success('Se ha eliminado la compañíag con éxito')
            incioComponente()
        } catch (error) {
            MessageUtils.error('Ha ocurrido un error')
            console.log(error)
        } finally {
            setOpenModal(false)
            setIsLoadingForm(false)
            setFormValues(initForm)
        }
    }

    const handleDeleteButton = (company) => {
        setFormValues(company)
        setOpenModal(true)
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        setFileList(null)
        form.resetFields()
        setOpen(false)
        setTimeout(() => {
            setAction('new')
        }, 300)
    }

    const onCancelModal = () => {
        setFormValues(initForm)
        form.resetFields()
        setOpenModal(false)
    }

    return (
        <div>
            <Titulo
                titulo={'Lista de Compañias'}
                tituloBoton={'Crear Compañia'}
                onAcccion={() => setOpen(true)}
            />
            <div>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <TablaFundaciones
                            reset={incioComponente}
                            data={fundaciones}
                            onEdit={onEdit}
                            onDelete={handleDeleteButton}
                        />
                        {fundaciones.length > 0 && (
                            <PaginationCom
                                page={page}
                                meta={meta}
                                setPage={(value) => setPage(value)}
                            />
                        )}
                    </>
                )}
            </div>
            <SidebarForm
                isLoading={isLoadingForm}
                title={action === 'new' ? 'Crear Compañía' : 'Editar Compañía'}
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                form={form}
                formValues={formValues}
                onCloseDrawer={onCloseDrawer}
                open={open}
                setFormValues={setFormValues}
                onFinish={action === 'new' ? onFinish : updateCompany}
                inputs={[
                    {
                        label: 'Compañia',
                        name: 'name',
                        rules: [
                            {
                                required: true,
                                message: 'Ingrese el nombre de la compañia',
                            },
                        ],
                    },
                    {
                        label: 'Email',
                        name: 'email',
                        rules: [
                            { required: true, message: 'Ingrese el email' },
                        ],
                    },
                    {
                        label: 'Password',
                        name: 'password',
                        type: 'password',
                        rules: [
                            {
                                required: true,
                                message: 'Ingrese una contraseña',
                            },
                        ],
                    },
                    ...(action === 'new'
                        ? [
                              {
                                  label: 'Dominio',
                                  name: 'domain',
                                  rules: [
                                      {
                                          required: true,
                                          message: 'Ingrese el dominio',
                                      },
                                  ],
                              },
                          ]
                        : []),
                ]}
                uploadInput={[
                    {
                        value: fileList,
                        onChange: (file) => addFile(file, true),
                        ref: uploaderRef,
                        text: 'Subir logo',
                    },
                    action === 'new'
                        ? {
                              value: fileImg,
                              onChange: (file) => addFile(file, false),
                              ref: uploaderRef,
                              text: 'Subir portada',
                          }
                        : {},
                ]}
            />
            <CancelConfirmModal
                loading={isLoadingForm}
                onCancelModal={onCancelModal}
                onOk={onDeleteCompany}
                openModal={openModal}
                strongText={
                    fundaciones.find((user) => user._id === formValues._id)
                        ?.name
                }
                text={`¿Estás seguro de que deseas eliminar la compañía`}
                title={'Eliminar Compañía'}
            />
        </div>
    )
}

export default AdminFundaciones
