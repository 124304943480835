import { Col, Form, Row } from 'antd'
import { DollarOutlined } from '@ant-design/icons'
import { useMemo } from 'react'
import { InputForm } from '../InputForm/InputForm'
import moment from 'moment'

const SIZE_COL = 24

export const ConstructorFormAntd = ({
    listInputs = [],
    inputsForRows = 2,
    modeLarge = false,
    values, // Recibe los valores del formulario
    // onChange,
    setValues,
    formValues,
}) => {
    let contRow = 1
    const spanRow = useMemo(() => {
        if (Number.isInteger(SIZE_COL / inputsForRows))
            return {
                sizeRow: SIZE_COL / inputsForRows,
            }

        const row = Math.round(SIZE_COL / inputsForRows)

        return {
            sizeRow: row,
            sizeRowFinal: SIZE_COL % inputsForRows,
        }
    }, [inputsForRows])

    const onChange = (label, value, type, date) => {
        if (type == 'date') {
            setValues({ ...formValues, [label]: date })
            console.log('valores', label, date, type)
        } else {
            setValues({ ...formValues, [label]: value.target.value })
        }
    }

    const onChangeSelect = (label, value) => {
        setValues({ ...formValues, [label]: value })
    }
    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={values} // Establece los valores iniciales del formulario
            onValuesChange={(_, allValues) => {
                // Llama a la función onChange para actualizar los valores del formulario
                onChange(allValues)
            }}
        >
            <Row gutter={[8, 0]}>
                {listInputs.map((inputProps) => {
                    let rowSize = spanRow.sizeRow
                    if (modeLarge) {
                        if (inputsForRows === contRow) {
                            rowSize = spanRow.sizeRowFinal || spanRow.sizeRow
                            contRow = 1
                        } else {
                            contRow = contRow + 1
                        }
                    }

                    if (inputProps.onlyShow) {
                        return <></>
                    }
                    if (inputProps.type) {
                        return (
                            <Col
                                span={rowSize}
                                key={inputProps.name}
                                style={{
                                    display: inputProps.none ? 'none' : null,
                                }}
                            >
                                <InputForm
                                    label={inputProps.label}
                                    name={inputProps.name}
                                    rules={inputProps.rules}
                                    type={inputProps.type}
                                    // value={values[inputProps.name]} // Proporciona el valor del input
                                    onChange={(e, dateString) =>
                                        onChange(
                                            inputProps.name,
                                            e,
                                            inputProps.type,
                                            dateString
                                        )
                                    }
                                    setValues={setValues}
                                />
                            </Col>
                        )
                    }

                    if (inputProps.items) {
                        return (
                            <Col
                                span={rowSize}
                                key={inputProps.name}
                                style={{
                                    display: inputProps.none ? 'none' : null,
                                }}
                            >
                                <InputForm
                                    label={inputProps.label}
                                    name={inputProps.name}
                                    items={inputProps.items}
                                    rules={inputProps.rules}
                                    modeSelect={inputProps.modeSelect}
                                    showSearch={inputProps.showSearch}
                                    value={values[inputProps.name]} // Proporciona el valor del input
                                    onChange={(e) =>
                                        onChangeSelect(inputProps.name, e)
                                    }
                                    setValues={setValues}
                                />
                            </Col>
                        )
                    }
                    if (inputProps.icon) {
                        return (
                            <Col
                                span={rowSize}
                                key={inputProps.name}
                                style={{
                                    display: inputProps.none ? 'none' : null,
                                }}
                            >
                                <InputForm
                                    label={inputProps.label}
                                    name={inputProps.name}
                                    rules={inputProps.rules}
                                    type="number"
                                    iconBefore={<DollarOutlined />}
                                    value={values[inputProps.name]} // Proporciona el valor del input
                                    onChange={(e) =>
                                        onChange(inputProps.name, e)
                                    }
                                    setValues={setValues}
                                />
                            </Col>
                        )
                    }

                    return (
                        <Col
                            span={rowSize}
                            key={inputProps.name}
                            style={{ display: inputProps.none ? 'none' : null }}
                        >
                            <InputForm
                                label={inputProps.label}
                                name={inputProps.name}
                                rules={inputProps.rules || []}
                                value={values[inputProps.name]} // Proporciona el valor del input
                                onChange={(e) => onChange(inputProps.name, e)}
                                setValues={setValues}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Form>
    )
}
