import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'antd/dist/antd.css'
import { TabLink, TabContent, Tabs } from 'react-tabs-redux'
import { RiEdit2Fill } from 'react-icons/ri'
import axios from 'axios'
import { Media, Row, Col } from 'react-bootstrap'
import { Form, Popover } from 'antd'
import { Button, Drawer, Icon, IconButton, Loader } from 'rsuite'
import País from '../../assets/images/paises/banderas/guate.svg'
import Background from '../../assets/images/cards/barrio.jpg'
import ExperienciaGaleria from '../Experiencias/ExperienciaGaleria'
import ExperienciaDetails from '../Experiencias/ExperienciaDetails'
import ExperienciaReview from '../Experiencias/ExperienciaReview'
import { BASE_PATH } from '../constants'

import { AuthContext } from '../../auth/AuthContext'
import logo from '../../assets/images/desvlop.svg'
// import ExperienciaNewGustos from './ExperienciaNewGustos'
// import ExperienciaNewPagar from './ExperienciaNewPagar'
import { MessageUtils } from 'utils'
import { Spinner } from 'componentes/commons'
import PackageDetails from './packageDetails'

import InformationModal from './informationModal'
import { getData, putData } from 'services'

import PhotosModal from './photosModal'
import Review from './review'

const InitShowModalState = {
    open: false,
    photos: false,
    categories: false,
    prices: false,
}

const initForm = {
    name: '',
    description: '',
    expirationDate: null,
    experiences: [],
    duration: '',
    merchantOwner: '',
}

const UploadPackage = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const { paqueteid } = useParams()
    const [selectedCategories, setselectedCategories] = useState([])
    const [merchants, setMerchants] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedPhotos, setselectedPhotos] = useState([])
    const [paquete, setpaquete] = useState([])
    const [experiences, setExperiences] = useState([])
    const [schedulesExist, setschedulesExist] = useState(false)
    const [showModal, setshowModal] = useState(InitShowModalState)
    const [form] = Form.useForm()
    const [showEditModal, setShowEditModal] = useState(false)
    const [galleryPhotos, setGalleryPhotos] = useState([])
    const [allExperiencies, setAllExperiencies] = useState([])
    const [formValues, setFormValues] = useState(initForm)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    useEffect(() => {
        getpaquetes()
        fetchAllExperiences()
    }, [])

    const HomeB = () =>
        navigate(`/company/${user.data.platform.domain}/paquetes`)

    const Editar = () => setShowEditModal(true)
    console.log(formValues)
    const Schedules = () =>
        navigate(`/experiencia/horarios/_/${paqueteid}`, { replace: true })

    const handlePhotos = () => setshowModal({ open: true, photos: true })

    const getpaquetes = async () => {
        try {
            setLoading(true)
            const res = await axios.get(
                `${BASE_PATH}/experiences/packages/${paqueteid}`,
                {
                    headers: {
                        Authorization: `Bearer ${user?.data?.token}`,
                    },
                }
            )
            if (res.status === 200) {
                const packageData = res.data
                console.log(packageData)
                setpaquete([packageData])
                const photos =
                    packageData?.pictures?.map((picture) => ({
                        url: picture.imageUrl,
                    })) || []
                setselectedPhotos(photos)
                setFormValues({
                    name: packageData.name,
                    description: packageData.description,
                    duration: packageData.duration,
                    expirationDate: packageData.expirationDate,
                    merchantOwner: packageData.merchantOwner,
                })
                const experienceIds = packageData?.experiences?.map(
                    (exp) => exp.experience
                )
                const experiences = await fetchExperiences(experienceIds)

                setgalleryPhotos(packageData, experiences)
                await getMerchants()
            }
        } catch (e) {
            console.error(e)
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setLoading(false)
        }
    }

    const fetchExperiences = async (experienceIds) => {
        try {
            const experienceData = await Promise.all(
                experienceIds.map(async (id) => {
                    const res = await axios.get(
                        `${BASE_PATH}/experiences/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${user?.data?.token}`,
                            },
                        }
                    )

                    return res.data
                })
            )
            console.log(experienceData)
            setExperiences(experienceData)
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                experiences: experienceData?.map((exp) => ({
                    value: exp.id,
                    label: exp.name,
                })),
            }))
            return experienceData
        } catch (e) {
            console.error('Error al obtener experiencias:', e)
        }
    }

    const setgalleryPhotos = (paquete, experiences) => {
        const photos = [
            ...(paquete?.pictures?.map((picture) => picture.imageUrl) || []),

            ...(paquete?.experiences?.map((exp) => {
                const experienciaCompleta = experiences?.filter(
                    (experiencia) => experiencia.id === exp.experience
                )

                return experienciaCompleta[0]?.pictures
                    ? experienciaCompleta[0].pictures.map((p) => p.imageUrl)
                    : []
            }) || []),
        ]
        const uniquePhotos = Array.from(new Set(photos))

        const formattedPhotos = uniquePhotos.map((url, index) => ({
            url,
            id: index,
        }))
        setGalleryPhotos(formattedPhotos)
    }

    const fetchAllExperiences = async () => {
        try {
            const response = await getData({
                path: `experiences`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
                platform: user?.data?.user?.config,
            })

            setAllExperiencies(response)
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al cargar las experiencias'
            )
            console.log(error)
        }
    }

    const getSchedules = async (id = paquete[0]?.id) => {
        try {
            const resp = await axios.get(
                `${BASE_PATH}/schedules?experience=${id}`
            )

            if (resp.status === 200 && resp.data.count > 0) {
                return true
            }
            return false
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
            return false
        }
    }

    const getMerchants = async () => {
        try {
            const responseSalesPoint = await getData({
                path: `users/partners/merchants/?isPaginate=false&merchantType=salesPoint`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            setMerchants([...responseSalesPoint[0]?.result])
            const responseReseller = await getData({
                path: `users/partners/merchants/?isPaginate=false&merchantType=reseller`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            setMerchants((prevState) => [
                ...prevState,
                ...responseReseller[0]?.result,
            ])
        } catch (error) {}
    }

    const updatePackage = async () => {
        try {
            setIsUpdateLoading(true)
            const experiencesValues = formValues.experiences.flatMap((item) =>
                typeof item === 'string' ? item : item.value
            )
            await putData({
                path: `experiences/packages`,
                id: paqueteid,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                    experiences: experiencesValues,
                },
            })
            MessageUtils.success('Paquete modificado con éxito')
            getpaquetes()
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar un paquete'
            )
        } finally {
            setIsUpdateLoading(false)
            setShowEditModal(false)
        }
    }

    const changeDraftMode = async () => {
        if (paquete[0]?.isDraft) {
            const validSchedules = await getSchedules()

            if (!validSchedules) {
                MessageUtils.info(
                    'Debes agregar fechas antes de publicar el paquete'
                )
                return
            }

            onChangeDraftMode()
        } else {
            onChangeDraftMode()
        }
    }

    const onChangeDraftMode = async () => {
        try {
            setLoading(true)
            const resp = await axios.put(
                `${BASE_PATH}/package/${paquete[0]?.id}`,
                {
                    isDraft: !paquete[0]?.isDraft,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 200) {
                setpaquete([resp.data])
                MessageUtils.success(
                    `Se ${paquete[0]?.isDraft ? 'publicó' : 'pasó a borrador'} con éxito`
                )
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error en el servidor')
        } finally {
            setLoading(false)
        }
    }

    // const handleSaveCategories = async () => {
    //     setLoading(true)
    //     try {
    //         const resp = await axios.put(
    //             `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
    //             {
    //                 category: selectedCategories,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${user.data.token}`,
    //                 },
    //             }
    //         )

    //         if (resp.status === 200) {
    //             setselectedCategories(resp.data.category)
    //             MessageUtils.success('Se actualizaron las categorias')
    //             setshowModal({ open: false })
    //         }
    //     } catch (error) {
    //         console.log(error)
    //         MessageUtils.error(
    //             'Ha ocurrido un error al actualizar las categorías'
    //         )
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const handleAddNewPhotos = async () => {
        const filterPhotosPerUpload = selectedPhotos.filter(
            (photo) => photo.originFileObj
        )
        const previousPhotos = selectedPhotos.filter((photo) => photo.url)

        if (filterPhotosPerUpload.length > 0) {
            setLoading(true)
            let uploadedUrls = []
            try {
                for (let photo of filterPhotosPerUpload) {
                    const fdata = new FormData()
                    fdata.append('experiencesId', paquete[0]?._id)
                    fdata.append('file', photo.originFileObj)
                    try {
                        const imagesUp = await axios.post(
                            `${BASE_PATH}/images/upload-files`,
                            fdata,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: `Bearer ${user.data.token}`,
                                },
                            }
                        )
                        if (imagesUp.status === 200) {
                            uploadedUrls.push(imagesUp.data)
                        }
                    } catch (error) {
                        console.log(error)
                        MessageUtils.error('Error al subir la imagen')
                    }
                }

                if (uploadedUrls.length > 0) {
                    try {
                        const resp = await axios.put(
                            `${BASE_PATH}/experiences/packages/${paquete[0]?._id}`,
                            {
                                pictures: [...previousPhotos, ...uploadedUrls],
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${user.data.token}`,
                                },
                            }
                        )
                        if (resp.status === 200) {
                            MessageUtils.success('Se actualizaron las fotos')
                            setshowModal({ open: false })
                            getpaquetes()
                        }
                    } catch (error) {
                        console.log(error)
                        MessageUtils.error(
                            'Ha ocurrido un error en el servidor'
                        )
                    }
                }
            } catch (error) {
                console.log(error)
                MessageUtils.error('Ha ocurrido un error en el servidor')
            } finally {
                setLoading(false)
            }
        }
    }

    const PopoverEditContent = (
        <div className="popover-edit-content">
            <p>
                <Button
                    block
                    onClick={changeDraftMode}
                    color={paquete[0]?.isDraft ? 'green' : 'red'}
                    appearance="ghost"
                >
                    {paquete[0]?.isDraft
                        ? 'Publicar ahora'
                        : 'Pasar a borrador'}
                </Button>
            </p>
            <p>
                <IconButton
                    block
                    onClick={Editar}
                    icon={<Icon icon="check-circle" className="green-color" />}
                >
                    Información
                </IconButton>
            </p>
            <p>
                <IconButton
                    block
                    onClick={Schedules}
                    icon={
                        schedulesExist ? (
                            <Icon icon="check-circle" className="green-color" />
                        ) : (
                            <Icon
                                icon="exclamation-circle"
                                className="red-color"
                            />
                        )
                    }
                >
                    Fechas
                </IconButton>
            </p>
            <p>
                <IconButton
                    block
                    onClick={handlePhotos}
                    icon={
                        paquete[0]?.pictures?.length > 0 ? (
                            <Icon icon="check-circle" className="green-color" />
                        ) : (
                            <Icon
                                icon="exclamation-circle"
                                className="red-color"
                            />
                        )
                    }
                >
                    Fotos
                </IconButton>
            </p>
        </div>
    )

    const evaluateClickActionInDrawer = () => {
        const { categories, photos, prices } = showModal

        if (categories) {
            // handleSaveCategories()
        }

        if (photos) {
            handleAddNewPhotos()
        }

        if (prices) {
            // handleUpdatePrices()
        }
    }

    if (loading) {
        return <Spinner />
    }

    return (
        <div>
            <button
                style={{ zIndex: '99999' }}
                type="button"
                className="back-window fixed-top"
                onClick={HomeB}
            >
                <i className="  icon-left-arrow"></i>
            </button>
            <img src={logo} className="logo_in ml-3" alt="logo" />

            <section className="exp-header fixed-top d-none d-md-none d-lg-block">
                <Row>
                    <Col>
                        <div
                            className="d-flex align-items-center"
                            style={{ height: '100vh' }}
                        >
                            <div className="bold exp-titulo exp-container">
                                <Media>
                                    <img
                                        width={40}
                                        height={40}
                                        className="align-self-center mr-3 border-flag rounded-circle"
                                        src={País}
                                        alt="Generic placeholder"
                                    />
                                    <Media.Body>
                                        <h5 className="text-white">
                                            {paquete.length > 0 ? (
                                                paquete[0].direction
                                            ) : (
                                                <></>
                                            )}
                                        </h5>
                                    </Media.Body>
                                </Media>
                                <p style={{ fontSize: '70px' }}>
                                    {paquete.length > 0 ? (
                                        `${paquete[0].name} `
                                    ) : (
                                        <></>
                                    )}{' '}
                                    <b
                                        className={
                                            paquete[0]?.isDraft
                                                ? 'red-color'
                                                : 'green-color'
                                        }
                                    >
                                        {paquete[0]?.isDraft
                                            ? '(borrador)'
                                            : '(publicada)'}
                                    </b>
                                </p>

                                <div
                                    className="review"
                                    style={{ marginBottom: '15rem' }}
                                >
                                    <i
                                        className="icon-star"
                                        style={{
                                            color: '#fe5000',
                                            fontSize: '30px',
                                        }}
                                    ></i>
                                    <text
                                        className="bold"
                                        style={{ fontSize: '30px' }}
                                    >
                                        {paquete.length > 0 ? (
                                            paquete[0].rating
                                        ) : (
                                            <></>
                                        )}
                                    </text>
                                </div>

                                <div>
                                    <Row
                                        className="exp-details-about"
                                        style={{ width: '600px' }}
                                    >
                                        <Col className="text-center">
                                            <i
                                                className="icon-face"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Cupo
                                            </p>
                                            <p className="bold">
                                                {/* {paquete.length > 0 ? (
                                                    paquete[0].quotas.min
                                                ) : (
                                                    <></>
                                                )}{' '}
                                                -
                                                {paquete.length > 0 ? (
                                                    paquete[0].quotas.max
                                                ) : (
                                                    <></>
                                                )}{' '} */}
                                                personas
                                            </p>
                                        </Col>
                                        <Col className="text-center">
                                            <i
                                                className="icon-time"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Duración
                                            </p>
                                            <p className="bold">
                                                {paquete.length > 0 ? (
                                                    `${paquete[0].duration} días`
                                                ) : (
                                                    <></>
                                                )}
                                            </p>
                                        </Col>
                                        <Col className="text-center">
                                            <i
                                                className="icon-money"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Precios
                                            </p>
                                            <p className="bold">
                                                Adultos ${' '}
                                                {paquete.length > 0 ? (
                                                    paquete[0]
                                                        ?.grossPriceAdult ||
                                                    null
                                                ) : (
                                                    <></>
                                                )}
                                                , <br />
                                                Niños ${' '}
                                                {paquete.length > 0 ? (
                                                    paquete[0]
                                                        ?.grossPriceChildren ||
                                                    null
                                                ) : (
                                                    <></>
                                                )}{' '}
                                                <></>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        className="exp-header-pic"
                        lg={4}
                        style={{
                            backgroundImage: `url(${
                                paquete[0]?.pictures?.length > 0
                                    ? paquete[0]?.pictures[0]?.imageUrl
                                    : Background
                            })`,
                        }}
                    ></Col>
                </Row>
                <i className="fi fi-br-angle-down neon_blue icon-font-exp text-center"></i>
            </section>
            <section
                className="exp-header fixed-top d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none"
                style={{
                    height: '100vh',
                    backgroundImage: `url(${
                        paquete.length > 0 && selectedPhotos[0]
                            ? selectedPhotos[0].url
                            : Background
                    })`,
                }}
            >
                <div className="bold exp-titulo container-sm">
                    <p className="text-left">
                        {paquete.length > 0 ? paquete[0].name : <></>}
                    </p>
                </div>
                <Media
                    className="container"
                    style={{
                        marginTop: '-2rem',
                        position: 'absolute',
                        bottom: '2rem',
                    }}
                >
                    <Media.Body>
                        <h5 className="text-white text-left mt-1">
                            {paquete.length > 0 ? paquete[0].direction : <></>}
                        </h5>
                    </Media.Body>
                </Media>
            </section>

            <section className="exp-contenido2">
                <Tabs className="container-sm">
                    <div className="sticky-top exp-tabs">
                        <TabLink
                            to="tab1"
                            className="exp-contenido-btn exp-active"
                            activeClassName="neon_blue"
                        >
                            <i className="  icon-details"></i> Info
                        </TabLink>
                        <TabLink
                            to="tab2"
                            className="exp-contenido-btn exp-active"
                            activeClassName="neon_blue"
                        >
                            <i className=" icon-pics"></i>Pics
                        </TabLink>
                        <TabLink
                            to="tab3"
                            className="exp-contenido-btn exp-active"
                            activeClassName="neon_blue"
                        >
                            <i className=" icon-star"></i>
                        </TabLink>
                    </div>
                    <TabContent for="tab1">
                        <PackageDetails
                            experiences={experiences}
                            paquete={paquete}
                        />
                    </TabContent>
                    <TabContent for="tab2" className="exp-galeria">
                        {galleryPhotos.length > 0 && (
                            <ExperienciaGaleria photos={galleryPhotos} />
                        )}
                    </TabContent>
                    <TabContent for="tab3">
                        <Review data={paquete.id} />
                    </TabContent>
                    <TabContent for="tab4"></TabContent>
                </Tabs>
                <>
                    {user.data.user.role !== 'merchant' && (
                        <Popover
                            content={PopoverEditContent}
                            trigger="click"
                            placement="topRight"
                        >
                            <button
                                style={{ zIndex: '1000' }}
                                type="button"
                                className="exp-fixed-bottom-open-host-upload"
                                // onClick={Editar}
                            >
                                <RiEdit2Fill style={{ fontSize: 26 }} />
                            </button>
                        </Popover>
                    )}
                </>
            </section>

            {paquete[0] && (
                <Drawer
                    placement="bottom"
                    closable={false}
                    show={showModal.open}
                    full={true}
                    style={{ width: '100%', height: '100%' }}
                    backdrop={true}
                    drawerStyle={{ background: '#17191A' }}
                >
                    <nav className="navbar-titles navbar-light d-flex align-items-center ml-3">
                        <button
                            type="button"
                            className="back-window-navbar-drawer"
                            onClick={() => setshowModal({ open: false })}
                        >
                            <i className=" icon-left-arrow"></i>
                        </button>

                        <h5 className="bold">
                            {showModal.categories && '¡Agrega categorías!'}
                            {showModal.photos && '¡Sube tus fotografías!'}
                            {showModal.prices && '¡Ajusta tus precios!'}
                        </h5>
                    </nav>

                    <Drawer.Body>
                        <>
                            <div className="steps-content">
                                {loading && (
                                    <Spinner />
                                    // <Loader
                                    //     backdrop
                                    //     content="Cargando..."
                                    //     vertical
                                    // />
                                )}

                                {/* {showModal.categories && !loading && (
                                    <ExperienciaNewGustos
                                        cats={selectedCategories}
                                        selectCategories={setselectedCategories}
                                    />
                                )} */}

                                {showModal.photos && (
                                    <PhotosModal
                                        getPackage={getpaquetes}
                                        pictures={selectedPhotos}
                                        selectPictures={setselectedPhotos}
                                        packageId={paquete[0]?._id}
                                    />
                                )}
                                {/*
                                {showModal.prices && !loading && (
                                    <ExperienciaNewPagar
                                        form={form}
                                        newForm={newForm}
                                        setNewForm={setNewForm}
                                        onFinish={handleUpdatePrices}
                                    />
                                )} */}
                            </div>

                            {!showModal.prices && (
                                <div
                                    className="steps-action bold navbar-sm-more-host exp-fixed-bottom"
                                    style={{ width: '0px' }}
                                >
                                    <Button
                                        className="bold exp-pasos btn-primary-host btn-reserva"
                                        type="primary"
                                        onClick={evaluateClickActionInDrawer}
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            )}
                        </>
                    </Drawer.Body>
                </Drawer>
            )}
            {allExperiencies && (
                <InformationModal
                    experiences={allExperiencies}
                    form={form}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    setShowEditModal={setShowEditModal}
                    showEditModal={showEditModal}
                    onOk={updatePackage}
                    loading={isUpdateLoading}
                    merchants={merchants}
                />
            )}
        </div>
    )
}

export default UploadPackage
