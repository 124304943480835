import 'antd/dist/antd.dark.css' // Importa el tema oscuro antes que tus otros componentes y estilos
import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    ConfigProvider,
    Layout,
    Row,
    Col,
    Modal,
    Space,
    Input,
    message,
} from 'antd'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Search } from '@chatscope/chat-ui-kit-react'
import { MenuDesplegable } from '../Navbars/elementos/MenuDesplegable'
import { PiFingerprintBold } from 'react-icons/pi'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { AuthContext } from '../../auth/AuthContext'
import SidebarIndex from '../Navbars/SidebarIndex'
import { getData } from '../../services'
import Login from '../Login/Login'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchCompanyFailure,
    fetchCompanyLoading,
    fetchCompanySuccess,
} from '../../redux/features/company/companySlice'
import SkeletonAvatar from 'antd/lib/skeleton/Avatar'
import SkeletonInput from 'antd/lib/skeleton/Input'
import { HomeButton, Spinner } from 'componentes/commons'
import OwlCarousel from 'react-owl-carousel'
import { Card, Image } from 'react-bootstrap'

const { Header, Sider, Content } = Layout

const ViajeroIndex = ({ searchExp = '' }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { data, loading, error } = useSelector((state) => state.company)
    const [collapsed, setCollapsed] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [dominios, setDominios] = useState(null)
    const { user } = useContext(AuthContext)
    const search = (value) => {
        searchExp(value)
    }
    const [darkMode, setDarkMode] = useState(false)
    const [experiencesFound, setExperiencesFound] = useState([])
    const [searchExperience, setSearchExperience] = useState('')
    const [debounceTimeout, setDebounceTimeout] = useState()
    const [isLoading, setIsLoading] = useState(false)
    console.log('viajeroindex', data)
    // Función para cambiar el modo (oscuro o claro)
    const toggleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    const rutaDominio = window.location.pathname
    const rutaB = rutaDominio.split('/')

    const getDataViajeroIndex = async () => {
        dispatch(fetchCompanyLoading(true))
        console.log('me ejecuto en viajero index', rutaB[1])
        try {
            const resDominio = await getData({
                path: `config/public/query?domain=${rutaB[1]}`,
                token: null,
                params: {},
                value: {},
                platform: null,
            })
            const res = await getData({
                path: `users/partners/ptnr/public`,
                token: null,
                params: {},
                value: {},
                platform: resDominio.id,
            })
            console.log(resDominio)
            setDominios(resDominio)
            dispatch(
                fetchCompanySuccess({
                    ...res[0],
                    dominioCompany: resDominio.platform.domain,
                })
            )
        } catch (error) {
            console.error(error)
            dispatch(fetchCompanyFailure(error))
        } finally {
            dispatch(fetchCompanyLoading(false))
        }
    }

    const fetchSearchResults = async (query) => {
        setIsLoading(true)
        try {
            const response = await getData({
                path: `experiences/search?query=${query}`,
                token: null,
                params: {},
                value: {},
                platform: null,
            })
            setExperiencesFound(response)
        } catch (error) {
            message.warning('Ha ocurrido un error', 3)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        setSearchExperience(value)

        if (debounceTimeout) {
            clearTimeout(debounceTimeout)
        }

        if (value === '') {
            setExperiencesFound([])
        } else {
            const timeout = setTimeout(() => {
                fetchSearchResults(value)
            }, 100)

            setDebounceTimeout(timeout)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getDataViajeroIndex()
    }, [])

    useEffect(() => {
        if (searchExperience === '') {
            setExperiencesFound([])
        }
    }, [searchExperience])

    useEffect(() => {
        setSearchExperience('')
    }, [location])

    return (
        <ConfigProvider theme={darkMode ? 'dark' : 'light'}>
            <div
                className={`app ${darkMode ? 'dark-mode' : ''}`}
                style={{ height: '100vh' }}
            >
                <Layout style={{ height: '100%', margin: 0 }}>
                    <div
                        className={`d-none d-md-none d-lg-block ${collapsed && 'hidden'}`}
                    >
                        <Sider
                            width={'17rem'}
                            trigger={null}
                            collapsible
                            collapsed={collapsed}
                            breakpoint="lg"
                            collapsedWidth={'0'}
                            className="!bg-[#111111] h-screen border-double border-r-2 border-black !z-[1]"
                        >
                            <div className="fixed flex flex-col justify-center items-center pt-8">
                                <div className="flex self-start">
                                    <HomeButton />
                                </div>
                                {loading ? (
                                    <div className="flex flex-col gap-2 items-center justify-center">
                                        <SkeletonAvatar size={130} />
                                        <SkeletonInput />
                                    </div>
                                ) : (
                                    <div className="flex flex-col gap-2 items-center justify-center">
                                        <img
                                            alt={`Logo Tugo`}
                                            src={data?.picture}
                                            height={150}
                                            className="rounded-[50%] w-[130px] h-[130px]"
                                        />
                                        <h3 className="uppercase font-bold">
                                            {data?.name}
                                        </h3>
                                    </div>
                                )}
                                <div className="d-none d-md-none d-lg-block navbar-search_20">
                                    <Input
                                        bordered={false}
                                        size="large"
                                        prefix={<SearchOutlined />}
                                        placeholder="Surf..."
                                        value={searchExperience}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <SidebarIndex user={user} />
                            </div>
                            <Row className="btn_user fixed">
                                <Col>
                                    {user.logged === true ? (
                                        <MenuDesplegable
                                            collapsed={collapsed}
                                        />
                                    ) : (
                                        <div className="dropdown">
                                            <div
                                                className="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenu2"
                                            >
                                                <h4 className="bold text-center"></h4>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                <Col className="d-flex justify-content-center container">
                                    {user.logged === false ? (
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="flex items-center gap-2 rounded-lg bg-[#FE5000] p-[7px] text-[#3A1507] transition-colors duration-200 hover:bg-[#3A1507] hover:text-white"
                                                onClick={() =>
                                                    setShowLogin(true)
                                                }
                                            >
                                                <PiFingerprintBold className="icon_menu_20" />
                                                Iniciar sesión
                                            </button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {user.logged && <></>}
                                </Col>
                            </Row>
                        </Sider>
                    </div>
                    <Layout
                        style={{
                            height: '100%',
                            margin: 0,
                        }}
                        className="bg_content"
                    >
                        <Header
                            style={{
                                padding: 0,
                                backgroundColor: 'rgb(17, 17, 17)',
                                zIndex: '1',
                            }}
                        >
                            <Button
                                type="text"
                                icon={
                                    collapsed ? (
                                        <MenuUnfoldOutlined />
                                    ) : (
                                        <MenuFoldOutlined />
                                    )
                                }
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    color: '#fff',
                                }}
                            />
                        </Header>
                        <Content
                            style={
                                searchExperience !== ''
                                    ? {
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                      }
                                    : null
                            }
                        >
                            {searchExperience !== '' ? (
                                isLoading ? (
                                    <Spinner />
                                ) : experiencesFound.length === 0 ? (
                                    <h3 className="text-center">
                                        No se encontraron experiencias
                                    </h3>
                                ) : (
                                    <OwlCarousel
                                        className="owl-carousel"
                                        style={{
                                            paddingLeft: '1.5rem',
                                        }}
                                        loop={experiencesFound.length >= 5}
                                        margin={20}
                                        dots={true}
                                        responsive={{
                                            1200: {
                                                items: 4,
                                            },
                                            800: {
                                                items: 4,
                                            },
                                            650: {
                                                items: 3,
                                            },
                                            450: {
                                                items: 2,
                                            },
                                            300: {
                                                items: 2,
                                            },
                                        }}
                                    >
                                        {experiencesFound.map((experience) => (
                                            <Link
                                                to={`/experiencia/${experience.id}`}
                                                key={experience.id}
                                            >
                                                <div>
                                                    <Card className="pointer border-0 image04">
                                                        <Card.Img
                                                            src={
                                                                experience
                                                                    .pictures[0]
                                                                    ?.path
                                                            }
                                                            className="card-border"
                                                            style={{
                                                                height: '500px',
                                                            }}
                                                        />
                                                        <Card.ImgOverlay>
                                                            <Image
                                                                src={
                                                                    experience
                                                                        .user
                                                                        ?.infoExtra
                                                                        ?.picture ||
                                                                    experience
                                                                        .user
                                                                        .picture
                                                                }
                                                                roundedCircle
                                                                className="avatar"
                                                            />
                                                            <div className="card-bottom">
                                                                <h3 className="bold text-white card-titulo">
                                                                    {
                                                                        experience.name
                                                                    }
                                                                </h3>
                                                                <p className="text-white bold d-none d-md-none d-lg-block card-precio">
                                                                    <p>
                                                                        $
                                                                        {experience
                                                                            .quotes[0]
                                                                            ?.prices[0]
                                                                            ?.netPrice ||
                                                                            experience
                                                                                .quotes[0]
                                                                                ?.prices[0]
                                                                                ?.grossPrice}
                                                                    </p>{' '}
                                                                    por persona
                                                                </p>
                                                                <p className="place">
                                                                    <i className="icon-marker"></i>
                                                                    {'   '}
                                                                    {
                                                                        experience.direction
                                                                    }
                                                                </p>
                                                                <p className="text-white bold d-none d-md-none d-lg-block">
                                                                    <i className="icon-star orange"></i>
                                                                    {'   '}
                                                                    {
                                                                        experience.rating
                                                                    }
                                                                </p>
                                                            </div>
                                                        </Card.ImgOverlay>
                                                    </Card>
                                                </div>
                                            </Link>
                                        ))}
                                    </OwlCarousel>
                                )
                            ) : (
                                <Outlet context={{ dominios }} />
                            )}
                        </Content>
                    </Layout>
                </Layout>
            </div>
            <Modal
                centered
                title=""
                open={showLogin}
                onCancel={() => setShowLogin(false)}
                footer={[]}
            >
                <Login onClose={() => setShowLogin(false)} />
            </Modal>
        </ConfigProvider>
    )
}

export default ViajeroIndex
