import { Button, Tooltip } from 'antd'
import React from 'react'
import { ButtonsActionTable } from '../../../../Buttons/ButtonsActionTable'
import { TablaAnt } from '../../../../table/TablaAnt'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'

const TablaTiposTickets = ({ data, onEdit, onDelete }) => {
    const columns = [
        {
            title: (
                <div>
                    <text className="bold titulo_table">Tipo</text>
                </div>
            ),
            key: 'typeName',
            dataIndex: 'typeName',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Descripción</text>
                </div>
            ),
            key: 'description',
            dataIndex: 'description',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Precio</text>
                </div>
            ),
            key: 'price',
            dataIndex: 'price',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Prefijo</text>
                </div>
            ),
            key: 'prefix',
            dataIndex: 'prefix',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Acciones</text>
                </div>
            ),
            key: 'acciones',
            align: 'right',
            fixed: 'right',
            render: (_, value) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => onEdit(value)}
                    />
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => onDelete(value._id)}
                    />
                </>
            ),
        },
    ]
    return (
        <TablaAnt
            scrollX="max-contend"
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaTiposTickets
