import {
    Button,
    Drawer,
    Form,
    Input,
    Select,
    DatePicker,
    TimePicker,
    Upload,
} from 'antd'
import moment from 'moment'
import momenttz from 'moment-timezone'
import { PiMagicWandBold } from 'react-icons/pi'

const { TextArea } = Input
const { Option } = Select

const SidebarForm = ({
    title,
    buttonText,
    onCloseDrawer,
    open,
    form,
    onFinish,
    formValues,
    setFormValues,
    inputs,
    width,
    isLoading = false,
    uploadInput = [],
}) => {
    const renderInput = (input) => {
        switch (input.type) {
            case 'textArea':
                return (
                    <TextArea
                        placeholder={input.placeholder}
                        value={formValues[input.name]}
                        onChange={(e) =>
                            setFormValues({
                                ...formValues,
                                [input.name]: e.target.value,
                            })
                        }
                        disabled={input.disabled === 'true'}
                    />
                )
            case 'date':
                return (
                    <DatePicker
                        disabledDate={input.disabledDate}
                        placeholder={input.placeholder}
                        // value={formValues[input.name]}

                        value={
                            formValues[input.name]
                                ? moment(formValues[input.name])
                                : null
                        }
                        defaultValue={
                            input.defaultValue
                                ? moment(input.defaultValue)
                                : null
                        }
                        onChange={(date) =>
                            setFormValues({
                                ...formValues,
                                [input.name]: date,
                            })
                        }
                        disabled={input.disabled === 'true'}
                    />
                )

            case 'time':
                return (
                    <TimePicker
                        placeholder={input.placeholder}
                        value={formValues[input.name]}
                        onChange={(value) => {
                            console.log('valor que entra', value)
                            if (value) {
                                // Extraer solo la hora y minutos seleccionados
                                const selectedHour = value.hour()
                                const selectedMinute = value.minute()

                                // Crear un momento con la hora seleccionada y fijarlo en la zona horaria de El Salvador
                                const salvadorTime = momenttz.tz(
                                    {
                                        hour: selectedHour,
                                        minute: selectedMinute,
                                    },
                                    'America/El_Salvador'
                                )

                                console.log(
                                    'Hora fijada en El Salvador:',
                                    salvadorTime
                                )

                                // Guardar la hora en formato ISO (para enviarla al backend)
                                setFormValues({
                                    ...formValues,
                                    [input.name]: salvadorTime.toISOString(),
                                })
                            } else {
                                // Si no se selecciona una hora, limpiar el valor
                                setFormValues({
                                    ...formValues,
                                    [input.name]: null,
                                })
                            }
                        }}
                        format="h:mm a" // Formato de hora que se mostrará
                        disabled={input.disabled === 'true'}
                    />
                )

            case 'select':
                return (
                    <Select
                        mode={input.modeSelect}
                        placeholder={input.placeholder}
                        value={formValues[input.name]}
                        onChange={(value) =>
                            setFormValues({
                                ...formValues,
                                [input.name]: value,
                            })
                        }
                        disabled={input.disabled === 'true'}
                    >
                        {input?.items?.map((item, idx) => (
                            <Option key={idx} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                )
            default:
                return (
                    <Input
                        placeholder={input.placeholder}
                        type={input.type || 'text'}
                        value={formValues[input.name]}
                        onChange={(e) =>
                            setFormValues({
                                ...formValues,
                                [input.name]: e.target.value,
                            })
                        }
                        disabled={input.disabled === 'true'}
                    />
                )
        }
    }

    return (
        <Drawer
            title={title}
            placement="right"
            onClose={onCloseDrawer}
            visible={open}
            width={width && width}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                {inputs?.map((input, index) => (
                    <Form.Item
                        key={index}
                        label={input.label}
                        name={input.name}
                        rules={input.rules}
                    >
                        {renderInput(input)}
                    </Form.Item>
                ))}

                {uploadInput.length > 0 &&
                    uploadInput?.map(
                        ({ value, onChange, onClick, ref, text }) => (
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Upload
                                    fileList={value}
                                    value={value}
                                    onChange={onChange}
                                    beforeUpload={() => false}
                                    className="mb-3 mt-3"
                                >
                                    <Button
                                        onClick={onClick}
                                        style={{
                                            backgroundColor: 'white',
                                            boxShadow:
                                                '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            color: 'black',
                                        }}
                                    >
                                        {text}
                                    </Button>
                                </Upload>
                            </Form.Item>
                        )
                    )}
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button
                        disabled={isLoading}
                        type="primary"
                        htmlType="submit"
                        className="bold btn_crear"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <PiMagicWandBold className="icon_menu_20" />{' '}
                        {buttonText}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default SidebarForm
