// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-alert-item-content div {
    color: #575757;
}

.rs-drawer-content {
    position: absolute;
    background-color: #171718;
    outline: 0;
    width: 100%;
    height: 100%;
    box-shadow:
        0 4px 4px rgb(0 0 0 / 12%),
        0 0 10px rgb(0 0 0 / 6%);
}
`, "",{"version":3,"sources":["webpack://./src/componentes/Experiencias/style.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,YAAY;IAIZ;;gCAE4B;AAChC","sourcesContent":[".rs-alert-item-content div {\n    color: #575757;\n}\n\n.rs-drawer-content {\n    position: absolute;\n    background-color: #171718;\n    outline: 0;\n    width: 100%;\n    height: 100%;\n    -webkit-box-shadow:\n        0 4px 4px rgb(0 0 0 / 12%),\n        0 0 10px rgb(0 0 0 / 6%);\n    box-shadow:\n        0 4px 4px rgb(0 0 0 / 12%),\n        0 0 10px rgb(0 0 0 / 6%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
