import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import '../../../../assets/css/App.css'
import 'antd/dist/antd.css'
import { Avatar } from 'antd'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Icon } from 'rsuite'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
//Importar Recursos
import Maps from '../../../Experiencias/maps'
import axios from 'axios'
import { BASE_PATH } from 'componentes/constants'
import Imagen2 from '../../../../assets/images/icono.svg'
import { Map } from 'componentes/commons'

const PackageDetails = ({ paquete }) => {
    const [ubicacion, setUbicacion] = useState({
        lat: '37.7749295',
        lng: '-122.4194155',
    })
    const [usern, setusern] = useState([])
    const [experiences, setExperiences] = useState([])

    const getExperiences = async () => {
        try {
            const experienceIds = paquete.experiences.map((p) => p.experience)
            const experienceRequests = experienceIds.map((id) =>
                axios.get(`${BASE_PATH}/experiences/${id}`)
            )
            const responses = await Promise.all(experienceRequests)
            const experiencesData = responses.map((response) => response.data)
            setExperiences(experiencesData)
        } catch (error) {
            console.error('Error fetching experiences:', error)
        }
    }

    useEffect(() => {
        getExperiences()
    }, [paquete])
    console.log(experiences)
    console.log(paquete)
    return (
        <>
            {' '}
            {paquete && (
                <div className="exp-details">
                    <section>
                        <div className="bold">
                            <span style={{ fontSize: 20 }}>Inicio: </span>
                            <p>
                                {experiences.length > 0 ? (
                                    experiences[0].start
                                ) : (
                                    <></>
                                )}
                            </p>
                            <br />
                            <span style={{ fontSize: 20 }}>Final: </span>
                            <p>
                                {experiences.length > 0 &&
                                experiences.length > 1 ? (
                                    experiences[experiences.length - 1].end
                                ) : experiences.length === 1 ? (
                                    experiences[0].end
                                ) : (
                                    <></>
                                )}
                            </p>
                        </div>
                        <div className="container-sm d-block d-sm-none d-none d-sm-block d-md-none text-center">
                            <Row>
                                <Col className="text-center card4">
                                    <p className="bold orange">Cupo</p>
                                    <p className="bold">
                                        Hasta{' '}
                                        {/* {experiencia.length > 0 ? experiencia[0].quotas.max : null} */}
                                    </p>
                                    <br /> <p>personas </p>
                                </Col>
                                <Col className="text-center card4">
                                    <p className="bold orange">Duración</p>
                                    <p className="bold">
                                        {/* {experiencia.length > 0 ? experiencia[0].duration : null} */}
                                    </p>
                                    <br /> <p>horas </p>
                                </Col>
                                <Col className="text-center card4">
                                    <p className="bold orange">Precio</p>
                                    <Row>
                                        <Col xs={6} className="bold">
                                            <p>Adultos </p> <br /> $
                                            {paquete.grossPriceAdult}
                                        </Col>
                                        <Col xs={6} className="bold">
                                            <p>Niños</p> <br /> $
                                            {paquete.grossPriceChildren}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section>
                        <h3 className="bold">Incluye</h3>
                        <Masonry gutter={20}>
                            {experiences.map((item, index) => {
                                return (
                                    <Link
                                        key={item?.id}
                                        to={`/experiencia/${item?.id}`}
                                        state={{ package: true }}
                                    >
                                        <div>
                                            <Card className="image04">
                                                <Card.Img src={Imagen2} />
                                                <Card.ImgOverlay>
                                                    <div className="card-bottom">
                                                        <h3 className="bold text-white card-titulo">
                                                            {item.name}
                                                        </h3>
                                                        <p
                                                            className="text-white bold d-none d-md-none d-lg-block card-precio"
                                                            style={{
                                                                lineHeight:
                                                                    '18px',
                                                            }}
                                                        >
                                                            <text
                                                                className="orange__gradient heavy"
                                                                style={{
                                                                    fontSize:
                                                                        '25px',
                                                                }}
                                                            >
                                                                $
                                                                {
                                                                    item
                                                                        ?.quotes?.[0]
                                                                        ?.prices?.[0]
                                                                        ?.netPrice
                                                                }{' '}
                                                                <text
                                                                    className="lite"
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                    }}
                                                                >
                                                                    por persona
                                                                </text>
                                                            </text>
                                                        </p>
                                                        <p className="place">
                                                            {item?.description}
                                                        </p>
                                                    </div>
                                                </Card.ImgOverlay>
                                            </Card>
                                        </div>
                                    </Link>
                                )
                            })}
                        </Masonry>
                    </section>

                    <section>
                        <h3 className="bold">Lo que haremos</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: paquete.description.replace(
                                    /\n/g,
                                    '<br />'
                                ),
                            }}
                        ></p>{' '}
                    </section>

                    {experiences[0] && (
                        <section>
                            <h3 className="bold">Ubicación</h3>
                            <div style={{ height: '10rem' }}>
                                <Map
                                    location={{
                                        lat: experiences[0]?.lat,
                                        lng: experiences[0]?.long,
                                    }}
                                />
                            </div>
                        </section>
                    )}
                </div>
            )}
        </>
    )
}

export default PackageDetails
