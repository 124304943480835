import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_PATH } from '../../constants'
import { AuthContext } from '../../../auth/AuthContext'

import ExperienciaNewContent from '../../Anfitrion/Experiencias/ExperienciaNewContent'
import { Form } from 'antd'
import { MessageUtils } from 'utils'
import { Spinner } from 'componentes/commons'

export const ExperienciaEditar = () => {
    const { experienciaid } = useParams()
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [experiencia, setExperiencia] = useState([])
    const [formValues, setFormValues] = useState()
    const [ubicacion, setUbicacion] = useState(false)
    const [loader, setLoader] = useState(true)
    const [percent, setpercent] = useState(1)
    const [form] = Form.useForm()

    const Datos = (form, ubicacion) => {
        setUbicacion(ubicacion)
    }

    const Back = () => {
        navigate('/host/experiencia/_/' + experienciaid, { replace: true })
    }

    const getExperiencias = async () => {
        try {
            let res = await axios.get(
                `${BASE_PATH}/experiences/${experienciaid}`
            )
            if (res.status === 200) {
                let exp = res.data
                const values = {
                    name: exp.name,
                    description: exp.description,
                    start: exp.start,
                    end: exp.end,
                    extra: exp.extra.map((item) => {
                        return {
                            label: item.label || item.valor,
                            value: item.value || item.valor,
                            icon: item.icon,
                        }
                    }),
                    minQuotas: exp.quotas?.min,
                    maxQuotas: exp.quotas?.max,
                    direction: exp.direction,
                    duration: exp.duration.split(' ')[0],
                }
                setExperiencia(exp)
                setFormValues(values)
                form.setFieldsValue(values)
                setUbicacion({ lat: exp.lat, lng: exp.long })
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoader(false)
        }
    }

    const updateExp = async () => {
        try {
            setpercent(10)
            const experienciaUp = await axios.put(
                `${BASE_PATH}/experiences/${experienciaid}`,
                {
                    name: formValues.name,
                    description: formValues.description,
                    duration: `${formValues.duration} horas`,
                    direction: formValues.direction,
                    lat: ubicacion.lat,
                    long: ubicacion.lng || ubicacion.long,
                    quotas: {
                        min: formValues.minQuotas,
                        max: formValues.maxQuotas,
                    },
                    start: formValues.start,
                    end: formValues.end,
                    extra: formValues.extra,
                },

                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )
            if (experienciaUp.status === 200) {
                setpercent(99)
                MessageUtils.success(
                    'Se ha actualizado la experiencia correctamente'
                )
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar actualizar la experiencia'
            )
        }
    }

    useEffect(() => {
        getExperiencias()
    }, [])

    return (
        <div>
            <button
                type="button"
                style={{
                    position: 'absolute',
                    top: '25px',
                    left: '25px',
                }}
                className="back-window-navbar-drawer"
                onClick={Back}
            >
                <i className=" icon-left-arrow"></i>
            </button>
            {loader ? (
                <Spinner />
            ) : (
                <div className="steps-content pt-20">
                    <ExperienciaNewContent
                        edit={true}
                        form={form}
                        setNewForm={setFormValues}
                        newform={formValues}
                        newubicacion={ubicacion}
                        onFinish={updateExp}
                        buttonText={'Actualizar'}
                        nextContent={Datos}
                    />
                </div>
            )}
        </div>
    )
}
