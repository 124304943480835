import React from 'react'

const ScheduleCard = ({
    title,
    days,
    time,
    from,
    to,
    repeat,
    onSelect,
    onDelete,
    isSelected = false,
}) => {
    return (
        <article
            className="schedule-item schedule-item-padding"
            style={isSelected ? { borderColor: '#fe5000' } : null}
        >
            <div onClick={onSelect}>
                <header className="schedule-item-header">
                    <span>{title}</span>
                </header>
                <main className="schedule-item-content">
                    <span>
                        <b>Días:</b> {days}
                    </span>
                    <br />
                    <span>
                        <b>Hora:</b> {time}
                    </span>
                    <br />
                    <span>
                        <b>Desde:</b> {from}
                    </span>
                    <br />
                    <span>
                        <b>Hasta:</b> {to}
                    </span>
                    <br />
                    <span className="schedule-item-content-repeat">
                        Se repite cada {repeat}
                    </span>
                </main>
            </div>

            <button className="bg-orange schedule-item-btn" onClick={onDelete}>
                <i className="icon-cancel"></i>
            </button>
        </article>
    )
}

ScheduleCard.defaultProps = {
    title: '',
    days: '',
    time: '',
    from: '',
    to: '',
    repeat: '',
    id: '',
    onDelete: () => {},
    onSelect: () => {},
}

export default ScheduleCard
