import React from 'react'
import { TablaAnt } from '../../../table/TablaAnt'

export const TablaIngresos = ({
  data
}) => {
  const columns = [
    {
      title: <div><p className='bold titulo_table'>Fecha</p></div>,
      key: 'fecha',
      dataIndex: 'fecha',
      sorter: false
    },
    {
      title: <div><p className='bold titulo_table'>Servicio</p></div>,
      key: 'servicio',
      dataIndex: 'servicio',
      sorter: false
    },
    {
      title: <div><p className='bold titulo_table'>Cantidad</p></div>,
      key: 'cantidad',
      dataIndex: 'cantidad',
      sorter: false
    },
    {
      title: <div><p className='bold titulo_table'>Costo Unitario</p></div>,
      key: 'costo_unitario',
      dataIndex: 'costo_unitario',
      sorter: false
    },
    {
      title: <div><p className='bold titulo_table'>Subtotal</p></div>,
      key: 'subtotal',
      dataIndex: 'subtotal',
      sorter: false
    },
  ]
  return (
    <TablaAnt
      scrollX='max-contend'
      columns={columns}
      data={data}
      loading={false}
      isCheckCell={false}
    />
  )
}