import { Avatar } from 'antd'
import React from 'react'
import { TablaAnt } from '../../../../table/TablaAnt'

const TablaPlanes = ({
    data,
    onQR
}) => {
  
    const columns = [
        {
            title: '',
            key: 'qrCode',
            dataIndex: 'qrCode',
            render: (value) => (
                <Avatar onClick={() => onQR(value)} size={64} shape="square" src={value} />
            ),
        },
        {
            title: <div><text className='bold titulo_table'>Tipo</text></div>,
            key: 'ticketType',
            dataIndex: 'ticketType',
            sorter: false
        },
        {
            title: <div><text className='bold titulo_table'>Numero</text></div>,
            key: 'ticketNumber',
            dataIndex: 'ticketNumber',
            sorter: false
        }
    ]
  return (
    <TablaAnt
        scrollX='max-contend'
        columns={columns}
        data={data}
        loading={false}
        isCheckCell={false}
    />
  )
}

export default TablaPlanes
