/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { Modal, Button, Alert, Input, Dropdown } from 'rsuite'
import axios from 'axios'
import { AuthContext } from '../../../auth/AuthContext'
import { types } from '../../../types/types'

import { BASE_PATH } from '../../constants'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PiChartLineUpBold, PiDoorOpenBold, PiUser } from 'react-icons/pi'
import { RiSettings3Line } from 'react-icons/ri'
import { Avatar } from 'antd'

export const MenuDesplegable = ({ collapsed = false }) => {
    const navigate = useNavigate()
    const { dispatch } = useContext(AuthContext)
    const { user } = useContext(AuthContext)
    const [openModal, setOpenModal] = useState(false)
    const [picture, setPicture] = useState('')
    const [name, setname] = useState('')
    const [petition, setpetition] = useState('')
    const { pathname } = useLocation()
    console.log(pathname)
    const rol = user?.data?.user?.role
    const menuTop = () => {
        let top
        if (pathname === '/explora') {
            top = '-5%'
        } else if (pathname !== '/') {
            if (user?.data?.user?.role !== 'admin') {
                top = '-80%'
            } else {
                top = '-110%'
            }
        } else {
            top = 0
        }
        return top
    }
    // const Configuraciones = () => {
    //   if (rol === "Viajero") {
    //     navigate("/configuraciones", { replace: true });
    //   } else {
    //     navigate("/host/configuraciones", { replace: true });
    //   }
    // };

    // const changeUser = async () => {
    //   if (user.data.user.rolRequest) {
    //     let switchh = 0;
    //     if (rol === "Viajero") {
    //       switchh = 1;
    //     }
    //     let headers = {
    //       Authorization: `Bearer ${user.data.token}`,
    //       "user-switch": switchh,
    //     };
    //     let change = await axios.post(
    //       `${BASE_PATH}/users/switchUser/`,
    //       {},
    //       { headers: headers }
    //     );
    //     //console.log(change);
    //     if (change.status === 200) {
    //       dispatch({
    //         type: types.login,
    //         payload: {
    //           data: {
    //             token: user.data.token,
    //             user: change.data.user,
    //           },
    //         },
    //       });
    //       if (change.data.user.state === 1) {
    //         navigate(`/host/_/${user.data.user.id}`, { replace: true });
    //       } else if (change.data.user.state === 0) {
    //         navigate("/", { replace: true });
    //       }
    //     }
    //   } else if (user.data.user.rolRequest === undefined) {
    //     setOpenModal(true);
    //   } else if (user.data.user.rolRequest === false) {
    //     Alert.info("Su solicitud está siendo evaluada.", 2000);
    //   }
    // };

    const setPetition = async () => {
        if (petition.length > 10) {
            let request = await axios.post(
                `${BASE_PATH}/users/switch-request/`,
                {
                    email: 'josue9aparicio@gmail.com',
                    reason: petition,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )
            if (request.status === 200) {
                Alert.success('Se ha enviado la solicitud!', 2000)
                setOpenModal(false)
                //console.log(request);
                dispatch({ type: types.login, payload: request })
            } else {
                Alert.error('Intentalo de nuevo.', 2000)
            }
        } else {
            Alert.info('Agrega un texto mas largo.', 2000)
        }
    }

    const cerrar = () => {
        navigate('/', { replace: true })
        dispatch({ type: types.logout })
    }

    useEffect(() => {
        if (user.data.user) {
            if (user.data.user.state === 1) {
                setname(user.data.user.infoExtra.name)
                user.data.user.infoExtra
                    ? user.data.user.infoExtra.picture
                        ? setPicture(user.data.user.infoExtra.picture)
                        : setPicture(user.data.user.picture)
                    : setPicture(user.data.user.picture)
            } else if (!user.data.user.state || user.data.user.state === 0) {
                setPicture(user.data.user.picture)
                setname(user.data.user.name)
            }
        }
    }, [user])

    const iconClass = 'fill-primary mr-2 h-6 w-6'
    console.log('Rol desde el menudesplegable', rol)
    const menuItems = [
        {
            type: 'link',
            name:
                rol === 'user' || rol === 'admin'
                    ? 'Configuraciones'
                    : 'Perfil',
            icon: <RiSettings3Line className={iconClass} />,
            action:
                rol === 'user' || rol === 'admin'
                    ? '/configuraciones'
                    : `/company/${user?.data?.platform?.domain}/perfil`,
            rol: [], // si el array esta vacio es para todos los users
        },
        {
            type: 'link',
            name: 'Dashboard',
            icon: <PiChartLineUpBold className={iconClass} />,
            action:
                rol === 'admin'
                    ? '/_/dashboard'
                    : rol === 'company' ||
                        rol === 'merchant' ||
                        rol === 'reseller' ||
                        rol === 'partner'
                      ? `/company/${user?.data?.platform?.domain}/dashboard`
                      : null,
            rol: ['admin', 'company', 'merchant', 'partner'],
        },
        {
            type: 'link',
            name: 'Cambiar Rol',
            icon: <PiUser className={iconClass} />,
            action: '',
            rol: ['admin'],
        },
        {
            type: 'button',
            name: 'Cerrar Sesión',
            icon: <PiDoorOpenBold className={iconClass} />,
            action: cerrar,
            rol: [],
        },
    ]

    const dropdownItems = menuItems.map((menuItem, index) =>
        menuItem.rol.includes(user?.data?.user?.role) ||
        menuItem.rol.length === 0
            ? {
                  key: `${index}`,
                  label:
                      menuItem.type === 'link' ? (
                          <Link
                              className="font-bold flex gap-1 items-center px-2 py-1 text-slate-500 hover:text-primary"
                              to={menuItem.action}
                          >
                              {menuItem.icon}
                              {menuItem.name}
                          </Link>
                      ) : (
                          <button
                              className="font-bold flex gap-1 items-center px-2 py-1"
                              onClick={menuItem.action}
                          >
                              {menuItem.icon}
                              {menuItem.name}
                          </button>
                      ),
              }
            : null
    )

    return (
        <>
            {/* <Dropdown
        menu={{
          dropdownItems,
        }}
        placement="bottomLeft"
      >
        <button className="dropdown-avatar image04">
          <Avatar size={80} src={picture} />
        </button>
      </Dropdown> */}
            <div className={`flex gap-3 items-center ${collapsed && 'hidden'}`}>
                <Dropdown
                    menuStyle={{ top: menuTop() }}
                    placement="rightStart"
                    renderTitle={() => {
                        return (
                            <div className="flex gap-3 ">
                                <button className={`dropdown-avatar image04`}>
                                    <Avatar size={80} src={picture} />
                                </button>
                            </div>
                        )
                    }}
                >
                    {menuItems.map((item, index) =>
                        item.rol.includes(user?.data?.user?.role) ||
                        item.rol.length === 0 ? (
                            item.type === 'link' ? (
                                <Dropdown.Item>
                                    <Link
                                        key={index}
                                        className="font-bold flex gap-1 items-center px-2 py-1 text-slate-500 hover:text-primary"
                                        to={item.action}
                                    >
                                        {item.icon}
                                        {item.name}
                                    </Link>
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item>
                                    <button
                                        key={index}
                                        className="font-bold flex gap-1 items-center px-2 py-1 text-slate-500 hover:text-primary"
                                        onClick={item.action}
                                    >
                                        {item.icon}
                                        {item.name}
                                    </button>
                                </Dropdown.Item>
                            )
                        ) : null
                    )}
                </Dropdown>
                <div className="flex flex-col">
                    <h5 className="bold title_nombre">
                        {name ? name : user.data.user.name}{' '}
                    </h5>
                    {user?.data?.user?.alias ? (
                        <p style={{ fontSize: '15px', color: 'grey' }}>
                            @{user?.data?.user?.alias}
                        </p>
                    ) : null}
                </div>
            </div>
            <Modal show={openModal} onHide={() => setOpenModal(false)}>
                <Modal.Header>
                    <Modal.Title>
                        Solicitud de permisos de Anfitrion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontSize: '12px' }}>
                        Describe brevemente el motivo que tienes para ser
                        Anfitrion en Tugo!, recuerda nuestro primer filtro será
                        tienes algún Certificado para ser Guía Local o de tu
                        especialidad
                    </p>

                    <Input
                        as="textarea"
                        rows={3}
                        placeholder="Breve motivo..."
                        onChange={(value) => setpetition(value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={setPetition}
                        style={{ background: '#fe5000' }}
                    >
                        Enviar
                    </Button>
                    <Button
                        onClick={() => setOpenModal(false)}
                        appearance="subtle"
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
