import React, { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";
import image from "../assets/images/viajero.png";
import Viajero from "./Navbars/Viajero";
import Anfitrion from "./Navbars/Anfitrión";
import { Button } from "antd";
import { BackwardOutlined } from "@ant-design/icons";
import { PiBackspace } from "react-icons/pi";
import { Link } from "react-router-dom";

const NotFound = () => {
  const { user } = useContext(AuthContext);
  // let match = useRouteMatch();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "8px",
        paddingBottom: "24px",
      }}
    >
      {user.logged && user.data.user.role !== "user" ? (
        <Anfitrion />
      ) : (
        <Viajero />
      )}

      <div className="notFound_page">
        <div className="column1">
          <h1 className="notFound_text">404</h1>
          <h4 className="notFound_text">Página no encontrada</h4>
        </div>
        <div className="column2">
          <img
            src={image}
            className="notFound_image"
            alt="Viajero en busca de experiencias"
          />
        </div>
      </div>
      <Link to={-1} size="large" type="link">
        Volver atrás
      </Link>
    </div>
  );
};

export default NotFound;
