import { Button, Drawer, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { deleteData, getData, postData, putData } from '../../../../services'
import { ConstructorFormAntd } from '../../../Forms/ConstructorFormAnt'
import { OPTION_TYPE_FORM } from '../../../InputForm/InputForm'
import Titulo from '../TituloTIposTickets'
import TablaTiposTickets from './items/TablaTiposTickets'
import { PiMagicWandBold } from 'react-icons/pi'
import { useOutletContext } from 'react-router-dom'
import PaginationCom from 'componentes/pagination/PaginationCom'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import { MessageUtils } from 'utils'

const initForm = {
    price: null,
    salesPrice: null,
    typeName: '',
    prefix: '',
    description: '',
}

const TiposTickets = () => {
    const { user, dominio } = useOutletContext()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tickets, setTikects] = useState([])
    const [page, setPage] = useState(1)
    const [meta, setMeta] = useState([])
    const [action, setAction] = useState('new')
    const [form] = Form.useForm()
    const [formValues, setFormValues] = useState(initForm)
    const [selectedTicketId, setSelectedTicketId] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    const inicioComponente = async () => {
        try {
            setLoading(true)
            const responseData = await getData({
                path: `partners/tickets/types/index?page=${page}&limit=10&isPaginate=true`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            setTikects(responseData[0].result)
            setMeta(responseData)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        inicioComponente()
    }, [])

    useEffect(() => {
        inicioComponente()
    }, [page])

    const onFinish = async (values) => {
        setFormValues(values)
        try {
            const response = await postData({
                path: `partners/tickets/types`,
                token: user.logged && user.data.token,
                params: {},
                value: formValues,
            })
            onCloseDrawer()
            inicioComponente()
            MessageUtils.success('Tipo de ticket creado con éxito')
        } catch (error) {
            MessageUtils.error('Ocurrió un error al crear un tipo de ticket')
        } finally {
            setLoading(false)
        }
    }

    const updateTicketType = async () => {
        try {
            setIsLoadingAction(true)
            const response = await putData({
                path: `partners/tickets/types`,
                id: selectedTicketId,
                token: user.logged && user.data.token,
                params: {},
                value: {
                    ...formValues,
                },
            })
            if (response) {
                MessageUtils.success('Tipo de ticket modificado con éxito')
                inicioComponente()
                onCloseDrawer()
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar modificar el tipo de ticket'
                )
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al intentar modificar el tipo de ticket'
            )
        } finally {
            setIsLoadingAction(false)
        }
    }

    const onEdit = (data) => {
        setSelectedTicketId(data._id)
        const values = {
            price: data.price,
            salesPrice: data.salesPrice,
            typeName: data.typeName,
            prefix: data.prefix,
            description: data.description,
        }
        setFormValues(values)
        form.setFieldsValue(values)
        setOpen(true)
        setAction('edit')
    }

    const onCloseDrawer = () => {
        setFormValues(initForm)
        form.resetFields()
        setTimeout(() => {
            setAction('new')
        }, 300)
        setOpen(false)
    }

    const onDeleteButton = (id) => {
        setSelectedTicketId(id)
        setOpenModal(true)
    }

    const onCancelModal = () => {
        setSelectedTicketId('')
        setOpenModal(false)
    }

    const deleteTicketType = async () => {
        try {
            setIsLoadingAction(true)
            const response = await deleteData({
                path: `partners/tickets/types`,
                id: selectedTicketId,
                token: user.logged && user.data.token,
            })

            if (response || response === '') {
                MessageUtils.success('Tipo de ticket eliminado con éxito')
                inicioComponente()
                setOpenModal(false)
            } else {
                MessageUtils.error(
                    'Ha ocurrido un error al intentar eliminar el tipo de ticket'
                )
            }
        } catch (error) {
            MessageUtils.error(
                'Ha ocurrido un error al intentar eliminar el tipo de ticket'
            )
        } finally {
            setIsLoadingAction(false)
        }
    }

    return (
        <div>
            <Titulo
                dominio={dominio}
                titulo={'Tipos de Tickets'}
                tituloBoton={'Crear Tipo'}
                onAcccion={() => setOpen(true)}
            />
            <div>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <TablaTiposTickets
                            data={tickets.map((item) => {
                                return {
                                    ...item,
                                    price: item?.price.toFixed(2),
                                }
                            })}
                            onEdit={onEdit}
                            onDelete={onDeleteButton}
                        />
                        <PaginationCom
                            page={page}
                            meta={meta}
                            setPage={(value) => setPage(value)}
                        />
                    </>
                )}
            </div>
            <SidebarForm
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                form={form}
                formValues={formValues}
                open={open}
                isLoading={isLoadingAction}
                onFinish={action === 'new' ? onFinish : updateTicketType}
                setFormValues={setFormValues}
                title={
                    action === 'new'
                        ? 'Crear tipo Ticket'
                        : 'Editar Tipo Ticket'
                }
                onCloseDrawer={onCloseDrawer}
                inputs={[
                    {
                        label: 'Precio',
                        name: 'price',
                        rules: [{ required: true, message: 'Precio' }],
                        type: 'number',
                    },
                    {
                        label: 'Precio Salida',
                        name: 'salesPrice',
                        type: 'number',
                        rules: [
                            {
                                required: true,
                                message: 'Precio Salida',
                            },
                        ],
                    },
                    {
                        label: 'Nombre',
                        name: 'typeName',
                        rules: [{ required: true, message: 'Nombre' }],
                    },
                    {
                        label: 'Prefijo',
                        name: 'prefix',
                        rules: [{ required: true, message: 'Prefijo' }],
                    },
                    {
                        label: 'Descripción',
                        name: 'description',
                        type: OPTION_TYPE_FORM.textArea,
                        rules: [{ required: true, message: 'Descripción' }],
                    },
                ]}
            />
            <CancelConfirmModal
                loading={loading}
                onCancelModal={onCancelModal}
                onOk={deleteTicketType}
                openModal={openModal}
                strongText={
                    tickets.find((item) => item._id === selectedTicketId)
                        ?.typeName
                }
                text={`¿Estás seguro de que deseas eliminar el siguiente tipo de ticket`}
                title={'Eliminar Tipo de ticket'}
            />
        </div>
    )
}

export default TiposTickets
