import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  loading: false,
  error: null,

}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    fetchCompanyLoading(state, action) {
      state.loading = action.payload;
    },
    fetchCompanySuccess: (state, action) => {
      state.data = action.payload
    },
    fetchCompanyFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  }
})

export const { fetchCompanyLoading, fetchCompanySuccess, fetchCompanyFailure } = companySlice.actions
export default companySlice.reducer