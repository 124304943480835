import { Avatar, Col, Drawer, Row } from 'antd'
import React from 'react'
import Galeria from '../../../../Experiencias/ExperienciaGaleria'

const Experiencia = ({
    openExperiencia,
    close,
    experiencia
}) => {
    return (
        <Drawer
            width={700}
            title={<text className='bold titulo_table'>Experiencia</text>}
            placement="right"
            onClose={() => close()}
            visible={openExperiencia}
        >
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col md={8} sm={12} xs={12}>
                    <Avatar shape="square" size={200} src={experiencia?.picture} />
                </Col>
                <Col md={16} sm={12} xs={12} >
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col md={24} sm={24} xs={24}>
                            <h4 style={{ marginBottom: '0px' }}>{experiencia?.name}</h4>
                        </Col>
                        <Col md={12} sm={12} xs={12}>
                            <text className='bold titulo_table'>Comienza</text>
                            <h5 style={{ marginBottom: '0px' }}>{experiencia?.start}</h5>
                        </Col>
                        <Col md={12} sm={12} xs={12}>
                            <text className='bold titulo_table'>Termina</text>
                            <h5 style={{ marginBottom: '0px' }}>{experiencia?.end}</h5>
                        </Col>
                    </Row>
                </Col>

                <Col md={24} sm={24} xs={24} style={{marginBottom: '10px', marginTop: '10px'}}>
                    <text className='bold titulo_table' >Galeria</text>
                </Col>

                <Col md={24} sm={24} xs={24}>
                    <Galeria photos={experiencia?.pictures.map((item) => {
                        return {
                            ...item,
                            url: item.path
                        }
                    })} />
                </Col>
            </Row>
        </Drawer>
    )
}

export default Experiencia
