import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_PATH } from '../constants'
import { Grid, Row } from 'rsuite'
import { Skeleton } from 'antd'
import {
    DaysInMonth,
    daysOfWeekObject,
    formatToElSalvadorTime,
    getAvailableMonths,
    isIncludedDayInFirstWeek,
    monthsInYear,
    setLeftZeroInNumber,
    setTimeInDate,
} from '../Anfitrion/Experiencias/schedules/utils'
import MonthCard from './schedules/month'
import ScheduleCard from './schedules/scheduleCard'
import moment from 'moment-timezone'

const scheduleSelectInit = { id: null, date: null, time: null }

const ExperienciaCalendar = ({ setSchedule, experienceId }) => {
    const [schedules, setschedules] = useState([])
    const [showSelect, setshowSelect] = useState(0)
    const [loading, setloading] = useState(true)
    const [availableMonths, setAvailableMonths] = useState([])
    const [availableSchedules, setAvailableSchedules] = useState([])
    const [scheduleSelected, setscheduleSelected] = useState(null)
    const [filterSchedules, setfilterSchedules] = useState({
        year: null,
        month: null,
        week: null,
    })

    const backSelect = () => {
        if (scheduleSelected) {
            setSchedule(scheduleSelectInit)
            setscheduleSelected(null)
        }

        setshowSelect(showSelect - 1)
    }

    const onSelectSchedule = (schedule, index, isSelected) => {
        let newArr = [
            ...availableSchedules.map((el) => {
                return {
                    ...el,
                    isSelected: false,
                }
            }),
        ]

        newArr[index] = { ...newArr[index], isSelected }
        setAvailableSchedules(newArr)

        if (!isSelected) {
            setSchedule(scheduleSelectInit)
            setscheduleSelected(null)
        } else {
            setscheduleSelected(schedule)
            setSchedule({
                id: schedule.id,
                date: schedule.date,
                time: schedule.time,
            })
        }
    }

    // const selectMonth = ({ year, month }) => {
    //     setloading(true)
    //     setshowSelect(1)
    //     setAvailableSchedules([])

    //     const lastMonthDay = DaysInMonth(year, month)
    //     const initMonthDate = new Date(year, month, 0)
    //     const lastMonthDate = new Date(year, month, lastMonthDay)

    //     let currentMonthDate = initMonthDate
    //     const daysInSelectMonth = []

    //     while (currentMonthDate.getTime() < lastMonthDate.getTime()) {
    //         let newCurrentDay = currentMonthDate.setDate(
    //             currentMonthDate.getDate() + 1
    //         )
    //         let newCurrentMonthDate = new Date(newCurrentDay)
    //         daysInSelectMonth.push(newCurrentMonthDate)
    //     }

    //     lastMonthDate.setHours(23)
    //     lastMonthDate.setMinutes(59)

    //     daysInSelectMonth[daysInSelectMonth.length - 1] = lastMonthDate
    //     console.log(schedules)
    //     const schedulesInSelectMonth = schedules.reduce((acc, current) => {
    //         const { date, dueDate, periodicity, id, start } = current
    //         const { days, repeat } = periodicity

    //         const initScheduleDate = new Date(date)
    //         const dueScheduleDate = new Date(dueDate)
    //         console.log(initScheduleDate)
    //         console.log(dueScheduleDate)
    //         initScheduleDate.setHours(0)
    //         initScheduleDate.setMinutes(0)
    //         dueScheduleDate.setHours(23)
    //         dueScheduleDate.setMinutes(59)

    //         console.log(initScheduleDate)
    //         console.log(dueScheduleDate)

    //         console.log(daysInSelectMonth)
    //         const daysAvailablesInMonth = daysInSelectMonth
    //             .filter((dayDate) => {
    //                 const currentDayDateTime = new Date(dayDate).getTime()

    //                 return (
    //                     currentDayDateTime >= initScheduleDate.getTime() &&
    //                     currentDayDateTime <= dueScheduleDate.getTime()
    //                 )
    //             })
    //             .filter((dayAvailable) =>
    //                 days.includes(new Date(dayAvailable).getDay())
    //             )
    //         console.log(daysAvailablesInMonth)
    //         const integrateSchedules = []

    //         for (const dayAvailable of daysAvailablesInMonth) {
    //             if (repeat === 'monthly') {
    //                 const isFisrtWeek = isIncludedDayInFirstWeek(
    //                     new Date(dayAvailable).getDate()
    //                 )

    //                 if (isFisrtWeek) {
    //                     integrateSchedules.push({
    //                         id,
    //                         date: new Date(setTimeInDate(start, dayAvailable)),
    //                         time: start,
    //                         repeat,
    //                         isSelected: false,
    //                     })
    //                 }
    //             } else {
    //                 integrateSchedules.push({
    //                     id,
    //                     // Ver que pasa aca con el setTimeInDate
    //                     date: new Date(setTimeInDate(start, dayAvailable)),
    //                     time: start,
    //                     repeat,
    //                     isSelected: false,
    //                 })
    //             }
    //         }

    //         return acc.concat(integrateSchedules)
    //     }, [])
    //     console.log(schedulesInSelectMonth)
    //     setfilterSchedules({ ...filterSchedules, month, year })
    //     const sortedSchedulesInSelectMonth = schedulesInSelectMonth.sort(
    //         (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    //     )
    //     console.log(sortedSchedulesInSelectMonth)
    //     setAvailableSchedules(sortedSchedulesInSelectMonth)
    //     setloading(false)
    // }

    // const selectMonth = ({ year, month }) => {
    //     setloading(true)
    //     setshowSelect(1)
    //     setAvailableSchedules([])

    //     const lastMonthDay = DaysInMonth(year, month)
    //     const initMonthDate = new Date(year, month, 1)
    //     const lastMonthDate = new Date(year, month, lastMonthDay)
    //     console.log(initMonthDate)
    //     console.log(lastMonthDate)
    //     let currentMonthDate = new Date(initMonthDate)
    //     const daysInSelectMonth = []

    //     while (currentMonthDate <= lastMonthDate) {
    //         const elSalvadorTime = formatToElSalvadorTime(currentMonthDate)
    //         daysInSelectMonth.push(new Date(elSalvadorTime))
    //         currentMonthDate.setDate(currentMonthDate.getDate() + 1) // Incrementar la fecha
    //     }

    //     lastMonthDate.setHours(23)
    //     lastMonthDate.setMinutes(59)

    //     daysInSelectMonth[daysInSelectMonth.length - 1] = lastMonthDate

    //     console.log(schedules)
    //     const schedulesInSelectMonth = schedules.reduce((acc, current) => {
    //         const { date, dueDate, periodicity, id, start } = current
    //         const { days, repeat } = periodicity

    //         const initScheduleDate = new Date(formatToElSalvadorTime(date))
    //         const dueScheduleDate = new Date(formatToElSalvadorTime(dueDate))
    //         initScheduleDate.setHours(0)
    //         initScheduleDate.setMinutes(0)
    //         dueScheduleDate.setHours(23)
    //         dueScheduleDate.setMinutes(59)

    //         const daysAvailablesInMonth = daysInSelectMonth
    //             .filter((dayDate) => {
    //                 const currentDayDateTime = new Date(dayDate).getTime()
    //                 return (
    //                     currentDayDateTime >= initScheduleDate.getTime() &&
    //                     currentDayDateTime <= dueScheduleDate.getTime()
    //                 )
    //             })
    //             .filter((dayAvailable) =>
    //                 days.includes(new Date(dayAvailable).getDay())
    //             )

    //         const integrateSchedules = []

    //         for (const dayAvailable of daysAvailablesInMonth) {
    //             const dayAvailableElSalvador =
    //                 formatToElSalvadorTime(dayAvailable)

    //             if (repeat === 'monthly') {
    //                 const isFirstWeek = isIncludedDayInFirstWeek(
    //                     new Date(dayAvailableElSalvador).getDate()
    //                 )

    //                 if (isFirstWeek) {
    //                     integrateSchedules.push({
    //                         id,
    //                         date: setTimeInDate(
    //                             start,
    //                             new Date(dayAvailableElSalvador)
    //                         ), // Usar setTimeInDate
    //                         time: start,
    //                         repeat,
    //                         isSelected: false,
    //                     })
    //                 }
    //             } else {
    //                 integrateSchedules.push({
    //                     id,
    //                     date: setTimeInDate(
    //                         start,
    //                         new Date(dayAvailableElSalvador)
    //                     ),
    //                     time: start,
    //                     repeat,
    //                     isSelected: false,
    //                 })
    //             }
    //         }

    //         return acc.concat(integrateSchedules)
    //     }, [])

    //     console.log(schedulesInSelectMonth)
    //     setfilterSchedules({ ...filterSchedules, month, year })

    //     const sortedSchedulesInSelectMonth = schedulesInSelectMonth.sort(
    //         (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    //     )

    //     console.log(sortedSchedulesInSelectMonth)
    //     setAvailableSchedules(sortedSchedulesInSelectMonth)
    //     setloading(false)
    // }

    const selectMonth = ({ year, month }) => {
        setloading(true)
        setshowSelect(1)
        setAvailableSchedules([])

        const lastMonthDay = DaysInMonth(year, month)
        const initMonthDate = moment.tz([year, month, 1], 'America/El_Salvador') // Usamos moment con zona horaria de El Salvador
        const lastMonthDate = moment.tz(
            [year, month, lastMonthDay],
            'America/El_Salvador'
        ) // Último día del mes en El Salvador

        let currentMonthDate = moment(initMonthDate) // Copia la fecha inicial con timezone de El Salvador
        const daysInSelectMonth = []

        while (currentMonthDate.isSameOrBefore(lastMonthDate, 'day')) {
            daysInSelectMonth.push(currentMonthDate.clone()) // Clona la fecha para no modificar la original
            currentMonthDate.add(1, 'days') // Incrementa la fecha en un día
        }

        // Ajustar el último día del mes
        lastMonthDate.set({ hour: 23, minute: 59 })

        const schedulesInSelectMonth = schedules.reduce((acc, current) => {
            const { date, dueDate, periodicity, id, start } = current
            const { days, repeat } = periodicity

            const initScheduleDate = moment
                .tz(date, 'America/El_Salvador')
                .startOf('day')
            const dueScheduleDate = moment
                .tz(dueDate, 'America/El_Salvador')
                .endOf('day')

            const daysAvailablesInMonth = daysInSelectMonth
                .filter((dayDate) => {
                    return dayDate.isBetween(
                        initScheduleDate,
                        dueScheduleDate,
                        'day',
                        '[]'
                    )
                })
                .filter((dayAvailable) => {
                    return days.includes(dayAvailable.day()) // 0 para domingo
                })

            const integrateSchedules = []

            for (const dayAvailable of daysAvailablesInMonth) {
                if (repeat === 'monthly') {
                    const isFirstWeek = isIncludedDayInFirstWeek(
                        dayAvailable.date()
                    )
                    if (isFirstWeek) {
                        integrateSchedules.push({
                            id,
                            date: moment(dayAvailable)
                                .set({
                                    hour: moment
                                        .tz(start, 'America/El_Salvador')
                                        .hours(),
                                    minute: moment
                                        .tz(start, 'America/El_Salvador')
                                        .minutes(),
                                })
                                .toDate(),
                            time: start,
                            repeat,
                            isSelected: false,
                        })
                    }
                } else {
                    integrateSchedules.push({
                        id,
                        date: moment(dayAvailable)
                            .set({
                                hour: moment
                                    .tz(start, 'America/El_Salvador')
                                    .hours(),
                                minute: moment
                                    .tz(start, 'America/El_Salvador')
                                    .minutes(),
                            })
                            .toDate(),
                        time: start,
                        repeat,
                        isSelected: false,
                    })
                }
            }

            return acc.concat(integrateSchedules)
        }, [])

        setfilterSchedules({ ...filterSchedules, month, year })

        const sortedSchedulesInSelectMonth = schedulesInSelectMonth.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )
        console.log(sortedSchedulesInSelectMonth)
        setAvailableSchedules(sortedSchedulesInSelectMonth)
        setloading(false)
    }

    const getSchedules = async () => {
        try {
            let res = await axios.get(
                `${BASE_PATH}/schedules?experience=${experienceId}`
            )
            if (res.status === 200) {
                const currentDate = new Date().getTime()
                const filterAvailableDates = res.data.rows.filter(
                    ({ dueDate, isEnabled }) =>
                        dueDate &&
                        new Date(dueDate).getTime() >= currentDate &&
                        isEnabled
                )
                console.log(
                    'Lo que se guarda en schedules',
                    filterAvailableDates
                )
                setschedules(filterAvailableDates)
                setloading(false)
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        setAvailableMonths(getAvailableMonths())
        getSchedules()
    }, [])

    return (
        <Grid fluid className="container-sm">
            <nav className="navbar-titles navbar-light d-flex align-items-center"></nav>

            <Row
                className="calendar container-fluid"
                style={{ paddingTop: '5rem' }}
            >
                <text className="heavy text-titulos-reserva orange__gradient">
                    Escoge tu fecha
                </text>
                <div className="schedule-page-subtitlex">
                    {showSelect > 0 && (
                        <button onClick={backSelect} className="back-window">
                            <i className=" icon-left-arrow"></i>
                        </button>
                    )}
                    <text
                        className="bold orange"
                        style={{ marginLeft: '1rem' }}
                    >
                        {showSelect === 0 && ''}

                        {showSelect === 1 &&
                            `¡${monthsInYear[filterSchedules.month]}, ${filterSchedules.year}!`}
                    </text>
                </div>

                <div
                    style={{ marginBottom: '15rem', textAlign: 'center' }}
                    className="justify-content-center"
                >
                    {loading && (
                        <div className="schedules-page-content-user-grid">
                            {[1, 2, 2, 3, 4, 5].map((_, index) => (
                                <article
                                    className="schedule-item-loader"
                                    key={index}
                                >
                                    <Skeleton
                                        title={false}
                                        loading={loading}
                                        active
                                    />
                                </article>
                            ))}
                        </div>
                    )}

                    {!loading && showSelect === 0 && schedules.length > 0 && (
                        <div className="schedules-page-content-user-grid">
                            {availableMonths.map((props, i) => (
                                <MonthCard
                                    key={`month_key_${i}`}
                                    {...props}
                                    onSelect={() => selectMonth(props)}
                                />
                            ))}
                        </div>
                    )}

                    {!loading && showSelect === 1 && (
                        <div className="schedules-page-content-user-grid-4">
                            {availableSchedules.map((schedule, i) => (
                                <ScheduleCard
                                    key={`schedule_key_${schedule.id}_${i}`}
                                    dayString={
                                        daysOfWeekObject[
                                            new Date(schedule.date).getDay()
                                        ]
                                    }
                                    dayNumber={setLeftZeroInNumber(
                                        new Date(schedule.date).getDate()
                                    )}
                                    time={moment
                                        .tz(
                                            schedule.time,
                                            'America/El_Salvador'
                                        )
                                        .format('hh:mm A')}
                                    isSelected={schedule.isSelected}
                                    onSelect={() =>
                                        onSelectSchedule(
                                            schedule,
                                            i,
                                            !schedule.isSelected
                                        )
                                    }
                                />
                            ))}
                        </div>
                    )}

                    {!loading && showSelect === 0 && schedules.length === 0 && (
                        <div className="schedules-no-items">
                            <text className="bold ">
                                No hay horarios disponibles para esta
                                experiencia
                            </text>
                        </div>
                    )}

                    {!loading &&
                        showSelect === 1 &&
                        availableSchedules.length === 0 && (
                            <div className="schedules-no-items">
                                <text className="bold ">
                                    {`No hay horarios disponibles para ${monthsInYear[filterSchedules.month]}, ${filterSchedules.year}`}
                                </text>

                                <button
                                    onClick={backSelect}
                                    className="bact-to-week-btn"
                                >
                                    Otro mes
                                </button>
                            </div>
                        )}
                </div>
            </Row>
        </Grid>
    )
}

export default ExperienciaCalendar
