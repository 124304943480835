import React, { useCallback, useEffect, useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Link } from 'react-router-dom'
import { Card, Image } from 'react-bootstrap'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Viajero from './Navbars/Viajero'
import { PiRocketBold } from 'react-icons/pi'
import logo from '../assets/images/logoD.svg'
import Imagen from '../assets/images/cards/green.png'
import { getData } from '../services'
import { Spinner } from './commons'
import { Input, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Loader } from 'rsuite'
import debounce from 'lodash.debounce'

const Home = () => {
    const [companias, setCompanias] = useState([])
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [searchExperience, setSearchExperience] = useState('')
    const [experiencesFound, setExperiencesFound] = useState([])
    const [debounceTimeout, setDebounceTimeout] = useState()

    const inicioComponente = async () => {
        try {
            let res = await getData({
                path: `config/public/query`,
                token: null,
                params: {},
                value: {},
                platform: null,
            })
            //   console.log('respuesta de ruta', res, res.filter(item => item.platform !== undefined))
            setCompanias(
                res.filter(
                    (item) => item.user !== null && Object.keys(item).length > 0
                )
            )
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }

    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query) {
                fetchSearchResults(query)
            } else {
                setExperiencesFound([])
            }
        }, 500),
        []
    )

    const handleSearchChange = (e) => {
        const { value } = e.target
        setSearchExperience(value)
        debouncedSearch(value)
    }
    console.log(experiencesFound)
    const fetchSearchResults = async (query) => {
        setIsLoading(true)
        try {
            const response = await getData({
                path: `experiences/search?query=${query}`,
                token: null,
                params: {},
                value: {},
                platform: null,
            })
            console.log(response)
            setExperiencesFound(response)
        } catch (error) {
            message.warning('Ha ocurrido un error', 3)
        } finally {
            setIsLoading(false)
        }
    }

    console.log(companias)
    useEffect(() => {
        window.scrollTo(0, 0)
        inicioComponente()
    }, [])

    useEffect(() => {
        if (searchExperience === '') {
            setExperiencesFound([])
        }
    }, [searchExperience])

    console.log(experiencesFound)
    console.log(experiencesFound.length)
    console.log(companias.length)
    return (
        <div style={{ paddingBottom: '4rem' }}>
            <Viajero companias={companias} />
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <section
                        className="container-sm text-center"
                        style={{ marginTop: '-1rem', paddingBottom: '1rem' }}
                    >
                        <div className="text_marca heavy">
                            <img src={logo} className="logo_index" alt="logo" />
                            <br />
                            <TypeAnimation
                                sequence={[
                                    500,
                                    'Deja de soñar y ¡Empieza a viajar!', // initially rendered starting point
                                    1000,
                                ]}
                                speed={1}
                                repeat={0}
                            />
                        </div>
                        <h4 className=" text-grey mt-5">
                            Tus <span className="bold">destinos</span> a un
                            click de distancia. 😉
                            {/*Transformamos destinos turístico con nuestra plataforma SaaS de última generación.*/}
                        </h4>
                    </section>
                    <section className="navbar-search_20 mx-auto w-[95%] max-w-4xl">
                        <div className="h-14 border-2 border-gray-300 rounded-3xl overflow-hidden flex items-center">
                            <Input
                                className="h-14 border-2 bg-transparent text-white px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
                                bordered={false}
                                size="large"
                                prefix={<SearchOutlined />}
                                placeholder="Surf..."
                                value={searchExperience}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </section>

                    <section
                        className="container-sm text-center"
                        style={{
                            paddingTop: '2rem',
                            paddingBottom: '5rem',
                        }}
                    >
                        {/*<h2 className='bold mb-5'><PiParkBold className='mr-2 icon_menu text_green' />Descubre nuestros <text className='text_green'>destinos</text></h2>*/}
                        {/* <OwlCarousel
              className="owl-carousel"
              margin={20}
              nav={false}
              dots={true}
              responsive={{
                1200: {
                  items: 4,
                },
                300: {
                  items: 2,
                },
              }}
            >
             {companias?.map((item) => (
                            <Link to={`/${item?.platform?.domain}`}>
                                <div key="key1">
                                    <Card className="pointer border-0 image04">
                                        <Card.Img src={item?.platform?.logoUrl != undefined ? item?.platform?.logoUrl != 'URL del Logo' && item?.platform.logoUrl != "" ? item?.platform?.logoUrl : Imagen : Imagen} className="" />
                                        <Card.ImgOverlay>
                                            <Image src={item?.user?.picture} roundedCircle className="avatar" />
                                            <div className="card-bottom">
                                                <h1 className="bold titulo_home" style={{ lineHeight: '3rem' }}>
                                                    {item?.user?.name}
                                                </h1>
                                                <h4 className="" style={{ paddingBottom: '1rem' }}>
                                                    <i className="icon-marker text_green"></i>{'   '}
                                                    México
                                                </h4>
                                            </div>
                                        </Card.ImgOverlay>
                                    </Card>
                                </div>
                            </Link>
                ))
            }
            </OwlCarousel> */}

                        <ResponsiveMasonry
                            columnsCountBreakPoints={{
                                350: 2,
                                750: 3,
                                1200: 4,
                            }}
                        >
                            <Masonry gutter={20}>
                                {experiencesFound.length > 0
                                    ? experiencesFound?.map((item, index) => {
                                          return (
                                              <Link
                                                  key={index}
                                                  to={`/experiencia/${item.id}`}
                                              >
                                                  <Card className="pointer border-0 image04 ">
                                                      <div
                                                          className="absolute inset-0 bg-cover bg-center"
                                                          style={{
                                                              backgroundImage: `url(${Imagen})`,
                                                          }}
                                                      />

                                                      <Card.Img
                                                          src={
                                                              item.pictures[0]
                                                                  ?.imageUrl
                                                                  ? item
                                                                        .pictures[0]
                                                                        ?.imageUrl
                                                                  : Imagen
                                                          }
                                                      />
                                                      <Card.ImgOverlay className="flex flex-col justify-end p-4">
                                                          <div className="card-bottom">
                                                              <h1
                                                                  className="bold titulo_home text-xl font-bold leading-tight"
                                                                  style={{
                                                                      lineHeight:
                                                                          '3rem',
                                                                  }}
                                                              >
                                                                  {item?.name}
                                                              </h1>
                                                          </div>
                                                      </Card.ImgOverlay>
                                                  </Card>
                                              </Link>
                                          )
                                      })
                                    : companias?.map((item, index) => (
                                          <Link
                                              key={index}
                                              to={
                                                  item?.platform?.domain
                                                      ? `/${item?.platform?.domain}/parati`
                                                      : null
                                              }
                                          >
                                              <div key="key1">
                                                  <Card className="pointer border-0 image04">
                                                      <Card.Img
                                                          src={
                                                              item?.platform
                                                                  ?.logoUrl !==
                                                              undefined
                                                                  ? item
                                                                        ?.platform
                                                                        ?.logoUrl !==
                                                                        'URL del Logo' &&
                                                                    item
                                                                        ?.platform
                                                                        .logoUrl !==
                                                                        ''
                                                                      ? item
                                                                            ?.platform
                                                                            ?.logoUrl
                                                                      : Imagen
                                                                  : Imagen
                                                          }
                                                          className=""
                                                      />
                                                      <Card.ImgOverlay>
                                                          <Image
                                                              src={
                                                                  item?.user
                                                                      ?.picture
                                                              }
                                                              roundedCircle
                                                              className="avatar"
                                                          />
                                                          <div className="card-bottom">
                                                              <h1
                                                                  className="bold titulo_home"
                                                                  style={{
                                                                      lineHeight:
                                                                          '3rem',
                                                                  }}
                                                              >
                                                                  {
                                                                      item?.user
                                                                          ?.name
                                                                  }
                                                              </h1>
                                                              <h4
                                                                  className=""
                                                                  style={{
                                                                      paddingBottom:
                                                                          '1rem',
                                                                  }}
                                                              >
                                                                  <i className="icon-marker text_green"></i>
                                                                  {'   '}
                                                                  México
                                                              </h4>
                                                          </div>
                                                      </Card.ImgOverlay>
                                                  </Card>
                                              </div>
                                          </Link>
                                      ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </section>
                    <section
                        className="text-center"
                        style={{ position: 'relative', marginTop: '-3rem' }}
                    >
                        {/*<div class="custom-shape-divider-top-1704436422">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                    </svg>
                </div>*/}
                        <div
                            className="container-sm"
                            style={{ zIndex: '1000', paddingTop: '8rem' }}
                        >
                            <h2 className="bold mb-5">
                                <PiRocketBold className="mr-2 icon_menu text_green text-center" />
                                Herramientas{' '}
                                <p className="text_green">todo en uno</p>
                            </h2>
                        </div>
                    </section>
                </>
            )}
        </div>
    )
}

export default Home
