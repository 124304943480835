import { Avatar } from 'antd'
import React from 'react'
import { TablaAnt } from '../../../../table/TablaAnt'

const TablaBoletos = ({
    data
}) => { 

    const columns = [
        {
            title: '',
            key: 'picture',
            dataIndex: 'picture',
            render: (value) => (
                <Avatar shape="square" size={64} src={value} />
            ),
        },
        {
            title: 'Experiencia/Paquete',
            key: 'name',
            dataIndex: 'name',
            sorter: false
        },
        {
            title: 'Cantidad',
            key: 'cantidad',
            dataIndex: 'cantidad',
            sorter: false
        }
    ]
  return (
    <TablaAnt 
        scrollX='max-contend'
        columns={columns}
        data={data}
        loading={false}
        isCheckCell={false}
    />
  )
}

export default TablaBoletos

