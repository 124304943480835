/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Card, Col, Row } from 'antd'
import { getData } from '../../../../services'
import { Link, useOutletContext } from 'react-router-dom'
import { InputPicker } from 'rsuite'
import { BASE_PATH } from '../../../constants'
import axios from 'axios'
import { RiErrorWarningFill } from 'react-icons/ri'
import { Spinner } from 'componentes/commons'

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Ingresos',
        },
    },
}

const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

const AdminDashboard = () => {
    const { user } = useOutletContext()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        ingresos: null,
        paises: null,
        select: [],
        dataRender: [],
        allData: true,
    })
    const [error, setError] = useState(false)
    const ruta = window.location.pathname
    const rutaB = ruta.split('/')
    const admin = user?.data?.user?.role === 'admin'
    const role = user?.data?.user?.role

    const dataOrganization = (res) => {
        const monthsData = []
        for (let i = 1; i <= 12; i++) {
            const monthObject = {
                month: i,
                totalAmount: 0,
            }

            monthsData.push(monthObject)
        }
        res.data.report?.salePerMonth.forEach((sale) => {
            const monthIndex = sale.month - 1

            monthsData[monthIndex] = {
                month: sale.month,
                totalAmount: sale.totalAmount,
            }
        })
        return monthsData
    }

    const getDatos = async () => {
        try {
            setLoading(true)
            const resDataRender = await axios.get(
                `${BASE_PATH}/partners/sales/search?${
                    !admin && role !== 'company'
                        ? `parentOwnerId=${user?.data?.user?.parentOwnerId}`
                        : role === 'company'
                          ? `parentOwnerId=${user?.data?.user?.parentId}`
                          : ''
                }&isPaginate=true`,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token ?? ''}`,
                    },
                }
            )
            console.log(resDataRender)
            const resSelected = await getData({
                path: admin ? `/users/partners/company` : `users/partners/ptnr`,
                token: user.logged && user.data.token,
                params: {},
                value: {},
            })
            setData({
                ...data,
                select: resSelected?.map((select) => ({
                    label: select.name,
                    value: select._id,
                })),
                ingresos: {
                    labels: months,
                    datasets: [
                        {
                            label: 'Ingresos',
                            data: dataOrganization(resDataRender).map(
                                (sale) => sale.totalAmount
                            ),
                            backgroundColor: '#64B32E',
                            totalAmount:
                                resDataRender?.data?.report?.salePerMonth?.reduce(
                                    (total, sale) => total + sale.totalAmount,
                                    0
                                ),
                        },
                    ],
                },
                paises: {
                    labels: resDataRender.data.report?.topCountry.map(
                        (top) => top.country
                    ),
                    datasets: [
                        {
                            label: 'Paises',
                            data: resDataRender.data.report?.topCountry.map(
                                (top) => top.count
                            ),
                            backgroundColor: '#64B32E',
                        },
                    ],
                },
                dataRender: resDataRender.data,
            })
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getSelected = async (id) => {
        setLoading(true)
        setData({ ...data, dataRender: [] })
        setError(false)
        if (id) {
            try {
                const res = await axios.get(
                    `${BASE_PATH}/partners/sales/search?${
                        admin ? 'parentOwnerId' : 'partnerOwner'
                    }=${id}&isPaginate=true`
                )
                setData({
                    ...data,
                    ingresos: {
                        labels: months,
                        datasets: [
                            {
                                label: 'Ingresos',
                                data: dataOrganization(res).map(
                                    (sale) => sale.totalAmount
                                ),
                                backgroundColor: '#64B32E',
                                totalAmount:
                                    res?.data?.report?.salePerMonth?.reduce(
                                        (total, sale) =>
                                            total + sale.totalAmount,
                                        0
                                    ),
                            },
                        ],
                    },
                    paises: {
                        labels: res.data.report?.topCountry.map(
                            (top) => top.country
                        ),
                        datasets: [
                            {
                                label: 'Paises',
                                data: res.data.report?.topCountry.map(
                                    (top) => top.count
                                ),
                                backgroundColor: '#64B32E',
                            },
                        ],
                    },
                    dataRender: res.data,
                    allData: false,
                })
            } catch (error) {
                setError(true)
                console.error(error)
            } finally {
                setLoading(false)
            }
        } else {
            const res = await axios.get(
                `${BASE_PATH}/partners/sales/search?${
                    !admin ? `parentOwnerId=${user?.data?.user?.id}` : ''
                }&isPaginate=true`
            )
            setData({
                ...data,
                ingresos: {
                    labels: months,
                    datasets: [
                        {
                            label: 'Ingresos',
                            data: dataOrganization(res).map(
                                (sale) => sale.totalAmount
                            ),
                            backgroundColor: '#64B32E',
                            totalAmount:
                                res?.data?.report?.salePerMonth?.reduce(
                                    (total, sale) => total + sale.totalAmount,
                                    0
                                ),
                        },
                    ],
                },
                paises: {
                    labels: res.data.report?.topCountry.map(
                        (top) => top.country
                    ),
                    datasets: [
                        {
                            label: 'Paises',
                            data: res.data.report?.topCountry.map(
                                (top) => top.count
                            ),
                            backgroundColor: '#64B32E',
                        },
                    ],
                },
                dataRender: res.data,
                allData: true,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        getDatos()
    }, [])

    return (
        <div>
            <h2 className="bold titulo_table">Tablero de Analíticas</h2>
            <InputPicker
                onChange={(value) => getSelected(value)}
                data={data.select}
                className="mb-14"
            />
            {loading ? (
                <Spinner />
            ) : data.dataRender.length !== 0 ? (
                <>
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col md={6}>
                            <Link to={'viajeros'}>
                                <Card className="card_dashboard">
                                    <p className="bold text_green">Viajeros</p>
                                    <span>
                                        {
                                            data.dataRender?.report
                                                ?.travelers[0]?.travelers
                                        }
                                    </span>
                                </Card>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <Link to={'ingresos'}>
                                <Card className="card_dashboard">
                                    <p className="bold text_green">Ingresos</p>
                                    <span>
                                        ${data.ingresos.datasets[0].totalAmount}
                                    </span>
                                </Card>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <Card className="card_dashboard">
                                <p className="bold text_green">Operadores</p>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="card_dashboard">
                                <p className="bold text_green">Transacciones</p>
                            </Card>
                        </Col>
                    </Row>
                    <div className="flex gap-4 py-10">
                        <Card
                            style={{
                                marginTop: '20px',
                                width: '50%',
                                height: '400px',
                                borderRadius: '20px',
                                border: '0',
                            }}
                        >
                            {data.ingresos ? (
                                <>
                                    <p className="bold text_green">Ingresos</p>
                                    <Bar
                                        options={options}
                                        data={data.ingresos}
                                    />
                                </>
                            ) : null}
                        </Card>
                        <Card
                            style={{
                                marginTop: '20px',
                                width: '50%',
                                height: '400px',
                                borderRadius: '20px',
                                border: '0',
                            }}
                        >
                            <p className="bold text_green">Paises</p>
                            <Bar
                                data={data.paises}
                                options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'right',
                                        },
                                        title: {
                                            display: true,
                                            text: 'Paises',
                                        },
                                    },
                                }}
                            />
                        </Card>
                    </div>
                </>
            ) : error ? (
                <div className="flex gap-1 items-center text-center">
                    <RiErrorWarningFill className="fill-red-500" />
                    <p>Ocurrio un error inesperado!</p>
                </div>
            ) : (
                <p>No se encontraron datos de esta </p>
            )}
        </div>
    )
}

export default AdminDashboard
