import React, { useEffect, useState } from 'react'
import { PiDownloadBold } from 'react-icons/pi'
import { DatePicker } from 'antd'

import Titulo from '../Titulo'

import { TablaIngresos } from './TablaIngresos'

const { RangePicker } = DatePicker

const initIngresos = [
    {
        fecha: '16/10/2016',
        servicio: 'Zoco Easy Travel',
        cantidad: 50,
        costo_unitario: 30,
        subtotal: 80,
    },
]

export const Ingresos = () => {
    const [ingresos, setIngresos] = useState(initIngresos)
    const [date, setDate] = useState([])
    console.log(ingresos)
    const getData = async () => {
        try {
            // if (res.length !== 0) {
            new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format()
            const total = {
                fecha: (
                    <p className="text-lg font-bold text-orange-600">Total</p>
                ),
                cantidad: 0,
                costo_unitario: 0,
                subtotal: 0,
            }

            setIngresos([...initIngresos, total])
            // }
        } catch (error) {
            console.error(error, 'Componente Ingresos')
        }
    }

    useEffect(() => {
        getData()
    }, [date])

    return (
        <div>
            <div className="flex w-full items-center justify-between">
                <div className="flex flex-col w-full gap-5">
                    <Titulo
                        titulo={'Ingresos'}
                        tituloBoton={'Crear viajero'}
                        onAcccion={() => console.log('accion')}
                        button={false}
                    />
                    <div className="w-full flex justify-between mb-6">
                        <RangePicker onChange={setDate} />
                        <button className="flex h-fit rounded-lg bg-orange-600 p-[7px]">
                            <PiDownloadBold className="icon_menu_20" />
                            Descargar
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <TablaIngresos data={ingresos} />
            </div>
        </div>
    )
}
