import { CloseOutlined } from '@ant-design/icons'
import { Modal, Form, Input, DatePicker, Select } from 'antd'
import moment from 'moment'
const { Option } = Select

const PayTicketModal = ({
    showEditModal,
    setShowEditModal,
    form,
    formValues,
    setFormValues,
    experiences,
    onOk,
    loading,
    merchants,
}) => {
    console.log(formValues)
    return (
        <Modal
            title={'Editar información'}
            closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
            open={showEditModal}
            okType="danger"
            onOk={onOk}
            onCancel={() => setShowEditModal(false)}
            confirmLoading={loading}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item
                    label={'Nombre'}
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese un nombre',
                        },
                    ]}
                >
                    <Input
                        placeholder={'nombre'}
                        type={'text'}
                        value={formValues.name}
                        onChange={(e) =>
                            setFormValues({
                                ...formValues,
                                name: e.target.value,
                            })
                        }
                        required
                    />
                </Form.Item>
                <Form.Item
                    label="Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese una descripción',
                        },
                    ]}
                >
                    <Input
                        placeholder={'descripción'}
                        type={'text'}
                        value={formValues.description}
                        onChange={(e) =>
                            setFormValues({
                                ...formValues,
                                description: e.target.value,
                            })
                        }
                        required
                    />
                </Form.Item>
                <Form.Item
                    label="Fecha de expiración"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese la fecha de expiración',
                        },
                    ]}
                >
                    <DatePicker
                        placeholder={'fecha de expiración'}
                        value={
                            formValues.expirationDate
                                ? moment(formValues.expirationDate)
                                : null
                        }
                        onChange={(date) =>
                            setFormValues({
                                ...formValues,
                                expirationDate: date,
                            })
                        }
                        required
                    />
                </Form.Item>
                <Form.Item
                    label="Experiencias"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese al menos una experiencia',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        placeholder="experiencias"
                        value={formValues.experiences}
                        onChange={(value) =>
                            setFormValues({
                                ...formValues,
                                experiences: value,
                            })
                        }
                    >
                        {experiences.map((item, idx) => (
                            <Option key={idx} value={item._id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Duración"
                    rules={[
                        {
                            required: true,
                            message: 'Ingrese una duración',
                        },
                    ]}
                >
                    <Input
                        placeholder={'duración'}
                        type={'number'}
                        value={formValues.duration}
                        onChange={(e) =>
                            setFormValues({
                                ...formValues,
                                duration: e.target.value,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item label="Revendedor">
                    <Select
                        placeholder="Revendedor"
                        value={formValues.merchantOwner}
                        allowClear
                        onChange={(value) =>
                            setFormValues({
                                ...formValues,
                                merchantOwner: value || null,
                            })
                        }
                    >
                        {merchants.map((item, idx) => (
                            <Option key={idx} value={item._id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default PayTicketModal
