import { Button } from 'antd'
import React from 'react'
import { TablaAnt } from '../../../../table/TablaAnt'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const TablaDescuentos = ({ data, onEdit, onDelete, showActions = true }) => {
    const columns = [
        {
            title: 'Codigo de descuento',
            key: 'discountCode',
            dataIndex: 'discountCode',
            sorter: false,
        },
        {
            title: 'Porcentaje de descuento',
            key: 'percentage',
            dataIndex: 'percentage',
            sorter: false,
        },
        {
            title: 'Minimo de boletos',
            key: 'minNumberTickets',
            dataIndex: 'minNumberTickets',
            sorter: false,
        },
        {
            title: 'Maximo de boletos',
            key: 'maxNumberTickets',
            dataIndex: 'maxNumberTickets',
            sorter: false,
        },
        {
            title: 'Fecha de expiración',
            key: 'expirationDate',
            dataIndex: 'expirationDate',
            sorter: false,
        },
        {
            title: 'Descripción',
            key: 'description',
            dataIndex: 'description',
            sorter: false,
        },
        showActions && {
            title: (
                <div>
                    <text className="bold titulo_table">Acciones</text>
                </div>
            ),
            key: 'acciones',
            align: 'right',
            fixed: 'right',
            render: (data) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => onEdit(data)}
                    />
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => onDelete(data)}
                    />
                </>
            ),
        },
    ].filter(Boolean)

    return (
        <TablaAnt
            scrollX="max-contend"
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaDescuentos
