import React, { useState, useContext, useEffect } from 'react'
import 'antd/dist/antd.css'
import { Media, Row, Col } from 'react-bootstrap'
import { TabLink, TabContent, Tabs } from 'react-tabs-redux'
import { Drawer } from 'rsuite'
import Chat from './chat/Chat'
import { Login } from './Login'
import './style.css'
import { useLocation, useParams } from 'react-router-dom'
import Background from '../../assets/images/cards/background_default.jpg'
import ExperienciaGaleria from './ExperienciaGaleria'
import ExperienciaDetails from './ExperienciaDetails'
import ExperienciaReview from './ExperienciaReview'
import { AuthContext } from '../../auth/AuthContext'
const defaultPhoto =
    'https://tugofiles.fra1.digitaloceanspaces.com/1630952868200-Icono_512x512.png'

const ExperienciaHeader = ({ experiencia }) => {
    let { experiencia: expParma, tab } = useParams()
    const location = useLocation()
    const { package: isPackage } = location.state || {}
    const [usern, setusern] = useState([])
    const { user } = useContext(AuthContext)
    const [photos, setphotos] = useState([])
    const [visible, setVisible] = useState(false)
    const showDrawer = () => {
        if (experiencia.length > 0) {
            setVisible(true)
        }
    }
    const onClose = () => {
        setVisible(false)
    }
    useEffect(() => {
        const pics = experiencia.pictures.map((pic) => {
            return {
                url: pic.imageUrl,
                id: pic.image_id,
            }
        })

        setphotos(pics)
    }, [experiencia])
    console.log(user)
    let selectedTab
    if (tab === 'info' || tab === 'pics' || tab === 'reseña') {
        selectedTab = tab
    } else {
        // Si el parámetro no es válido, selecciona el primer tab por defecto
        selectedTab = 'info'
    }

    return (
        <div>
            <section className="exp-header fixed-top d-none d-md-none d-lg-block">
                <Row className="">
                    <Col>
                        <div
                            className="d-flex align-items-center"
                            style={{ height: '100vh' }}
                        >
                            <div className="bold exp-titulo exp-container">
                                <Media className="mb-1">
                                    <Media.Body>
                                        <h5 className="text-white">
                                            {experiencia.direction}
                                        </h5>
                                    </Media.Body>
                                </Media>
                                <p style={{ fontSize: '70px' }}>
                                    {experiencia.name}
                                </p>
                                <div className="review">
                                    <i
                                        className="icon-star"
                                        style={{
                                            color: '#fe5000',
                                            fontSize: '30px',
                                        }}
                                    ></i>
                                    <text
                                        className="bold"
                                        style={{ fontSize: '30px' }}
                                    >
                                        {experiencia.rating}
                                    </text>
                                </div>
                                <div>
                                    <Row
                                        className="exp-details-about"
                                        style={
                                            !isPackage
                                                ? { width: '600px' }
                                                : { width: '300px' }
                                        }
                                    >
                                        {!isPackage && (
                                            <Col className="text-center">
                                                <i
                                                    className="icon-face"
                                                    style={{ fontSize: '45px' }}
                                                ></i>
                                                <p
                                                    style={{
                                                        fontSize: '16px',
                                                        color: 'grey',
                                                    }}
                                                >
                                                    Cupo
                                                </p>
                                                <p className="bold">
                                                    Hasta{' '}
                                                    {experiencia.quotas.max}{' '}
                                                    personas
                                                </p>
                                            </Col>
                                        )}
                                        <Col className="text-center">
                                            <i
                                                className="icon-time"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Duración
                                            </p>
                                            <p className="bold">
                                                {experiencia.duration}
                                            </p>
                                        </Col>
                                        {!isPackage && (
                                            <Col className="text-center">
                                                <i
                                                    className="icon-money"
                                                    style={{ fontSize: '45px' }}
                                                ></i>
                                                <p
                                                    style={{
                                                        fontSize: '16px',
                                                        color: 'grey',
                                                    }}
                                                >
                                                    Precio
                                                </p>
                                                <p className="bold">
                                                    Adultos $
                                                    {experiencia.quotes[0]
                                                        .prices[0].netPrice ||
                                                        experiencia.price
                                                            ?.adultPrice}
                                                    <br />
                                                    Niños $
                                                    {experiencia.quotes[0]
                                                        .prices[1].netPrice ||
                                                        experiencia.price
                                                            ?.childrenPrice}
                                                    <></>
                                                </p>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        className="exp-header-pic"
                        lg={4}
                        style={{
                            backgroundImage: `url(${
                                experiencia?.pictures[0]?.imageUrl
                                    ? experiencia?.pictures[0]?.imageUrl
                                    : Background
                            })`,
                        }}
                    ></Col>
                </Row>
                <i className="fi fi-br-angle-up orange icon-font-exp text-center"></i>
            </section>

            <section
                className="exp-header fixed-top d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none"
                style={{
                    height: '100vh',
                    width: '100%',
                    backgroundImage: `url(${
                        experiencia.pictures[0]
                            ? experiencia.pictures[0].imageUrl
                            : Background
                    })`,
                }}
            >
                <div className="bold exp-titulo container-sm">
                    <p className="text-left">
                        {experiencia.length > 0 ? experiencia.name : <></>}
                    </p>
                </div>
                <Media
                    className="container"
                    style={{
                        marginTop: '-2rem',
                        position: 'absolute',
                        bottom: '2rem',
                    }}
                >
                    <Media.Body>
                        <h5 className="text-white text-left mt-1">
                            {experiencia.length > 0 ? (
                                experiencia[0].direction
                            ) : (
                                <></>
                            )}
                        </h5>
                    </Media.Body>
                </Media>
            </section>
            <section className="exp-contenido">
                <Tabs selectedTab={selectedTab} className="container-sm">
                    <div className="sticky-top exp-tabs colorChange">
                        {/* {colorChange ? 'navbar colorChange' : 'navbar'} estilos css??? */}
                        <TabLink
                            key="tablink1_key"
                            to="info"
                            className="exp-contenido-btn exp-active"
                            activeClassName="orange "
                        >
                            <i className="  icon-details"></i> Info
                        </TabLink>
                        <TabLink
                            key="tablink2_key"
                            to="pics"
                            className="exp-contenido-btn exp-active "
                            activeClassName="orange"
                        >
                            <i className=" icon-pics"></i>Pics
                        </TabLink>
                        <TabLink
                            key="tablink13key"
                            to="reseña"
                            className="exp-contenido-btn exp-active "
                            activeClassName="orange"
                        >
                            <i className=" icon-star "></i>
                        </TabLink>
                    </div>
                    <TabContent key="tab1_key" for="info">
                        <ExperienciaDetails
                            key="_kettab1"
                            data={experiencia.id}
                        />
                    </TabContent>
                    <TabContent
                        key="tab2_key"
                        for="pics"
                        className="exp-galeria"
                    >
                        <ExperienciaGaleria key="_kettab2" photos={photos} />
                    </TabContent>
                    <TabContent key="tab3_key" for="reseña">
                        <ExperienciaReview
                            key="_kettab3"
                            data={experiencia.id}
                        />
                    </TabContent>
                </Tabs>
                <>
                    {/* <button
            type="button"
            className="exp-chat exp-fixed-bottom btn-primary"
            onClick={showDrawer}
          >
            <i className=" icon-chat" style={{ fontSize: 26, marginTop: '0.4rem' }}></i>
          </button> */}
                    <Drawer size="xs" onHide={onClose} show={visible}>
                        <Drawer.Body className="exp-chat-open">
                            {user.logged === true &&
                                visible === true &&
                                usern?._id != user.data.user.id && (
                                    <Chat
                                        dataUser={usern}
                                        closeChat={onClose}
                                    ></Chat>
                                )}
                            {user.logged === false && <Login></Login>}
                            {user.logged === true &&
                                visible === true &&
                                usern?._id === user.data.user.id && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            flexDirection: 'column',
                                            marginTop: '100px',
                                        }}
                                    >
                                        <button
                                            type="button"
                                            className="back-window-navbar"
                                            style={{
                                                position: 'absolute',
                                                margin: '20px',
                                                top: '0',
                                            }}
                                            onClick={onClose}
                                        >
                                            <i className="icon-back icon-left-arrow"></i>
                                        </button>
                                        <img
                                            src={defaultPhoto}
                                            alt="tugo image"
                                            style={{
                                                maxWidth: '250px',
                                                width: '100%',
                                                margin: 'auto',
                                            }}
                                        />
                                        <h2
                                            style={{
                                                fontSize: '18px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Chat no disponible en esta
                                            experiencia
                                        </h2>
                                    </div>
                                )}
                        </Drawer.Body>
                    </Drawer>
                </>
            </section>
        </div>
    )
}

export default ExperienciaHeader
