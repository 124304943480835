import { Form } from 'antd'
import React, { useEffect } from 'react'
import Titulo from '../Titulo'
import TablaRevendedores from './items/TablaRevendedores'
import { CancelConfirmModal, SidebarForm, Spinner } from 'componentes/commons'
import useAbmRoles from 'hooks/useAbmRoles'
import PaginationCom from 'componentes/pagination/PaginationCom'

const AdminRevendedores = () => {
    const {
        setOpen,
        user,
        isLoading,
        openModal,
        data,
        onEdit,
        handleDeleteButton,
        action,
        onCloseDrawer,
        open,
        onFinish,
        formValues,
        setFormValues,
        onCancelModal,
        onDeleteUser,
        userIdSelected,
        page,
        setPage,
        meta,
        form,
        linkForm,
        openLink,
        setOpenLink,
        linkFormValues,
        setLinkFormValues,
        onCloseLinkDrawer,
        experiences,
        paquetes,
        tickets,
        onFinishLink,
        isLoadingButton,
    } = useAbmRoles({ merchantType: 'reseller' })

    return (
        <div>
            <Titulo
                tituloBoton={'Crear vinculación'}
                onAcccion={() => setOpenLink(true)}
            />
            <Titulo
                titulo={'Usuarios revendedores'}
                tituloBoton={'Crear Usuario'}
                onAcccion={() => setOpen(true)}
                button={user?.data?.user?.role === 'admin' ? false : true}
            />
            <div>
                {isLoading && !openModal ? (
                    <Spinner />
                ) : (
                    <>
                        <TablaRevendedores
                            data={data}
                            onEdit={onEdit}
                            onDelete={handleDeleteButton}
                        />
                        {data.length > 0 && (
                            <PaginationCom
                                page={page}
                                meta={meta}
                                setPage={(value) => setPage(value)}
                            />
                        )}
                    </>
                )}
            </div>
            <SidebarForm
                form={form}
                formValues={formValues}
                open={open}
                onFinish={onFinish}
                onCloseDrawer={onCloseDrawer}
                setFormValues={setFormValues}
                title={
                    action === 'new' ? 'Crear revendedor' : 'Editar revendedor'
                }
                buttonText={action === 'new' ? 'Crear' : 'Editar'}
                inputs={[
                    {
                        label: 'Usuario',
                        name: 'name',
                        rules: [
                            {
                                required: true,
                                message: 'Ingrese un nombre de usuario',
                            },
                        ],
                    },
                    {
                        label: 'Email',
                        name: 'email',
                        rules: [
                            {
                                required: true,
                                pattern:
                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                message: 'Ingrese un email',
                            },
                        ],
                    },
                    {
                        label: 'Password',
                        name: 'password',
                        rules: [
                            {
                                required: true,
                                message:
                                    'Ingrese una contraseña de al menos 6 caracteres',
                                min: 6,
                            },
                        ],
                    },
                ]}
            />
            <SidebarForm
                form={linkForm}
                formValues={linkFormValues}
                open={openLink}
                onFinish={onFinishLink}
                onCloseDrawer={onCloseLinkDrawer}
                setFormValues={setLinkFormValues}
                title={'Crear Vinculación'}
                buttonText={'Vincular'}
                isLoading={isLoadingButton}
                inputs={[
                    {
                        label: 'Revendedor',
                        name: 'merchant',
                        type: 'select',
                        items: data?.map((item) => {
                            return {
                                label: item.name,
                                value: item._id,
                            }
                        }),
                        rules: [
                            {
                                required: true,
                                message: 'Seleccione al revendedor',
                            },
                        ],
                    },
                    {
                        label: 'Tickets',
                        name: 'tickets',
                        type: 'select',
                        items: tickets?.map((item) => {
                            return {
                                label: item?.ticketType?.typeName,
                                value: item._id,
                            }
                        }),
                        modeSelect: 'multiple',
                    },
                    {
                        label: 'Paquetes',
                        name: 'package',
                        type: 'select',
                        items: paquetes?.map((item) => {
                            return {
                                label: item.name,
                                value: item._id,
                            }
                        }),
                        modeSelect: 'multiple',
                    },
                    {
                        label: 'Experiencia',
                        name: 'experiences',
                        type: 'select',
                        items: experiences?.map((item) => {
                            return {
                                label: item.name,
                                value: item._id,
                            }
                        }),
                        modeSelect: 'multiple',
                    },
                ]}
            />
            <CancelConfirmModal
                loading={isLoading}
                onCancelModal={onCancelModal}
                onOk={onDeleteUser}
                openModal={openModal}
                strongText={
                    data.find((user) => user._id === userIdSelected)?.name
                }
                text={`¿Estás seguro de que deseas eliminar al revendedor`}
                title={'Eliminar Revendedor'}
            />
        </div>
    )
}

export default AdminRevendedores
