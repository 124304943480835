// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputForm_InputForm__A\\+cau label { 
    font-size: 12px !important; 
} 
.InputForm_InputForm__A\\+cau div[role="alert"] { 
    font-size: 12px !important; 
}`, "",{"version":3,"sources":["webpack://./src/componentes/InputForm/InputForm.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".InputForm label { \n    font-size: 12px !important; \n} \n.InputForm div[role=\"alert\"] { \n    font-size: 12px !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputForm": `InputForm_InputForm__A+cau`
};
export default ___CSS_LOADER_EXPORT___;
