import React, { useContext, useEffect, useState } from "react";

import PickerImage from "../Anfitrion/Experiencias/elementos/ImagePicker";
import { AuthContext } from "../../auth/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_PATH } from "../constants";
import { Alert } from "rsuite";
import { Skeleton } from "antd";

//import images from local
import img1 from "../../assets/images/gustos/amor.svg";
import img2 from "../../assets/images/gustos/aves.svg";
import img3 from "../../assets/images/gustos/bici.svg";
import img4 from "../../assets/images/gustos/bienestar.svg";
import img5 from "../../assets/images/gustos/camp.svg";
import img6 from "../../assets/images/gustos/caminata.svg";
import img7 from "../../assets/images/gustos/ciudad.svg";
import img8 from "../../assets/images/gustos/comida.svg";
import img9 from "../../assets/images/gustos/correr.svg";
import img10 from "../../assets/images/gustos/deportes.svg";
import img11 from "../../assets/images/gustos/escalar.svg";
import img12 from "../../assets/images/gustos/fiesta.svg";
import img13 from "../../assets/images/gustos/musica.svg";
import img14 from "../../assets/images/gustos/nadar.svg";
import img15 from "../../assets/images/gustos/navegar.svg";
import img16 from "../../assets/images/gustos/playa.svg";
import img17 from "../../assets/images/gustos/surf.svg";
import img18 from "../../assets/images/gustos/torneo.svg";
import img19 from "../../assets/images/gustos/volcan.svg";
import img20 from "../../assets/images/gustos/astro.svg";
import img21 from "../../assets/images/gustos/cultura.svg";
import img22 from "../../assets/images/gustos/storytelling.svg";
import img23 from "../../assets/images/gustos/artesania.svg";

const newImageList = {
  Amor: { image: img1, isSelected: false },
  Aves: { image: img2, isSelected: false },
  Bicicleta: { image: img3, isSelected: false },
  Bienestar: { image: img4, isSelected: false },
  Campamento: { image: img5, isSelected: false },
  Caminata: { image: img6, isSelected: false },
  Urbano: { image: img7, isSelected: false },
  Comida: { image: img8, isSelected: false },
  Correr: { image: img9, isSelected: false },
  Extremo: { image: img10, isSelected: false },
  Escalada: { image: img11, isSelected: false },
  Fiesta: { image: img12, isSelected: false },
  Musica: { image: img13, isSelected: false },
  Nadar: { image: img14, isSelected: false },
  Navegar: { image: img15, isSelected: false },
  Playa: { image: img16, isSelected: false },
  Surf: { image: img17, isSelected: false },
  Torneos: { image: img18, isSelected: false },
  Volcanes: { image: img19, isSelected: false },
  Astrologia: { image: img20, isSelected: false },
  Cultura: { image: img21, isSelected: false },
  Storytelling: { image: img22, isSelected: false },
  Artesanías: { image: img23, isSelected: false },
};
export const TusGustos = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [gustos, setgustos] = useState([]);
  const [gustosedits, setgustosedit] = useState([]);
  const [selectGustos, setselectGustos] = useState([]);
  const [loading, setloading] = useState(true);

  const Back = () => {
    navigate("/configuraciones", { replace: true });
  };

  const onSelected = (e) => {
    setselectGustos(e);
  };

  const getCategorys = async () => {
    try {
      let result = await axios.get(`${BASE_PATH}/categories`, {
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
      });

      //console.log(result)

      if (result.status === 200) {
        const allCategories = result.data.rows
          .map((cat) => {
            return {
              id: cat.id,
              name: cat.category,
              ...newImageList[cat.category],
            };
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

        setgustos(allCategories);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      Alert.error("Ha ocurrido un error en el servidor", 5000);
      setloading(false);
    }
  };

  const getMyGustos = async () => {
    try {
      let myGustos = await axios.get(
        `${BASE_PATH}/users/categories/${user.data.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );

      if (myGustos.status == 201) {
        let edit = myGustos.data.row.categories.map((e) => e.id);
        setgustosedit(edit);
        setselectGustos(edit);
      }
    } catch (error) {
      console.log(error);
      //Alert.error('Ups, intentalo luego', 5000)
    }
  };

  const saveGustos = async () => {
    try {
      let result = await axios.post(
        `${BASE_PATH}/users/categories`,
        {
          user: user.data.user.id,
          categories: selectGustos,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        }
      );
      result.status = 200
        ? Alert.success("Se guardaron los cambios!", 3000)
        : Alert.error("Ups, intentalo luego", 5000);
    } catch (error) {
      console.log(error);
      Alert.error("Ups, intentalo luego", 5000);
    }
  };

  useEffect(() => {
    getCategorys();
    getMyGustos();
  }, []);

  return (
    <div style={{ paddingBottom: "6rem" }}>
      <nav
        className="navbar-titles navbar-light 
        d-flex align-items-center"
      >
        <button type="button" className="back-window-navbar" onClick={Back}>
          <i className="icon-left-arrow"></i>
        </button>
        <h5 className="bold">Tus Gustos</h5>
      </nav>

      {loading && (
        <>
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: "1rem", paddingBotton: "4rem" }}
          >
            <div className="page_pay container">
              <div className="image_picker">
                {Object.keys(newImageList).map((key) => (
                  <Skeleton key={key} className="ip_tarjeta no-events" active />
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && gustos.length > 0 && (
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "1rem", paddingBotton: "4rem" }}
        >
          <div className="page_pay container-gustos">
            <PickerImage
              categorys={gustos}
              onSelected={onSelected}
              selectes={gustosedits}
            ></PickerImage>

            <button
              type="button"
              onClick={saveGustos}
              className="btn-primary
              bold btn-login"
            >
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TusGustos;
