import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'antd/dist/antd.css'
import { TabLink, TabContent, Tabs } from 'react-tabs-redux'
import { RiEdit2Fill } from 'react-icons/ri'
import axios from 'axios'
import { Media, Row, Col } from 'react-bootstrap'
import { Form, Popover, message } from 'antd'
import { Button, Drawer, Icon, IconButton, Loader } from 'rsuite'

//Importar Recursos
import País from '../../../assets/images/paises/banderas/guate.svg'
import Background from '../../../assets/images/cards/barrio.jpg'
import ExperienciaGaleria from '../../Experiencias/ExperienciaGaleria'
import ExperienciaDetails from './ExperienciaDetails'
import ExperienciaReview from '../../Experiencias/ExperienciaReview'
import { BASE_PATH } from '../../constants'

import { AuthContext } from '../../../auth/AuthContext'
import logo from '../../../assets/images/desvlop.svg'
import ExperienciaNewGustos from './ExperienciaNewGustos'
import ExperienciaNewPhotos from './ExperienciaNewPhotos'
import ExperienciaNewPagar from './ExperienciaNewPagar'
import { MessageUtils } from 'utils'
import { Spinner } from 'componentes/commons'

const InitShowModalState = {
    open: false,
    photos: false,
    categories: false,
    prices: false,
}

const ExperienciaSubida = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const { experienciaid } = useParams()
    const [selectedCategories, setselectedCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedPhotos, setselectedPhotos] = useState([])
    const [experiencia, setExperiencia] = useState([])
    const [schedulesExist, setschedulesExist] = useState(false)
    const [showModal, setshowModal] = useState(InitShowModalState)
    const [visualizePrices, setVisualizePrices] = useState(false)
    const [form] = Form.useForm()
    const [newForm, setNewForm] = useState({
        adult: '',
        children: '',
        discountPeople: '',
        discountPrice: '',
        couponCode: '',
        couponDiscount: '',
    })

    const HomeB = () =>
        // navigate(`/company/${user.data.platform.domain}/lista-experiencias`)
        navigate(-1)

    const Editar = () =>
        navigate(`/host/editar/_/${experienciaid}`, { replace: true })

    const Schedules = () =>
        navigate(`/experiencia/horarios/_/${experienciaid}`, { replace: true })

    const handlePhotos = () => setshowModal({ open: true, photos: true })

    const handleCategories = () =>
        setshowModal({ open: true, categories: true })

    const handlePrices = () => setshowModal({ open: true, prices: true })

    const getExperiencias = async () => {
        try {
            setLoading(true)
            const res = await axios.get(
                `${BASE_PATH}/experiences/${experienciaid}`
            )
            if (res.status === 200) {
                setExperiencia([res.data])

                const pricesValues = {
                    adult: res.data.quotes[0].prices[0].grossPrice,
                    children: res.data.quotes[0].prices[1].grossPrice,
                    discountPeople: '',
                    discountPrice: '',
                    couponCode: '',
                    couponDiscount: '',
                }
                setNewForm(pricesValues)
                form.setFieldsValue(pricesValues)
                const schedules = await getSchedules(res.data.id)
                setschedulesExist(schedules)
                setselectedCategories(res.data.category)

                const pics = res.data.pictures.map((pic) => {
                    return {
                        uid: Math.random(1, 99999),
                        url: pic.imageUrl || pic.url,
                    }
                })

                setselectedPhotos(pics)

                if (res?.data?.quotes[0]?.prices[0]?.grossPrice > 0) {
                    setVisualizePrices(true)
                } else {
                    setVisualizePrices(false)
                }
            }
        } catch (e) {
            console.error(e)
            MessageUtils.error('Ha ocurrido un error')
        } finally {
            setLoading(false)
        }
    }

    const getSchedules = async (id = experiencia[0]?.id) => {
        try {
            const resp = await axios.get(
                `${BASE_PATH}/schedules?experience=${id}`
            )

            if (resp.status === 200 && resp.data.count > 0) {
                return true
            }
            return false
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error')
            return false
        }
    }

    const changeDraftMode = async () => {
        if (experiencia[0]?.isDraft) {
            const validSchedules = await getSchedules()
            const validCategories = selectedCategories.length > 0
            const validPhotos = selectedPhotos.length > 0
            const validPrices = visualizePrices

            if (!validSchedules) {
                MessageUtils.info(
                    'Debes agregar horarios antes de publicar la experiencia'
                )
                return
            }

            if (!validPrices) {
                MessageUtils.info(
                    'Debes agrgar los precios antes de publicar la experiencia'
                )
                return
            }
            // if (!validCategories) {
            //     MessageUtils.info(
            //         'Debes agregar categorías antes de publicar la experiencia'
            //     )
            //     return
            // }
            // if (!validPhotos) {
            //     message.info(
            //         'Debes agregar fotos antes de publicar la experiencia',
            //         5
            //     )
            //     return
            // }
            if (!MessageUtils) {
                message.info(
                    'Debes agregar precios antes de publicar la experiencia'
                )
                return
            }

            onChangeDraftMode()
        } else {
            onChangeDraftMode()
        }
    }

    const onChangeDraftMode = async () => {
        try {
            setLoading(true)
            const resp = await axios.put(
                `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
                {
                    isDraft: !experiencia[0]?.isDraft,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 200) {
                setExperiencia([resp.data])
                MessageUtils.success(
                    `Se ${experiencia[0]?.isDraft ? 'publicó' : 'pasó a borrador'} con éxito`
                )
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error en el servidor')
        } finally {
            setLoading(false)
        }
    }

    const handleSaveCategories = async () => {
        setLoading(true)
        try {
            const resp = await axios.put(
                `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
                {
                    category: selectedCategories,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 200) {
                setselectedCategories(resp.data.category)
                MessageUtils.success('Se actualizaron las categorias')
                setshowModal({ open: false })
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error(
                'Ha ocurrido un error al actualizar las categorías'
            )
        } finally {
            setLoading(false)
        }
    }

    const handleAddNewPhotos = async () => {
        const filterPhotosPerUpload = selectedPhotos.filter(
            (photo) => photo.originFileObj
        )
        const previousPhotos = selectedPhotos.filter((photo) => photo.url)

        if (filterPhotosPerUpload.length > 0) {
            setLoading(true)
            let uploadedUrls = []
            try {
                for (let photo of filterPhotosPerUpload) {
                    const fdata = new FormData()
                    fdata.append('experiencesId', experiencia[0]?.id)
                    fdata.append('file', photo.originFileObj)
                    try {
                        const imagesUp = await axios.post(
                            `${BASE_PATH}/images/upload-files`,
                            fdata,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: `Bearer ${user.data.token}`,
                                },
                            }
                        )
                        if (imagesUp.status === 200) {
                            uploadedUrls.push(imagesUp.data)
                        }
                    } catch (error) {
                        console.log(error)
                        MessageUtils.error('Error al subir la imagen')
                    }
                }

                if (uploadedUrls.length > 0) {
                    try {
                        const resp = await axios.put(
                            `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
                            {
                                pictures: [...previousPhotos, ...uploadedUrls],
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${user.data.token}`,
                                },
                            }
                        )
                        if (resp.status === 200) {
                            MessageUtils.success('Se actualizaron las fotos')
                            setshowModal({ open: false })
                            getExperiencias()
                        }
                    } catch (error) {
                        console.log(error)
                        MessageUtils.error(
                            'Ha ocurrido un error en el servidor'
                        )
                    }
                }
            } catch (error) {
                console.log(error)
                MessageUtils.error('Ha ocurrido un error en el servidor')
            } finally {
                setLoading(false)
            }
        }
    }

    const handleUpdatePrices = async () => {
        const newPrices = {
            adultPrice: Number(newForm.adult),
            childrenPrice: Number(newForm.children),
            coupon: {
                code:
                    newForm.couponCode && newForm.couponDiscount
                        ? newForm.couponCode
                        : '',
                discount:
                    newForm.couponCode && newForm.couponDiscount
                        ? newForm.couponDiscount
                        : '',
            },
            discount: {
                price:
                    newForm.discountPeople && newForm.discountPrice
                        ? newForm.discountPrice
                        : '',
                people:
                    newForm.discountPeople && newForm.discountPrice
                        ? newForm.discountPeople
                        : '',
            },
        }

        try {
            setLoading(true)
            const resp = await axios.put(
                `${BASE_PATH}/experiences/${experiencia[0]?.id}`,
                {
                    price: newPrices,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.data.token}`,
                    },
                }
            )

            if (resp.status === 200) {
                setExperiencia([resp.data])
                // const pricesValues = {
                //     adult: resp.data.quotes[0].prices[0].grossPrice,
                //     children: resp.data.quotes[0].prices[1].grossPrice,
                //     discountPeople: '',
                //     discountPrice: '',
                //     couponCode: '',
                //     couponDiscount: '',
                // }
                // setNewForm(pricesValues)
                // form.setFieldsValue(pricesValues)
                setVisualizePrices(true)
                MessageUtils.success('Se actualizaron los precios')
                setshowModal({ open: false })
            }
        } catch (error) {
            console.log(error)
            MessageUtils.error('Ha ocurrido un error en el servidor')
        } finally {
            setLoading(false)
        }
    }

    const PopoverEditContent = (
        <div className="popover-edit-content">
            <p>
                <Button
                    block
                    onClick={changeDraftMode}
                    color={experiencia[0]?.isDraft ? 'green' : 'red'}
                    appearance="ghost"
                >
                    {experiencia[0]?.isDraft
                        ? 'Publicar ahora'
                        : 'Pasar a borrador'}
                </Button>
            </p>
            <p>
                <IconButton
                    block
                    onClick={Editar}
                    icon={<Icon icon="check-circle" className="green-color" />}
                >
                    Información
                </IconButton>
            </p>
            <p>
                <IconButton
                    block
                    onClick={Schedules}
                    icon={
                        schedulesExist ? (
                            <Icon icon="check-circle" className="green-color" />
                        ) : (
                            <Icon
                                icon="exclamation-circle"
                                className="red-color"
                            />
                        )
                    }
                >
                    Horarios
                </IconButton>
            </p>
            <p>
                <IconButton
                    block
                    onClick={handleCategories}
                    icon={
                        selectedCategories.length > 0 ? (
                            <Icon icon="check-circle" className="green-color" />
                        ) : (
                            <Icon
                                icon="exclamation-circle"
                                className="red-color"
                            />
                        )
                    }
                >
                    Categorías
                </IconButton>
            </p>
            <p>
                <IconButton
                    block
                    onClick={handlePhotos}
                    icon={
                        selectedPhotos.length > 0 ? (
                            <Icon icon="check-circle" className="green-color" />
                        ) : (
                            <Icon
                                icon="exclamation-circle"
                                className="red-color"
                            />
                        )
                    }
                >
                    Fotos
                </IconButton>
            </p>
            <p>
                <IconButton
                    block
                    onClick={handlePrices}
                    icon={
                        visualizePrices ? (
                            <Icon icon="check-circle" className="green-color" />
                        ) : (
                            <Icon
                                icon="exclamation-circle"
                                className="red-color"
                            />
                        )
                    }
                >
                    Precios
                </IconButton>
            </p>
        </div>
    )

    const evaluateClickActionInDrawer = () => {
        const { categories, photos, prices } = showModal

        if (categories) {
            handleSaveCategories()
        }

        if (photos) {
            handleAddNewPhotos()
        }

        if (prices) {
            handleUpdatePrices()
        }
    }

    useEffect(() => {
        getExperiencias()
    }, [])

    if (loading) {
        return <Spinner />
    }

    return (
        <div>
            <button
                style={{ zIndex: '99999' }}
                type="button"
                className="back-window fixed-top"
                onClick={HomeB}
            >
                <i className="  icon-left-arrow"></i>
            </button>
            <img src={logo} className="logo_in ml-3" alt="logo" />

            <section className="exp-header fixed-top d-none d-md-none d-lg-block">
                <Row>
                    <Col>
                        <div
                            className="d-flex align-items-center"
                            style={{ height: '100vh' }}
                        >
                            <div className="bold exp-titulo exp-container">
                                <Media>
                                    <img
                                        width={40}
                                        height={40}
                                        className="align-self-center mr-3 border-flag rounded-circle"
                                        src={País}
                                        alt="Generic placeholder"
                                    />
                                    <Media.Body>
                                        <h5 className="text-white">
                                            {experiencia.length > 0 ? (
                                                experiencia[0].direction
                                            ) : (
                                                <></>
                                            )}
                                        </h5>
                                    </Media.Body>
                                </Media>
                                <p style={{ fontSize: '70px' }}>
                                    {experiencia.length > 0 ? (
                                        `${experiencia[0].name} `
                                    ) : (
                                        <></>
                                    )}{' '}
                                    <b
                                        className={
                                            experiencia[0]?.isDraft
                                                ? 'red-color'
                                                : 'green-color'
                                        }
                                    >
                                        {experiencia[0]?.isDraft
                                            ? '(borrador)'
                                            : '(publicada)'}
                                    </b>
                                </p>

                                <div
                                    className="review"
                                    style={{ marginBottom: '15rem' }}
                                >
                                    <i
                                        className="icon-star"
                                        style={{
                                            color: '#fe5000',
                                            fontSize: '30px',
                                        }}
                                    ></i>
                                    <text
                                        className="bold"
                                        style={{ fontSize: '30px' }}
                                    >
                                        {experiencia.length > 0 ? (
                                            experiencia[0].rating
                                        ) : (
                                            <></>
                                        )}
                                    </text>
                                </div>

                                <div>
                                    <Row
                                        className="exp-details-about"
                                        style={{ width: '600px' }}
                                    >
                                        <Col className="text-center">
                                            <i
                                                className="icon-face"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Cupo
                                            </p>
                                            <p className="bold">
                                                {experiencia.length > 0 ? (
                                                    experiencia[0].quotas.min
                                                ) : (
                                                    <></>
                                                )}{' '}
                                                -
                                                {experiencia.length > 0 ? (
                                                    experiencia[0].quotas.max
                                                ) : (
                                                    <></>
                                                )}{' '}
                                                personas
                                            </p>
                                        </Col>
                                        <Col className="text-center">
                                            <i
                                                className="icon-time"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Duración
                                            </p>
                                            <p className="bold">
                                                {experiencia.length > 0 ? (
                                                    experiencia[0].duration
                                                ) : (
                                                    <></>
                                                )}
                                            </p>
                                        </Col>
                                        <Col className="text-center">
                                            <i
                                                className="icon-money"
                                                style={{ fontSize: '45px' }}
                                            ></i>
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Duración
                                            </p>
                                            <p className="bold">
                                                Adultos ${' '}
                                                {experiencia.length > 0 ? (
                                                    experiencia[0]?.quotes[0]
                                                        ?.prices[0]?.netPrice ||
                                                    experiencia[0].price
                                                        ?.adultPrice
                                                ) : (
                                                    <></>
                                                )}
                                                , <br />
                                                Niños ${' '}
                                                {experiencia.length > 0 ? (
                                                    experiencia[0]?.quotes[0]
                                                        ?.prices[1]?.netPrice ||
                                                    experiencia[0].price
                                                        ?.childrenPrice
                                                ) : (
                                                    <></>
                                                )}{' '}
                                                <></>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        className="exp-header-pic"
                        lg={4}
                        style={{
                            backgroundImage: `url(${
                                experiencia.length > 0 && selectedPhotos[0]
                                    ? selectedPhotos[0].url
                                    : Background
                            })`,
                        }}
                    ></Col>
                </Row>
                <i className="fi fi-br-angle-down neon_blue icon-font-exp text-center"></i>
            </section>
            <section
                className="exp-header fixed-top d-block d-sm-none d-none d-sm-block d-md-none d-md-block d-lg-none"
                style={{
                    height: '100vh',
                    backgroundImage: `url(${
                        experiencia.length > 0 && selectedPhotos[0]
                            ? selectedPhotos[0].url
                            : Background
                    })`,
                }}
            >
                <div className="bold exp-titulo container-sm">
                    <p className="text-left">
                        {experiencia.length > 0 ? experiencia[0].name : <></>}
                    </p>
                </div>
                <Media
                    className="container"
                    style={{
                        marginTop: '-2rem',
                        position: 'absolute',
                        bottom: '2rem',
                    }}
                >
                    <Media.Body>
                        <h5 className="text-white text-left mt-1">
                            {experiencia.length > 0 ? (
                                experiencia[0].direction
                            ) : (
                                <></>
                            )}
                        </h5>
                    </Media.Body>
                </Media>
            </section>

            <section className="exp-contenido2">
                <Tabs className="container-sm">
                    <div className="sticky-top exp-tabs">
                        <TabLink
                            to="tab1"
                            className="exp-contenido-btn exp-active"
                            activeClassName="neon_blue"
                        >
                            <i className="  icon-details"></i> Info
                        </TabLink>
                        <TabLink
                            to="tab2"
                            className="exp-contenido-btn exp-active"
                            activeClassName="neon_blue"
                        >
                            <i className=" icon-pics"></i>Pics
                        </TabLink>
                        <TabLink
                            to="tab3"
                            className="exp-contenido-btn exp-active"
                            activeClassName="neon_blue"
                        >
                            <i className=" icon-star"></i>
                        </TabLink>
                    </div>
                    <TabContent for="tab1">
                        <ExperienciaDetails
                            data={experienciaid}
                            experience={experiencia}
                        />
                    </TabContent>
                    <TabContent for="tab2" className="exp-galeria">
                        <ExperienciaGaleria photos={selectedPhotos} />
                    </TabContent>
                    <TabContent for="tab3">
                        <ExperienciaReview data={experienciaid} />
                    </TabContent>
                    <TabContent for="tab4"></TabContent>
                </Tabs>
                <>
                    {user.data.user.role !== 'merchant' && (
                        <Popover
                            content={PopoverEditContent}
                            trigger="click"
                            placement="topRight"
                        >
                            <button
                                style={{ zIndex: '1000' }}
                                type="button"
                                className="exp-fixed-bottom-open-host-upload"
                                // onClick={Editar}
                            >
                                <RiEdit2Fill style={{ fontSize: 26 }} />
                            </button>
                        </Popover>
                    )}
                </>
            </section>

            {experiencia[0] && (
                <Drawer
                    placement="bottom"
                    closable={false}
                    show={showModal.open}
                    full={true}
                    style={{ width: '100%', height: '100%' }}
                    backdrop={true}
                    drawerStyle={{ background: '#17191A' }}
                >
                    <nav className="navbar-titles navbar-light d-flex align-items-center ml-3">
                        <button
                            type="button"
                            className="back-window-navbar-drawer"
                            onClick={() => setshowModal({ open: false })}
                        >
                            <i className=" icon-left-arrow"></i>
                        </button>

                        <h5 className="bold">
                            {showModal.categories && '¡Agrega categorías!'}
                            {showModal.photos && '¡Sube tus fotografías!'}
                            {showModal.prices && '¡Ajusta tus precios!'}
                        </h5>
                    </nav>

                    <Drawer.Body>
                        <>
                            <div className="steps-content">
                                {loading && (
                                    <Spinner />
                                    // <Loader
                                    //     backdrop
                                    //     content="Cargando..."
                                    //     vertical
                                    // />
                                )}

                                {showModal.categories && !loading && (
                                    <ExperienciaNewGustos
                                        cats={selectedCategories}
                                        selectCategories={setselectedCategories}
                                    />
                                )}

                                {showModal.photos && (
                                    <ExperienciaNewPhotos
                                        pictures={selectedPhotos}
                                        selectPictures={setselectedPhotos}
                                        experience={experiencia[0]?.id}
                                    />
                                )}

                                {showModal.prices && !loading && (
                                    <ExperienciaNewPagar
                                        form={form}
                                        newForm={newForm}
                                        setNewForm={setNewForm}
                                        onFinish={handleUpdatePrices}
                                    />
                                )}
                            </div>

                            {!showModal.prices && (
                                <div
                                    className="steps-action bold navbar-sm-more-host exp-fixed-bottom"
                                    style={{ width: '0px' }}
                                >
                                    <Button
                                        className="bold exp-pasos btn-primary-host btn-reserva"
                                        type="primary"
                                        onClick={evaluateClickActionInDrawer}
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            )}
                        </>
                    </Drawer.Body>
                </Drawer>
            )}
        </div>
    )
}

export default ExperienciaSubida
