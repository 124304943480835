import React, { useEffect, useState } from 'react'
import axios from 'axios';
import DatePicker from "react-datepicker";
import "../../assets/css/react-datepicker.css";
import * as moment from "moment";
import { BASE_PATH } from '../constants';
const Calendar = () => {
    useEffect(() => {
        getExperiencias()
    }, [])
    const [startDate, setStartDate] = useState(new Date());
    const [experiencia, setExperiencia] = useState([])
    const [prueba, setPrueba] = useState([])
    const getExperiencias = async () => {
        try {
            let res = await axios.get(`${BASE_PATH}/schedules?experience=60d4a36d68cbc7684fa06704`)
            if (res.status === 200) {
                setExperiencia(res.data.rows)
                /*res.data.rows.map(data => {
                    return {date: data.date}
                })*/
                setPrueba(res.data.rows.map(x => x.date))
                //setPrueba(fecha)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div >
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                includeDates={prueba.map(e => new Date(e))}
                inline
            />
        </div>
    )
}

export default Calendar
