import { Avatar, Button } from 'antd'
import React from 'react'
import { TablaAnt } from '../../../../table/TablaAnt'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'

const TablaExperiencias = ({
    data,
    onView,
    onDelete,
    showDelete = true,
    isMerchant = false,
    onMerchantEdition,
}) => {
    const columns = [
        {
            title: (
                <div>
                    <text className="bold titulo_table">Fotografía</text>
                </div>
            ),
            key: 'picture',
            dataIndex: 'picture',
            render: (value) => (
                <Avatar
                    shape="square"
                    size={50}
                    src={value}
                    style={{ borderRadius: '10px' }}
                />
            ),
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">
                        <i class="fi fi-sr-home-heart"></i>Experiencia
                    </text>
                </div>
            ),
            key: 'name',
            dataIndex: 'name',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Ubicación</text>
                </div>
            ),
            key: 'direction',
            dataIndex: 'direction',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Duración</text>
                </div>
            ),
            key: 'duration',
            dataIndex: 'duration',
            sorter: false,
        },
        {
            title: (
                <div>
                    <text className="bold titulo_table">Acciones</text>
                </div>
            ),
            key: 'acciones',
            align: 'right',
            fixed: 'right',
            render: (_, value) => (
                <>
                    {isMerchant && (
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => onMerchantEdition(value)}
                        />
                    )}
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => onView(value)}
                    />

                    {showDelete && (
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete(value.id)}
                        />
                    )}
                </>
            ),
        },
    ]
    return (
        <TablaAnt
            scrollX="max-contend"
            columns={columns}
            data={data}
            loading={false}
            isCheckCell={false}
        />
    )
}

export default TablaExperiencias
